import { Component, OnInit } from "@angular/core";
import { fromEvent, Observable, Subscription } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Piccosupport";
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  countDown = 0;
  connectionStatusMessage: string;
  connectionStatus: string;
  showError: boolean;

  constructor() {}
  ngOnInit() {
    this.onlineEvent = fromEvent(window, "online");
    this.offlineEvent = fromEvent(window, "offline");
    let interval: any;
    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "Back to online";
        this.connectionStatus = "online";
        this.showError = true;
        this.countDown = 3;
        interval = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(interval);
          }
        }, 1000);
        setTimeout(() => {
          this.showError = false;
          window.location.reload();
        }, 3000);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "Connection lost! You are not connected to internet";
        this.connectionStatus = "offline";
        this.showError = true;
      })
    );
  }
  closeNetworkError() {
    this.showError = false;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as actions from '../actions/auth.action';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { MyDownloadsService } from 'src/app/client/mydownloads/mydownloads.service';

@Injectable()
export class AuthEffect {

  /**
   * login effect
   */
  @Effect()
  doLogin$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.DO_LOGIN),
      map((action: actions.DoLoginAction) => action.payload),
      switchMap((state) => {
        return this.api.doLogin(state)
          .pipe(
            map((user) =>
              new actions.DoLoginSuccess(user),
            ),
            tap((res) => {}),
            catchError(error => of(new actions.DoLoginFail(error.error)),
            )
          );
      }
      )
    );
    /**
   * web login effect
   */
  @Effect()
  doWebLogin$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.DO_WEB_LOGIN),
      map((action: actions.DoWebLoginAction) => action.payload),
      switchMap((state) => {
        return this.api.doWebLogin(state)
          .pipe(
            map((user) =>
              new actions.DoWebLoginSuccess(user),
            ),
            tap((res) => {
              if (res) {
                this.router.navigate(['client/dashboard']);
              }
            }),
            catchError(error => of(new actions.DoWebLoginFail(error.error)),
            )
          );
      }
      )
    );
  @Effect({ dispatch: false })
  forgotSuccess$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DO_LOGIN_FAIL),
    map((action: actions.DoLoginFail) => action.payload),
  );
  @Effect()
  forgetPassword$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.FORGET_PASSWORD),
      map((action: actions.ForgetPassword) => action.payload),
      switchMap((state) => {
        return this.api.forgetPassword(state)
          .pipe(
            map((user) =>
              new actions.ForgetPasswordSuccess(user),
            ),
            tap((reset) => {
              if (reset.payload.status === 1) {
                this.toastr.success(reset['payload']['message']);
                this.router.navigate(['/auth/settings/confirmation']);
              }
            }),
            catchError(error => of(new actions.ForgetPasswordFail(error.error))),
          );
      }
      )
    );
  @Effect({ dispatch: false })
  forgotPassWordSuccess$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.FORGET_PASSWORD_FAIL),
    map((action: actions.ForgetPasswordFail) => action.payload),
    tap((res) => {
      if (res['status'] === 0) {
        this.toastr.error(res['message']);
      } else if (res['status'] === 1) {
        this.toastr.success(res['message']);
      }
    }),
  );
  @Effect()
  ResetPassword$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.RESET_PASSWORD),
      map((action: actions.ResetPassword) => action.payload),
      switchMap((state) => {
        return this.api.resetPassword(state)
          .pipe(
            map((user) =>
              new actions.ResetPasswordSuccess(user),
            ),
            tap((reset) => {
              if (reset.payload.status === 1) {
                this.toastr.success(reset['payload']['message']);
                this.router.navigate(['auth/login']);
              }
            }),
            catchError(error => of(new actions.ResetPasswordFail(error.error))),
          );
      }
      )
    );
  @Effect()
  CheckUser$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.CHECK_USER),
      map((action: actions.CheckUser) => action.payload),
      switchMap((state) => {
        return this.api.checkUser(state)
          .pipe(
            map((user) =>
              new actions.CheckUserSuccess(user),
            ),
            tap((reset) => {
            }),
            catchError(error => of(new actions.CheckUserFail(error.error))),
          );
      }
      )
    );

    @Effect()
    doVerifyOtp$: Observable<Action> = this.action$
      .pipe(
        ofType(actions.ActionTypes.VERIFY_OTP),
        map((action: actions.VerifyOtpAction) => action.payload),
        switchMap((state) => {
          return this.api.doVerifyOtp(state)
            .pipe(
              map((user) =>
                new actions.VerifyOtpSuccess(user),
              ),
              tap((res) => {
                if (res) {
                  if (res.payload.status == 1) {
                    const clientEmail = res?.payload?.data?.user?.emailId;
                    this.downloadsService.validateEmail({email: clientEmail}).subscribe(obj=> {
                      if(obj?.status == 1) {
                        localStorage.setItem('purchase', obj?.token);
                      }
                    })
                  }
                   this.router.navigate(['client/dashboard']);
                }
              }),
              catchError(error => of(new actions.VerifyOtpFail(error.error)),
              )
            );
        }
        )
      );



    @Effect()
    doResendOtp$: Observable<Action> = this.action$
      .pipe(
        ofType(actions.ActionTypes.RESEND_OTP),
        map((action: actions.ResendOtpAction) => action.payload),
        switchMap((state) => {
          return this.api.doResendOtp(state)
            .pipe(
              map((user) =>
                new actions.ResendOtpSuccess(user),
              ),
              tap((res) => {
                if (res) {}
              }),
              catchError(error => of(new actions.ResendOtpFail(error.error)),
              )
            );
        }
        )
      );
  constructor(private action$: Actions,
    protected api: AuthService,
    public router: Router,
    public toastr: ToastrService,
    private downloadsService: MyDownloadsService) {
  }

}

import { UserProfile } from "src/app/layout/common/models/user-profile.response.modal";
import * as actions from "../action/settings.action";
import { SettingsState, SettingsStateRecord } from "./settings.state";

export const initialState: SettingsState =
  new SettingsStateRecord() as unknown as SettingsState;

export function reducer(
  state = initialState,
  { type, payload }: any
): SettingsState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get user profile case function
     */
    case actions.ActionTypes.GET_USER_PROFILE:
      return Object.assign({}, state, {
        userProfileLoading: true,
        userProfileLoaded: false,
        userProfileFailed: false,
      });

    case actions.ActionTypes.GET_USER_PROFILE_SUCCESS:
      const userDetail = new UserProfile(payload.data);
      return Object.assign({}, state, {
        userProfile: userDetail,
        userProfileLoading: false,
        userProfileLoaded: true,
        userProfileFailed: false,
      });
    case actions.ActionTypes.GET_USER_PROFILE_FAIL:
      return Object.assign({}, state, {
        userProfileLoading: false,
        userProfileLoaded: true,
        userProfileFailed: false,
      });
    /**
     * update user profile case function
     */
    case actions.ActionTypes.UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        updateuserProfileLoading: true,
        updateuserProfileLoaded: false,
        updateuserProfileFailed: false,
      });

    case actions.ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        updateuserProfile: payload,
        updateuserProfileLoading: false,
        updateuserProfileLoaded: true,
        updateuserProfileFailed: false,
      });

    case actions.ActionTypes.UPDATE_USER_PROFILE_FAIL:
      return Object.assign({}, state, {
        updateuserProfile: payload,
        updateuserProfileLoading: false,
        updateuserProfileLoaded: true,
        updateuserProfileFailed: false,
      });

    /**
     * SUB_USER_LIST
     */
    case actions.ActionTypes.SUB_USER_LIST:
      return Object.assign({}, state, {
        subuserListLoading: true,
        subuserListLoaded: false,
        subuserListFailed: false,
      });

    case actions.ActionTypes.SUB_USER_LIST_SUCCESS:
      return Object.assign({}, state, {
        subuserList: payload.data,
        subuserListLoading: false,
        subuserListLoaded: true,
        subuserListFailed: false,
      });

    case actions.ActionTypes.SUB_USER_LIST_FAIL:
      return Object.assign({}, state, {
        subuserList: payload,
        subuserListLoading: false,
        subuserListLoaded: true,
        subuserListFailed: false,
      });

    /**
     * createSubuser
     */
    case actions.ActionTypes.CREATE_SUB_USER:
      return Object.assign({}, state, {
        createSubuserLoading: true,
        createSubuserLoaded: false,
        createSubuserFailed: false,
      });

    case actions.ActionTypes.CREATE_SUB_USER_SUCCESS:
      return Object.assign({}, state, {
        createSubuser: payload,
        createSubuserLoading: false,
        createSubuserLoaded: true,
        createSubuserFailed: false,
      });

    case actions.ActionTypes.CREATE_SUB_USER_FAIL:
      return Object.assign({}, state, {
        createSubuser: payload,
        createSubuserLoading: false,
        createSubuserLoaded: true,
        createSubuserFailed: false,
      });

    /**
     * updateSubuser
     */
    case actions.ActionTypes.UPDATE_SUB_USER:
      return Object.assign({}, state, {
        updateSubuserLoading: true,
        updateSubuserLoaded: false,
        updateSubuserFailed: false,
      });

    case actions.ActionTypes.UPDATE_SUB_USER_SUCCESS:
      return Object.assign({}, state, {
        updateSubuser: payload,
        updateSubuserLoading: false,
        updateSubuserLoaded: true,
        updateSubuserFailed: false,
      });

    case actions.ActionTypes.UPDATE_SUB_USER_FAIL:
      return Object.assign({}, state, {
        updateSubuser: payload,
        updateSubuserLoading: false,
        updateSubuserLoaded: true,
        updateSubuserFailed: false,
      });

    /**
     * updateSubuserStatus
     */
    case actions.ActionTypes.UPDATE_SUB_USER_STATUS:
      return Object.assign({}, state, {
        updateSubuserStatusLoading: true,
        updateSubuserStatusLoaded: false,
        updateSubuserStatusFailed: false,
      });

    case actions.ActionTypes.UPDATE_SUB_USER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        updateSubuserStatus: payload,
        updateSubuserStatusLoading: false,
        updateSubuserStatusLoaded: true,
        updateSubuserStatusFailed: false,
      });

    case actions.ActionTypes.UPDATE_SUB_USER_STATUS_FAIL:
      return Object.assign({}, state, {
        updateSubuserStatus: payload,
        updateSubuserStatusLoading: false,
        updateSubuserStatusLoaded: true,
        updateSubuserStatusFailed: false,
      });

    /**
     * update user profile case function
     */
    case actions.ActionTypes.DELETE_SUB_USER:
      return Object.assign({}, state, {
        deleteSubuserLoading: true,
        deleteSubuserLoaded: false,
        deleteSubuserFailed: false,
      });

    case actions.ActionTypes.DELETE_SUB_USER_SUCCESS:
      return Object.assign({}, state, {
        deleteSubuser: payload,
        deleteSubuserLoading: false,
        deleteSubuserLoaded: true,
        deleteSubuserFailed: false,
      });

    case actions.ActionTypes.DELETE_SUB_USER_FAIL:
      return Object.assign({}, state, {
        deleteSubuser: payload,
        deleteSubuserLoading: false,
        deleteSubuserLoaded: true,
        deleteSubuserFailed: false,
      });
    default: {
      return state;
    }
  }
}

export const getUserProfile = (state: SettingsState) => state.userProfile;
export const getUserProfileLoading = (state: SettingsState) => state.userProfileLoading;
export const getUserProfileLoaded = (state: SettingsState) => state.userProfileLoaded;
export const getUserProfileFailed = (state: SettingsState) => state.userProfileFailed;

export const updateUserProfile = (state: SettingsState) => state.updateuserProfile;
export const updateUserProfileLoading = (state: SettingsState) => state.updateuserProfileLoading;
export const updateUserProfileLoaded = (state: SettingsState) => state.updateuserProfileLoaded;
export const updateUserProfileFailed = (state: SettingsState) => state.updateuserProfileFailed;

export const subuserList = (state: SettingsState) => state.subuserList;
export const subuserListLoading = (state: SettingsState) => state.subuserListLoading;
export const subuserListLoaded = (state: SettingsState) => state.subuserListLoaded;
export const subuserListFailed = (state: SettingsState) => state.subuserListFailed;

export const createSubuser = (state: SettingsState) => state.createSubuser;
export const createSubuserLoading = (state: SettingsState) => state.createSubuserLoading;
export const createSubuserLoaded = (state: SettingsState) => state.createSubuserLoaded;
export const createSubuserFailed = (state: SettingsState) => state.createSubuserFailed;

export const updateSubuser = (state: SettingsState) => state.updateSubuser;
export const updateSubuserLoading = (state: SettingsState) => state.updateSubuserLoading;
export const updateSubuserLoaded = (state: SettingsState) => state.updateSubuserLoaded;
export const updateSubuserFailed = (state: SettingsState) => state.updateSubuserFailed;

export const updateSubuserStatus = (state: SettingsState) => state.updateSubuserStatus;
export const updateSubuserStatusLoading = (state: SettingsState) => state.updateSubuserStatusLoading;
export const updateSubuserStatusLoaded = (state: SettingsState) => state.updateSubuserStatusLoaded;
export const updateSubuserStatusFailed = (state: SettingsState) => state.updateSubuserStatusFailed;

export const deleteSubuser = (state: SettingsState) => state.deleteSubuser;
export const deleteSubuserLoading = (state: SettingsState) => state.deleteSubuserLoading;
export const deleteSubuserLoaded = (state: SettingsState) => state.deleteSubuserLoaded;
export const deleteSubuserFailed = (state: SettingsState) => state.deleteSubuserFailed;

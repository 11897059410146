export class CreateRequestModel {
  ticketTitle: string;
  ticketDescription: string;
  ticketPriorityId: string;
  projectId: string;
  clientId: string;
  file: any;
  url: string;
  ticketId: any;
  uploadDocuments: any;

  constructor(val: any) {
    console.log('cccc', val);
    
    this.ticketTitle = val.title || '';
    this.ticketDescription = val.description || '';
    this.ticketPriorityId = val.priority || '';
    this.projectId = val.project || '';
    if (val.clientId) {
      this.clientId = val.clientId;
    }
    this.file = val.file || [];
    this.url = val.url || '';
    this.ticketId = val.ticketId || '';
    if (val.uploadDocuments) {
      this.uploadDocuments = val.uploadDocuments || [];
    }
  }
}

import { ChatMessage } from '../../shared-interfaces/message.interface';
import 'rxjs/add/operator/take';
import {
  ServerUserList,
  UserListUpdate,
} from '../../shared-interfaces/server.interface';
import { WebsocketService } from '../websocket.service';

// Socket event names
export const CHAT_MESSAGE_HANDLER = 'chat-message';
export const JOINED_CHANNEL_HANDLER = 'joined-channel';
export const SERVER_UPDATE_USERLIST_HANDLER = 'update-user-list';
export const SERVER_USERLIST_HANDLER = 'server-user-list';

// Handlers for each socket event (functions get called when event received from server)
export const handlers: { [key: string]: (wsService: WebsocketService) => void } = {
  [CHAT_MESSAGE_HANDLER]: chatMessage,
  [SERVER_USERLIST_HANDLER]: serverUserList,
  [SERVER_UPDATE_USERLIST_HANDLER]: updateUserList,
};

function chatMessage(wsService: WebsocketService) {
  wsService.socket.on(CHAT_MESSAGE_HANDLER, (message: ChatMessage) => {
    // --- chat message
  });
}


function serverUserList(wsService: WebsocketService) {
  wsService.socket.on(SERVER_USERLIST_HANDLER, (response: ServerUserList) => {
    // --- server user list
    // wsService.store.dispatch({
    //   type: SERVER_SET_USER_LIST,
    //   payload: response,
    // });
  });
}

function updateUserList(wsService: WebsocketService) {
  wsService.socket.on(SERVER_UPDATE_USERLIST_HANDLER, (response: UserListUpdate) => {
    // --- user list
    // wsService.store.dispatch({
    //   type: SERVER_UPDATE_USER_LIST,
    //   payload: response,
    // });
  });
}

export class PriorityListModel {
  createdBy?: string;
  createdDate?: string;
  id?: string;
  isActive?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  priorityName?: string;
  prioritySlug?: string;

  constructor(val: any) {
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.priorityName = val.priorityName || '';
    this.prioritySlug = val.prioritySlug || '';
  }
}

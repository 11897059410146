import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as actions from '../actions/common.action';
import { CommonService } from '../common.service';
import { ToastrService } from 'ngx-toastr';
import * as store from '../../../app.state';
import * as authActions from './../actions/common.action';
import { TicketListModel } from '../models/ticket-list.model';
import { CommonSandbox } from '../common.sandbox';
import { saveAs } from 'file-saver';



@Injectable()
export class CommonEffects {

  /**
   * project list effect
   */
  @Effect()
  getProject$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PROJECT_LIST),
      map((action: actions.GetProjectList) => action.payload),
      switchMap((state) => {
        return this.api.getProjects(state)
          .pipe(
            map((user) =>
              new actions.GetProjectListSuccess(user),
            ),
            catchError(error => of(new actions.GetProjectListFail(error.error)))
          );
      }
      )
    );
  @Effect()
  // downloadinvoice$: Observable<Action> = this.action$
  //   .pipe(
  //     ofType(actions.ActionTypes.DOWNLOAD_INVOICE),
  //     map((action: actions.DownloadInvoice) => action.payload),
  //     switchMap((state) => {
  //       return this.api.downloadReceipt(state)
  //         .pipe(
  //             tap(data => {
  //             console.log('sssexport', data);
  //             if(data) {
  //             const filename = 'Invoice' + Date.now() + '.pdf';
  //             const blob = new Blob([data], { type: 'application/pdf' });
  //             saveAs(blob, filename);
  //             }
  //           }), 
  //           map((user) =>
  //             new actions.DownloadInvoiceSuccess(user),
  //           ),
  //           catchError(error => of(new actions.GetProjectListFail(error))),
  //         );
  //     })
  //   );

    downloadinvoice$: Observable<Action> = this.action$.pipe(
      ofType(actions.ActionTypes.DOWNLOAD_INVOICE),
      map((action: actions.DownloadInvoice) => action.payload),
      switchMap(state => {
        const invoiceNo = state.invoiceNo;
        delete state.invoiceNo;
        return this.api.downloadReceipt(state).pipe(
          tap(response => {
            this.downloadPdfFile(response, invoiceNo);
          }),
          switchMap(Payments => [
            new actions.DownloadInvoiceSuccess(Payments)
          ]),
          catchError(error => of(new actions.GetProjectListFail(error)))
        );
      })
    );
  /**
* total project list effect
*/
  @Effect()
  getTotalProject$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TOTAL_PROJECT_LIST),
      map((action: actions.GetTotalProjectList) => action.payload),
      switchMap((state) => {
        return this.api.getTotalProjects(state)
          .pipe(
            map((user) =>
              new actions.GetTotalProjectListSuccess(user),
            ),
            catchError(error => of(new actions.GetTotalProjectListFail(error.error)))
          );
      }
      )
    );

  /**
* total project count effect
*/
  @Effect()
  getTotalProjectCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TOTAL_PROJECT_COUNT),
      map((action: actions.GetTotalProjectCount) => action.payload),
      switchMap((state) => {
        return this.api.getTotalProjects(state)
          .pipe(
            map((user) =>
              new actions.GetTotalProjectCountSuccess(user),
            ),
            catchError(error => of(new actions.GetTotalTicketCountFail(error.error)))
          );
      }
      )
    );
  /**
   * priority list effect
   */
  @Effect()
  getPriority$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PRIORITY_LIST),
      map((action: actions.GetPriorityList) => action.payload),
      switchMap((state) => {
        return this.api.getPriority(state)
          .pipe(
            map((user) =>
              new actions.GetPriorityListSuccess(user),
            ),
            catchError(error => of(new actions.GetPriorityListFail(error.error)))
          );
      }
      )
    );
  /**
   * create Request effect
   */
  @Effect()
  createRequest$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.CREATE_REQUEST),
      map((action: actions.CreateRequestAction) => action.payload),
      switchMap((state) => {
        return this.api.createRequest(state)
          .pipe(
            map((user) =>
              new actions.CreateRequestSuccess(user),
            ),
            tap((res) => {
              if (res.payload) {
                this.toastr.success(res.payload.message);
                this.appState.dispatch(new authActions
                  .GetTicketListAction(new TicketListModel({
                    orderBy: 2, refresh: true,
                    offset: 0, clientId: res.payload.data.createdBy
                  })));
              }
            }),
            catchError(error => of(new actions.CreateRequestFail(error.error)))
          );
      }
      )
    );
  /**
 * get status effect
 */
  @Effect()
  getStatus$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TICKET_STATUS_LIST),
      map((action: actions.GetStatusAction) => action.payload),
      switchMap((state) => {
        return this.api.getStatus(state)
          .pipe(
            map((user) =>
              new actions.GetStatusSuccess(user),
            ),
            catchError(error => of(new actions.GetStatusFail(error.error)))
          );
      }
      )
    );
  /**
    * get ticket status change effect
    */
  @Effect()
  ticketStatusChange$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.TICKET_STATUS_CHANGE),
      map((action: actions.GetTicketStatusChange) => action.payload),
      switchMap((state) => {
        return this.api.getTicketStatusChange(state)
          .pipe(
            map((user) =>
              new actions.GetTicketStatusChangeSuccess(user),
            ),
            tap(response => {
              this.toastr.success(response.payload.message);
              this.commonSandbox.getTicketDetail({id: state.ticketId});
            }),
            catchError(error => of(new actions.GetTicketStatusChangeFail(error.error)))
          );
      }
      )
    );
  /**
   * implementation ticket list effect
   */
  @Effect()
  implimentTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TICKET_LIST),
      map((action: actions.GetTicketListAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetTicketListSuccess(user),
            ),
            catchError(error => of(new actions.GetTicketListFail(error.error)))
          );
      }
      )
    );
  /**
   * implementation ticket count effect
   */
  @Effect()
  implimentTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TICKET_COUNT),
      map((action: actions.GetTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetTicketCountFail(error.error)))
          );
      }
      )
    );
  // reviewTicketList
  @Effect()
  reviewTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_REVIEW_TICKET_LIST),
      map((action: actions.GetReviewTicketsList) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetReviewTicketsListSuccess(user),
            ),
            catchError(error => of(new actions.GetReviewTicketsListFail(error.error)))
          );
      }
      )
    );

  /**
   * review ticket count effect
   */
  @Effect()
  reviewTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_REVIEW_TICKET_COUNT),
      map((action: actions.GetReviewTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetReviewTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetReviewTicketCountFail(error.error)))
          );
      }
      )
    );
  /**
   * schedule tickets effect
   */
  @Effect()
  scheduleTickets$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SCHEDULE_TICKETS),
      map((action: actions.GetScheduleTicketsAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetScheduleTicketsSuccess(user),
            ),
            catchError(error => of(new actions.GetScheduleTicketsFail(error.error)))
          );
      }
      )
    );
  /**
   * schedule ticket count effect
   */
  @Effect()
  scheduleTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SCHEDULE_TICKET_COUNT),
      map((action: actions.GetScheduleTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetScheduleTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetScheduleTicketCountFail(error.error)))
          );
      }
      )
    );
  // 

 /**
   * get approved ticket list count.
   */
  @Effect()
  approvedTicketListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_APPROVED_TICKET_LIST_COUNT),
      map((action: actions.GetApprovedTicketListCount) => action.payload),
      switchMap((state) => {
        return this.api.approvedTicketListCount(state)
          .pipe(
            map((user) =>
              new actions.GetApprovedTicketListCountSuccess(user),
            ),
            catchError(error => of(new actions.GetApprovedTicketListCountFail(error.error)))
          );
      }
      )
    );
  // 


  @Effect()
  closeTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_CLOSURE_TICKET_LIST),
      map((action: actions.GetClosureTicketList) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetClosureTicketListSuccess(user),
            ),
            catchError(error => of(new actions.GetClosureTicketListFail(error.error)))
          );
      }
      )
    );

  /**
   * closure ticket count effect
   */
  @Effect()
  closeTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_CLOSURE_TICKET_COUNT),
      map((action: actions.GetClosureTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetClosureTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetClosureTicketCountFail(error.error)))
          );
      }
      )
    );
  /**
  * total ticket count effect
  */
  @Effect()
  totalTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TOTAL_TICKET_COUNT),
      map((action: actions.GetTotalTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetTotalTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetTotalTicketCountFail(error.error)))
          );
      }
      )
    );
  /**
   * total total ticket  effect
   */
  @Effect()
  totalTicket$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TOTAL_TICKET),
      map((action: actions.GetTotalTicketAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetTotalTicketSuccess(user),
            ),
            catchError(error => of(new actions.GetTotalTicketFail(error.error)))
          );
      }
      )
    );

  /**
   * free ticket count effect
   */
  @Effect()
  freeTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_FREE_TICKET_COUNT),
      map((action: actions.GetFreeTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetFreeTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetFreeTicketCountFail(error.error)))
          );
      }
      )
    );
  @Effect()
  approveTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_APPROVE_TICKET_LIST),
      map((action: actions.GetApproveTicketsList) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetApproveTicketsListSuccess(user),
            ),
            catchError(error => of(new actions.GetApproveTicketsListFail(error.error)))
          );
      }
      )
    );
  /**
   * approval ticket count effect
   */
  @Effect()
  approvalTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_APPROVAL_COUNT),
      map((action: actions.GetApprovalCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetApprovalCountSuccess(user),
            ),
            catchError(error => of(new actions.GetApprovalCountFail(error.error)))
          );
      }
      )
    );
  /**
 * raised ticket count effect
 */
  @Effect()
  raisedTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_RAISED_TICKET_COUNT),
      map((action: actions.GetRaisedTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetRaisedTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetRaisedTicketCountFail(error.error)))
          );
      }
      )
    );
  /**
  * ticket detail effect
  */
  @Effect()
  ticketsDetail$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TICKET_DETAIL),
      map((action: actions.GetTicketDetailAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketDetail(state)
          .pipe(
            map((user) =>
              new actions.GetTicketDetailSuccess(user),
            ),
            catchError(error => of(new actions.GetTicketDetailFail(error.error)))
          );
      }
      )
    );
  /**
* user profile effect
*/
  @Effect()
  changePassword$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.CHANGE_PASSWORD),
      map((action: actions.ChangePasswordAction) => action.payload),
      switchMap((state) => {
        return this.api.changePassword(state)
          .pipe(
            map((user) =>
              new actions.ChangePasswordSuccess(user),
            ),
            catchError(error => of(new actions.ChangePasswordActionFail(error.error)))
          );
      }
      )
    );


  /**
 * payment list effect
 */
  @Effect()
  paymentList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PAYMENTS_LIST),
      map((action: actions.GetPaymentList) => action.payload),
      switchMap((state) => {
        console.log('paymentStatus', state);
        if (state.paymentWayType === 'listType') {
          return this.api.getPaymentList(state)
            .pipe(
              map((user) =>
                new actions.GetPaymentListSuccess(user, 'listType'),
              ),
              catchError(error => of(new actions.GetPaymentListFail(error.error)))
            );
        } else {
          console.log('elsepart');

          return this.api.getPaymentListWithGroupBy(state)
            .pipe(
              map((user) =>
                new actions.GetPaymentListSuccess(user, ''),
              ),
              catchError(error => of(new actions.GetPaymentListFail(error.error)))
            );
        }
      }
      )
    );

  /**
* payment list Count effect
*/
  @Effect()
  paymentListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PAYMENTS_LIST_COUNT),
      map((action: actions.GetPaymentListCount) => action.payload),
      switchMap((state) => {
        console.log('count');
        return this.api.getPaymentList(state)
          .pipe(
            map((user) =>
              new actions.GetPaymentListCountSuccess(user),
            ),
            catchError(error => of(new actions.GetPaymentListCountFail(error.error)))
          );
      }
      )
    );


  /**
* INVOICE Details effect
*/
  @Effect()
  invoiceDetails$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_INVOICE_DETAILS),
      map((action: actions.GetInvoiceDetails) => action.payload),
      switchMap((state) => {
        return this.api.getInvoiceDetails(state)
          .pipe(
            map((user) =>
              new actions.GetInvoiceDetailsSuccess(user),
            ),
            catchError(error => of(new actions.GetInvoiceDetailsFail(error.error)))
          );
      }
      )
    );
  /**
* INVOICE Details effect
*/
  @Effect()
  paymentDetails$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PAYMENTS_DETAILS),
      map((action: actions.GetPaymentDetails) => action.payload),
      switchMap((state) => {
        return this.api.getPaymentDetails(state)
          .pipe(
            map((user) =>
              new actions.GetPaymentDetailsSuccess(user),
            ),
            catchError(error => of(new actions.GetPaymentDetailsFail(error.error)))
          );
      }
      )
    );
    
  /**
   * payment effect
   */
  @Effect()
  paymentLog$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_PAYMENT_LOG),
      map((action: actions.PaymentLog) => action.payload),
      switchMap((state) => {
        return this.api.paymentLog(state)
          .pipe(
            map((user) =>
              new actions.PaymentLogSuccess(user),
            ),
            catchError(error => of(new actions.PaymentLogFail(error.error)))
          );
      }
      )
    );

  /**
  * online resources list effect
  */
  @Effect()
  onlineresourcesTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.ONLINE_RESOURCES_LIST),
      map((action: actions.GetOnlineResourcesListAction) => action.payload),
      switchMap((state) => {
        return this.api.onlineResourcesList(state)
          .pipe(
            map((user) =>
              new actions.GetOnlineResourcesListSuccess(user),
            ),
            tap((res) => {
              this.toastr.success(res['payload']['message']);
            }),
            catchError(error => of(new actions.GetOnlineResourcesListFailure(error.error)))
          );
      }
      )
    );
  /**
* assign resources list effect
*/
  @Effect()
  assignedresourcesTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.ASSIGNED_RESOURCES_LIST),
      map((action: actions.GetAssignedResourcesListAction) => action.payload),
      switchMap((state) => {
        return this.api.assignedResourcesList(state)
          .pipe(
            map((user) =>
              new actions.GetAssignedResourcesListSuccess(user),
            ),
            catchError(error => of(new actions.GetAssignedResourcesListFailure(error.error)))
          );
      }
      )
    );
  /**
* get ticket status change effect
*/
  @Effect()
  ticketDecline$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.TICKET_DECLINE),
      map((action: actions.GetTicketDecline) => action.payload),
      switchMap((state) => {
        return this.api.getTicketDecline(state)
          .pipe(
            map((user) =>
              new actions.GetTicketDeclineSuccess(user),
            ),
            tap(response => {
              this.toastr.success(response.payload.message);
            }),
            catchError(error => of(new actions.GetTicketDeclineFail(error.error)))
          );
      }
      )
    );

  // updateTicketList
  @Effect()
  updateTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_TICKET_UPDATE_LIST),
      map((action: actions.GetTicketUpdateList) => action.payload),
      switchMap((state) => {
        return this.api.updateTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetTicketUpdateListSuccess(user),
            ),
            tap(response => {
              // this.toastr.success(response.payload.message);
            }),
            catchError(error => of(new actions.GetTicketUpdateListFailure(error.error)))
          );
      }
      )
    );

  /**
* open ticket list  effect
*/
  @Effect()
  openTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_OPEN_TICKET_LIST),
      map((action: actions.GetOpenTicketListAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetOpenTicketListActionSuccess(user),
            ),
            catchError(error => of(new actions.GetOpenTicketListActionFail(error.error)))
          );
      }
      )
    );
  // Open Ticket List Count                
  @Effect()
  openTicketListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_OPEN_TICKET_LIST_COUNT),
      map((action: actions.GetOpenTicketListCount) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetOpenTicketListCountSuccess(user),
            ),
            catchError(error => of(new actions.GetOpenTicketListCountFail(error.error)))
          );
      }
      )
    );

  // inprogress TicketList

  @Effect()
  inprogressTicketListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_INPROGRESS_TICKET_LIST),
      map((action: actions.GetInProgressTicketListAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetInProgressTicketListActionSuccess(user),
            ),
            catchError(error => of(new actions.GetInProgressTicketListActionFail(error.error)))
          );
      }
      )
    );
  @Effect()
  acceptanceTicketList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_ACCEPTANCE_TICKETS),
      map((action: actions.GetAcceptanceTickets) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetAcceptanceTicketsSuccess(user),
            ),
            catchError(error => of(new actions.GetAcceptanceTicketsFail(error.error)))
          );
      }
      )
    );

  /**
   * closure ticket count effect
   */
  @Effect()
  acceptanceTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT),
      map((action: actions.GetAcceptanceTicketsCount) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetAcceptanceTicketsCountSuccess(user),
            ),
            catchError(error => of(new actions.GetAcceptanceTicketsCountFail(error.error)))
          );
      }
      )
    );

  @Effect()
  sideScheduleTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT),
      map((action: actions.GetSideScheduleTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetSideScheduleTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetSideScheduleTicketCountFail(error.error)))
          );
      }
      )
    );
  @Effect()
  sideOpenTicketListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT),
      map((action: actions.GetSideOpenTicketListCount) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetSideOpenTicketListCountSuccess(user),
            ),
            catchError(error => of(new actions.GetSideOpenTicketListCountFail(error.error)))
          );
      }
      )
    );

  @Effect()
  sideInprogressTicketListCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST),
      map((action: actions.GetSideInProgressTicketListAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetSideInProgressTicketListActionSuccess(user),
            ),
            catchError(error => of(new actions.GetSideInProgressTicketListActionFail(error.error)))
          );
      }
      )
    );
  // sideClosureCount
  @Effect()
  sideCloseTicketCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT),
      map((action: actions.GetSideClosureTicketCountAction) => action.payload),
      switchMap((state) => {
        return this.api.getTicketList(state)
          .pipe(
            map((user) =>
              new actions.GetSideClosureTicketCountSuccess(user),
            ),
            catchError(error => of(new actions.GetSideClosureTicketCountFail(error.error)))
          );
      }
      )
    );
  // UPLOAD_DOCUMENTS    
  @Effect()
  uploadDocuments$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.UPLOAD_DOCUMENTS),
      map((action: actions.UploadDocumets) => action.payload),
      switchMap((state) => {
        return this.api.uploadDocuments(state)
          .pipe(
            map((user) =>
              new actions.UploadDocumetsSuccess(user),
            ),
            catchError(error => of(new actions.UploadDocumetsFail(error.error)))
          );
      }
      )
    );
  // DELETE_DOCUMENTS
  @Effect()
  deleteDocuments$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.DELETE_DOCUMENTS),
      map((action: actions.DeleteDocumets) => action.payload),
      switchMap((state) => {
        return this.api.deleteDocuments(state)
          .pipe(
            map((user) =>
              new actions.DeleteDocumetsSuccess(user),
            ),
            catchError(error => of(new actions.DeleteDocumetsFail(error.error)))
          );
      }
      )
    );
  // OfflinePayment
  @Effect()
  offlinePayment$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.OFFLINE_PAYMENT),
      map((action: actions.OfflinePayment) => action.payload),
      switchMap((state) => {
        return this.api.updatePayments(state)
          .pipe(
            map((user) =>
              new actions.OfflinePaymentSuccess(user),
            ),
            tap(response => {
              this.toastr.success(response.payload.message);
            }),
            catchError(error => of(new actions.OfflinePaymentFail(error.error)))
          );
      }
      )
    );
    // SEND_MESSAGE_DATA
  @Effect()
  sendMessageData$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.SEND_MESSAGE_DATA),
      map((action: actions.sendMessage) => action.payload),
      switchMap((state) => {
        return this.api.sendMessage(state)
          .pipe(
            map((user) =>
              new actions.sendMessageSuccess(user,state),
            ),
            tap(response => {
                const params: any = {}
                params.limit = 4;
                params.offset = 0;
                params.count = 0;
              this.toastr.success(response.payload.message);
              if(state.detail.check == 1 || state.detail.check  == 3){
                params.ticketId = state.detail.ticketId;                
                this.commonSandbox.getMessageList(params);
              }else if(state.detail.check == 2 || state.detail.check  == 4){
                params.ticketConversationId = state.detail.ticketConversationId;
                this.commonSandbox.getRepliedBaseMessage(params);
                params.ticketId = state.detail.ticketId;
                this.commonSandbox.getMessageList(params);
              }
              
            }),
            catchError(error => of(new actions.sendMessageFail(error.error)))
          );
      }
      )
    );
      // GET_MESSAGE_LIST
  @Effect()
  getMessageList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_MESSAGE_LIST),
      map((action: actions.getMessageList) => action.payload),
      switchMap((state) => {
        return this.api.getMessageList(state)
          .pipe(
            map((user) =>
              new actions.getMessageListSuccess(user),              
            ),
            catchError(error => of(new actions.getMessageListFail(error.error)))
          );
      }
      )
    );

         // GET_MESSAGE_LIST_COUNT
  @Effect()
  getRepliedBaseMessageCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_MESSAGE_LIST_COUNT),
      map((action: actions.getRepliedBaseMessageCount) => action.payload),
      switchMap((state) => {
        return this.api.getRepliedBaseMessageCount(state)
          .pipe(
            map((user) =>
              new actions.getRepliedBaseMessageCountSuccess(user),              
            ),
            catchError(error => of(new actions.getRepliedBaseMessageCountFail(error.error)))
          );
      }
      )
    );
        // GET_REPLIEDBASEMESSAGE_LIST
  @Effect()
  getRepliedBaseMessageList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_REPLIEDBASEMESSAGE_LIST),
      map((action: actions.getRepliedBaseMessage) => action.payload),
      switchMap((state) => {
        return this.api.getRepliedBaseMessage(state)
          .pipe(
            map((user) =>
              new actions.getRepliedBaseMessageSuccess(user,state),              
            ),
            tap(response => {
              // const params: any = {}
              // params.limit = 4;
              // params.offset = 0;
              // params.ticketId = state.ticketId;
              // params.count = 0;
              // this.commonSandbox.getMessageList(params);
            }),
            catchError(error => of(new actions.getRepliedBaseMessageFail(error.error)))
          );
      }
      )
    );

    // DELETE_MESSAGE
  @Effect()
  deleteMessage$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.DELETE_MESSAGE),
      map((action: actions.deleteMessage) => action.payload),
      switchMap((state) => {
        return this.api.deleteMessage(state)
          .pipe(
            map((user) =>
              new actions.deleteMessageSuccess(user),            
            ),
            tap(response => {
              console.log(state);
              const params: any = {}
              params.limit = 4;
              params.offset = 0;
              params.count = 0;
              params.ticketId = state.ticketId;
              if(state.data.ticketId){                                                
                this.commonSandbox.getMessageList(params);
              }else {
                this.commonSandbox.getMessageList(params);
                params.ticketConversationId = state.data.ticketConversationId;
                this.commonSandbox.getRepliedBaseMessage(params);
              }
            }),
            catchError(error => of(new actions.deleteMessageFail(error.error)))
          );
      }
      )
    );
     // UNDO_APPROVAL
  @Effect()
  undoApproval$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.UNDO_APPROVAL),
      map((action: actions.undoApproval) => action.payload),
      switchMap((state) => {
        return this.api.undoApproval(state).pipe(
            map((user) =>{
              console.log('user', user);
              
               this.appState.select('common').subscribe(sub => {
            if (sub.scheduleList) {
              sub.scheduleList.map(update => {
                if (update.id === user.data.id) {
                  update.clientsApprovalStatus =
                    user.data.clientsApprovalStatus;
                }
                return update;
              });
            }
            if (sub.ticketDetail) {
              sub.ticketDetail.clientsApprovalStatus =
                user.data.clientsApprovalStatus;
            }
            return sub.ticketDetail;
              });
              return new actions.undoApprovalSuccess(user);
            }),
            catchError(error => of(new actions.undoApprovalFail(error.error)))
          );
      }
      )
    );
  // startWork
    @Effect()
  startWork$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.START_WORK),
      map((action: actions.StartWork) => action.payload),
      switchMap((state) => {
        return this.api.startWork(state)
          .pipe(
            map((user) =>
              new actions.StartWorkSuccess(user),
            ),
            catchError(error => of(new actions.StartWorkFail(error.error)))
          );
      }
      )
    );
  // MobileNotificationList
     @Effect()
  mobilenotificationList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.MOBILE_NOTIFICATION_LIST),
      map((action: actions.MobileNotificationList) => action.payload),
      switchMap((state) => {
        return this.api.mobileNotificationList(state)
          .pipe(
            map((user) =>
              new actions.MobileNotificationListSuccess(user),
            ),
            catchError(error => of(new actions.MobileNotificationListFail(error.error)))
          );
      }
      )
    );
    // getMessageList
    @Effect()
    getMessageListData$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_MESSAGE_LIST_MOBILE),
      map((action: actions.GetMessageListMobile) => action.payload),
      switchMap((state) => {
        return this.api.getMessageListMobile(state)
          .pipe(
            map((user) =>
              new actions.GetMessageListMobileSuccess(user),
            ),
            catchError(error => of(new actions.GetMessageListMobileFail(error.error)))
          );
      }
      )
    );


     @Effect()
     clientCancelRequest$: Observable<Action> = this.action$
     .pipe(
       ofType(actions.ActionTypes.CLIENT_CANCEL_REQUEST),
       map((action: actions.ClientCancelRequest) => action.payload),
       switchMap((state) => {
         return this.api.clientCancelRequest(state)
           .pipe(
             map((user) =>
               new actions.ClientCancelRequestSuccess(user),
             ),
             catchError(error => of(new actions.ClientCancelRequestFail(error.error)))
           );
       }
       )
     );

     @Effect()
     orderDetails$: Observable<Action> = this.action$
       .pipe(
         ofType(actions.ActionTypes.GET_ORDER_DETAILS),
         map((action: actions.GetOrderDetailsAction) => action.payload),
         switchMap((state) => {
           return this.api.getOrderDetails(state)
             .pipe(
               map((user) =>
                 new actions.GetOrderDetailsSuccess(user),
               ),
               catchError(error => of(new actions.GetOrderDetailsFailure(error.error)))
             );
         }
         )
       );

  @Effect()
  updatePayment$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.UPDATE_PAYMENT),
      map((action: actions.UpdatePayment) => action.payload),
      switchMap((state) => {
        return this.api.updatePayments(state)
          .pipe(
            map((user) =>
              new actions.UpdatePaymentSuccess(user),
            ),
            catchError(error => of(new actions.UpdatePaymentFailure(error.error)))
          );
      }
      )
    );


  @Effect()
  UploadProof$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.UPLOAD_PROOF),
      map((action: actions.UploadProof) => action.payload),
      switchMap((state) => {
        return this.api.uploadProof(state)
          .pipe(
            map((user) =>
              new actions.UploadProofSuccess(user),
            ),
            catchError(error => of(new actions.UploadProofFailure(error.error)))
          );
      }
      )
    );


    @Effect()
    export$: Observable<Action> = this.action$
      .pipe(
        ofType(actions.ActionTypes.EXPORT),
        map((action: actions.Export) => action.payload),
        switchMap((state) => {
          return this.api.export(state)
            .pipe(
              tap(data => {
                const filename = 'OrderExcel_' + Date.now() + '.xlsx';
                const blob = new Blob([data], { type: 'text/xlsx' });
                saveAs(blob, filename);
              }),
              map((user) =>
                new actions.ExportSuccess(user),
              ),
              catchError(error => of(new actions.ExportFailure(error.error)))
            );
        }
        )
      );


      @Effect()
      orderExport$: Observable<Action> = this.action$
        .pipe(
          ofType(actions.ActionTypes.ORDER_EXPORT),
          map((action: actions.OrderExport) => action.payload),
          switchMap((state) => {
            return this.api.orderExport(state)
              .pipe(
                tap(data => {
                  const filename = 'OrderExcel_' + Date.now() + '.xlsx';
                  const blob = new Blob([data], { type: 'text/xlsx' });
                  saveAs(blob, filename);
                }),
                map((user) =>
                  new actions.OrderExportSuccess(user),
                ),
                catchError(error => of(new actions.OrderExportFailure(error.error)))
              );
          }
          )
        )

        @Effect()
        assessmentList$: Observable<Action> = this.action$
        .pipe(
          ofType(actions.ActionTypes.ASSESSMENT_LIST),
          map((action: actions.AssessmentList) => action.payload),
          switchMap((state) => {
            return this.api.assessmentList(state)
              .pipe(
                map((user) =>
                  new actions.AssessmentListSuccess(user),
                ),
                catchError(error => of(new actions.AssessmentListFail(error.error)))
              );
          }
          )
        );
        @Effect()
        clientApproval$: Observable<Action> = this.action$
        .pipe(
          ofType(actions.ActionTypes.CLIENT_APPROVAL),
          map((action: actions.ClientApproval) => action.payload),
          switchMap((state) => {
            return this.api.clientApproval(state)
              .pipe(
                map((user) =>
                  new actions.ClientApprovalSuccess(user),
                ),
                catchError(error => of(new actions.ClientApprovalFail(error.error)))
              );
          }
          )
        );

        @Effect()
        sidebarCount$: Observable<Action> = this.action$
        .pipe(
          ofType(actions.ActionTypes.SIDEBAR_COUNT),
          map((action: actions.sidebarCount) => action.payload),
          switchMap((state) => {
            return this.api.sidebarCount(state)
              .pipe(
                map((user) =>
                  new actions.sidebarCountSuccess(user),
                ),
                catchError(error => of(new actions.sidebarCountFail(error.error)))
              );
          }
          )
        );
  constructor(private action$: Actions,
    public commonSandbox: CommonSandbox,
    protected api: CommonService,
    public router: Router,
    private toastr: ToastrService,
    protected appState: Store<store.AppState>,

  ) {
  }

  downloadPdfFile(base64content: string, orderPrefixId: string) {
    const fileName = orderPrefixId.toUpperCase() + '-' + new Date();
    const blobData = this.convertBase64PDFToBlobData(base64content);
    let newVariable: any = window.navigator;
    if (newVariable && newVariable.msSaveOrOpenBlob) { // IE
      newVariable.msSaveOrOpenBlob(blobData, fileName);
    } else { // chrome
      const blob = new Blob([blobData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    }
  }
  convertBase64PDFToBlobData(base64Data: string, contentType: string = 'application/pdf', sliceSize = 512) {
    const byteCharacters = atob(base64Data.replace(/^data:([A-Za-z-+\/]+);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }



}



import {Component, OnInit, ViewChild, ChangeDetectionStrategy, OnDestroy, ElementRef} from '@angular/core';
import {NgbActiveModal, NgbDropdown, NgbDropdownConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
// import {AdminSandbox} from 'src/app/admin/admin.sandbox';
import {PaymentsSandbox} from '../../../client/payments/payments.sandbox';
import {CommonSandbox} from '../../../layout/common/common.sandbox';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs/index';
import { InvoiceComponent } from '../invoice/invoice.component';

@Component({
    selector: 'app-make-payment',
    templateUrl: './make-payment.component.html',
    styleUrls: ['./make-payment.component.scss'],
    providers: [NgbDropdownConfig, NgbDropdown],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class MakePaymentComponent implements OnInit, OnDestroy {
    @ViewChild('NgbDropdown', {static:false, read: NgbDropdown}) dropdown: NgbDropdown;
    isOffline: boolean;
    payMode: any;
    offlineForm: FormGroup;
    submitted: boolean;
    payment: any;
    today: any;
    isOnline: boolean;
    paymentStatus: any;
    makePayment = false;
    onlinePay: boolean;
    nativeWindow: any;
    private subscriptions: Array<Subscription> = [];

    constructor(public modal: NgbActiveModal, public modalService: NgbModal,
         public commonSandbox: CommonSandbox,
                private datePipe: DatePipe,
                private fb: FormBuilder, public paymentSandbox: PaymentsSandbox) {
        const dateVal = datePipe.transform(new Date, 'dd/MM/yyyy').split('/');
        this.today = {day: +dateVal[0], month: +dateVal[1], year: +dateVal[2]};
    }

    ngOnInit() {
        this.isOffline = false;
        this.isOnline = false;
        this.submitted = false;
        this.initOfflineForm();
        this.getPaymentDetail();
this.subscriptions.push(this.commonSandbox.paymentDetailsLoaded$.subscribe(detail => {
if (detail === true) {
    this.commonSandbox.paymentDetails$.subscribe(data => {
        if (data) {
        const params: any = {};
        params.id = data.id;
        this.commonSandbox.getPaymentLog(params);
        }
    });
}
}));

    }
    public initOfflineForm(): void {
        this.offlineForm = this.fb.group({
            paymentType: ['', Validators.required],
            transactionDetails: ['', Validators.required],
        });
    }
    getPaymentDetail() {
        const params: any = {};
        params.id = this.payment.id;
        this.commonSandbox.getPaymentDetails(params);
        this.subscriptions.push(this.commonSandbox.paymentDetails$.subscribe(detail => {
            if (detail) {
                if (detail.paymentMode === 1) {
                    this.isOffline = true;
                    this.isOnline = false;

                } else if (detail.paymentMode === 0) {
                    this.isOffline = false;
                    this.isOnline = true;

                }
            }
        }));
    }

    chooseMode(mode) {
        if (mode === 'Offline') {
            this.isOffline = true;
            this.isOnline = false;
            this.onlinePay = false;
        } else if (mode === 'Online') {
            this.isOffline = false;
            this.isOnline = true;
        }
    }

    close() {
        this.subscriptions.forEach(each => {
            each.unsubscribe();
        });
        this.modal.close();
    }
    makeOnlinePayment() {
        this.onlinePay = true;
        const params: any = {};
        params.paymentId = this.payment.id;
        // this.paymentSandbox.payPalPayment(params);
    }

    openInvoice(data) {
        if (data) {
            const modalRef = this.modalService.open(InvoiceComponent, {
                size: 'lg',
                windowClass: 'invoicemodal'
            });
            modalRef.componentInstance.invoiceId = data.id;
        }
    }
    submitInfo(form) {
        this.submitted = true;
        if (this.offlineForm.controls['paymentType'].value === 1) {
        }
        if (!form.valid) {
            return;
        }
        const tempForm = form.value;
        if (this.isOffline) {
            tempForm.paymentMode = 1;
        }
        if (this.isOnline) {
            tempForm.paymentMode = 0;
        }
        tempForm.depositDate = new Date;
        tempForm.depositAmount = this.payment.netAmount;
        tempForm.paymentId = this.payment.id;

        // this.paymentSandbox.offlinePayment(tempForm);
        // this.paymentSandbox.offlineLoaded$.subscribe(loaded => {
        //     if (loaded) {
        //         this.close();
        //     }
        // });
    }
    continuePay(id) {
        this.paymentStatus = id;
    }
    closeDetails() {
        this.dropdown.close();
    }
ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
}
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from '../action/notification.action';
import { NotificationService } from '../notification.service';

@Injectable()
export class NotificationEffects {
    /**
     * get admin notify count  effect
     */
    /**
* notification count effect
*/
  @Effect()
  notificationCount$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_NOTIFICATION_COUNT),
      map((action: actions.GetNotificationCount) => action.payload),
      switchMap((state) => {
        return this.api.getNotificationCount(state)
          .pipe(
            map((user) =>
              new actions.GetNotificationCountSuccess(user),
            ),
            catchError(error => of(new actions.GetNotificationCountFail(error.error)))
          );
      }
      )
    );

    /**
   * notification list effect
   */
  @Effect()
  notificationList$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_NOTIFICATION_LIST),
      map((action: actions.GetNotificationList) => action.payload),
      switchMap((state) => {
        return this.api.getNotificationList(state)
          .pipe(
            map((user) =>
              new actions.GetNotificationListSuccess(user),
            ),
            catchError(error => of(new actions.GetNotificationListFail(error.error)))
          );
      }
      )
    );

     /**
* notification detail effect
*/
  @Effect()
  notificationDetail$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.GET_NOTIFICATION_DETAIL),
      map((action: actions.GetNotificationDetail) => action.payload),
      switchMap((state) => {
        return this.api.getNotificationDetail(state)
          .pipe(
            map((user) =>
              new actions.GetNotificationDetailSuccess(user),
            ),
            catchError(error => of(new actions.GetNotificationDetailFail(error.error)))
          );
      }
      )
    );
  /**
* change notification status effect
*/
  @Effect()
  notificationStatus$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.CHANGE_NOTIFICATION_STATUS),
      map((action: actions.ChangeNotificationStatus) => action.payload),
      switchMap((state) => {
        return this.api.changeNotification(state)
          .pipe(
            map((user) =>
              new actions.ChangeNotificationStatusSuccess(user),
            ),
            catchError(error => of(new actions.ChangeNotificationStatusFail(error.error)))
          );
      }
      )
    );

  /**
   * change notification status as unread effect
   */
  @Effect()
  notificationUnread$: Observable<Action> = this.action$
    .pipe(
      ofType(actions.ActionTypes.NOTIFICATION_UNREAD),
      map((action: actions.NotificationUnread) => action.payload),
      switchMap((state) => {
        return this.api.notificationUnread(state)
          .pipe(
            map((user) =>
              new actions.NotificationUnreadSuccess(user),
            ),
            catchError(error => of(new actions.NotificationUnreadFail(error.error)))
          );
      }
      )
    );

    constructor(private action$: Actions,
        protected api: NotificationService) {
    }
}

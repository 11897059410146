import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-popup-modal',
  templateUrl: './delete-popup-modal.component.html',
  styleUrls: ['./delete-popup-modal.component.scss']
})
export class DeletePopupModalComponent implements OnInit {

  message: string
  constructor(public activeModal:NgbActiveModal) { }

  ngOnInit() {
  }
 
 close() {
    this.activeModal.close();
  }

  logout(){
    this.activeModal.close('deleted');
  }
}

import { Action } from '@ngrx/store';
import { type } from '../../shared/utility';
import { ResponseInterface } from '../../shared/interfaces/interface';

export const ActionTypes = {

    RESOURCE_NOTIFY_COUNT: type('[notify] resource notify count'),
    RESOURCE_NOTIFY_COUNT_SUCCESS: type('[notify] resource notify count Success'),
    RESOURCE_NOTIFY_COUNT_FAIL: type('[notify] resource notify count Fail'),
};

/**
 * get admin notification count actions.
 */
export class ResourceNotifyCount implements Action {
    type = ActionTypes.RESOURCE_NOTIFY_COUNT;

    constructor(public payload: any) {
    }
}

export class ResourceNotifyCountSuccess implements Action {
    type = ActionTypes.RESOURCE_NOTIFY_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ResourceNotifyCountFail implements Action {
    type = ActionTypes.RESOURCE_NOTIFY_COUNT_FAIL;

    constructor(public payload: any) {
    }
}


export type Actions =

    | ResourceNotifyCount
    | ResourceNotifyCountSuccess
    | ResourceNotifyCountFail;



import { Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth.guard";
import { CONTAINERS } from "./layout/common/common.module";
import { ServerComponent } from "./server-error/server.component";
import { MobileNotificationComponent } from "./mobile-notification/mobile-notification.component";
import { MobileNotificationUrlComponent } from "./mobile-notification-url/mobile-notification-url.component";

export const appRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "server-error",
    component: ServerComponent,
  },
  {
    path: ":id",
    component: MobileNotificationUrlComponent,
  },
  {
    path: "conversation/:id",
    component: MobileNotificationComponent,
  },
  {
    path: "",
    component: CONTAINERS.CommonComponent,
    children: [
      {
        path: "client",
        canActivate: [AuthGuard],

        data: {
          role: 2,
        },
        loadChildren: () =>
          import("./client/client.module").then((m) => m.ClientModule),
      },
    ],
  },
];

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyLength'
})
export class KeyLengthPipe implements PipeTransform {
  transform(obj: any): number {
    if (obj === null || obj === undefined) {
        return 0;
      }
    return Object.keys(obj).length;
  }
}

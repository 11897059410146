import { Map, Record } from 'immutable';
import { TicketListResponseModel } from 'src/app/layout/common/models/ticket-list-response.model';

export interface OpenTicketState extends Map<string, any> {

  // ticketsList: Array<TicketListResponseModel>;
  // ticketsListLoading: boolean;
  // ticketsListLoaded: boolean;
  // ticketsListFailed: boolean;

}

export const openticketStateRecord = Record({

  // ticketsList: [],
  // ticketsListLoading: false,
  // ticketsListLoaded: false,
  // ticketsListFailed: false,
});


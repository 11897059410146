import { Map, Record } from 'immutable';
import { ProjectListResponse, TotalProjectListResponse } from '../models/project-list.response';
import { PriorityListModel } from '../models/priority-list.model';
import { StatusResponseModel } from '../models/status-response.model';
// import { OrderListResponseModal } from 'src/app/client/payments/models/order-list-response-model';
import { TicketListResponseModel } from '../models/ticket-list-response.model';
import { ActivityDetailModel } from '../models/activity-detail.model';
import { PaymentDetailResponse } from '../models/payment-detail-response.model';

export interface CommonState extends Map<string, any> {

    projectList: Array<ProjectListResponse>;
    projectListLoading: boolean;
    projectListLoaded: boolean;
    projectListFailed: boolean;

    assessmentList: any;
    assessmentListLoading: boolean;
    assessmentListLoaded: boolean;
    assessmentListFailed: boolean;

    totalprojectList: Array<TotalProjectListResponse>;
    totalprojectListLoading: boolean;
    totalprojectListLoaded: boolean;
    totalprojectListFailed: boolean;

    totalprojectCount: Array<TotalProjectListResponse>;
    totalprojectCountLoading: boolean;
    totalprojectCountLoaded: boolean;
    totalprojectCountFailed: boolean;

    priorityList: Array<PriorityListModel>;
    priorityListLoading: boolean;
    priorityListLoaded: boolean;
    priorityListFailed: boolean;

    statusList: Array<StatusResponseModel>;
    statusListLoading: boolean;
    statusListLoaded: boolean;
    statusListFailed: boolean;

    createRequestLoading: boolean;
    createRequestLoaded: boolean;
    createRequestFailed: boolean;

    pendingPaymentAmount: Number;
    paymentList: Array<any>;
    paymentListLoading: boolean;
    paymentListLoaded: boolean;
    paymentListFailed: boolean;

    TicketStatusChange: any;
    TicketStatusChangeLoading: boolean;
    TicketStatusChangeLoaded: boolean;
    TicketStatusChangeFailed: boolean;

    TicketDecline: any;
    TicketDeclineLoading: boolean;
    TicketDeclineLoaded: boolean;
    TicketDeclineFailed: boolean;

    uploadProof: any;
    uploadProofLoading: boolean;
    uploadProofLoaded: boolean;
    uploadProofFailed: boolean;

    ticketList: Array<TicketListResponseModel>;
    ticketListLoading: boolean;
    ticketListLoaded: boolean;
    ticketListFailed: boolean;

    ticketCount: number;
    ticketCountLoading: boolean;
    ticketCountLoaded: boolean;
    ticketCountFailed: boolean;

    reviewTicketList: any;
    reviewTicketListLoading: boolean;
    reviewTicketListLoaded: boolean;
    reviewTicketListFailed: boolean;

    reviewCount: number;
    reviewCountLoading: boolean;
    reviewCountLoaded: boolean;
    reviewCountFailed: boolean;

    scheduleCount: number;
    scheduleCountLoading: boolean;
    scheduleCountLoaded: boolean;
    scheduleCountFailed: boolean;

    scheduleList: Array<TicketListResponseModel>;
    scheduleListLoading: boolean;
    scheduleListLoaded: boolean;
    scheduleListFailed: boolean;

    totalCount: number;
    totalCountLoading: boolean;
    totalCountLoaded: boolean;
    totalCountFailed: boolean;

    orderDetails: any;
    orderDetailsLoading: boolean;
    orderDetailsLoaded: boolean;
    orderDetailsFailed: boolean;

    totalTicket: Array<any>;
    totalTicketLoading: boolean;
    totalTicketLoaded: boolean;
    totalTicketFailed: boolean;

    approveTicketList: Array<any>;
    approveTicketListLoading: boolean;
    approveTicketListLoaded: boolean;
    approveTicketListFailed: boolean;

    approvalCount: number;
    approvalCountLoading: boolean;
    approvalCountLoaded: boolean;
    approvalCountFailed: boolean;

    freeCount: number;
    freeCountLoading: boolean;
    freeCountLoaded: boolean;
    freeCountFailed: boolean;

    closureList: any;
    closureListLoading: boolean;
    closureListLoaded: boolean;
    closureListFailed: boolean;

    closureCount: number;
    closureCountLoading: boolean;
    closureCountLoaded: boolean;
    closureCountFailed: boolean;

    raisedCount: any;
    raisedCountLoading: boolean;
    raisedCountLoaded: boolean;
    raisedCountFailed: boolean;

    ticketDetail: ActivityDetailModel;
    ticketDetailLoading: boolean;
    ticketDetailLoaded: boolean;
    ticketDetailFailed: boolean;

    changePassword: any;
    changePasswordLoading: boolean;
    changePasswordLoaded: boolean;
    changePasswordFailed: boolean;

    invoiceDetails: any;
    invoiceCompanyDetails: any;
    invoiceDetailsLoading: boolean;
    invoiceDetailsLoaded: boolean;
    invoiceDetailsFail: boolean;

    paymentDetails: PaymentDetailResponse;
    paymentDetailsLoading: boolean;
    paymentDetailsLoaded: boolean;
    paymentDetailsFailed: boolean;

    paymentListCount: Number;
    paymentListCountLoading: boolean;
    paymentListCountLoaded: boolean;
    paymentListCountFailed: boolean;

    paymentLog: any;
    paymentLogLoading: boolean;
    paymentLogLoaded: boolean;
    paymentLogFailed: boolean;
    invoiceNumber: any;

    getOnlineResourcesList: any;
    getOnlineResourcesListLoading: boolean;
    getOnlineResourcesListLoaded: boolean;
    getOnlineResourcesListFailed: boolean;

    getAssignedResourcesList: Array<any>;
    getAssignedResourcesLength: number;
    getAssignedResourcesListLoading: boolean;
    getAssignedResourcesListLoaded: boolean;
    getAssignedResourcesListFailed: boolean;

    invoiceLoading: boolean;
    invoiceLoaded: boolean;
    invoiceFailed: boolean;

    updateTicketList: any;
    updateTicketListLoaded: boolean;
    clientUptateTicketList: any;

    openTicketList: any;
    openTicketListLoading: boolean;
    openTicketListLoaded: boolean;
    openTicketListFailed: boolean;

    openTicketListCount: any;
    openTicketListCountLoading: boolean;
    openTicketListCountLoaded: boolean;
    openTicketListCountFailed: boolean;

    clientApproval: any;
    clientApprovalLoading: boolean;
    clientApprovalLoaded: boolean;
    clientApprovalFailed: boolean;

    inprogressTicketList: any;
    inprogressTicketListLoading: boolean;
    inprogressTicketListLoaded: boolean;
    inprogressTicketListFailed: boolean;

    approvedTicketListCount: number;
    approvedTicketListCountLoading: boolean;
    approvedTicketListCountLoaded: boolean;
    approvedTicketListCountFailed: boolean;

    acceptanceTicketList: any;
    acceptanceTicketListLoading: boolean;
    acceptanceTicketListLoaded: boolean;
    acceptanceTicketListFailed: boolean;

    updatePayment: any;
    updatePaymentLoading: boolean;
    updatePaymentLoaded: boolean;
    updatePaymentFailed: boolean;

    acceptanceTicketListCount: any;
    acceptanceTicketListCountLoading: boolean;
    acceptanceTicketListCountLoaded: boolean;
    acceptanceTicketListCountFailed: boolean;

    sideScheduleCount: Number;
    sideOpenTicketListCount: any;
    sideInprogressTicketListCount: any;
    sideClosureCount: any;

    offlinePayment: any;
    offlinePaymentLoading: boolean;
    offlinePaymentLoaded: boolean;
    offlinePaymentFailed: boolean;

    clientCancelRequest: any;
    clientCancelRequestLoading: boolean;
    clientCancelRequestLoaded: boolean;
    clientCancelRequestFailed: boolean;

    sendMessageResponse: any;
    sendMessageLoading: any;
    loadingIndex: any;

    messageListResponse: any;
    messageListLoading: any;
    messageTicketDetails: any;

    messageListCount: any;

    repliedBaseMessage: any;
    repliedBaseMessageLoading: any;

    deleteMessage: any;

    // assignresourceList: any;

    undoApproval: any;

    startWork: any;

    mobileNotificationList: any;
    getMessageList: any;

    sidebarCount: any;
    sidebarCountLoading: boolean;
    sidebarCountLoaded: boolean;
    sidebarCountFailed: boolean;
}

export const commonStateRecord = Record({
    invoiceNumber: '',
    projectList: [],
    projectListLoading: false,
    projectListLoaded: false,
    projectListFailed: false,

    totalprojectList: [],
    totalprojectListLoading: false,
    totalprojectListLoaded: false,
    totalprojectListFailed: false,

    totalprojectCount: [],
    totalprojectCountLoading: false,
    totalprojectCountLoaded: false,
    totalprojectCountFailed: false,

    priorityList: [],
    priorityListLoading: false,
    priorityListLoaded: false,
    priorityListFailed: false,

    statusList: [],
    statusListLoading: false,
    statusListLoaded: false,
    statusListFailed: false,

    orderDetails: {},
    orderDetailsLoading: false,
    orderDetailsLoaded: false,
    orderDetailsFailed: false,

    createRequestLoading: false,
    createRequestLoaded: false,
    createRequestFailed: false,

    pendingPaymentAmount: 0,
    paymentList: {},
    paymentListLoading: false,
    paymentListLoaded: false,
    paymentListFailed: false,

    TicketStatusChange: {},
    TicketStatusChangeLoading: false,
    TicketStatusChangeLoaded: false,
    TicketStatusChangeFailed: false,

    TicketDecline: {},
    TicketDeclineLoading: false,
    TicketDeclineLoaded: false,
    TicketDeclineFailed: false,

    ticketList: [],
    ticketListLoading: false,
    ticketListLoaded: false,
    ticketListFailed: false,

    ticketCount: 0,
    ticketCountLoading: false,
    ticketCountLoaded: false,
    ticketCountFailed: false,

    reviewTicketList: [],
    reviewTicketListLoading: false,
    reviewTicketListLoaded: false,
    reviewTicketListFailed: false,

    reviewCount: 0,
    reviewCountLoading: false,
    reviewCountLoaded: false,
    reviewCountFailed: false,

    totalCount: 0,
    totalCountLoading: false,
    totalCountLoaded: false,
    totalCountFailed: false,

    totalTicket: [],
    totalTicketLoading: false,
    totalTicketLoaded: false,
    totalTicketFailed: false,

    freeCount: 0,
    freeCountLoading: false,
    freeCountLoaded: false,
    freeCountFailed: false,

    approveTicketList: [],
    approveTicketListLoading: false,
    approveTicketListLoaded: false,
    approveTicketListFailed: false,

    approvalCount: 0,
    approvalCountLoading: false,
    approvalCountLoaded: false,
    approvalCountFailed: false,

    closureList: [],
    closureListLoading: false,
    closureListLoaded: false,
    closureListFailed: false,

    closureCount: 0,
    closureCountLoading: false,
    closureCountLoaded: false,
    closureCountFailed: false,

    scheduleCount: 0,
    scheduleCountLoading: false,
    scheduleCountLoaded: false,
    scheduleCountFailed: false,

    scheduleList: 0,
    scheduleListLoading: false,
    scheduleListLoaded: false,
    scheduleListFailed: false,

    raisedCount: 0,
    raisedCountLoading: false,
    raisedCountLoaded: false,
    raisedCountFailed: false,

    userProfile: {},
    userProfileLoading: false,
    userProfileLoaded: false,

    updateuserProfile: {},
    updateuserProfileLoading: false,
    updateuserProfileLoaded: false,

    changePassword: {},
    changePasswordLoading: false,
    changePasswordLoaded: false,
    changePasswordFailed: false,

    invoiceDetails: {},
    invoiceCompanyDetails: {},
    invoiceDetailsLoading: false,
    invoiceDetailsLoaded: false,
    invoiceDetailsFail: false,

    paymentDetails: {},
    paymentDetailsLoading: false,
    paymentDetailsLoaded: false,
    paymentDetailsFailed: false,

    paymentListCount: 0,
    paymentListCountLoading: false,
    paymentListCountLoaded: false,
    paymentListCountFailed: false,

    uploadProof: {},
    uploadProofLoading: false,
    uploadProofLoaded: false,
    uploadProofFailed: false,

    paymentLog: {},
    paymentLogLoading: false,
    paymentLogLoaded: false,
    paymentLogFailed: false,

    invoiceLoading: false,
    invoiceLoaded: false,
    invoiceFailed: false,

    getOnlineResourcesList: [],
    getOnlineResourcesListLoading: false,
    getOnlineResourcesListLoaded: false,
    getOnlineResourcesListFailed: false,

    getAssignedResourcesList: [],
    getAssignedResourcesLength: 0,
    getAssignedResourcesListLoading: false,
    getAssignedResourcesListLoaded: false,
    getAssignedResourcesListFailed: false,

    updateTicketList: [],
    updateTicketListLoaded: false,
    clientUptateTicketList: [],

    openTicketList: [],
    openTicketListLoading: false,
    openTicketListLoaded: false,
    openTicketListFailed: false,

    openTicketListCount: [],
    openTicketListCountLoading: false,
    openTicketListCountLoaded: false,
    openTicketListCountFailed: false,

    inprogressTicketList: [],
    inprogressTicketListLoading: false,
    inprogressTicketListLoaded: false,
    inprogressTicketListFailed: false,


    acceptanceTicketList: [],
    acceptanceTicketListLoading: false,
    acceptanceTicketListLoaded: false,
    acceptanceTicketListFailed: false,

    acceptanceTicketListCount: [],
    acceptanceTicketListCountLoading: false,
    acceptanceTicketListCountLoaded: false,
    acceptanceTicketListCountFailed: false,


    approvedTicketListCount: {},
    approvedTicketListCountLoading: false,
    approvedTicketListCountLoaded: false,
    approvedTicketListCountFailed: false,

    sideScheduleCount: {},
    sideOpenTicketListCount: {},
    sideInprogressTicketListCount: {},
    sideClosureCount: {},


    offlinePayment: {},
    offlinePaymentLoading: false,
    offlinePaymentLoaded: false,
    offlinePaymentFailed: false,

    updatePayment: {},
    updatePaymentLoading: false,
    updatePaymentLoaded: false,
    updatePaymentFailed: false,

    clientCancelRequest: {},
    clientCancelRequestLoading: false,
    clientCancelRequestLoaded: false,
    clientCancelRequestFailed: false,

    assessmentList: {},
    assessmentListLoading: false,
    assessmentListLoaded: false,
    assessmentListFailed: false,

    clientApproval: {},
    clientApprovalLoading: false,
    clientApprovalLoaded: false,
    clientApprovalFailed: false,


    sendMessageResponse: [],
    sendMessageLoading: [],
    loadingIndex: [],

    messageListResponse: [],
    messageListLoading: [],
    messageTicketDetails: {},

    messageListCount: [],

    repliedBaseMessage: [],
    repliedBaseMessageLoading: [],

    deleteMessage: [],

    ticketDetail: {},
    // assignresourceList: [],
    undoApproval: {},
    startWork: false,
    mobileNotificationList: {},
    getMessageList: {},

    sidebarCount: {},
    sidebarCountLoading: false,
    sidebarCountLoaded: false,
    sidebarCountFailed: false,
});

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentPath: any;
  constructor() { }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('clientDetail'));
    if (userData.userGroupDetails.slug == "superadmin" || userData.userGroupDetails.slug == "admin" || userData.userGroupDetails.slug == "delivery_manager") {
      this.currentPath = ['/admin/activity'];
    }
    if (userData.userGroupDetails.slug == "client") {
      this.currentPath = ['/client/dashboard'];
    }
    if (userData.userGroupDetails.slug == "developer") {
      this.currentPath = ['/developer/dashboard'];
    }
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OnlyNumberDirective} from './only-number.directive';
import { NumericDecimalDirective } from './only-number-decimal.directive';

@NgModule({

    declarations: [ OnlyNumberDirective, NumericDecimalDirective ],
    imports: [ CommonModule ],
    exports: [ OnlyNumberDirective , NumericDecimalDirective]
})

export class NumberAcceptModule {
}

import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthSandbox } from "src/app/auth/auth.sandbox";
import { WebsocketService } from "src/app/shared/chat/services/websocket.service";
import { Config } from "protractor/built/config";
import {
  EDeveloperConnect,
  EDownloadsAndUpgrades,
  ESupportTicket,
} from "./login.constant";
import {
  IDeveloperConnect,
  IDownloadsAndUpgrades,
  ILoginDetail,
  ILoginParam,
  ISupportTicket,
} from "./login.model";
import { NgOtpInputComponent } from "ng-otp-input";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  // constant value Start
  supportTicketContent: ISupportTicket = ESupportTicket;
  downloadsAndUpgrades: IDownloadsAndUpgrades = EDownloadsAndUpgrades;
  developerConnect: IDeveloperConnect = EDeveloperConnect;
  // constant value End
  counter: number = 0;
  public loginForm: FormGroup;
  public submitted = false;
  public username: string;
  private otp: string = "";
  public loginDetail: ILoginDetail;
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
  };
  @ViewChild("ngOtpInput") ngOtpInput: NgOtpInputComponent | undefined;
  @ViewChild("firstField", { static: true }) initialField: ElementRef;
  emailRegexp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  message: any;

  constructor(
    private fb: FormBuilder,
    private webService: WebsocketService,
    public authSandbox: AuthSandbox,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.initLoginForm();
  }

  public initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailRegexp)]],
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{10}$")],
      ],
      tabset: ["email"],
    });
  }

  public doLogin(): void {
    this.submitted = true;
    if (this.loginForm.value.tabset == "email") {
      this.loginForm.controls["email"].setValidators([Validators.required, Validators.pattern(this.emailRegexp)]);
      this.loginForm.controls["email"].updateValueAndValidity();
      this.loginForm.controls["mobileNumber"].clearValidators();
      this.loginForm.controls["mobileNumber"].updateValueAndValidity();
    }
    if (this.loginForm.value.tabset == "mobile") {
      this.loginForm.controls["mobileNumber"].setValidators([Validators.required, Validators.pattern("^[0-9]{10}$")]);
      this.loginForm.controls["mobileNumber"].updateValueAndValidity();
      this.loginForm.controls["email"].clearValidators();
      this.loginForm.controls["email"].updateValueAndValidity();
    }
    if (!this.loginForm.valid) {
      return;
    }
    const param: ILoginParam = {
      login:
        this.loginForm.value.tabset == "email"
          ? this.loginForm.value.email
          : this.loginForm.value.mobileNumber,
    };
    this.authSandbox.doLogin(param);
    this.authSandbox.getLoginSuccess$.subscribe((data: any) => {
      if (data && data.status == 1) {
        this.loginDetail = data.data;
        this.username = data.loginMode == 1 ? data.data.emailId : data.data.mobileNo;
        this.message = data.message
        this.counter = 1;
        this.submitted = false;
        this.loginForm.reset();
        this.loginForm.clearValidators();
      }
    });

    this.authSandbox.loginFailed$.subscribe(async (data: any) => {
      if (data === true) {
        this.submitted = false;
        this.loginForm.reset();
        this.loginForm.clearValidators();
        this.loginForm.controls["tabset"].setValue("email");
      }
    });
    this.authSandbox.getLoginSuccess$.subscribe(async (val: any) => {
      if (val) {
        await this.connectToSocket();
      }
    });
  }
  async connectToSocket() {
    const connected = await this.webService.connect().toPromise();
    if (!connected) {
    }
  }

  onPrev(): void {
    this.counter = 0;
    this.submitted = false;
    this.loginForm.reset();
    this.loginForm.clearValidators();
    this.loginForm.controls["tabset"].setValue("email");
    this.username = "";
  }

  onOtpChange(event: string): void {
    this.otp = event;
    this.submitted = true;
  }
  otpVerify(): void {
    if (JSON.parse(JSON.stringify(this.otp)).length !== 6) {
      return;
    }
    const params: ILoginParam = {};
    params.otp = this.otp;
    params.id = this.loginDetail.id;
    this.authSandbox.doVerifyOtp(params);
    this.authSandbox.verifyOtpFailed$.subscribe((val) => {
      if (val == true) {
        this.otp = "";
        this.ngOtpInput.setValue("");
      }
    });
  }
  resendOtp(): void {
    console.log('1414',this.loginDetail)
    if (this.ngOtpInput) {
      this.otp = "";
      this.ngOtpInput.setValue("");
      const param: ILoginParam = {};
      param.login = this.username;
      this.authSandbox.doLogin(param);
    }
  }
}

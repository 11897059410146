import * as actions from '../actions/payments.action';
import { PaymentsState, paymentsStateRecord } from './payments.state';


export const initialState: PaymentsState = new paymentsStateRecord() as unknown as PaymentsState;

export function reducer(state = initialState, { type, payload }: any): PaymentsState {
  if (!type) {
    return state;
  }

  switch (type) {

    // Payment Dashboard Count
    case actions.ActionTypes.PAYMENTS_DASHBOARD_COUNT:
      return Object.assign({}, state, {
        paymentDashboardCountLoading: true,
        paymentDashboardCountLoaded: false,
        paymentDashboardCountFailed: false
      });
    case actions.ActionTypes.PAYMENTS_DASHBOARD_COUNT_SUCCESS:
      return Object.assign({}, state, {
        paymentDashboardCount: payload.data,
        paymentDashboardCountLoading: false,
        paymentDashboardCountLoaded: true,
        paymentDashboardCountFailed: false,
      });

    case actions.ActionTypes.PAYMENTS_DASHBOARD_COUNT_FAIL:

      return Object.assign({}, state, {
        paymentDashboardCountLoading: false,
        paymentDashboardCountLoaded: false,
        paymentDashboardCountFailed: true,

      });

    // Recent Payments Dues
    case actions.ActionTypes.RECENT_PAYMENT_DUES:
      return Object.assign({}, state, {
        recentPaymentDuesLoading: true,
        recentPaymentDuesLoaded: false,
        recentPaymentDuesFailed: false
      });
    case actions.ActionTypes.RECENT_PAYMENT_DUES_SUCCESS:
      return Object.assign({}, state, {
        recentPaymentDues: payload.data?.paymentsList ? payload.data?.paymentsList : [],
        recentPaymentDuesLoading: false,
        recentPaymentDuesLoaded: true,
        recentPaymentDuesFailed: false,
      });

    case actions.ActionTypes.RECENT_PAYMENT_DUES_FAIL:

      return Object.assign({}, state, {
        recentPaymentDuesLoading: false,
        recentPaymentDuesLoaded: false,
        recentPaymentDuesFailed: true,

      });

    // GET_PAYMENTS_HISTORY_LIST
    case actions.ActionTypes.GET_PAYMENTS_HISTORY_LIST:
      return Object.assign({}, state, {
        paymentHistoryList: [],
        paymentHistoryListLoading: true,
        paymentHistoryListLoaded: false,
        paymentHistoryListFailed: false
      });
    case actions.ActionTypes.GET_PAYMENTS_HISTORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        paymentHistoryList: payload.data?.paymentsList ? payload.data?.paymentsList : [],
        paymentHistoryListLoading: false,
        paymentHistoryListLoaded: true,
        paymentHistoryListFailed: false,
      });

    case actions.ActionTypes.GET_PAYMENTS_HISTORY_LIST_FAIL:

      return Object.assign({}, state, {
        paymentHistoryListLoading: false,
        paymentHistoryListLoaded: false,
        paymentHistoryListFailed: true,

      });

    /**
     * Payment Order List
     */
    case actions.ActionTypes.PAYMENTS_ORDER_LIST:

      return Object.assign({}, state, {
        paymentOrderList: [],
        paymentOrderListLoading: true,
        paymentOrderListLoaded: false,
        paymentOrderListFailed: false
      });

    case actions.ActionTypes.PAYMENTS_ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        paymentOrderList: payload.data?.ordersList ? payload.data?.ordersList : [],
        paymentOrderListLoading: false,
        paymentOrderListLoaded: true,
        paymentOrderListFailed: false,
      });

    case actions.ActionTypes.PAYMENTS_ORDER_LIST_FAIL:
      return Object.assign({}, state, {
        paymentOrderListLoading: false,
        paymentOrderListLoaded: false,
        paymentOrderListFailed: true,
      });

    // PAYMENTS_HISTORY_LIST_COUNT
    case actions.ActionTypes.PAYMENTS_HISTORY_LIST_COUNT:
      return Object.assign({}, state, {
        paymentHistoryListCount: []
      });

    case actions.ActionTypes.PAYMENTS_HISTORY_LIST_COUNT_SUCCESS:
      return Object.assign({}, state, {
        paymentHistoryListCount: payload.data['paymentsListCount'],
      });

    case actions.ActionTypes.PAYMENTS_HISTORY_LIST_COUNT_FAIL:
      return Object.assign({}, state, {
      });

    /**
         * get order List Count reducer case function
         */
    case actions.ActionTypes.GET_ORDER_LIST_COUNT:
      return Object.assign({}, state, {
          orderListCount:[],
          orderListCountLoading: true,
          orderListCountLoaded: false,
          orderListCountFailed: false
      });
  case actions.ActionTypes.GET_ORDER_LIST_COUNT_SUCCESS:
      return Object.assign({}, state, {
          orderListCount: payload.data.ordersListCount,
          orderListCountLoading: false,
          orderListCountLoaded: true,
          orderListCountFailed: false
      });
  case actions.ActionTypes.GET_ORDER_LIST_COUNT_FAIL:
      return Object.assign({}, state, {
          orderListCountLoading: false,
          orderListCountLoaded: true,
          orderListCountFailed: true
      });



    default: {
      return state;
    }
  }
}

export const paymentDashboardCount = (state: PaymentsState) => state.paymentDashboardCount;
export const paymentDashboardCountLoading = (state: PaymentsState) => state.paymentDashboardCountLoading;
export const paymentDashboardCountLoaded = (state: PaymentsState) => state.paymentDashboardCountLoaded;
export const paymentDashboardCountFailed = (state: PaymentsState) => state.paymentDashboardCountFailed;

export const recentPaymentDues = (state: PaymentsState) => state.recentPaymentDues;
export const recentPaymentsDuesLoading = (state: PaymentsState) => state.recentPaymentDuesLoading;
export const recentPaymentsDuesLoaded = (state: PaymentsState) => state.recentPaymentDuesLoaded;
export const recentPaymentsDuesFailed = (state: PaymentsState) => state.recentPaymentDuesFailed;

export const paymentHistoryList = (state: PaymentsState) => state.paymentHistoryList;
export const paymentHistoryListLoading = (state: PaymentsState) => state.paymentHistoryListLoading;
export const paymentHistoryListLoaded = (state: PaymentsState) => state.paymentHistoryListLoaded;
export const paymentHistoryListFailed = (state: PaymentsState) => state.paymentHistoryListFailed;

export const paymentOrderList = (state: PaymentsState) => state.paymentOrderList;
export const paymentOrderListLoading = (state: PaymentsState) => state.paymentOrderListLoading;
export const paymentOrderListLoaded = (state: PaymentsState) => state.paymentOrderListLoaded;
export const paymentOrderListFailed = (state: PaymentsState) => state.paymentOrderListFailed;

export const paymentHistoryListCount = (state: PaymentsState) => state.paymentHistoryListCount;

export const getOrderListCount = (state: PaymentsState) => state.orderListCount;
export const getOrderListCountLoading = (state: PaymentsState) => state.orderListCountLoading;
export const getOrderListCountLoaded = (state: PaymentsState) => state.orderListCountLoaded;
export const getOrderListCountFailed = (state: PaymentsState) => state.orderListCountFailed;
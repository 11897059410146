import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MustMatch } from '../../validators/confirm.password';
import { SettingsSandbox } from 'src/app/settings/settings.sandbox';

@Component({
  selector: 'app-changeemail',
  templateUrl: './changeemail.component.html',
  styleUrls: ['./changeemail.component.scss']
})
export class ChangeemailComponent implements OnInit {
  updateEmail: FormGroup;
  submitted: boolean = false;
  clientId: number;
  editProfileParam: any;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder, private settingsSandbox: SettingsSandbox, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.updateEmailForm();
  }

  updateEmailForm(){
    this.updateEmail = this.fb.group({
      newEmail: ['', [Validators.required,Validators.pattern(this.emailPattern)]],
      confirmEmail: ['',[Validators.required,Validators.pattern(this.emailPattern)]]
  }, {
      validator: MustMatch('newEmail', 'confirmEmail')
  });
  }

  public dismiss() {
    this.activeModal.close();
  }
  submit() {
    this.submitted = true
    if(this.updateEmail.invalid){
      return
    }
      this.editProfileParam.emailId = this.updateEmail.value.confirmEmail;
      this.editProfileParam.sendOTPflag = 1;
      this.editProfileParam.id = this.clientId;

    this.settingsSandbox.updateProfile(this.editProfileParam);
    this.settingsSandbox.updateUserProfile$.subscribe((data: any) => {
      if (data && data.status == 1) {
        this.activeModal.close(data);
      }
    });
  }

}

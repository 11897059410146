import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/client/dashboard",
    title: "Dashboard",
    image: "assets/images/Dashboard.svg",
    activeImage: "assets/images/Dashboard-white.svg",
    class: "",
    role: 2,
    disabled: false,
    countData: false,
  },

  {
    path: "",
    title: "Create",
    image: "assets/images/new-cr.svg",
    activeImage: "assets/images/new-cr-white.svg",
    class: "",
    role: 2,
    disabled: false,
    countData: false,
  },
  {
    path: "/client/approvals",
    title: "Estimate",
    image: "assets/images/estimationblack.svg",
    activeImage: "assets/images/estimationwhite.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: true,
  },
  {
    path: "/client/openticket",
    title: "Open Ticket",
    image: "assets/images/openticketblack.svg",
    activeImage: "assets/images/openticketwhite.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: true,
  },
  {
    path: "/client/inprogress",
    title: "Ticket Status",
    image: "assets/images/inprogressblack.svg",
    activeImage: "assets/images/inprogresswhite.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: true,
  },
  // {
  //   path: "/client/chat",
  //   title: "Chat",
  //   image: "assets/images/chat-tag.svg",
  //   activeImage: "assets/images/chat-tag-white.svg",
  //   class: "has-arrow",
  //   role: 2,
  //   disabled: false,
  //   countData: false,
  // },
  {
    path: "/client/closed",
    title: "Closed",
    image: "assets/images/closeds.svg",
    activeImage: "assets/images/closed w.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: true,
  },
  {
    path: "/client/payments",
    title: "Payments",
    image: "assets/images/Paymentblack.svg",
    activeImage: "assets/images/Paymentwhite.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: false,
  },
  {
    path: "/client/mydownloads",
    title: "Downloads",
    image: "assets/images/downloads-black.svg",
    activeImage: "assets/images/downloads-white.svg",
    class: "has-arrow",
    role: 2,
    disabled: false,
    countData: false,
  },
];
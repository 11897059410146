import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as actions from '../action/settings.action';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../settings.service';
import { SettingsSandbox } from '../settings.sandbox';



@Injectable()
export class settingsEffects {
  /**
   * user profile effect
   */
  @Effect()
  userprofile$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.GET_USER_PROFILE),
    map((action: actions.GetUserProfileAction) => action),
    switchMap((state) => {
      return this.api.getUserProfile().pipe(
        map((user) => new actions.GetUserProfileSuccessAction(user)),
        tap((res) => {
          if (res) {
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.payload.data.userDetails)
            );
          }
        }),
        catchError((error) => of(new actions.GetUserProfileFail(error.error)))
      );
    })
  );
  /**
   * user profile effect
   */
  @Effect()
  updateUserprofile$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.UPDATE_USER_PROFILE),
    map((action: actions.UpdateUserProfileAction) => action.payload),
    switchMap((state) => {
      return this.api.updateUserProfile(state).pipe(
        map((user) => new actions.UpdateUserProfileSuccess(user)),
        tap((data) => {
          this.toastr.success(data["payload"]["message"]);
          if (data["payload"].status == 1) {
            this.settingsSandbox.userProfile();
          }
        }),
        catchError((error) =>
          of(new actions.UpdateUserProfileFail(error.error))
        )
      );
    })
  );

  @Effect()
  subuserList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.SUB_USER_LIST),
    map((action: actions.SubuserListAction) => action.payload),
    switchMap((state) => {
      return this.api.subuserList(state).pipe(
        map((user) => new actions.SubuserListSuccess(user)),
        tap((data) => {}),
        catchError((error) =>
          of(new actions.SubuserListFail(error.error))
        )
      );
    })
  );

  @Effect()
  createSubuser$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.CREATE_SUB_USER),
    map((action: actions.CreateSubuserAction) => action.payload),
    switchMap((state) => {
      return this.api.CreateSubuser(state).pipe(
        map((user) => new actions.CreateSubuserSuccess(user)),
        tap((data) => {}),
        catchError((error) =>
          of(new actions.CreateSubuserFail(error.error))
        )
      );
    })
  );

  @Effect()
  updateSubuser$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.UPDATE_SUB_USER),
    map((action: actions.UpdateSubuserAction) => action.payload),
    switchMap((state) => {
      return this.api.updateSubuser(state).pipe(
        map((user) => new actions.UpdateSubuserSuccess(user)),
        tap((data) => {}),
        catchError((error) =>
          of(new actions.UpdateSubuserFail(error.error))
        )
      );
    })
  );

  @Effect()
  updateSubuserStatus$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.UPDATE_SUB_USER_STATUS),
    map((action: actions.UpdateSubuserStatusAction) => action.payload),
    switchMap((state) => {
      return this.api.updateSubuserStatus(state).pipe(
        map((user) => new actions.UpdateSubuserStatusSuccess(user)),
        tap((data) => {}),
        catchError((error) =>
          of(new actions.UpdateSubuserStatusFail(error.error))
        )
      );
    })
  );

  @Effect()
  deleteSubuser$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.DELETE_SUB_USER),
    map((action: actions.DeleteSubuserAction) => action.payload),
    switchMap((state) => {
      return this.api.deleteSubuser(state).pipe(
        map((user) => new actions.DeleteSubuserSuccess(user)),
        tap((data) => {}),
        catchError((error) =>
          of(new actions.DeleteSubuserFail(error.error))
        )
      );
    })
  );

  constructor(
    private action$: Actions,
    public settingsSandbox: SettingsSandbox,
    protected api: SettingsService,
    private toastr: ToastrService
  ) {}
}
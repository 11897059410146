import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
 
  @Input()orderDetails:any;

  constructor(public modal:NgbActiveModal) { }

  ngOnInit() {}

  close(){
    this.modal.close();
  }
}

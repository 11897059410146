import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { CommonService } from 'src/app/layout/common/common.service';
import { environment } from 'src/environments/environment';
import { DeletePopupComponent } from '../../popups/deletepopup/deletepopup.component';
import Quill from 'quill';

import BlotFormatter from "quill-blot-formatter";
import { ActivatedRoute } from '@angular/router';

Quill.register("modules/blotFormatter", BlotFormatter);
const Embed: any = Quill.import('blots/embed');

class DocumentBlot extends Embed {
  static create(paramValue) {
      const node = super.create();
      node.innerHTML = paramValue;
      return node;
  }

  static value(node) {
      return node.innerHTML;
  }
}
DocumentBlot.blotName = 'document-tag';
DocumentBlot.className = 'document-tag';
DocumentBlot.tagName = 'document-tag';
Quill.register(DocumentBlot);
@Component({
  selector: 'app-ticket-activity',
  templateUrl: './ticket-activity.component.html',
  styleUrls: ['./ticket-activity.component.scss']
})
export class TicketActivityComponent implements OnInit {

  ticketStatusId: number;
  ticketsDetails: any;
  ticketId: number;
  changeTicketStatus: any;
  imageBaseURL = environment.baseImgUrl;
  imageFileURL = environment.baseFileUrl
  statusList: any;
  currentState = '';
  replyDetail: any;
  mainContent: string;
  mainOpen: boolean = false;
  editor: any = {};
  userLoginId = JSON.parse(localStorage.getItem('userDetails')).userLoginId
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputDocument') fileInputDocument: ElementRef<HTMLInputElement>;
  quillEditorModuleOptions = {
    blotFormatter: {},
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['image', 'link','code-block'],
      ['table']      
    ]
  };

  constructor(
    public commonSandbox: CommonSandbox,
    private commonService: CommonService,
    private modalService: NgbModal,
    private route: ActivatedRoute) {
      this.ticketId = route.snapshot.params['id'];
    }

  ngOnInit(): void {
    this.getDetails();
    this.getAssignedResource();
    this.getMessageList();
  }
  getDetails() {
    this.commonSandbox.getTicketDetail({ id: this.ticketId });
    this.commonSandbox.ticketsDetail$.subscribe(res=>{
      if(Object.keys(res).length > 0) {
        this.ticketsDetails = res;
        this.ticketStatusId = this.ticketsDetails?.ticketStatusId == 1 ? this.ticketsDetails?.ticketStatusId + 1 : this.ticketsDetails?.ticketStatusId == 5 &&  this.ticketsDetails?.implementationFlag !== 2 ? this.ticketsDetails?.ticketStatusId -1 : this.ticketsDetails?.ticketStatusId;
        this.commonSandbox.GetStatus();
        this.commonSandbox.statusList$.subscribe(list=> {
          if(list?.length > 0) {
            this.statusList = list?.map(obj=> ({...obj, disabled: (this.ticketsDetails.ticketStatusId + 1 < obj.id && this.ticketsDetails.ticketStatusId !== 4 && this.ticketsDetails.ticketStatusId !== 5) || ((this.ticketsDetails.ticketStatusId == 4 || this.ticketsDetails.ticketStatusId == 5) && this.ticketsDetails.implementationFlag !== 2 && this.ticketsDetails.ticketStatusId < obj.id)  || obj.id == 1}))
          }
        })
      }
    })
  }

  getMessageList(): void {
    this.commonSandbox.getMessageList({ticketId: this.ticketId});
  }

  updateStatusDate(ticketDetail, statusId) {
    if(statusId == 1) {
      return ticketDetail.createdDate
    }
    if(ticketDetail?.ticketLogDetails?.find(id=> id.changedTicketStatusId == statusId)){
      if(ticketDetail?.ticketStatusId == 4 && ticketDetail?.clientsApprovalStatus == 1) {
        return ticketDetail?.clientsApprovedDate
      }
      else {
        return ticketDetail.ticketLogDetails?.find(id=> id.changedTicketStatusId == statusId).createdDate
      }
    }
    if(ticketDetail?.ticketStatusId + 1 == statusId){
      if(ticketDetail?.ticketStatusId + 1 == 2) {
        return ticketDetail.createdDate
      }
      else if (ticketDetail?.ticketStatusId == 4 || (ticketDetail?.ticketStatusId == 5 && ticketDetail?.implementationFlag !== 2)) {
        return
      }
      else if (ticketDetail?.ticketStatusId == 5 && ticketDetail?.implementationFlag == 2) {
        return ticketDetail.modifiedDate
      }
      else {
        return ticketDetail.ticketLogDetails?.find(id=> id.changedTicketStatusId == statusId - 1).createdDate
      }
    }
  }
  
  getAssignedResource() {
    const params: any = {};
    params.ticketId = this.ticketId;
    this.commonSandbox.getAssignedResourcesList(params);
  }

  downloadFile(doc) {
    const documentUrl = this.imageFileURL + '?name=' + doc.fileName + '&path=' + doc.filePath;
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    a.href = documentUrl;
    a.download = documentUrl;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(documentUrl);
    a.remove();
  }

  editorCreated(editorInstance: any): void {
    this.editor = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    toolbar.addHandler('link', this.fileHandler.bind(this));
  }

  imageHandler(): void {
    const inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
    inputElement.click();
  }

  fileHandler(): void {
    const inputElement: HTMLElement = this.fileInputDocument.nativeElement as HTMLElement;
    inputElement.click();
  }

  replyMessage(state, data) {
    window.scrollTo(0,document.body.scrollHeight);  
    document.body.style.overflowY = "hidden";
    this.mainOpen =  true;
    this.currentState = state; 
    this.replyDetail = data;
    if(state == 'edit' || state == 'replyedit') {
     this.mainContent = data.message;
    }
  }

  sendReply() {
    this.mainOpen = false;
    if(this.mainContent){
    const params: any={};
    params.message =this.mainContent.replace(/(<p)/igm, '<div class="view-image"').replace(/<\/p>/igm, '</div>');
    if(this.currentState == 'reply1') {
    params.detail = 1;
    params.ticketId = this.ticketId;
    } else if(this.currentState == 'reply2') {
    params.detail = 2;
    params.ticketId = this.ticketId;
    params.ticketConversationId = this.replyDetail.id;
    } else if(this.currentState == 'reply3') {
    params.detail = 2;
    params.ticketId = this.ticketId;
    params.ticketConversationId = this.replyDetail.id;
    } else if(this.currentState == 'edit') {
      params.detail = 3;
      params.ticketId = this.replyDetail.id;
    }  else if(this.currentState == 'replyedit') {
      params.detail = 4;
      params.ticketId = this.replyDetail.id;
    }
    this.commonSandbox.sendMessage(params);
    this.commonSandbox.sendMessageResponse$.subscribe(load => {
    if (load) {
        this.getMessageList(); 
        this.mainContent = '';
        document.body.style.overflowY = "scroll";
    }
  });
  }
}
  cancel() {
    this.mainContent = '';
    this.mainOpen = false;
    document.body.style.overflowY = "scroll";
  }

  fileUpload(event: any) {
    const file = event.target.files[0];
    if(file) {
   const params: any = {};
    params.ticketId = this.ticketId;
    params.file = file;
    this.commonService.uploadFile(params).subscribe((data: any) => {
      if (data && data.data) {
        this.placeInEditor(this.imageBaseURL + '?name=' + data.data.fileName + '&path=' + data.data.filePath + '&width=100 & height=100' );
        this.commonSandbox.clearData();
      }
    });
    }
 
  }
  fileUploadDocument(event: any) {    
    const file = event.target.files[0];
    if(file) {
    const params: any = {};
    params.ticketId = this.ticketId;
    params.file = file;
    this.commonService.uploadFile(params).subscribe((val: any) => { 
      if (val && val.data) {
        this.placeInEditorDocument(this.imageBaseURL + '?name=' + val.data.fileName + '&path=' + val.data.filePath , val.data.fileName);
        this.commonSandbox.clearData();
      }
    });
    }
  }
  placeInEditor(url: string): void {
    const range = this.editor.getSelection();
    this.editor.insertEmbed(range.index, 'image', url, 'user');
    this.editor.insertText(range.index + 2, '\n');
    this.editor.setSelection(range.index + 3);
    this.editor.updateContents();
    
  }
  placeInEditorDocument(url: string, name: string): void {
    const range = this.editor.getSelection();
    const URL = '<a target="_blank" href="' + url + '"> ' + name + ' </a>';
    this.editor.insertEmbed(range.index + 0, 'document-tag', URL, 'user');
    this.editor.insertText(range.index + 1,'\n');
    this.editor.setSelection(range.index + 3);
  }

  privateReplyMessage(data, i , state, val) {
  this.mainContent = data.message;
  if(state == 'private') {
    const params: any = {};
    params.ticketId = data.id;
    params.check = val;
    params.message = this.mainContent.replace(/(<p)/igm, '<div class="view-image"').replace(/<\/p>/igm, '</div>');
    if(val == 'edit') {
    params.detail = 3;
    } else {
    params.detail = 4;
    }
    params.isPrivate = 1;
    params.index = i;
    this.mainContent = '';
    this.commonSandbox.sendMessage(params);
  } else if(state == 'public') {
    const params: any = {};
    params.ticketId = data.id;
    params.message = this.mainContent.replace(/(<p)/igm, '<div class="view-image"').replace(/<\/p>/igm, '</div>');
    params.check = val;
    if(val == 'edit') {
    params.detail = 3;
    } else {
    params.detail = 4;
    }
    params.isPrivate = 0;
    this.mainContent = '';
    params.index = i;
    this.commonSandbox.sendMessage(params);

  }
}

deleteMessage(data, val){
  const params: any = {};
  params.ticketId = this.ticketId;
  if(val == 'main') {
    params.detail = 'maindeletemsg';
  } else {
    params.detail = 'subdeletemsg';
  }
  params.data = data;
  const modalRef = this.modalService.open(DeletePopupComponent, {
    windowClass: 'delete-model' , backdrop: 'static', centered:true});
    modalRef.componentInstance.msgDetails = params;
    modalRef.result.then((result) => {
      if (result === 'success') {
      }
    }); 
}

openReply(data,i) {    
  if(data && data.replyMessages.length > 0) {
    data.replyMessagesData = !data.replyMessagesData;
  }
}

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../shared/interfaces/interface';
import { Api } from '../providers/api/api';

@Injectable()

export class AuthService extends Api {

  private URL: string = this.getBaseUrl();

  doLogin(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/cilent-login', params);
  }
  doWebLogin(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/independent-ticket/login', params);
  }
  forgetPassword(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/forgot-password', params);
  }
  resetPassword(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/reset-password', params);
  }
  checkUser(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/check-url-string', params);
  }
  doVerifyOtp(params: any):Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/login-otp-check', params);
  }
  doResendOtp(params: any):Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/resend-otp', params);
  }
  dosignUp(params: any):Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/client/signup', params);
  }
}

import * as actions from '../action/notification.action';
import { NotificationState, notificationStateRecord } from './notification.state';


export const initialState: NotificationState = new notificationStateRecord() as unknown as NotificationState;

export function reducer(state = initialState, { type, payload }: any): NotificationState {
    if (!type) {
        return state;
    }

    switch (type) {

        /**
         * get notification count case function
         */
        case actions.ActionTypes.GET_NOTIFICATION_COUNT:

            return Object.assign({}, state, {
                notificationCountLoading: true,
                notificationCountLoaded: false,
                notificationCountFailed: false
            });

        case actions.ActionTypes.GET_NOTIFICATION_COUNT_SUCCESS:
            return Object.assign({}, state, {
                notificationCount: payload.data,
                notificationCountLoading: false,
                notificationCountLoaded: true,
                notificationCountFailed: false,
            });

        case actions.ActionTypes.GET_NOTIFICATION_COUNT_FAIL:

            return Object.assign({}, state, {
                notificationCountLoading: false,
                notificationCountLoaded: true,
                notificationCountFailed: true,
            });

        /**
     * get notification list case function
     */
        case actions.ActionTypes.GET_NOTIFICATION_LIST:
            const isLoad = payload.refresh;
            if (isLoad) {
                return Object.assign({}, state, {
                    notificationListLoading: true,
                    notificationListLoaded: false,
                    notificationListFailed: false
                });
            } else {
                return Object.assign({}, state, {
                    notificationListLoading: false,
                    notificationListLoaded: false,
                    notificationListFailed: false
                });
            }


        case actions.ActionTypes.GET_NOTIFICATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                notificationList: payload.data.map(res=> ({...res, checked: false})),
                notificationListLoading: false,
                notificationListLoaded: true,
                notificationListFailed: false,
            });

        case actions.ActionTypes.GET_NOTIFICATION_LIST_FAIL:

            return Object.assign({}, state, {
                notificationListLoading: false,
                notificationListLoaded: true,
                notificationListFailed: true,
            });

        /**
     * get notification detail case function
     */
        case actions.ActionTypes.GET_NOTIFICATION_DETAIL:

            return Object.assign({}, state, {
                notificationDetailLoading: true,
                notificationDetailLoaded: false,
                notificationDetailFailed: false
            });

        case actions.ActionTypes.GET_NOTIFICATION_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                notificationDetail: payload.data,
                notificationDetailLoading: false,
                notificationDetailLoaded: true,
                notificationDetailFailed: false,
            });

        case actions.ActionTypes.GET_NOTIFICATION_DETAIL_FAIL:

            return Object.assign({}, state, {
                notificationDetailLoading: false,
                notificationDetailLoaded: true,
                notificationDetailFailed: true,
            });
        case actions.ActionTypes.CHANGE_NOTIFICATION_STATUS_SUCCESS:

            return Object.assign({}, state, {
                notificationStatusChange: payload,
            });
        case actions.ActionTypes.NOTIFICATION_UNREAD_SUCCESS:

            return Object.assign({}, state, {
                notificationUnreadStatus: payload,
            });

        default: {
            return state;
        }
    }
}

/**
 * export values
 */

export const getNotificationCount = (state: NotificationState) => state.notificationCount;
export const getNotificationCountLoading = (state: NotificationState) => state.notificationCountLoading;
export const getNotificationCountLoaded = (state: NotificationState) => state.notificationCountLoaded;
export const getNotificationCountFailed = (state: NotificationState) => state.notificationCountFailed;

export const getNotificationList = (state: NotificationState) => state.notificationList;
export const getNotificationListLoading = (state: NotificationState) => state.notificationListLoading;
export const getNotificationListLoaded = (state: NotificationState) => state.notificationListLoaded;
export const getNotificationListFailed = (state: NotificationState) => state.notificationListFailed;

export const getNotificationDetail = (state: NotificationState) => state.notificationDetail;
export const getNotificationDetailLoading = (state: NotificationState) => state.notificationDetailLoading;
export const getNotificationDetailLoaded = (state: NotificationState) => state.notificationDetailLoaded;
export const getNotificationDetailFailed = (state: NotificationState) => state.notificationDetailFailed;
export const getNotificationStatusChange = (state: NotificationState) => state.notificationStatusChange;
export const getNotificationUnreadStatus = (state: NotificationState) => state.notificationUnreadStatus;
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  ckeConfig: any;
  mycontent: string;
  getFile: any;
  public file : any;
  log: string = "";
  @ViewChild('myckeditor', {static: true}) ckeditor: ElementRef;
  @Input() public detail;
  constructor( public modal: NgbActiveModal,
   public commonSandbox: CommonSandbox ) { }

  ngOnInit() {
    this.ckeConfig = { 
      removePlugins: 'templates,elementspath,save,font,undo,save,newpage,print,template,preview,flash,horizontalrule,smiley,specialchar,pagebreak,iframe,copyformatting,removeformat,outdent,blockquote,createDiv,bidiltr,idirtl,language,showblocks,about,forms,find,format,font,fontSize,justify',
      removeButtons: 'BidiLtr,BidiRtl,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,Scayt,RemoveFormat,Superscript,Subscript,BGColor,TextColor,Styles,Format,ShowBlocks,UIColor,Underline,Strike,Table,Cut,Copy,CreateDivContainer,Maximize,PasteFromWord,SpellChecker,SelectAll,Paste,PasteText,PasteFromWord', 
      extraPlugins: 'codesnippet',  
      toolbar: [ { name: 'document', items: ['-', 'Bold', 'Italic', 'CodeSnippet'] },{
          name: 'insert',
          items: ['Image']
        },],
      filebrowserBrowseUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
      filebrowserImageBrowseUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
      filebrowserUploadUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
      filebrowserImageUploadUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
      uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
      removeDialogTabs: 'image:advanced;link:advanced',
         height: 450,
    };


    
    if(this.detail.check == 4 || this.detail.check == 3){
      this.mycontent = this.detail.message;
    }
  }

  submit(){
    // let data = this.ckeConfig.getData();
    const params: any={};
    console.log('sssd', this.mycontent);
    params.message = this.mycontent;
    params.detail = this.detail;
    this.commonSandbox.sendMessage(params);
    this.modal.close();
  }
  cancel(){
    this.modal.close();
  }

  handleFileInput(files) {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      this.file = reader.result;
    };
  }
}

import * as actions from '../actions/activity.action';
import { ActivityState, activityStateRecord } from './activity.state';
import { NotesListModel } from '../models/notes-list.model';
import { TicketListResponseModel } from 'src/app/layout/common/models/ticket-list-response.model';


export const initialState: ActivityState = new activityStateRecord() as unknown as ActivityState;

export function reducer(state = initialState, { type, payload }: any): ActivityState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get tickets reducer case function
     */
    case actions.ActionTypes.GET_TICKETS:

      return Object.assign({}, state, {
        ticketsListLoading: true,
        ticketsListLoaded: false,
        ticketsListFailed: false
      });

    case actions.ActionTypes.GET_TICKETS_SUCCESS:


      let totalTicketsList: TicketListResponseModel[];
      const templist = payload.data.map(list => {
        const ticketDetail = new TicketListResponseModel(list);
        return ticketDetail;
      });
      totalTicketsList = state.ticketsList ? state.ticketsList : [];
      totalTicketsList = totalTicketsList.concat(templist);
      return Object.assign({}, state, {
        ticketsList: totalTicketsList,
        ticketsListLoading: false,
        ticketsListLoaded: true,
        ticketsListFailed: false,
      });

    case actions.ActionTypes.GET_TICKETS_FAIL:

      return Object.assign({}, state, {
        ticketsListLoading: false,
        ticketsListLoaded: true,
        ticketsListFailed: true,
      });
    /**
     * get ticket count reducer case function
     */
    case actions.ActionTypes.GET_TICKETS_COUNT:

      return Object.assign({}, state, {
        ticketsCountLoading: true,
        ticketsCountLoaded: false,
        ticketsCountFailed: false
      });

    case actions.ActionTypes.GET_TICKETS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        ticketsCount: payload.data.ticketListCount,
        ticketsCountLoading: false,
        ticketsCountLoaded: true,
        ticketsCountFailed: false,
      });

    case actions.ActionTypes.GET_TICKETS_COUNT_FAIL:

      return Object.assign({}, state, {
        ticketsCountLoading: false,
        ticketsCountLoaded: true,
        ticketsCountFailed: true,
      });

    /**
     * get notes list reducer case function
     */
    case actions.ActionTypes.GET_NOTES_LIST:

      return Object.assign({}, state, {
        notesListLoading: true,
        notesListLoaded: false,
        notesListFailed: false
      });

    case actions.ActionTypes.GET_NOTES_LIST_SUCCESS:
      const tempNotes = payload.data.list.map(notes => {
        const notesDetail = new NotesListModel(notes);
        return notesDetail;
      });
      return Object.assign({}, state, {
        notesList: tempNotes,
        notesListLoading: false,
        notesListLoaded: true,
        notesListFailed: false,
      });

    case actions.ActionTypes.GET_NOTES_LIST_FAIL:

      return Object.assign({}, state, {
        notesListLoading: false,
        notesListLoaded: true,
        notesListFailed: true,
      });
    /**
* get notes count reducer case function
*/
    case actions.ActionTypes.GET_NOTES_COUNT:

      return Object.assign({}, state, {
        notesCountLoading: true,
        notesCountLoaded: false,
        notesCountFailed: false
      });

    case actions.ActionTypes.GET_NOTES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        notesCount: payload.data.count,
        notesCountLoading: false,
        notesCountLoaded: true,
        notesCountFailed: false,
      });

    case actions.ActionTypes.GET_NOTES_COUNT_FAIL:

      return Object.assign({}, state, {
        notesCountLoading: false,
        notesCountLoaded: true,
        notesCountFailed: true,
      });
    /**
     * get add notes reducer case function
     */
    case actions.ActionTypes.ADD_NOTES_ACTION:

      return Object.assign({}, state, {
        addNotesLoading: true,
        addNotesLoaded: false,
        addNotesFailed: false
      });

    case actions.ActionTypes.ADD_NOTES_SUCCESS:
      return Object.assign({}, state, {
        addNotesLoading: false,
        addNotesLoaded: true,
        addNotesFailed: false,
      });

    case actions.ActionTypes.ADD_NOTES_FAIL:

      return Object.assign({}, state, {
        addNotesLoading: false,
        addNotesLoaded: false,
        addNotesFailed: true,
      });
    case actions.ActionTypes.CLEAR_LIST:

      return Object.assign({}, state, {
        ticketsList: []
      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const getTicketsList = (state: ActivityState) => state.ticketsList;
export const getTicketsListLoading = (state: ActivityState) => state.ticketsListLoading;
export const getTicketsListLoaded = (state: ActivityState) => state.ticketsListLoaded;
export const getTicketsListFailed = (state: ActivityState) => state.ticketsListFailed;

export const getTicketsCount = (state: ActivityState) => state.ticketsCount;
export const getTicketsCountLoading = (state: ActivityState) => state.ticketsCountLoading;
export const getTicketsCountLoaded = (state: ActivityState) => state.ticketsCountLoaded;
export const getTicketsCountFailed = (state: ActivityState) => state.ticketsCountFailed;


export const getNotesList = (state: ActivityState) => state.notesList;
export const getNotesListLoading = (state: ActivityState) => state.notesListLoading;
export const getNotesListLoaded = (state: ActivityState) => state.notesListLoaded;
export const getNotesListFailed = (state: ActivityState) => state.notesListFailed;

export const getNotesCount = (state: ActivityState) => state.notesCount;
export const getNotesCountLoading = (state: ActivityState) => state.notesCountLoading;
export const getNotesCountLoaded = (state: ActivityState) => state.notesCountLoaded;
export const getNotesCountFailed = (state: ActivityState) => state.notesCountFailed;

export const getAddNotesLoading = (state: ActivityState) => state.addNotesLoading;
export const getAddNotesLoaded = (state: ActivityState) => state.addNotesLoaded;
export const getAddNotesFailed = (state: ActivityState) => state.addNotesFailed;

import { Action } from '@ngrx/store';
import {type} from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  GET_TICKETS: type('[tickets] tickets list'),
  GET_TICKETS_SUCCESS: type('[tickets] tickets list Success'),
  GET_TICKETS_FAIL: type('[tickets] tickets list Fail'),

  GET_TICKETS_COUNT: type('[tickets] tickets count'),
  GET_TICKETS_COUNT_SUCCESS: type('[tickets] tickets count Success'),
  GET_TICKETS_COUNT_FAIL: type('[tickets] tickets count Fail'),

  GET_NOTES_LIST: type('[notes] notes list'),
  GET_NOTES_LIST_SUCCESS: type('[notes] notes list Success'),
  GET_NOTES_LIST_FAIL: type('[notes] notes list Fail'),

  GET_NOTES_COUNT: type('[notes] notes count'),
  GET_NOTES_COUNT_SUCCESS: type('[notes] notes count Success'),
  GET_NOTES_COUNT_FAIL: type('[notes] notes count Fail'),

  ADD_NOTES_ACTION: type('[add notes] add new notes'),
  ADD_NOTES_SUCCESS: type('[add notes] add new notes Success'),
  ADD_NOTES_FAIL: type('[add notes] add new notes Fail'),

  CLEAR_LIST: type('[clear] clear list'),
};

/**
 * ticket list actions.
 */
export class GetTicketsAction implements Action {
  type = ActionTypes.GET_TICKETS;

  constructor(public payload: any) {
  }
}

export class GetTicketsSuccess implements Action {
  type = ActionTypes.GET_TICKETS_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetTicketsFail implements Action {
  type = ActionTypes.GET_TICKETS_FAIL;

  constructor(public payload: any) {
  }
}


/**
 * get ticket count actions.
 */
export class GetTicketsCountAction implements Action {
  type = ActionTypes.GET_TICKETS_COUNT;

  constructor(public payload: any) {
  }
}

export class GetTicketsCountSuccess implements Action {
  type = ActionTypes.GET_TICKETS_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetTicketsCountFail implements Action {
  type = ActionTypes.GET_TICKETS_COUNT_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * get notes list actions.
 */
export class GetNotesListAction implements Action {
  type = ActionTypes.GET_NOTES_LIST;

  constructor(public payload: any) {
  }
}

export class GetNotesListSuccess implements Action {
  type = ActionTypes.GET_NOTES_LIST_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetNotesListFail implements Action {
  type = ActionTypes.GET_NOTES_LIST_FAIL;

  constructor(public payload: any) {
  }
}
/**
 * get notes count actions.
 */
export class GetNotesCountAction implements Action {
  type = ActionTypes.GET_NOTES_COUNT;

  constructor(public payload: any) {
  }
}

export class GetNotesCountSuccess implements Action {
  type = ActionTypes.GET_NOTES_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetNotesCountFail implements Action {
  type = ActionTypes.GET_NOTES_COUNT_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * add notes actions.
 */
export class AddNotesAction implements Action {
  type = ActionTypes.ADD_NOTES_ACTION;

  constructor(public payload: any) {
  }
}

export class AddNotesSuccess implements Action {
  type = ActionTypes.ADD_NOTES_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class AddNotesFail implements Action {
  type = ActionTypes.ADD_NOTES_FAIL;

  constructor(public payload: any) {
  }
}
export class ClearActivityList implements Action {
  type = ActionTypes.CLEAR_LIST;

  constructor(public payload: any = null) {
  }
}


export type Actions =
  | GetTicketsAction
  | GetTicketsSuccess
  | GetTicketsFail

  | GetTicketsCountAction
  | GetTicketsCountSuccess
  | GetTicketsCountFail

  | GetNotesListAction
  | GetNotesListSuccess
  | GetNotesListFail

  | GetNotesCountAction
  | GetNotesCountSuccess
  | GetNotesCountFail

  | AddNotesAction
  | AddNotesSuccess
  | AddNotesFail
  | ClearActivityList;

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { SharedComponentsModule } from "./shared/components/index";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { appRoutes } from "./app.routing";
import { CreateCRComponent } from "./shared/popups/createcr/createcr.component";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { metaReducers, reducers } from "./app.reducer";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import { AuthGuard } from "./shared/guards/auth.guard";
import { RequestInterceptor } from "./providers/interceptor/request.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { CONTAINERS, ContainersModule } from "./layout/common/common.module";
import { CommonEffects } from "./layout/common/effects/common.effects";
import { CommonService } from "./layout/common/common.service";
import { CommonSandbox } from "./layout/common/common.sandbox";
import { AuthModule } from "./auth/auth.module";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { InvoiceComponent } from "./shared/popups/invoice/invoice.component";
import { DataTablesModule } from "angular-datatables";
import { DeletePopupComponent } from "./shared/popups/deletepopup/deletepopup.component";
import { DatePipe } from "@angular/common";
import { NumberAcceptModule } from "./shared/Directive/onlyNumber.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MakePaymentComponent } from "./shared/popups/make-payment/make-payment.component";
import { OfflineComponent } from "./shared/components/offline/offline.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AppMaterial } from "./app.material.module";
import { ServerComponent } from "./server-error/server.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ConfigService } from "./app-config.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ChatModule } from "./shared/chat/chat.module";
import { OfflinePaymentComponent } from "./shared/popups/offline-payment/offline-payment.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { TextEditorComponent } from "./shared/popups/text-editor/text-editor.component";
import { CKEditorModule } from "ckeditor4-angular";
import { QuillModule } from "ngx-quill";
import { NgSelectModule } from "@ng-select/ng-select";
import { MobileNotificationComponent } from "./mobile-notification/mobile-notification.component";
import { MobileNotificationUrlComponent } from "./mobile-notification-url/mobile-notification-url.component";
import { UpdatePaymentComponent } from "./shared/popups/update-payment/update-payment.component";
import { OrderDetailComponent } from "./shared/popups/order-detail/order-detail.component";
import { DeletePopupModalComponent } from "./shared/popups/delete-popup-modal/delete-popup-modal.component";
import { ClickOutsideDirective } from "./shared/Directive/clickoutside.directive";
import { GroupByPipe } from "./shared/pipes/group-by-array.pipe";
import { SortByDatePipe } from "./shared/pipes/sort-by-date.pipe";
import { ChangeemailComponent } from "./shared/popups/changeemail/changeemail.component";
import { AuthSandbox } from "./auth/auth.sandbox";
import { VerifyotpComponent } from "./shared/popups/verifyotp/verifyotp.component";
import { NgOtpInputModule } from "ng-otp-input";
import { settingsEffects } from "./settings/effects/settings.effects";
import { NotificationEffects } from "./client/notification/effects/notification.effects";
import { SettingsSandbox } from "./settings/settings.sandbox";
import { SettingsService } from "./settings/settings.service";
import { NotificationSandbox } from "./client/notification/notification.sandbox";
import { NotificationService } from "./client/notification/notification.service";
export function configServiceFactory(config: ConfigService) {
  return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    CONTAINERS.CommonComponent,
    CreateCRComponent,
    InvoiceComponent,
    DeletePopupComponent,
    MakePaymentComponent,
    OfflineComponent,
    ServerComponent,
    OfflinePaymentComponent,
    TextEditorComponent,
    MobileNotificationComponent,
    MobileNotificationUrlComponent,
    UpdatePaymentComponent,
    OrderDetailComponent,
    DeletePopupModalComponent,
    ClickOutsideDirective,
    GroupByPipe,
    SortByDatePipe,
    ChangeemailComponent,
    VerifyotpComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    HttpClientModule,
    BrowserAnimationsModule,
    ContainersModule,
    SharedComponentsModule,
    AuthModule,
    NgbModule,
    DataTablesModule,
    NgOtpInputModule,
    NumberAcceptModule,
    ChatModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([CommonEffects, settingsEffects, NotificationEffects]),
    ToastrModule.forRoot({
      timeOut: 2000,
      easing: "ease-in",
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    RouterModule.forRoot(appRoutes),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AppMaterial,
    ImageCropperModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxChartsModule,
    CKEditorModule,
    QuillModule,
    NgSelectModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.load(),
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CommonService,
    CommonSandbox,AuthSandbox, SettingsSandbox,SettingsService, NotificationSandbox, NotificationService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateCRComponent,
    InvoiceComponent,
    DeletePopupComponent,
    OfflinePaymentComponent,
    MakePaymentComponent,
    OfflineComponent,
    UpdatePaymentComponent,
    OrderDetailComponent,
    TextEditorComponent,
    DeletePopupModalComponent,
    ChangeemailComponent,
    VerifyotpComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}

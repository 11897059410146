import { Router, ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() count: number;
  @Input() pageSize: number;
  @Input() currentPage: number;
  @Output() getPageData = new EventEmitter<any>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  pageData(event) {
    const params: any = {
      offset: this.pageSize * (event - 1),
      pageIndex: event
    }
    this.getPageData.emit(params);
    this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: { offset: params.offset, pageIndex: params.pageIndex}, queryParamsHandling: 'merge'})
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../../app.state';
import * as paymentsActions from './actions/payments.action';
import { Subscription } from 'rxjs';
import { orderListCount, orderListCountLoaded, orderListCountLoading, paymentDashboardCount, paymentDashboardCountFailed, paymentDashboardCountLoaded, paymentDashboardCountLoading, paymentHistoryList, paymentHistoryListCount, paymentHistoryListLoaded, paymentHistoryListLoading, paymentOrderList, paymentOrderListFailed, paymentOrderListLoaded, paymentOrderListLoading, recentPaymentDues, recentPaymentDuesFailed, recentPaymentDuesLoaded, recentPaymentDuesLoading } from './reducer/payments.selector';

@Injectable()
export class PaymentsSandbox {


  public paymentDashboardCount$ = this.appState.select(paymentDashboardCount);
  public paymentDashboardCountLoading$ = this.appState.select(paymentDashboardCountLoading);
  public paymentDashboardCountLoaded$ = this.appState.select(paymentDashboardCountLoaded);
  public paymentDashboardCountFailed$ = this.appState.select(paymentDashboardCountFailed);

  public recentPaymentDues$ = this.appState.select(recentPaymentDues);
  public recentPaymentDuesLoading$ = this.appState.select(recentPaymentDuesLoading);
  public recentPaymentDuesLoaded$ = this.appState.select(recentPaymentDuesLoaded);
  public recentPaymentDuesFailed$ = this.appState.select(recentPaymentDuesFailed);

  public paymentHistoryList$ = this.appState.select(paymentHistoryList);
  public paymentHistoryListLoading$ = this.appState.select(paymentHistoryListLoading);
  public paymentHistoryListLoaded$ = this.appState.select(paymentHistoryListLoaded);
  public paymentHistoryListFailed$ = this.appState.select(paymentDashboardCountFailed);

  public paymentOrderList$ = this.appState.select(paymentOrderList);
  public paymentOrderListLoading$ = this.appState.select(paymentOrderListLoading);
  public paymentOrderListLoaded$ = this.appState.select(paymentOrderListLoaded);
  public paymentOrderListFailed$ = this.appState.select(paymentOrderListFailed);

  public paymentHistoryListCount$ = this.appState.select(paymentHistoryListCount);

  public OrderListCount$ = this.appState.select(orderListCount);
  public OrderListCountLoading$ = this.appState.select(orderListCountLoading);
  public OrderListCountLoaded$ = this.appState.select(orderListCountLoaded);

  constructor(protected appState: Store<store.AppState>) {

  }

  public paymentDashboardCount(params) {
    this.appState.dispatch(new paymentsActions.PaymentDashboardCount(params));
  }
  public recentPaymentsDues(params) {
    this.appState.dispatch(new paymentsActions.GetRecentPaymentDues(params));
  }

  public getPaymentHistoryList(params) {
    this.appState.dispatch(new paymentsActions.GetPaymentHistoryList((params)));
  }

  public paymentOrderList(params) {
    this.appState.dispatch(new paymentsActions.PaymentOrderList(params));
  }

  public paymentHistoryListCount(params) {
    this.appState.dispatch(new paymentsActions.PaymentHistoryListCount((params)));
  }

  public OrderListCount(params) {
    this.appState.dispatch(new paymentsActions.GetOrderListCountAction(params));
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthSandbox } from '../../auth/auth.sandbox';
@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss']
})
export class WebComponent implements OnInit {

  loginId: String;
  isShow = false;
  constructor(private router: ActivatedRoute, public authSandbox: AuthSandbox) { }

  ngOnInit() {

    this.router.params.subscribe(data => {
      this.loginId = data.loginId;
   });

   this.doLogin(this.loginId);
  }

  public doLogin(loginId) {
    this.isShow = true;
    const params = {loginId : loginId};
    this.authSandbox.doWebLogin(params);
   
  }

  
}

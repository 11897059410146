import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonSandbox } from '../layout/common/common.sandbox';

@Component({
  selector: 'app-mobile-notification-url',
  templateUrl: './mobile-notification-url.component.html',
  styleUrls: ['./mobile-notification-url.component.scss']
})
export class MobileNotificationUrlComponent implements OnInit {
  urlId: any;
    private subscriptions: Array<Subscription> = [];
  constructor(private route: ActivatedRoute,public commonSandbox: CommonSandbox,
              public router: Router) {
        this.route.params.subscribe(val => {
      if (val) {
        this.urlId = val.id;
      }
    });
   }

  ngOnInit() {
    const params: any = {};
    params.key = this.urlId;
   this.commonSandbox.mobileNotificationList(params);
      this.subscriptions.push(this.commonSandbox.mobileNotificationList$.subscribe(load => {
      if (load) {
        console.log('vvv', load);
        if (load.status == 1) {
          this.router.navigate(['/conversation/' + this.urlId]);
        }
      }
    }));
  }

}

export class ChangeTicketStatusRequestModel {
    ticketId: string;
    ticketStatusId: string;
    reviewNotes: string;
    approvalNotes: string;
    scheduleNotes: string;
    implementationNotes: string;
    ticketDuration: string;
    ticketStartDate: string;
    ticketClosureDate: string;
    ticketCost: string;
    implementationFlag: any;
    isSendMail: any;
    resources: any;
    constructor(val: any) {
      console.log(val);
      this.ticketId  = val.ticketId || '';
      this.ticketStatusId = val.ticketStatusId || '';
      this.reviewNotes = val.reviewNotes || '';
      this.approvalNotes = val.approvalNotes || '';
      this.scheduleNotes = val.scheduleNotes || '';
      this.implementationNotes = val.implementationNotes || '';
      this.ticketDuration = val.ticketDuration || '';
      this.ticketStartDate = val.ticketStartDate || '';
      this.ticketClosureDate = val.ticketClosureDate || '';
      this.ticketCost = val.ticketCost || '';
      this.implementationFlag = val.implementationFlag || '';
      this.isSendMail = val.isSendMail || '';
      this.resources = val.resources || '';
    }
  }

export const environment = {
  production: false,

  baseImgUrl: 'https://api.picco.support/backend/api/image/open-image',
  baseFileUrl: 'https://api.picco.support/backend/api/image/download-file',
  baseUrl: 'https://api.picco.support/backend/',
  baseLink: 'https://api.picco.support/backend/',
  socketUrl: 'https://api.picco.support/backend',
  paymentUrl: 'https://gateway.picco.digital/api/',
  productTypeKey : '7gWqjyOEaRse6PA7ynGJqMc7oHT7h5U8BRE435aa',
  CRYPTO_SECRET : 'BTPk*2FpQD22cbZ*_Q9wSny4U88?9j?T',

  piccoDigital: {
    userName: 'piccosupport',
    password: 'support123@'
  },
  
  notifications: {
    unauthorizedEndpoints: [
      'auth/login'
    ],
    notFoundEndpoints: []
  },
  debugging: false,
};

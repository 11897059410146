import { Map, Record } from 'immutable';
import { NotesListModel } from '../models/notes-list.model';
import { TicketListResponseModel } from 'src/app/layout/common/models/ticket-list-response.model';

export interface ActivityState extends Map<string, any> {

  ticketsList: Array<TicketListResponseModel>;
  ticketsListLoading: boolean;
  ticketsListLoaded: boolean;
  ticketsListFailed: boolean;

  ticketsCount: number;
  ticketsCountLoading: boolean;
  ticketsCountLoaded: boolean;
  ticketsCountFailed: boolean;

  notesList: Array<NotesListModel>;
  notesListLoading: boolean;
  notesListLoaded: boolean;
  notesListFailed: boolean;

  notesCount: number;
  notesCountLoading: boolean;
  notesCountLoaded: boolean;
  notesCountFailed: boolean;

  addNotesLoading: boolean;
  addNotesLoaded: boolean;
  addNotesFailed: boolean;
}

export const activityStateRecord = Record({

  ticketsList: [],
  ticketsListLoading: false,
  ticketsListLoaded: false,
  ticketsListFailed: false,

  ticketsCount: 0,
  ticketsCountLoading: false,
  ticketsCountLoaded: false,
  ticketsCountFailed: false,

  ticketDetail: {},
  ticketDetailLoading: false,
  ticketDetailLoaded: false,
  ticketDetailFailed: false,

  notesList: [],
  notesCount: 0,
  notesListLoading: false,
  notesListLoaded: false,
  notesListFailed: false,

  addNotesLoading: false,
  addNotesLoaded: false,
  addNotesFailed: false,
});


export enum ESupportTicket  
  {
    header = "SUPPORT TICKET",
    content = "As a Premium Customer, we regret for the inconvenience you are facing. Please rest assured that your issue is important to us. We kindly request you to raise a support ticket with us, using this portal. One of our dedicated representatives will get in touch with you to assist and resolve your concern.",
  }
  export enum EDownloadsAndUpgrades 
  {
    header = "DOWNLOADS AND UPGRADES",
    content = "As a Premium Customer, you can download the purchased source code and access future upgrades by logging into our portal. Stay up-to-date with the latest versions and feel free to reach out to our support team for any assistance. Thank you for choosing our services!",
  }
  export enum EDeveloperConnect 
  {
    header= "DEVELOPER CONNECT",
    content="For any questions regarding the source code, installation and setup of Piccosupport or for any clarifications, you may get in touch with our Developers by writing to"
  }

import { Component, OnInit, ViewChild } from "@angular/core";
import { NgOtpInputComponent } from "ng-otp-input";
import { Config } from "protractor/built/config";
import { AuthSandbox } from "../../auth.sandbox";
import { ActivatedRoute } from "@angular/router";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { ILoginDetail } from "./otpverify.model";

@Component({
  selector: "app-optverify",
  templateUrl: "./optverify.component.html",
  styleUrls: ["./optverify.component.scss"],
})
export class OptverifyComponent implements OnInit {
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
  };
  @ViewChild("ngOtpInput") ngOtpInput: NgOtpInputComponent | undefined;
  public submitted = false;
  private otp: string = "";
  public loginDetail: ILoginDetail;

  constructor(public authSandbox: AuthSandbox, public route: ActivatedRoute) {
    const uniqueId = this.route.snapshot.queryParamMap.get("uniqueId")
    this.loginDetail = JSON.parse(CryptoJS.AES.decrypt(
        (uniqueId.split(' ') ? uniqueId.split(' ').join('+') : uniqueId),environment.CRYPTO_SECRET).toString(CryptoJS.enc.Utf8));
  }

  ngOnInit(): void {}

  onOtpChange(event) {
    this.otp = event;
    this.submitted = true;
  }

  otpVerify() {
    if (JSON.parse(JSON.stringify(this.otp)).length !== 6) {
      return;
    }
    const params: ILoginDetail = {
      otp: this.otp,
      id: this.loginDetail.id
    };
    this.authSandbox.doVerifyOtp(params);
    this.authSandbox.verifyOtpFailed$.subscribe((val) => {
      if (val == true) {
        this.otp = "";
        this.ngOtpInput.setValue("");
      }
    });
  }
  resendOtp() {
    if (this.ngOtpInput) {
      this.otp = "";
      this.ngOtpInput.setValue("");
      const param: ILoginDetail = {
        login: this.loginDetail.sentTo
      };
      this.authSandbox.doLogin(param);
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { CreateCRComponent } from '../../popups/createcr/createcr.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
    clientId = JSON.parse(localStorage.getItem('clientDetail')).id;;
    sideBarNavItems: RouteInfo[];
    Colors: Array<any> = ["#faf2ee"];
    public isCreate = false;
    public select: any = 0;
    constructor(private modalService: NgbModal, public commonSandbox: CommonSandbox,
        public router: Router, private active: ActivatedRoute) {
        this.sideBarNavItems = ROUTES;
    }

    ngOnInit() {
        this.getOpenTicketCount();
        this.getClosedTicketsCount();
        this.getScheduleTicketsCount();
    }

    getOpenTicketCount() {
        const param: any = {};
        param.ticketStatusId = 1;
        param.clientId = this.clientId;
        param.count = true;
        param.declined = 0;
        this.commonSandbox.getOpenTicketListCount(param);
    }

    getClosedTicketsCount() {
        const param: any = {};
        param.ticketStatusId = 6;
        param.clientId = this.clientId;
        param.count = true;
        this.commonSandbox.getClosureTicketCount(param);
    }

    getScheduleTicketsCount() {
        const param: any = {};
        param.ticketStatusId = 4;
        param.clientApprovalStatus = 0;
        param.clientId = this.clientId;
        param.count = true;
        param.declined = 0;
        param.waitForApproval='1';
        this.commonSandbox.getScheduleTicketCount(param);
      }

    opencreatecr() {
        this.isCreate = true;
        this.select = 1;
        this.modalService.open(CreateCRComponent, {
            size: 'lg', windowClass: 'modal-holder', backdrop: 'static'
        }).result.then((result) => {
            this.isCreate = false;
            this.select = 0;
        });
    }

    close() {
        this.modalService.dismissAll();
    }

    checkIsAcive(sidebarPath) {
        const sideBar = sidebarPath.split('/')[2];
        const routerPath = this.active.snapshot['_routerState'].url.split('/')[2];
        return (routerPath == 'opentickets' ? 'openticket' : routerPath).includes(sideBar)
    }   
}

import { Component, OnInit } from '@angular/core';
import { AuthSandbox } from 'src/app/auth/auth.sandbox';

@Component({
  selector: 'app-confirmationpage',
  templateUrl: './confirmationpage.component.html',
  styleUrls: ['./confirmationpage.component.scss']
})
export class ConfirmationPageComponent implements OnInit {

  constructor(public authSanbox: AuthSandbox) { }

  ngOnInit() {
  }

}

import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility/utilityHelpers';
import { ResponseInterface } from '../../../shared/interfaces/interface';
import { CreateRequestModel } from '../models/create-request.model';
import { PaymentListRequestModel } from '../models/payment-list-request.modal';
import { TicketListModel } from '../models/ticket-list.model';
import { UpdateUserDetailRequest } from '../models/update.profile.request';
import { SendApprovalRequestModel } from '../models/send-approval.request.model';
import { PaymentLogRequestModel } from '../models/payment-log-request.modal';

export const ActionTypes = {

    GET_PROJECT_LIST: type('[project] project list'),
    GET_PROJECT_LIST_SUCCESS: type('[project] project list Success'),
    GET_PROJECT_LIST_FAIL: type('[project] project list Fail'),

    CREATE_REQUEST_ADMIN: type('[project] create request admin'),
    CREATE_REQUEST_ADMIN_SUCCESS: type('[project] create request admin Success'),
    CREATE_REQUEST_ADMIN_FAIL: type('[project] create request admin Fail'),

    GET_TOTAL_PROJECT_LIST: type('[project] project total list'),
    GET_TOTAL_PROJECT_LIST_SUCCESS: type('[project] project total list Success'),
    GET_TOTAL_PROJECT_LIST_FAIL: type('[project] project total list Fail'),

    GET_TOTAL_PROJECT_COUNT: type('[project] project total count'),
    GET_TOTAL_PROJECT_COUNT_SUCCESS: type('[project] project total count Success'),
    GET_TOTAL_PROJECT_COUNT_FAIL: type('[project] project total count Fail'),


    CREATE_REQUEST: type('[create_request] create request '),
    CREATE_REQUEST_SUCCESS: type('[create_request] create request Success'),
    CREATE_REQUEST_FAIL: type('[create_request] create request Fail'),

    GET_PRIORITY_LIST: type('[priority] priority list'),
    GET_PRIORITY_LIST_SUCCESS: type('[priority] priority list Success'),
    GET_PRIORITY_LIST_FAIL: type('[priority] priority list Fail'),

    GET_TICKET_STATUS_LIST: type('[status] ticket status'),
    GET_TICKET_STATUS_LIST_SUCCESS: type('[status] ticket status Success'),
    GET_TICKET_STATUS_LIST_FAIL: type('[status] ticket status Fail'),

    TICKET_STATUS_CHANGE: type('[status] ticket status change'),
    TICKET_STATUS_CHANGE_SUCCESS: type('[status] ticket status change Success'),
    TICKET_STATUS_CHANGE_FAIL: type('[status] ticket status change Fail'),

    TICKET_DECLINE: type('[status] ticket decline'),
    TICKET_DECLINE_SUCCESS: type('[status] ticket decline Success'),
    TICKET_DECLINE_FAIL: type('[status] ticket decline Fail'),

    GET_TICKET_LIST: type('[ticket] ticket list'),
    GET_TICKET_LIST_SUCCESS: type('[ticket] ticket list Success'),
    GET_TICKET_LIST_FAIL: type('[ticket] ticket list Fail'),

    GET_TICKET_COUNT: type('[ticket] ticket count'),
    GET_TICKET_COUNT_SUCCESS: type('[ticket] ticket count Success'),
    GET_TICKET_COUNT_FAIL: type('[ticket] ticket count Fail'),

    GET_TOTAL_TICKET_COUNT: type('[ticket] total ticket count'),
    GET_TOTAL_TICKET_COUNT_SUCCESS: type('[ticket] total ticket count Success'),
    GET_TOTAL_TICKET_COUNT_FAIL: type('[ticket] total ticket count Fail'),

    GET_TOTAL_TICKET: type('[ticket] total ticket '),
    GET_TOTAL_TICKET_SUCCESS: type('[ticket] total ticket  Success'),
    GET_TOTAL_TICKET_FAIL: type('[ticket] total ticket  Fail'),


    GET_FREE_TICKET_COUNT: type('[ticket] free ticket count'),
    GET_FREE_TICKET_COUNT_SUCCESS: type('[ticket] free ticket count Success'),
    GET_FREE_TICKET_COUNT_FAIL: type('[ticket] free ticket count Fail'),

    GET_APPROVE_TICKET_LIST: type('[ticket] approval ticket list'),
    GET_APPROVE_TICKET_LIST_SUCCESS: type('[ticket] approval ticket list Success'),
    GET_APPROVE_TICKET_LIST_FAIL: type('[ticket] approval ticket list Fail'),

    GET_APPROVAL_COUNT: type('[ticket] approval ticket count'),
    GET_APPROVAL_COUNT_SUCCESS: type('[ticket] approval ticket count Success'),
    GET_APPROVAL_COUNT_FAIL: type('[ticket] approval ticket count Fail'),

    GET_REVIEW_TICKET_LIST: type('[ticket] review ticket list'),
    GET_REVIEW_TICKET_LIST_SUCCESS: type('[ticket] review ticket list Success'),
    GET_REVIEW_TICKET_LIST_FAIL: type('[ticket] review ticket list Fail'),

    GET_REVIEW_TICKET_COUNT: type('[ticket] review ticket count'),
    GET_REVIEW_TICKET_COUNT_SUCCESS: type('[ticket] review ticket count Success'),
    GET_REVIEW_TICKET_COUNT_FAIL: type('[ticket] review ticket count Fail'),

    GET_SCHEDULE_TICKET_COUNT: type('[ticket] schedule ticket count'),
    GET_SCHEDULE_TICKET_COUNT_SUCCESS: type('[ticket] schedule ticket count Success'),
    GET_SCHEDULE_TICKET_COUNT_FAIL: type('[ticket] schedule ticket count Fail'),

    GET_SCHEDULE_TICKETS: type('[ticket] schedule tickets'),
    GET_SCHEDULE_TICKETS_SUCCESS: type('[ticket] schedule tickets Success'),
    GET_SCHEDULE_TICKETS_FAIL: type('[ticket] schedule tickets Fail'),

    GET_CLOSURE_TICKET_LIST: type('[ticket] closure ticket list'),
    GET_CLOSURE_TICKET_LIST_SUCCESS: type('[ticket] closure ticket list Success'),
    GET_CLOSURE_TICKET_LIST_FAIL: type('[ticket] closure ticket list Fail'),

    GET_CLOSURE_TICKET_COUNT: type('[ticket] closure ticket count'),
    GET_CLOSURE_TICKET_COUNT_SUCCESS: type('[ticket] closure ticket count Success'),
    GET_CLOSURE_TICKET_COUNT_FAIL: type('[ticket] closure ticket count Fail'),

    GET_RAISED_TICKET_COUNT: type('[ticket] raised ticket count'),
    GET_RAISED_TICKET_COUNT_SUCCESS: type('[ticket] raised ticket count Success'),
    GET_RAISED_TICKET_COUNT_FAIL: type('[ticket] raised ticket count Fail'),

    GET_TICKET_DETAIL: type('[detail] ticket detail'),
    GET_TICKET_DETAIL_SUCCESS: type('[detail] ticket detail Success'),
    GET_TICKET_DETAIL_FAIL: type('[detail] ticket detail Fail'),

    CHANGE_PASSWORD: type('[auth] change password'),
    CHANGE_PASSWORD_SUCCESS: type('[auth] change password Success'),
    CHANGE_PASSWORD_FAIL: type('[auth] change password Fail'),

    CLEAR_ORDER_LIST: type('[payments] clear order list'),

    GET_PAYMENTS_LIST: type('[payment_list] payment list '),
    GET_PAYMENTS_LIST_SUCCESS: type('[payment_list] payment list Success'),
    GET_PAYMENTS_LIST_FAIL: type('[payment_list] payment list Fail'),

    GET_PAYMENTS_LIST_COUNT: type('[payment_list] payment list Count '),
    GET_PAYMENTS_LIST_COUNT_SUCCESS: type('[payment_list] payment list Count Success'),
    GET_PAYMENTS_LIST_COUNT_FAIL: type('[payment_list] payment list Count Fail'),


    GET_PAYMENTS_DETAILS: type('[payment_list] payment Details '),
    GET_PAYMENTS_DETAILS_SUCCESS: type('[payment_list] payment Details Success'),
    GET_PAYMENTS_DETAILS_FAIL: type('[payment_list] payment Details Fail'),

    GET_INVOICE_DETAILS: type('[Invoice] invoice details '),
    GET_INVOICE_DETAILS_SUCCESS: type('[Invoice] invoice details success'),
    GET_INVOICE_DETAILS_FAIL: type('[Invoice] invoice details fail'),

    GET_PAYMENT_LOG: type('[payment log] payment log unread'),
    GET_PAYMENT_LOG_SUCCESS: type('[payment log] payment log Success'),
    GET_PAYMENT_LOG_FAIL: type('[payment log] payment log Fail'),

    DOWNLOAD_INVOICE: type('[download] invoice'),
    DOWNLOAD_INVOICE_SUCCESS: type('[download] invoice success'),
    DOWNLOAD_INVOICE_FAIL: type('[download] invoice Fail'),

    ONLINE_RESOURCES_LIST: type('[resources online] resources online'),
    ONLINE_RESOURCES_LIST_SUCCESS: type('[resources online] resources online Success'),
    ONLINE_RESOURCES_LIST_FAIL: type('[resources online] resources online Fail'),


    ASSIGNED_RESOURCES_LIST: type('[resources assigned] resources assigned'),
    ASSIGNED_RESOURCES_LIST_SUCCESS: type('[resources assigned] resources assigned Success'),
    ASSIGNED_RESOURCES_LIST_FAIL: type('[resources assigned] resources assigned Fail'),


    GET_TICKET_UPDATE_LIST: type('[modified ticket date] Get Ticket Update List'),
    GET_TICKET_UPDATE_LIST_SUCCESS: type('[modified ticket date] Get Ticket Update List Success'),
    GET_TICKET_UPDATE_LIST_FAIL: type('[modified ticket date] Get Ticket Update List Fail'),


    GET_OPEN_TICKET_LIST: type('[GET_OPEN_TICKET_LIST] Open Ticket List'),
    GET_OPEN_TICKET_LIST_SUCCESS: type('[GET_OPEN_TICKET_LIST] Open Ticket List success'),
    GET_OPEN_TICKET_LIST_FAIL: type('[GET_OPEN_TICKET_LIST] Open Ticket List Fail'),

    GET_OPEN_TICKET_LIST_COUNT: type('[GET_OPEN_TICKET_LIST] Open Ticket List Count'),
    GET_OPEN_TICKET_LIST_COUNT_SUCCESS: type('[GET_OPEN_TICKET_LIST] Open Ticket List Count success'),
    GET_OPEN_TICKET_LIST_COUNT_FAIL: type('[GET_OPEN_TICKET_LIST] Open Ticket List Count Fail'),


    GET_INPROGRESS_TICKET_LIST: type('[GET_INPROGRESS_TICKET_LIST] Inprogress Ticket List'),
    GET_INPROGRESS_TICKET_LIST_SUCCESS: type('[GET_INPROGRESS_TICKET_LIST] Inprogress Ticket List success'),
    GET_INPROGRESS_TICKET_LIST_FAIL: type('[GET_INPROGRESS_TICKET_LIST] Inprogress Ticket List Fail'),


    GET_ACCEPTANCE_TICKETS: type('[GET_ACCEPTANCE_TICKETS] Acceptance Open Ticket List'),
    GET_ACCEPTANCE_TICKETS_SUCCESS: type('[GET_ACCEPTANCE_TICKETS] Acceptance Ticket List success'),
    GET_ACCEPTANCE_TICKETS_FAIL: type('[GET_ACCEPTANCE_TICKETS] Acceptance Ticket List Fail'),

    GET_ACCEPTANCE_TICKETS_COUNT: type('[GET_ACCEPTANCE_TICKETS_COUNT] Acceptance Ticket List Count'),
    GET_ACCEPTANCE_TICKETS_COUNT_SUCCESS: type('[GET_ACCEPTANCE_TICKETS_COUNT] Acceptance Ticket List Count success'),
    GET_ACCEPTANCE_TICKETS_COUNT_FAIL: type('[GET_ACCEPTANCE_TICKETS_COUNT] Acceptance Ticket List Count Fail'),

    GET_SIDE_SCHEDULE_TICKET_COUNT: type('[ticket] side schedule ticket count'),
    GET_SIDE_SCHEDULE_TICKET_COUNT_SUCCESS: type('[ticket] side schedule ticket count Success'),
    GET_SIDE_SCHEDULE_TICKET_COUNT_FAIL: type('[ticket] side schedule ticket count Fail'),

    GET_SIDE_OPEN_TICKET_LIST_COUNT: type('[ticket] side open ticket count'),
    GET_SIDE_OPEN_TICKET_LIST_COUNT_SUCCESS: type('[ticket] side open ticket count Success'),
    GET_SIDE_OPEN_TICKET_LIST_COUNT_FAIL: type('[ticket] side open ticket count Fail'),

    GET_SIDE_INPROGRESS_TICKET_LIST: type('[GET_SIDE_INPROGRESS_TICKET_LIST] Inprogress Ticket List'),
    GET_SIDE_INPROGRESS_TICKET_LIST_SUCCESS: type('[GET_SIDE_INPROGRESS_TICKET_LIST] Inprogress Ticket List success'),
    GET_SIDE_INPROGRESS_TICKET_LIST_FAIL: type('[GET_SIDE_INPROGRESS_TICKET_LIST] Inprogress Ticket List Fail'),

    GET_SIDE_CLOSURE_TICKET_COUNT: type('[SIDE_ CLOSE COUNT] closure ticket count'),
    GET_SIDE_CLOSURE_TICKET_COUNT_SUCCESS: type('[SIDE_ CLOSE COUNT] closure ticket count Success'),
    GET_SIDE_CLOSURE_TICKET_COUNT_FAIL: type('[SIDE_ CLOSE COUNT] closure ticket count Fail'),

    UPLOAD_DOCUMENTS: type('[UPLOAD_DOCUMENTS] upload documents count'),
    UPLOAD_DOCUMENTS_SUCCESS: type('[UPLOAD_DOCUMENTS] upload documents Success'),
    UPLOAD_DOCUMENTS_FAIL: type('[UPLOAD_DOCUMENTS] upload documents Fail'),

    DELETE_DOCUMENTS: type('[DELETE_DOCUMENTS] delete documents count'),
    DELETE_DOCUMENTS_SUCCESS: type('[DELETE_DOCUMENTS] delete documents Success'),
    DELETE_DOCUMENTS_FAIL: type('[DELETE_DOCUMENTS] delete documents Fail'),

    OFFLINE_PAYMENT: type('[OFFLINE_PAYMENT] Offline Payment'),
    OFFLINE_PAYMENT_SUCCESS: type('[OFFLINE_PAYMENT] Offline Payment Success'),
    OFFLINE_PAYMENT_FAIL: type('[OFFLINE_PAYMENT] Offline Payment Fail'),


    SEND_MESSAGE_DATA: type('[messageData] Send Message Data'),
    SEND_MESSAGE_DATA_SUCCESS: type('[messageData] Send Message Data Success'),
    SEND_MESSAGE_DATA_FAIL: type('[messageData] Send Message Data Fail'),

    GET_MESSAGE_LIST: type('[messageList] Get Message List'),
    GET_MESSAGE_LIST_SUCCESS: type('[messageList] Get Message List Success'),
    GET_MESSAGE_LIST_FAIL: type('[messageList] Get Message List Fail'),

    GET_MESSAGE_LIST_COUNT: type('[messageList] Get Message List Count'),
    GET_MESSAGE_LIST_COUNT_SUCCESS: type('[messageList] Get Message List Count Success'),
    GET_MESSAGE_LIST_COUNT_FAIL: type('[messageList] Get Message List Count Fail'),


    GET_REPLIEDBASEMESSAGE_LIST: type('[messageList] Get RepliedBaseMessage List'),
    GET_REPLIEDBASEMESSAGE_LIST_SUCCESS: type('[messageList] Get RepliedBaseMessage List Success'),
    GET_REPLIEDBASEMESSAGE_LIST_FAIL: type('[messageList] Get RepliedBaseMessage List Fail'),

    DELETE_MESSAGE: type('[messageList] delete Message List'),
    DELETE_MESSAGE_SUCCESS: type('[messageList] delete Message List Success'),
    DELETE_MESSAGE_FAIL: type('[messageList] delete Message List Fail'),

    STATUS_ACTIVITY: type('[statusActivity] statusActivity Data'),
    
    
    UNDO_APPROVAL: type('[undoApproval] undo approval Data'),
    UNDO_APPROVAL_SUCCESS: type('[undoApproval] undo approval Data Success'),
    UNDO_APPROVAL_FAIL: type('[undoApproval] undo approval Data Fail'),

    START_WORK: type('[startWork] Start Work'),
    START_WORK_SUCCESS: type('[startWork] Start Work Success'),
    START_WORK_FAIL: type('[startWork] Start Work Fail'),

    MOBILE_NOTIFICATION_LIST: type('[mobilenotification] Mobile Notification List'),
    MOBILE_NOTIFICATION_LIST_SUCCESS: type('[mobilenotification] Mobile Notification List Success'),
    MOBILE_NOTIFICATION_LIST_FAIL: type('[mobilenotification] Mobile Notification List Fail'),
 
    GET_MESSAGE_LIST_MOBILE: type('[mobilenotification] Get Message List Mobile'),
    GET_MESSAGE_LIST_MOBILE_SUCCESS: type('[mobilenotification] Get Message List Mobile Success'),
    GET_MESSAGE_LIST_MOBILE_FAIL: type('[mobilenotification] Get Message List Mobile Fail'),

    CLIENT_CANCEL_REQUEST: type('[Cilent Cancel Request] Cilent Cancel Request'),
    CLIENT_CANCEL_REQUEST_SUCCESS: type('[Cilent Cancel Request] Cilent Cancel Request Success'),
    CLIENT_CANCEL_REQUEST_FAIL: type('[Cilent Cancel Request] Cilent Cancel Request Fail'),
    
    CLEAR_DATA: type('[uploadFile] Clear Data'),


    GET_APPROVED_TICKET_LIST_COUNT: type('[ticket] approved ticket count'),
    GET_APPROVED_TICKET_LIST_COUNT_SUCCESS: type('[ticket] approved ticket count Success'),
    GET_APPROVED_TICKET_LIST_COUNT_FAIL: type('[ticket] approved ticket count Fail'),

    GET_ORDER_DETAILS: type('[payments] order details'),
    GET_ORDER_DETAILS_SUCCESS: type('[payments] order details Success'),
    GET_ORDER_DETAILS_FAIL: type('[payments] order details Fail'),

    UPDATE_PAYMENT: type('[PAYMENT] update payment '),
    UPDATE_PAYMENT_SUCCESS: type('[PAYMENT] update payment success'),
    UPDATE_PAYMENT_FAIL: type('[PAYMENT] update payment fail'),

    UPLOAD_PROOF: type('[PAYMENT] upload proof '),
    UPLOAD_PROOF_SUCCESS: type('[PAYMENT] upload proof success'),
    UPLOAD_PROOF_FAIL: type('[PAYMENT] upload proof fail'),

    EXPORT: type('[PAYMENT] export '),
    EXPORT_SUCCESS: type('[PAYMENT] export success'),
    EXPORT_FAIL: type('[PAYMENT] export fail'),


    ORDER_EXPORT: type('[PAYMENT] order export '),
    ORDER_EXPORT_SUCCESS: type('[PAYMENT] order export success'),
    ORDER_EXPORT_FAIL: type('[PAYMENT]  order export fail'),


    ASSESSMENT_LIST :type('[assessment] assessment list'),
    ASSESSMENT_LIST_SUCCESS: type('[assessment] assessment list Success'),
    ASSESSMENT_LIST_FAIL: type('[assessment] assessment list Fail'),

       
    CLIENT_APPROVAL:type('[client] client approval'),
    CLIENT_APPROVAL_SUCCESS: type('[client] client approval Success'),
    CLIENT_APPROVAL_FAIL: type('[client]  client approval  Fail'),

    SIDEBAR_COUNT:type('[sidebar] sidebar count'),
    SIDEBAR_COUNT_SUCCESS: type('[sidebar] sidebar count Success'),
    SIDEBAR_COUNT_FAIL: type('[sidebar]  sidebar count  Fail'),



};

/**
 * get project list actions.
 */
export class GetProjectList implements Action {
    type = ActionTypes.GET_PROJECT_LIST;

    constructor(public payload: any) {
    }
}

export class GetProjectListSuccess implements Action {
    type = ActionTypes.GET_PROJECT_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class GetProjectListFail implements Action {
    type = ActionTypes.GET_PROJECT_LIST_FAIL;

    constructor(public payload: any) {

    }
}

/**
 * get approved ticket list count.
 */
export class GetApprovedTicketListCount implements Action {
    type = ActionTypes.GET_APPROVED_TICKET_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class GetApprovedTicketListCountSuccess implements Action {
    type = ActionTypes.GET_APPROVED_TICKET_LIST_COUNT_SUCCESS;

    constructor(public payload: any) {

    }
}

export class GetApprovedTicketListCountFail implements Action {
    type = ActionTypes.GET_APPROVED_TICKET_LIST_COUNT_FAIL;

    constructor(public payload: any) {

    }
}
/**
 * get total project list actions.
 */
export class GetTotalProjectList implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_LIST;

    constructor(public payload: any) {
    }
}

export class GetTotalProjectListSuccess implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTotalProjectListFail implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get total project count actions.
 */
export class GetTotalProjectCount implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_COUNT;

    constructor(public payload: any) {
    }
}

export class GetTotalProjectCountSuccess implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTotalProjectCountFail implements Action {
    type = ActionTypes.GET_TOTAL_PROJECT_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get priority list actions.
 */
export class GetPriorityList implements Action {
    type = ActionTypes.GET_PRIORITY_LIST;

    constructor(public payload: any) {
    }
}

export class GetPriorityListSuccess implements Action {
    type = ActionTypes.GET_PRIORITY_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPriorityListFail implements Action {
    type = ActionTypes.GET_PRIORITY_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * create request actions.
 */
export class CreateRequestAction implements Action {
    type = ActionTypes.CREATE_REQUEST;

    constructor(public payload: CreateRequestModel) {
    }
}

export class CreateRequestSuccess implements Action {
    type = ActionTypes.CREATE_REQUEST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CreateRequestFail implements Action {
    type = ActionTypes.CREATE_REQUEST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get ticket status list actions.
 */
export class GetStatusAction implements Action {
    type = ActionTypes.GET_TICKET_STATUS_LIST;

    constructor(public payload: any) {
    }
}

export class GetStatusSuccess implements Action {
    type = ActionTypes.GET_TICKET_STATUS_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetStatusFail implements Action {
    type = ActionTypes.GET_TICKET_STATUS_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get ticket status change actions.
 */
export class GetTicketStatusChange implements Action {
    type = ActionTypes.TICKET_STATUS_CHANGE;

    constructor(public payload: any) {
    }
}

export class GetTicketStatusChangeSuccess implements Action {
    type = ActionTypes.TICKET_STATUS_CHANGE_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketStatusChangeFail implements Action {
    type = ActionTypes.TICKET_STATUS_CHANGE_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get ticket decline actions.
 */
export class GetTicketDecline implements Action {
    type = ActionTypes.TICKET_DECLINE;

    constructor(public payload: any) {
    }
}

export class GetTicketDeclineSuccess implements Action {
    type = ActionTypes.TICKET_DECLINE_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketDeclineFail implements Action {
    type = ActionTypes.TICKET_DECLINE_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * ticket list actions.
 */
export class GetTicketListAction implements Action {
    type = ActionTypes.GET_TICKET_LIST;

    constructor(public payload: TicketListModel) {
    }
}

export class GetTicketListSuccess implements Action {
    type = ActionTypes.GET_TICKET_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketListFail implements Action {
    type = ActionTypes.GET_TICKET_LIST_FAIL;

    constructor(public payload: any) {
    }
}


/**
 * get ticket count actions.
 */
export class GetTicketCountAction implements Action {
    type = ActionTypes.GET_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetTicketCountSuccess implements Action {
    type = ActionTypes.GET_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketCountFail implements Action {
    type = ActionTypes.GET_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get total ticket count actions.
 */
export class GetTotalTicketCountAction implements Action {
    type = ActionTypes.GET_TOTAL_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetTotalTicketCountSuccess implements Action {
    type = ActionTypes.GET_TOTAL_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTotalTicketCountFail implements Action {
    type = ActionTypes.GET_TOTAL_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * get total ticket  actions.
 */
export class GetTotalTicketAction implements Action {
    type = ActionTypes.GET_TOTAL_TICKET;

    constructor(public payload: TicketListModel) {
    }
}

export class GetTotalTicketSuccess implements Action {
    type = ActionTypes.GET_TOTAL_TICKET_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTotalTicketFail implements Action {
    type = ActionTypes.GET_TOTAL_TICKET_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get free ticket count actions.
 */
export class GetFreeTicketCountAction implements Action {
    type = ActionTypes.GET_FREE_TICKET_COUNT;

    constructor(public payload: any) {
    }
}

export class GetFreeTicketCountSuccess implements Action {
    type = ActionTypes.GET_FREE_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetFreeTicketCountFail implements Action {
    type = ActionTypes.GET_FREE_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

// getReviewTicketsList
export class GetReviewTicketsList implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_LIST;

    constructor(public payload: TicketListModel) {
    }
}

export class GetReviewTicketsListSuccess implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetReviewTicketsListFail implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_LIST_FAIL;

    constructor(public payload: any) {
    }
}


/**
 * get review ticket count actions.
 */
export class GetReviewTicketCountAction implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetReviewTicketCountSuccess implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetReviewTicketCountFail implements Action {
    type = ActionTypes.GET_REVIEW_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}
// GetApproveTicketsList
export class GetApproveTicketsList implements Action {
    type = ActionTypes.GET_APPROVE_TICKET_LIST;

    constructor(public payload: TicketListModel) {
    }
}

export class GetApproveTicketsListSuccess implements Action {
    type = ActionTypes.GET_APPROVE_TICKET_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetApproveTicketsListFail implements Action {
    type = ActionTypes.GET_APPROVE_TICKET_LIST_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * get approval count actions.
 */
export class GetApprovalCountAction implements Action {
    type = ActionTypes.GET_APPROVAL_COUNT;

    constructor(public payload: any) {
    }
}

export class GetApprovalCountSuccess implements Action {
    type = ActionTypes.GET_APPROVAL_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetApprovalCountFail implements Action {
    type = ActionTypes.GET_APPROVAL_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get schedule ticket  actions.
 */
export class GetScheduleTicketsAction implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKETS;

    constructor(public payload: TicketListModel) {
    }
}

export class GetScheduleTicketsSuccess implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKETS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetScheduleTicketsFail implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKETS_FAIL;

    constructor(public payload: any) {
    }
}


/**
 * get schedule ticket count actions.
 */
export class GetScheduleTicketCountAction implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKET_COUNT;
  
    constructor(public payload: TicketListModel) {
    }
}

export class GetScheduleTicketCountSuccess implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetScheduleTicketCountFail implements Action {
    type = ActionTypes.GET_SCHEDULE_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

// GetClosureTicketList
export class GetClosureTicketList implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_LIST;

    constructor(public payload: TicketListModel) {
    }
}

export class GetClosureTicketListSuccess implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetClosureTicketListFail implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get closure ticket count actions.
 */
export class GetClosureTicketCountAction implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetClosureTicketCountSuccess implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetClosureTicketCountFail implements Action {
    type = ActionTypes.GET_CLOSURE_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get raised ticket count actions.
 */
export class GetRaisedTicketCountAction implements Action {
    type = ActionTypes.GET_RAISED_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetRaisedTicketCountSuccess implements Action {
    type = ActionTypes.GET_RAISED_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetRaisedTicketCountFail implements Action {
    type = ActionTypes.GET_RAISED_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get ticket detail actions.
 */
export class GetTicketDetailAction implements Action {
    type = ActionTypes.GET_TICKET_DETAIL;

    constructor(public payload: any) {
    }
}

export class GetTicketDetailSuccess implements Action {
    type = ActionTypes.GET_TICKET_DETAIL_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketDetailFail implements Action {
    type = ActionTypes.GET_TICKET_DETAIL_FAIL;

    constructor(public payload: any) {
    }
}

export class ChangePasswordAction implements Action {
    type = ActionTypes.CHANGE_PASSWORD;

    constructor(public payload: UpdateUserDetailRequest) {
    }
}

export class ChangePasswordSuccess implements Action {
    type = ActionTypes.CHANGE_PASSWORD_SUCCESS;

    constructor(public payload: any) {
    }
}

export class ChangePasswordActionFail implements Action {
    type = ActionTypes.CHANGE_PASSWORD_FAIL;

    constructor(public payload: any) {
    }
}



export class ClearOrderListsAction implements Action {
    type = ActionTypes.CLEAR_ORDER_LIST;

    constructor(public payload: any = null) {
    }
}

/**
 * payment list  actions.
 */
export class GetPaymentList implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST;

    constructor(public payload: any) {
    }
}

export class GetPaymentListSuccess implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST_SUCCESS;

    constructor(public payload: ResponseInterface, public payType: string) {
    }
}

export class GetPaymentListFail implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * payment list  Count actions.
 */
export class GetPaymentListCount implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST_COUNT;

    constructor(public payload: PaymentListRequestModel) {
    }
}

export class GetPaymentListCountSuccess implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPaymentListCountFail implements Action {
    type = ActionTypes.GET_PAYMENTS_LIST_COUNT_FAIL;

    constructor(public payload: any) {
    }
}




/**
 * payment Details  actions.
 */
export class GetPaymentDetails implements Action {
    type = ActionTypes.GET_PAYMENTS_DETAILS;

    constructor(public payload: any) {
    }
}

export class GetPaymentDetailsSuccess implements Action {
    type = ActionTypes.GET_PAYMENTS_DETAILS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPaymentDetailsFail implements Action {
    type = ActionTypes.GET_PAYMENTS_DETAILS_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get Invoice Details  actions.
 */
export class GetInvoiceDetails implements Action {
    type = ActionTypes.GET_INVOICE_DETAILS;

    constructor(public payload: any) {
    }
}

export class GetInvoiceDetailsSuccess implements Action {
    type = ActionTypes.GET_INVOICE_DETAILS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetInvoiceDetailsFail implements Action {
    type = ActionTypes.GET_INVOICE_DETAILS_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * payment log actions.
 */
export class PaymentLog implements Action {
    type = ActionTypes.GET_PAYMENT_LOG;

    constructor(public payload: PaymentLogRequestModel) {
    }
}

export class PaymentLogSuccess implements Action {
    type = ActionTypes.GET_PAYMENT_LOG_SUCCESS;

    constructor(public payload: any) {
    }
}

export class PaymentLogFail implements Action {
    type = ActionTypes.GET_PAYMENT_LOG_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * invoice download action
 */
export class DownloadInvoice implements Action {
    type = ActionTypes.DOWNLOAD_INVOICE;

    constructor(public payload: any) {
    }
}

export class DownloadInvoiceSuccess implements Action {
    type = ActionTypes.DOWNLOAD_INVOICE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DownloadInvoiceFail implements Action {
    type = ActionTypes.DOWNLOAD_INVOICE_FAIL;

    constructor(public payload: any) {
    }
}



/**
 * Assign Resources list countactions.
 */
export class GetAssignedResourcesListAction implements Action {
    type = ActionTypes.ASSIGNED_RESOURCES_LIST;

    constructor(public payload: any) {
    }
}

export class GetAssignedResourcesListSuccess implements Action {
    type = ActionTypes.ASSIGNED_RESOURCES_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetAssignedResourcesListFailure implements Action {
    type = ActionTypes.ASSIGNED_RESOURCES_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * Online Resources list countactions.
 */
export class GetOnlineResourcesListAction implements Action {
    type = ActionTypes.ONLINE_RESOURCES_LIST;

    constructor(public payload: any) {
    }
}

export class GetOnlineResourcesListSuccess implements Action {
    type = ActionTypes.ONLINE_RESOURCES_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetOnlineResourcesListFailure implements Action {
    type = ActionTypes.ONLINE_RESOURCES_LIST_FAIL;

    constructor(public payload: any) {
    }
}

// GetTicketUpdateList
export class GetTicketUpdateList implements Action {
    type = ActionTypes.GET_TICKET_UPDATE_LIST;

    constructor(public payload: any) {
    }
}

export class GetTicketUpdateListSuccess implements Action {
    type = ActionTypes.GET_TICKET_UPDATE_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetTicketUpdateListFailure implements Action {
    type = ActionTypes.GET_TICKET_UPDATE_LIST_FAIL;

    constructor(public payload: any) {
    }
}


// GetOpenTicketListAction
export class GetOpenTicketListAction implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST;

    constructor(public payload: any) {
    }
}

export class GetOpenTicketListActionSuccess implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetOpenTicketListActionFail implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// GetOpenTicketListCount
export class GetOpenTicketListCount implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class GetOpenTicketListCountSuccess implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST_COUNT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetOpenTicketListCountFail implements Action {
    type = ActionTypes.GET_OPEN_TICKET_LIST_COUNT_FAIL;
    constructor(public payload: any) {
    }
}
// GetInProgressTicketListAction
export class GetInProgressTicketListAction implements Action {
    type = ActionTypes.GET_INPROGRESS_TICKET_LIST;

    constructor(public payload: any) {
    }
}

export class GetInProgressTicketListActionSuccess implements Action {
    type = ActionTypes.GET_INPROGRESS_TICKET_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetInProgressTicketListActionFail implements Action {
    type = ActionTypes.GET_INPROGRESS_TICKET_LIST_FAIL;
    constructor(public payload: any) {
    }
}
// GetAcceptanceTickets
export class GetAcceptanceTickets implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS;

    constructor(public payload: any) {
    }
}

export class GetAcceptanceTicketsSuccess implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetAcceptanceTicketsFail implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS_FAIL;
    constructor(public payload: any) {
    }
}

// GetAcceptanceTicketsCount
export class GetAcceptanceTicketsCount implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT;

    constructor(public payload: any) {
    }
}

export class GetAcceptanceTicketsCountSuccess implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetAcceptanceTicketsCountFail implements Action {
    type = ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT_FAIL;
    constructor(public payload: any) {
    }
}
// GetSideScheduleTicketCountAction
export class GetSideScheduleTicketCountAction implements Action {
    type = ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetSideScheduleTicketCountSuccess implements Action {
    type = ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetSideScheduleTicketCountFail implements Action {
    type = ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}
// GetSideOpenTicketListCount
export class GetSideOpenTicketListCount implements Action {
    type = ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class GetSideOpenTicketListCountSuccess implements Action {
    type = ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetSideOpenTicketListCountFail implements Action {
    type = ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT_FAIL;
    constructor(public payload: any) {
    }
}
export class GetSideInProgressTicketListAction implements Action {
    type = ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST;

    constructor(public payload: any) {
    }
}

export class GetSideInProgressTicketListActionSuccess implements Action {
    type = ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class GetSideInProgressTicketListActionFail implements Action {
    type = ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST_FAIL;
    constructor(public payload: any) {
    }
}
export class GetSideClosureTicketCountAction implements Action {
    type = ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT;

    constructor(public payload: TicketListModel) {
    }
}

export class GetSideClosureTicketCountSuccess implements Action {
    type = ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetSideClosureTicketCountFail implements Action {
    type = ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

// UploadDocumets
export class UploadDocumets implements Action {
    type = ActionTypes.UPLOAD_DOCUMENTS;

    constructor(public payload: any) {
    }
}

export class UploadDocumetsSuccess implements Action {
    type = ActionTypes.UPLOAD_DOCUMENTS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class UploadDocumetsFail implements Action {
    type = ActionTypes.UPLOAD_DOCUMENTS_FAIL;

    constructor(public payload: any) {
    }
}
// DeleteDocumets
export class DeleteDocumets implements Action {
    type = ActionTypes.DELETE_DOCUMENTS;

    constructor(public payload: any) {
    }
}

export class DeleteDocumetsSuccess implements Action {
    type = ActionTypes.DELETE_DOCUMENTS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class DeleteDocumetsFail implements Action {
    type = ActionTypes.DELETE_DOCUMENTS_FAIL;

    constructor(public payload: any) {
    }
}
// OfflinePayment
export class OfflinePayment implements Action {
    type = ActionTypes.OFFLINE_PAYMENT;

    constructor(public payload: any) {
    }
}

export class OfflinePaymentSuccess implements Action {
    type = ActionTypes.OFFLINE_PAYMENT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class OfflinePaymentFail implements Action {
    type = ActionTypes.OFFLINE_PAYMENT_FAIL;

    constructor(public payload: any) {
    }
}

export class sendMessage implements Action {
    type = ActionTypes.SEND_MESSAGE_DATA;

    constructor(public payload: any) {
    }
}

export class sendMessageSuccess implements Action {
    type = ActionTypes.SEND_MESSAGE_DATA_SUCCESS;

    constructor(public payload: ResponseInterface, public payload2:any) {

    }
}

export class sendMessageFail implements Action {
    type = ActionTypes.SEND_MESSAGE_DATA_FAIL;

    constructor(public payload: any) {

    }
}
export class getMessageList implements Action {
    type = ActionTypes.GET_MESSAGE_LIST;

    constructor(public payload: any) {
    }
}

export class getMessageListSuccess implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class getMessageListFail implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_FAIL;

    constructor(public payload: any) {

    }
}

export class getRepliedBaseMessageCount implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class getRepliedBaseMessageCountSuccess implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class getRepliedBaseMessageCountFail implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_COUNT_FAIL;

    constructor(public payload: any) {

    }
}

export class getRepliedBaseMessage implements Action {
    type = ActionTypes.GET_REPLIEDBASEMESSAGE_LIST;

    constructor(public payload: any) {
    }
}

export class getRepliedBaseMessageSuccess implements Action {
    type = ActionTypes.GET_REPLIEDBASEMESSAGE_LIST_SUCCESS;

    constructor(public payload: ResponseInterface,public payload2: any) {

    }
}

export class getRepliedBaseMessageFail implements Action {
    type = ActionTypes.GET_REPLIEDBASEMESSAGE_LIST_FAIL;

    constructor(public payload: any) {

    }
}
export class deleteMessage implements Action {
    type = ActionTypes.DELETE_MESSAGE;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class deleteMessageSuccess implements Action {
    type = ActionTypes.DELETE_MESSAGE_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class deleteMessageFail implements Action {
    type = ActionTypes.DELETE_MESSAGE_FAIL;

    constructor(public payload: any) {

    }

}


export class  statusActivity implements Action {
    type = ActionTypes.STATUS_ACTIVITY;

    constructor(public payload: any, public payload1:any) {
        console.log("STATUS_ACTIVITY", payload,payload1);
    }
}

export class undoApproval implements Action {
    type = ActionTypes.UNDO_APPROVAL;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class undoApprovalSuccess implements Action {
    type = ActionTypes.UNDO_APPROVAL_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class undoApprovalFail implements Action {
    type = ActionTypes.UNDO_APPROVAL_FAIL;

    constructor(public payload: any) {

    }
}
// StartWork

export class StartWork implements Action {
    type = ActionTypes.START_WORK;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class StartWorkSuccess implements Action {
    type = ActionTypes.START_WORK_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class StartWorkFail implements Action {
    type = ActionTypes.START_WORK_FAIL;

    constructor(public payload: any) {

    }
}
// MobileNotificationList
export class MobileNotificationList implements Action {
    type = ActionTypes.MOBILE_NOTIFICATION_LIST;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class MobileNotificationListSuccess implements Action {
    type = ActionTypes.MOBILE_NOTIFICATION_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class MobileNotificationListFail implements Action {
    type = ActionTypes.MOBILE_NOTIFICATION_LIST_FAIL;

    constructor(public payload: any) {

    }
}
// GetMessageListMobile
export class GetMessageListMobile implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_MOBILE;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class GetMessageListMobileSuccess implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_MOBILE_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class GetMessageListMobileFail implements Action {
    type = ActionTypes.GET_MESSAGE_LIST_MOBILE_FAIL;

    constructor(public payload: any) {

    }
}
// clearData
export class clearData implements Action {
    type = ActionTypes.CLEAR_DATA;

    constructor() {

    }
}



export class ClientCancelRequest implements Action {
    type = ActionTypes.CLIENT_CANCEL_REQUEST;

    constructor(public payload: any) {
        console.log("actionsucess");
    }
}

export class ClientCancelRequestSuccess implements Action {
    type = ActionTypes.CLIENT_CANCEL_REQUEST_SUCCESS;

    constructor(public payload: ResponseInterface) {

    }
}

export class ClientCancelRequestFail implements Action {
    type = ActionTypes.CLIENT_CANCEL_REQUEST_FAIL;

    constructor(public payload: any) {

    }
}

/**
 * Payments Order Details actions.
 */
export class GetOrderDetailsAction implements Action {
    type = ActionTypes.GET_ORDER_DETAILS;

    constructor(public payload: any) {
    }
}

export class GetOrderDetailsSuccess implements Action {
    type = ActionTypes.GET_ORDER_DETAILS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetOrderDetailsFailure implements Action {
    type = ActionTypes.GET_ORDER_DETAILS_FAIL;

    constructor(public payload: any) {
    }

}


/**
 * Update payment  actions.
 */
export class UpdatePayment implements Action {
    type = ActionTypes.UPDATE_PAYMENT;

    constructor(public payload: any) {
    }
}

export class UpdatePaymentSuccess implements Action {
    type = ActionTypes.UPDATE_PAYMENT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class UpdatePaymentFailure implements Action {
    type = ActionTypes.UPDATE_PAYMENT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * Upload Proof
 */
export class UploadProof implements Action {
    type = ActionTypes.UPLOAD_PROOF;

    constructor(public payload: any) {
    }
}

export class UploadProofSuccess implements Action {
    type = ActionTypes.UPLOAD_PROOF_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class UploadProofFailure implements Action {
    type = ActionTypes.UPLOAD_PROOF_FAIL;

    constructor(public payload: any) {
    }
}


/**
 *Export
 */
export class Export implements Action {
    type = ActionTypes.EXPORT;

    constructor(public payload: any =null) {
    }
}

export class ExportSuccess implements Action {
    type = ActionTypes.EXPORT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ExportFailure implements Action {
    type = ActionTypes.EXPORT_FAIL;

    constructor(public payload: any = null ) {
    }
}

/**
 *Order Export
 */
export class OrderExport implements Action {
    type = ActionTypes.ORDER_EXPORT;

    constructor(public payload: any =null) {
    }
}

export class OrderExportSuccess implements Action {
    type = ActionTypes.ORDER_EXPORT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class OrderExportFailure implements Action {
    type = ActionTypes.ORDER_EXPORT_FAIL;

    constructor(public payload: any = null ) {
    }
}

export class AssessmentBasedReviewFail implements Action {
    type = ActionTypes.ASSESSMENT_LIST;

    constructor(public payload: any) {

    }
}


export class   AssessmentList    implements Action {
    type = ActionTypes.ASSESSMENT_LIST;

    constructor(public payload: any) {
    }
}

export class AssessmentListSuccess implements Action {
    type = ActionTypes.ASSESSMENT_LIST_SUCCESS;

    constructor(public payload: any) {

    }
}

export class AssessmentListFail implements Action {
    type = ActionTypes.ASSESSMENT_LIST_FAIL;

    constructor(public payload: any) {

    }
}


export class   ClientApproval    implements Action {
    type = ActionTypes.CLIENT_APPROVAL;

    constructor(public payload: any) {
    }
}

export class ClientApprovalSuccess implements Action {
    type = ActionTypes.CLIENT_APPROVAL_SUCCESS;

    constructor(public payload: any) {

    }
}

export class  ClientApprovalFail implements Action {
    type = ActionTypes.CLIENT_APPROVAL_FAIL;

    constructor(public payload: any) {

    }
}

export class sidebarCount  implements Action {
    type = ActionTypes.SIDEBAR_COUNT;

    constructor(public payload: any) {
    }
}

export class sidebarCountSuccess implements Action {
    type = ActionTypes.SIDEBAR_COUNT_SUCCESS;

    constructor(public payload: any) {

    }
}

export class  sidebarCountFail implements Action {
    type = ActionTypes.SIDEBAR_COUNT_FAIL;

    constructor(public payload: any) {

    }
}
export type Actions =
    | DownloadInvoice
    | DownloadInvoiceSuccess
    | DownloadInvoiceFail

    | GetProjectList
    | GetProjectListSuccess
    | GetProjectListFail

    | GetTotalProjectList
    | GetTotalProjectListSuccess
    | GetTotalProjectListFail

    | GetTotalProjectCount
    | GetTotalProjectCountSuccess
    | GetTotalProjectCountFail

    | GetPriorityList
    | GetPriorityListSuccess
    | GetPriorityListFail

    | GetPaymentList
    | GetPaymentListSuccess
    | GetPaymentListFail

    | GetTicketStatusChange
    | GetTicketStatusChangeSuccess
    | GetTicketStatusChangeFail

    | GetTicketDecline
    | GetTicketDeclineSuccess
    | GetTicketDeclineFail

    | CreateRequestAction
    | CreateRequestSuccess
    | CreateRequestFail

    | GetTicketListAction
    | GetTicketListSuccess
    | GetTicketListFail

    | GetTicketCountAction
    | GetTicketCountSuccess
    | GetTicketCountFail

    | GetTotalTicketCountAction
    | GetTotalTicketCountSuccess
    | GetTotalTicketCountFail

    | GetFreeTicketCountAction
    | GetFreeTicketCountSuccess
    | GetFreeTicketCountFail

    | GetReviewTicketCountAction
    | GetReviewTicketCountSuccess
    | GetReviewTicketCountFail

    | GetScheduleTicketsAction
    | GetScheduleTicketsSuccess
    | GetScheduleTicketsFail

    | GetScheduleTicketCountAction
    | GetScheduleTicketCountSuccess
    | GetScheduleTicketCountFail

    | GetClosureTicketCountAction
    | GetClosureTicketCountSuccess
    | GetClosureTicketCountFail

    | GetApprovalCountSuccess
    | GetApprovalCountSuccess
    | GetApprovalCountFail


    | GetTicketDetailAction
    | GetTicketDetailSuccess
    | GetTicketDetailFail

    | ChangePasswordAction
    | ChangePasswordSuccess
    | ChangePasswordActionFail

    | ClearOrderListsAction
 
    | GetInvoiceDetails
    | GetInvoiceDetailsSuccess
    | GetInvoiceDetailsFail

    | GetPaymentDetails
    | GetPaymentDetailsSuccess
    | GetPaymentDetailsFail

    | GetPaymentListCount
    | GetPaymentListCountSuccess
    | GetPaymentListCountFail

    | GetPaymentListCount
    | GetPaymentListCountSuccess
    | GetPaymentListCountFail

    | GetTotalTicketAction
    | GetTotalTicketSuccess
    | GetTotalTicketFail

    | PaymentLog
    | PaymentLogSuccess
    | PaymentLogFail

    | GetOnlineResourcesListAction
    | GetOnlineResourcesListSuccess
    | GetOnlineResourcesListFailure

    | GetAssignedResourcesListAction
    | GetAssignedResourcesListSuccess
    | GetAssignedResourcesListFailure

    | sendMessage
    | sendMessageSuccess
    | sendMessageFail

    | getMessageList
    | getMessageListSuccess
    | getMessageListFail
 
    | statusActivity
    
    | ClientCancelRequest
    | ClientCancelRequestSuccess
    | ClientCancelRequestFail

    | GetOrderDetailsAction
    | GetOrderDetailsSuccess
    | GetOrderDetailsFailure
    
    | UpdatePayment
    | UpdatePaymentSuccess
    | UpdatePaymentFailure

    | UploadProof
    | UploadProofSuccess
    | UploadProofFailure

    | Export
    | ExportSuccess
    | ExportFailure

    | OrderExport
    | OrderExportSuccess
    | OrderExportFailure

    |AssessmentList
    |AssessmentListSuccess
    |AssessmentListFail

        
    |ClientApproval
    |ClientApprovalSuccess
    |ClientApprovalFail

    |sidebarCount
    |sidebarCountSuccess
    |sidebarCountFail;







import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { sideMenu } from '../../animations/sidemenu.animation';
import { NgbDateCustomParserFormatter } from '../../interfaces/dateformat';

@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.scss'],
  providers: [DatePipe,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}],
  animations: [sideMenu]
})
export class UpdatePaymentComponent implements OnInit {
  form:FormGroup;
  public imageUrl: any;
  public imagePath: string;
  public submitted:boolean=false;
  public userImage: any;
  public uploadedfileName: string;
  @Input()invoiceDetails:any;
  @Input()currencySymbol:any;
  @Input()orderId:any;
  public enableError: boolean;
  public success: boolean;
  public fileName: any ='';
  public filePath: any ='';
  public today:any;
  public paymentDate:any='';
  public imageSize:boolean;
  mindate: any;
  disableButton: boolean = false
  constructor( public modal: NgbActiveModal,public fb:FormBuilder,public datePipe: DatePipe,public commonSandbox:CommonSandbox) { 
    this.today = new Date();
   
  }

  ngOnInit() {
    console.log('invoiceDetails', this.invoiceDetails);
    const date = this.datePipe.transform(this.invoiceDetails.createdDate, 'dd/MM/yyyy').split('/');
    this.mindate = {day: +date[0], month: +date[1], year: +date[2]};
    this.today=new Date();
    this.form=this.fb.group({
      // Amountreceived:['',Validators.required],
      Receiveddate:['',Validators.required],
      TransactionID:['',Validators.required],
      Status:['']
    })
  }

  close(id) {
    this.modal.close(id);
  }

  uploadChange($event): void {
    let file = $event.srcElement.files
    if (this.isValidFile(file[0])) {
      this.convertBase64($event.target);
      this.enableError = false;
    } else {
      this.enableError = true;
    }
  }

  isValidFile(file: any) {
    if (file.name.endsWith('.pdf')) {
      return file.name.endsWith('.pdf')
    }
  }

 convertBase64(inputValue: any): void {
    const file: File = inputValue.files[0];
    this.uploadedfileName=file.name;
    const myReader: FileReader = new FileReader();
    const size = Math.round(inputValue.files[0].size / 5120);
    if (size > 5120) {
      this.imageUrl = '';
      this.userImage='';
     this.imageSize=true;
      return;
    }else{
      myReader.onloadend = e => {
        this.imageUrl = myReader.result;
        this.userImage = myReader.result;
        this.imageSize=false;
        this.uploaddocument()
      };
    }
    myReader.readAsDataURL(file);
   
  }

  uploaddocument() {
    this.success = false
    let params: any = {}
    params.image = this.userImage;
    this.commonSandbox.uploadProof(params);
    this.commonSandbox.uploadProof$.subscribe(data => {
      if (data && data.status === 1) {
        this.success = true
        this.fileName = data.data.fileName;
        this.filePath = data.data.filePath
      } else {
        this.success = false
      }
    })
  }

  update(){
    this.submitted=true;
    if(!this.form.valid){
      return;
    }
    this.disableButton = true
    const form = this.form.value.Receiveddate || '';
    if (form && form.year) {
    const fromM = (form.month < 10) ? '0' + form.month : form.month;
    const fromD = (form.day < 10) ? '0' + form.day : form.day;
    this.paymentDate = form.year + '-' + fromM + '-' + fromD;
    }
   const param:any={};
   param.orderId=this.orderId || '';
   param.paymentStatus= '3';
   param.transactionId= this.form.value.TransactionID || '';
   param.paidDate=this.paymentDate;
   param.amount= this.invoiceDetails.invoiceNetAmount;
   param.paymentid = this.invoiceDetails.orderPaymentId;
   param.documentFileName=this.fileName;
   param.documentFilePath= this.filePath;
   param.originalFileName = this.uploadedfileName;
   this.commonSandbox.updatePayments(param);
   this.commonSandbox.updatePaymentsLoaded$.subscribe(data =>{
     if(data ==  true){
      const id = 'result';
      this.close(id);
     }
   })
  }
  
removeuploadedDocument(){
    this.userImage='';
    this.uploadedfileName='';
    this.success=false;
    this.fileName ='';
    this.filePath='';
  }

}

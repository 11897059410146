import { Map, Record } from 'immutable';

export interface SettingsState extends Map<string, any> {

    userProfile: any;
    userProfileLoading: boolean;
    userProfileLoaded: boolean;
    userProfileFailed: boolean;
    
    updateuserProfile: any;
    updateuserProfileLoading: boolean;
    updateuserProfileLoaded: boolean;
    updateuserProfileFailed: boolean;

    subuserList: any;
    subuserListLoading: boolean;
    subuserListLoaded: boolean;
    subuserListFailed: boolean;

    createSubuser: any;
    createSubuserLoading: boolean;
    createSubuserLoaded: boolean;
    createSubuserFailed: boolean;

    updateSubuser: any;
    updateSubuserLoading: boolean;
    updateSubuserLoaded: boolean;
    updateSubuserFailed: boolean;

    updateSubuserStatus: any;
    updateSubuserStatusLoading: boolean;
    updateSubuserStatusLoaded: boolean;
    updateSubuserStatusFailed: boolean;

    deleteSubuser: any;
    deleteSubuserLoading: boolean;
    deleteSubuserLoaded: boolean;
    deleteSubuserFailed: boolean;
}

export const SettingsStateRecord = Record({
    userProfile: {},
    userProfileLoading: false,
    userProfileLoaded: false,
    userProfileFailed: false,

    updateuserProfile: {},
    updateuserProfileLoading: false,
    updateuserProfileLoaded: false,
    updateuserProfileFailed: false,

    subuserList: {},
    subuserListLoading: false,
    subuserListLoaded: false,
    subuserListFailed: false,

    createSubuser: {},
    createSubuserLoading: false,
    createSubuserLoaded: false,
    createSubuserFailed: false,

    updateSubuser: {},
    updateSubuserLoading: false,
    updateSubuserLoaded: false,
    updateSubuserFailed: false,

    updateSubuserStatus: {},
    updateSubuserStatusLoading: false,
    updateSubuserStatusLoaded: false,
    updateSubuserStatusFailed: false,

    deleteSubuser: {},
    deleteSubuserLoading: false,
    deleteSubuserLoaded: false,
    deleteSubuserFailed: false,
    
});


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { CommonService } from 'src/app/layout/common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sprint-details',
  templateUrl: './sprint-details.component.html',
  styleUrls: ['./sprint-details.component.scss']
})
export class SprintDetailsComponent implements OnInit {
  contantShow: boolean;
  imageFileURL = environment.baseFileUrl;
  imageBaseURL = environment.baseImgUrl;
  ticketTaskDetails = [];
  ticketId: string;
  submitted: boolean;

  constructor(public commonSandbox: CommonSandbox, private commonService: CommonService, public route: ActivatedRoute) { 
    this.ticketId = this.route.snapshot.params['id']
  }

  ngOnInit(): void {
    this.commonSandbox.getTicketDetail({ id: this.ticketId });
    this.getTaskDetails();
  }

  getTaskDetails() {
    this.submitted = true;
    const params: any = {
      ticketId: this.ticketId,
    }
    this.commonService.ticketTask(params).subscribe(res=>{
      this.submitted = false;
      if(res.data.length > 0) {
        this.ticketTaskDetails = res.data.map(list=> list.child).flat();
      }
    })
  }

  close(data) {
    if (data) {
      this.contantShow = false;
    } else {
      this.contantShow = true;
    }
  }

  downloadFile(doc) {
    const documentUrl = this.imageFileURL + '?name=' + doc.fileName + '&path=' + doc.filePath;
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    a.href = documentUrl;
    a.download = documentUrl;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(documentUrl);
    a.remove();
  }

}

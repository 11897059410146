import * as authReducer from "./auth/reducer/auth.reducer";
import * as dashboardReducer from "./client/dashboard/reducer/dashboard.reducer";
import * as commonReducer from "./layout/common/reducer/common.reducer";
import * as activityReducer from "./client/activities/reducer/activity.reducer";
import * as paymentReducer from "./client/payments/reducer/payments.reducer";
import * as resourceReducer from "./resource/reducer/resource.reducer";
import * as approvalsReducer from "./client/approvals/reducer/approvals.reducer";
import * as openticketReducer from "./client/openticket/reducer/openticket.reducer";
import * as inprogressReducer from "./client/inprogress/reducer/inprogress.reducer";
import * as closedReducer from "./client/closed/reducer/closed.reducer";
import * as settingsReducer from "./settings/reducer/settings.reducer";
import * as notificationReducer from "./client/notification/reducer/notification.reducer";
import { ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { AppState as State } from "./app.state";
import { environment } from "../environments/environment.prod";

export const reducers: ActionReducerMap<State> = {
  auth: authReducer.reducer,
  dashboard: dashboardReducer.reducer,
  common: commonReducer.reducer,
  activity: activityReducer.reducer,
  payments: paymentReducer.reducer,
  resource: resourceReducer.reducer,
  approvals: approvalsReducer.reducer,
  openticket: openticketReducer.reducer,
  inprogress: inprogressReducer.reducer,
  closed: closedReducer.reducer,
  settings: settingsReducer.reducer,
  notification: notificationReducer.reducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
  return function (state: State, action: any): State {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

import {NgModule} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NodataComponent } from './nodata/nodata.component';
import { LoadingComponent } from './loading/loading.component';
import { SharedEventEmitterService } from '../services/event.emitter.service';
import { PagerComponent } from '../pager/pager.component';
import { CreateRequestComponent } from './default-design/create-request/create-request.component';
import { LoaderstyleoneComponent } from './loaders/loaderstyleone/loaderstyleone.component';
import { LayoutSettingsComponent } from './layout-settings/layout-settings.component';
import { NumberAcceptModule } from '../Directive/onlyNumber.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CKEditorModule } from 'ckeditor4-angular';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { KeyLengthPipe } from '../pipes/key-length.pipe';
import { DateFormatPipe } from '../pipes/dateformat.pipe';
import { FilterComponent } from './filter/filter.component';
import { TicketActivityComponent } from './ticket-activity/ticket-activity.component';
import { QuillModule } from 'ngx-quill';
import { AppMaterial } from 'src/app/app.material.module';
import { PaginationComponent } from './pagination/pagination.component';
import { SprintDetailsComponent } from './sprint-details/sprint-details.component';
import { ImagecropComponent } from './imagecrop/imagecrop.component';


// custom directive component

export const COMPONENTS = [
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  BreadcrumbComponent,
  LayoutSettingsComponent,
  LoadingComponent,
  PagerComponent,
  NodataComponent,
  CreateRequestComponent,
  LoaderstyleoneComponent,
  CkeditorComponent,
  KeyLengthPipe,
  DateFormatPipe,
  FilterComponent,
  TicketActivityComponent,
  PaginationComponent,
  SprintDetailsComponent,
  ImagecropComponent
];

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppMaterial,
    NumberAcceptModule,
    ImageCropperModule,
    NgbModule,
    InfiniteScrollModule,
    CKEditorModule,
    NgOtpInputModule,
    NgSelectModule,
    QuillModule.forRoot(),
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: [],
  providers: [SharedEventEmitterService]
})
export class SharedComponentsModule {
}

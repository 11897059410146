import { Component, OnInit } from '@angular/core';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { VerifyotpComponent } from 'src/app/shared/popups/verifyotp/verifyotp.component';
import { CreateCRComponent } from 'src/app/shared/popups/createcr/createcr.component';

@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss']
})
export class CreateRequestComponent implements OnInit {
  clientId: any;
  isCreate = false;
  constructor(public commonSandbox: CommonSandbox, private modalService: NgbModal, private offcanvasService: NgbOffcanvas) { }

  ngOnInit() {
    this.getClientId();
    this.commonSandbox.getProjectList({clientId: this.clientId});
    this.commonSandbox.projectList$.subscribe(data => {
    });
  }
  getClientId() {
    if (localStorage.getItem('clientDetail')) {
       this.clientId = JSON.parse(localStorage.getItem('clientDetail')).id;
    }
  }
  opencreatecr(project) {
    this.isCreate = true;
    const modalRef = this.modalService.open(CreateCRComponent, {
      size: 'lg', windowClass: 'modal-holder', backdrop: 'static', backdropClass: 'createcr'
    });
  }

  openverifyotp() {
    const modelRef = this.modalService.open(VerifyotpComponent, {
      size: 'xl', windowClass: 'verifyotpmodal', backdrop: 'static', backdropClass: ''
    });
  }


}

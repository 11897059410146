import { Action } from '@ngrx/store';
import { type } from '../../shared/utility';
import { LoginRequestModel } from '../models/login-request.model';
import { WebLoginRequestModel } from '../models/web-login-request.model';
import { ResponseInterface } from '../../shared/interfaces/interface';
import { VerifyOtpRequestModel } from '../models/verify-otp-request.model';

export const ActionTypes = {
    DO_LOGIN: type('[Common] do login'),
    DO_LOGIN_SUCCESS: type('[Common] login Success'),
    DO_LOGIN_FAIL: type('[Common] login Fail'),

    DO_WEB_LOGIN: type('[Common] do web login'),
    DO_WEB_LOGIN_SUCCESS: type('[Common] web login Success'),
    DO_WEB_LOGIN_FAIL: type('[Common] web login Fail'),

    FORGET_PASSWORD: type('[Common] forgt Password'),
    FORGET_PASSWORD_SUCCESS: type('[Common] forgt Password Success'),
    FORGET_PASSWORD_FAIL: type('[Common] forgt Password Fail'),

    CHECK_USER: type('[User] User'),
    CHECK_USER_SUCCESS: type('[User] User Success'),
    CHECK_USER_FAIL: type('[User] User Fail'),

    RESET_PASSWORD: type('[Common] Reset Password'),
    RESET_PASSWORD_SUCCESS: type('[Common] Reset Password Success'),
    RESET_PASSWORD_FAIL: type('[Common] Reset Password Fail'),

    VERIFY_OTP: type('[Verify Otp] Verify Otp'),
    VERIFY_OTP_SUCCESS: type('[Verify Otp] Verify Otp Success'),
    VERIFY_OTP_FAIL: type('[Verify Otp] Verify Otp Fail'),

    RESEND_OTP: type('[Resend Otp] Resend Otp'),
    RESEND_OTP_SUCCESS: type('[Resend Otp] Resend Otp Success'),
    RESEND_OTP_FAIL: type('[Resend Otp] Resend Otp Fail'),
};

/**
 * client login actions.
 */
export class DoLoginAction implements Action {
    type = ActionTypes.DO_LOGIN;

    constructor(public payload: LoginRequestModel) {
    }
}

export class DoLoginSuccess implements Action {
    type = ActionTypes.DO_LOGIN_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class DoLoginFail implements Action {
    type = ActionTypes.DO_LOGIN_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * web login actions.
 */
export class DoWebLoginAction implements Action {
    type = ActionTypes.DO_WEB_LOGIN;

    constructor(public payload: WebLoginRequestModel) {
    }
}

export class DoWebLoginSuccess implements Action {
    type = ActionTypes.DO_WEB_LOGIN_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class DoWebLoginFail implements Action {
    type = ActionTypes.DO_WEB_LOGIN_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * forget password actions.
 */
export class ForgetPassword implements Action {
    type = ActionTypes.FORGET_PASSWORD;

    constructor(public payload: any) {
    }
}

export class ForgetPasswordSuccess implements Action {
    type = ActionTypes.FORGET_PASSWORD_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ForgetPasswordFail implements Action {
    type = ActionTypes.FORGET_PASSWORD_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * reset password actions.
 */
export class ResetPassword implements Action {
    type = ActionTypes.RESET_PASSWORD;

    constructor(public payload: any) {
    }
}

export class ResetPasswordSuccess implements Action {
    type = ActionTypes.RESET_PASSWORD_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ResetPasswordFail implements Action {
    type = ActionTypes.RESET_PASSWORD_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * check user actions.
 */
export class CheckUser implements Action {
    type = ActionTypes.CHECK_USER;

    constructor(public payload: any) {
    }
}

export class CheckUserSuccess implements Action {
    type = ActionTypes.CHECK_USER_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CheckUserFail implements Action {
    type = ActionTypes.CHECK_USER_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * verify otp actions.
 */
export class VerifyOtpAction implements Action {
    type = ActionTypes.VERIFY_OTP;

    constructor(public payload: VerifyOtpRequestModel) {
    }
}

export class VerifyOtpSuccess implements Action {
    type = ActionTypes.VERIFY_OTP_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class VerifyOtpFail implements Action {
    type = ActionTypes.VERIFY_OTP_FAIL;

    constructor(public payload: any) {
    }
}


/**
 * resend otp actions.
 */
export class ResendOtpAction implements Action {
    type = ActionTypes.RESEND_OTP;

    constructor(public payload: any) {
    }
}

export class ResendOtpSuccess implements Action {
    type = ActionTypes.RESEND_OTP_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class ResendOtpFail implements Action {
    type = ActionTypes.RESEND_OTP_FAIL;

    constructor(public payload: any) {
    }
}

export type Actions =
    | DoLoginAction
    | DoLoginSuccess
    | DoLoginFail
    | DoWebLoginAction
    | DoWebLoginSuccess
    | DoWebLoginFail
    | ForgetPassword
    | ForgetPasswordSuccess
    | ForgetPasswordFail
    | ResetPassword
    | ResetPasswordSuccess
    | ResetPasswordFail
    | CheckUser
    | CheckUserSuccess
    | CheckUserFail
    | VerifyOtpAction
    | VerifyOtpSuccess
    | VerifyOtpFail
    | ResendOtpAction
    | ResendOtpSuccess
    | ResendOtpFail;


import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { DeletePopupComponent } from '../deletepopup/deletepopup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-createcr',
  templateUrl: './createcr.component.html',
  styleUrls: ['./createcr.component.scss'],
})
export class CreateCRComponent implements OnInit {
  createRequestForm: FormGroup;
  uploadedFile = [];
  ticketId: any;
  submitted = false;
  project: any;
  totalSize = 0;
  clientId: any;
  newFiles = [];
  oldFiles = [];
  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public commonSandbox: CommonSandbox,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private route: Router
             ) {
              console.log('1414',window.location.hash)
             }

  ngOnInit() {
    this.initRequestForm();
    this.clientId = JSON.parse(localStorage.getItem('clientDetail')).id;
    this.commonSandbox.getProjectList({ clientId: this.clientId });
     if (this.project == 'edit') {
      this.editTicket();
     }    
  }
  public initRequestForm(): void {
    this.createRequestForm = this.fb.group({
      project: [null, Validators.required],
      title: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      priority: [null, Validators.required],
      description: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      file: [''],
    });
  }
  editTicket() {
    const params: any = {};
      params.id = this.ticketId;
      this.commonSandbox.getTicketDetail(params);
      this.commonSandbox.ticketsDetail$.subscribe(value => {
        if (value && value.priorityDetails && value.ticketDescription) {
          this.createRequestForm.controls['project'].setValue(value.projectDetails.id);
          this.createRequestForm.controls['priority'].setValue(value.priorityDetails.id);
          this.createRequestForm.controls['description'].setValue(value.ticketDescription.replace(/(<([^>]+)>)/ig,'\n'));
          this.createRequestForm.controls['title'].setValue(value.ticketTitle);
          this.uploadedFile = value.ticketAttachmentsDetails.length > 0 ? value.ticketAttachmentsDetails.map((element) => ((element.name = element.fileName), element)) : [];
        }
      });
  }

  close() {
    this.activeModal.close();
  }
  uploadFile(event) {
    const file = Math.round(event.target.files[0].size / 1024);
    if(this.isValidFile(event.srcElement.files[0])) {
    this.totalSize = this.totalSize + file;
    if (event.target.files.length > 0) {
      const files =  event.target.files[0];
      this.uploadedFile.push(files);
  }
} else {
  this.toastr.error('Upload .pdf, .doc, .docx, .xlsx, .jpg, .png are only supported')
}
  }
  isValidFile(file: any) {
    return file.name.endsWith('.jpg')|| file.name.endsWith('.png') || file.name.endsWith('.xlsx') || file.name.endsWith('.pdf') || file.name.endsWith('.doc') || file.name.endsWith('.docx');
  }
  deleteFile(file, event) {
    event.stopPropagation();
    const files = Math.round(file.size / 1024);
    this.totalSize = this.totalSize - files;
    if (file.type) {
      this.uploadedFile = this.uploadedFile.filter(removedItem => {
        if (file === removedItem) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      const modalRef = this.modalService.open(DeletePopupComponent, {
        windowClass: 'delete-model' , backdrop: 'static'});
      modalRef.componentInstance.fileDetails = file;
      modalRef.result.then((result) => {
          if (result === 'success') {
            this.uploadedFile = this.uploadedFile.filter(removedItem => {
              if (file === removedItem) {
                return false;
              } else {
                return true;
              }
            });
          }
      });
    }    
  }
  /**
   * Handles form 'submit' event. Calls sandbox login function if form is valid.
   *
   * @param formData form field datas
   */
  createRequest(formData) {
    this.submitted = true;
    if (!formData.valid) {
      this.validateAllFormFields(formData);
      return;
    }
    if (this.totalSize > 5000) {
      this.toastr.warning('file size should not exceed 5MB');
      return;
    }
    const params = formData.value;
    if (this.project == 'edit') {
      this.newFiles = [];
      this.oldFiles = [];
      this.uploadedFile.forEach(element => {
       if (element.type) {
        this.newFiles.push(element);
       }
      });
    } else {
      params.file = this.uploadedFile;
    }
    if (this.project == 'edit') {
      params.file = this.newFiles;
      params.url = '/ticket/update-client-ticket';
      params.ticketId = this.ticketId;
    }else {
      params.url = '/ticket';
    }
    this.commonSandbox.createRequest(params);
    this.commonSandbox.createRequestLoaded$.subscribe(data => {
      if (data) {
          this.activeModal.close('Success');
          if(window.location.hash == '#/client/openticket'){
            this.route.navigate(['/client/opentickets'])
          } else {
            this.route.navigate(['/client/openticket'])
          }
      }                            
    });

  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  sortItems(item) {
    return item?.sort((a, b) => a?.projectDetails?.projectTitle.localeCompare(b?.projectDetails?.projectTitle));
  }
}
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { ServerResolver } from './resolver/server-resolver.service';

/* services */
import {AppStateService} from './services/app-state.service';
import {ErrorService} from './services/error.service';
import {WebsocketService} from './services/websocket.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild()
  ],
  declarations: [
  ],
  providers: [
    ServerResolver,
    AppStateService,
    ErrorService,
    WebsocketService
  ]
})
export class ChatModule {
}

export class VerifyOtpRequestModel {
    otp: number;
    id :string;
  
  
    constructor(val: any) {
      this.otp = val.otp;
      this.id=val.id;
    }
  }
  
import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';

import * as authreducer from './common.reducer';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getCommonState = (state: AppState) => state.common;
export const projectList = createSelector(getCommonState, authreducer.getProjectList);
export const projectListLoading = createSelector(getCommonState, authreducer.getProjectListLoading);
export const projectListLoaded = createSelector(getCommonState, authreducer.getProjectListLoaded);
export const projectListFailed = createSelector(getCommonState, authreducer.getProjectListFailed);

export const totalprojectList = createSelector(getCommonState, authreducer.getTotalProjectList);
export const totalprojectListLoading = createSelector(getCommonState, authreducer.getTotalProjectListLoading);
export const totalprojectListLoaded = createSelector(getCommonState, authreducer.getTotalProjectListLoaded);
export const totalprojectListFailed = createSelector(getCommonState, authreducer.getTotalProjectListFailed);

export const totalprojectCount = createSelector(getCommonState, authreducer.getTotalProjectCount);
export const totalprojectCountLoading = createSelector(getCommonState, authreducer.getTotalProjectCountLoading);
export const totalprojectCountLoaded = createSelector(getCommonState, authreducer.getTotalProjectCountLoaded);
export const totalprojectCountFailed = createSelector(getCommonState, authreducer.getTotalProjectCountFailed);


export const priorityList = createSelector(getCommonState, authreducer.getPriorityList);
export const priorityListLoading = createSelector(getCommonState, authreducer.getPriorityListLoading);
export const priorityListLoaded = createSelector(getCommonState, authreducer.getPriorityListLoaded);
export const priorityListFailed = createSelector(getCommonState, authreducer.getPriorityListFailed);

export const createRequestLoading = createSelector(getCommonState, authreducer.getCreateRequestLoading);
export const createRequestLoaded = createSelector(getCommonState, authreducer.getCreateRequestLoaded);
export const createRequestFailed = createSelector(getCommonState, authreducer.getCreateRequestFailed);

export const statusList = createSelector(getCommonState, authreducer.getStatusList);
export const statusListLoading = createSelector(getCommonState, authreducer.getStatusListLoading);
export const statusListLoaded = createSelector(getCommonState, authreducer.getStatusListLoaded);
export const statusListFailed = createSelector(getCommonState, authreducer.getStatusListFailed);

export const ticketStatusChange = createSelector(getCommonState, authreducer.getTicketStatusChange);
export const ticketStatusChangeLoading = createSelector(getCommonState, authreducer.getTicketStatusChangeLoading);
export const ticketStatusChangeLoaded = createSelector(getCommonState, authreducer.getTicketStatusChangeLoaded);
export const ticketStatusChangeFailed = createSelector(getCommonState, authreducer.getTicketStatusChangeFailed);

export const ticketList = createSelector(getCommonState, authreducer.getTicketList);
export const ticketListLoading = createSelector(getCommonState, authreducer.getTicketListLoading);
export const ticketListLoaded = createSelector(getCommonState, authreducer.getTicketListLoaded);
export const ticketListFailed = createSelector(getCommonState, authreducer.getTicketListFailed);


export const ticketCount = createSelector(getCommonState, authreducer.getTicketCount);
export const ticketCountLoading = createSelector(getCommonState, authreducer.getTicketCountLoading);
export const ticketCountLoaded = createSelector(getCommonState, authreducer.getTicketCountLoaded);
export const ticketCountFailed = createSelector(getCommonState, authreducer.getTicketCountFailed);

export const reviewTicketList = createSelector(getCommonState, authreducer.reviewTicketList);
export const reviewTicketListLoading = createSelector(getCommonState, authreducer.reviewTicketListLoading);
export const reviewTicketListLoaded = createSelector(getCommonState, authreducer.reviewTicketListLoaded);
export const reviewTicketListFailed = createSelector(getCommonState, authreducer.reviewTicketListFailed);

export const reviewCount = createSelector(getCommonState, authreducer.getReviewCount);
export const reviewCountLoading = createSelector(getCommonState, authreducer.getReviewCountLoading);
export const reviewCountLoaded = createSelector(getCommonState, authreducer.getReviewCountLoaded);
export const reviewCountFailed = createSelector(getCommonState, authreducer.getReviewCountFailed);

export const scheduleCount = createSelector(getCommonState, authreducer.getScheduleCount);
export const scheduleCountLoading = createSelector(getCommonState, authreducer.getScheduleCountLoading);
export const scheduleCountLoaded = createSelector(getCommonState, authreducer.getScheduleCountLoaded);
export const scheduleCountFailed = createSelector(getCommonState, authreducer.getScheduleCountFailed);

export const scheduleList = createSelector(getCommonState, authreducer.getScheduleList);
export const scheduleListLoading = createSelector(getCommonState, authreducer.getScheduleListLoading);
export const scheduleListLoaded = createSelector(getCommonState, authreducer.getScheduleListLoaded);
export const scheduleListFailed = createSelector(getCommonState, authreducer.getScheduleListFailed);

export const totalCount = createSelector(getCommonState, authreducer.getTotalCount);
export const totalCountLoading = createSelector(getCommonState, authreducer.getTotalCountLoading);
export const totalCountLoaded = createSelector(getCommonState, authreducer.getTotalCountLoaded);
export const totalCountFailed = createSelector(getCommonState, authreducer.getTotalCountFailed);

export const totalTicket = createSelector(getCommonState, authreducer.getTotalTicket);
export const totalTicketLoading = createSelector(getCommonState, authreducer.getTotalTicketLoading);
export const totalTicketLoaded = createSelector(getCommonState, authreducer.getTotalTicketLoaded);
export const totalTicketFailed = createSelector(getCommonState, authreducer.getTotalTicketFailed);

export const freeCount = createSelector(getCommonState, authreducer.getFreeCount);
export const freeCountLoading = createSelector(getCommonState, authreducer.getFreeCountLoading);
export const freeCountLoaded = createSelector(getCommonState, authreducer.getFreeCountLoaded);
export const freeCountFailed = createSelector(getCommonState, authreducer.getFreeCountFailed);

export const approveTicketList = createSelector(getCommonState, authreducer.approveTicketList);
export const approveTicketListLoading = createSelector(getCommonState, authreducer.approveTicketListLoading);
export const approveTicketListLoaded = createSelector(getCommonState, authreducer.approveTicketListLoaded);
export const approveTicketListFailed = createSelector(getCommonState, authreducer.approveTicketListFailed);

export const approvalCount = createSelector(getCommonState, authreducer.getApprovalCount);
export const approvalCountLoading = createSelector(getCommonState, authreducer.getApprovalCountLoading);
export const approvalCountLoaded = createSelector(getCommonState, authreducer.getApprovalCountLoaded);
export const approvalCountFailed = createSelector(getCommonState, authreducer.getApprovalCountFailed);

export const closureList = createSelector(getCommonState, authreducer.getClosureList);
export const closureListLoading = createSelector(getCommonState, authreducer.getClosureListLoading);
export const closureListLoaded = createSelector(getCommonState, authreducer.getClosureListLoaded);
export const closureListFailed = createSelector(getCommonState, authreducer.getClosureListFailed);

export const closureCount = createSelector(getCommonState, authreducer.getClosureCount);
export const closureCountLoading = createSelector(getCommonState, authreducer.getClosureCountLoading);
export const closureCountLoaded = createSelector(getCommonState, authreducer.getClosureCountLoaded);
export const closureCountFailed = createSelector(getCommonState, authreducer.getClosureCountFailed);

export const raisedCount = createSelector(getCommonState, authreducer.getRaisedCount);
export const raisedCountLoading = createSelector(getCommonState, authreducer.getRaisedCountLoading);
export const raisedCountLoaded = createSelector(getCommonState, authreducer.getRaisedCountLoaded);
export const raisedCountFailed = createSelector(getCommonState, authreducer.getRaisedCountFailed);

export const ticketsDetail = createSelector(getCommonState, authreducer.getTicketsDetail);
export const ticketsDetailLoading = createSelector(getCommonState, authreducer.getTicketsDetailLoading);
export const ticketsDetailLoaded = createSelector(getCommonState, authreducer.getTicketsDetailLoaded);
export const ticketsDetailFailed = createSelector(getCommonState, authreducer.getTicketsDetailFailed);

export const changePassword = createSelector(getCommonState, authreducer.changePassword);
export const changePasswordLoading = createSelector(getCommonState, authreducer.changePasswordLoading);
export const changePasswordLoaded = createSelector(getCommonState, authreducer.changePasswordLoaded);
export const changePasswordFailed = createSelector(getCommonState, authreducer.changePasswordFail);



export const pendingPaymentAmount = createSelector(getCommonState, authreducer.getpendingPaymentAmount);
export const paymentList = createSelector(getCommonState, authreducer.getPaymentList);
export const paymentListLoading = createSelector(getCommonState, authreducer.getPaymentListLoading);
export const paymentListLoaded = createSelector(getCommonState, authreducer.getPaymentListLoaded);
export const paymentListFailed = createSelector(getCommonState, authreducer.getPaymentListFailed);

export const paymentDetails = createSelector(getCommonState, authreducer.getPaymentDetails);
export const paymentDetailsLoading = createSelector(getCommonState, authreducer.getPaymentDetailsLoading);
export const paymentDetailsLoaded = createSelector(getCommonState, authreducer.getPaymentDetailsLoaded);
export const paymentDetailsFailed = createSelector(getCommonState, authreducer.getPaymentDetailsFailed);

export const invoiceDetails = createSelector(getCommonState, authreducer.getInvoiceDetails);
export const invoiceCompanyDetails = createSelector(getCommonState, authreducer.invoiceCompanyDetails);
export const invoiceDetailsLoading = createSelector(getCommonState, authreducer.getInvoiceDetailsLoading);
export const invoiceDetailsLoaded = createSelector(getCommonState, authreducer.getInvoiceDetailsLoaded);
export const invoiceDetailsFailed = createSelector(getCommonState, authreducer.getInvoiceDetailsFailed);

export const paymentListCount = createSelector(getCommonState, authreducer.getPaymentListCount);
export const paymentListCountLoading = createSelector(getCommonState, authreducer.getPaymentListCountLoading);
export const paymentListCountLoaded = createSelector(getCommonState, authreducer.getPaymentListCountLoaded);
export const paymentListCountFailed = createSelector(getCommonState, authreducer.getPaymentListCountFailed);

export const paymentLog = createSelector(getCommonState, authreducer.getPaymentLog);
export const paymentLogLoading = createSelector(getCommonState, authreducer.getPaymentLogLoading);
export const paymentLogLoaded = createSelector(getCommonState, authreducer.getPaymentLogLoaded);
export const paymentLogFailed = createSelector(getCommonState, authreducer.getPaymentLogFailed);


export const currencyListFailed = createSelector(getCommonState, authreducer.getClosureCountFailed);

export const assignedResourcesLength = createSelector(getCommonState, authreducer.getAssignedResourcesLength);

export const onlineResourcesList = createSelector(getCommonState, authreducer.getOnlineResourcesList);
export const onlineResourcesListLoading = createSelector(getCommonState, authreducer.getOnlineResourcesListLoading);
export const onlineResourcesListLoaded = createSelector(getCommonState, authreducer.getOnlineResourcesListLoaded);
export const onlineResourcesListFailed = createSelector(getCommonState, authreducer.getOnlineResourcesListFailed);

export const assignedResourcesList = createSelector(getCommonState, authreducer.getAssignedResourcesList);
export const assignedResourcesListLoading = createSelector(getCommonState, authreducer.getAssignedResourcesListLoading);
export const assignedResourcesListLoaded = createSelector(getCommonState, authreducer.getAssignedResourcesListLoaded);
export const assignedResourcesListFailed = createSelector(getCommonState, authreducer.getAssignedResourcesListFailed);

export const invoiceLoading = createSelector(getCommonState, authreducer.getInvoiceLoading);
export const invoiceLoaded = createSelector(getCommonState, authreducer.getInvoiceLoaded);
export const invoiceFailed = createSelector(getCommonState, authreducer.getInvoiceFailed);


export const ticketDecline = createSelector(getCommonState, authreducer.getTicketDecline);
export const ticketDeclineLoading = createSelector(getCommonState, authreducer.getTicketDeclineLoading);
export const ticketDeclineLoaded = createSelector(getCommonState, authreducer.getTicketDeclineLoaded);
export const ticketDeclineFailed = createSelector(getCommonState, authreducer.getTicketDeclineFailed);

export const updateTicketList = createSelector(getCommonState, authreducer.updateTicketList);
export const updateTicketListLoaded = createSelector(getCommonState, authreducer.updateTicketListLoaded);
export const clientUptateTicketList = createSelector(getCommonState, authreducer.clientUptateTicketList);

export const openTicketList = createSelector(getCommonState, authreducer.openTicketList);
export const openTicketListLoading = createSelector(getCommonState, authreducer.openTicketListLoading);
export const openTicketListLoaded = createSelector(getCommonState, authreducer.openTicketListLoaded);
export const openTicketListFailed = createSelector(getCommonState, authreducer.openTicketListFailed);

export const openTicketListCount = createSelector(getCommonState, authreducer.openTicketListCount);
export const openTicketListCountLoading = createSelector(getCommonState, authreducer.openTicketListCountLoading);
export const openTicketListCountLoaded = createSelector(getCommonState, authreducer.openTicketListCountLoaded);
export const openTicketListCountFailed = createSelector(getCommonState, authreducer.openTicketListCountFailed);

export const inprogressTicketList = createSelector(getCommonState, authreducer.inprogressTicketList);
export const inprogressTicketListLoading = createSelector(getCommonState, authreducer.inprogressTicketListLoading);
export const inprogressTicketListLoaded = createSelector(getCommonState, authreducer.inprogressTicketListLoaded);
export const inprogressTicketListFailed = createSelector(getCommonState, authreducer.inprogressTicketListFailed);


export const acceptanceTicketList = createSelector(getCommonState, authreducer.acceptanceTicketList);
export const acceptanceTicketListLoading = createSelector(getCommonState, authreducer.acceptanceTicketListLoading);
export const acceptanceTicketListLoaded = createSelector(getCommonState, authreducer.acceptanceTicketListLoaded);
export const acceptanceTicketListFailed = createSelector(getCommonState, authreducer.acceptanceTicketListFailed);

export const acceptanceTicketListCount = createSelector(getCommonState, authreducer.acceptanceTicketListCount);
export const acceptanceTicketListCountLoading = createSelector(getCommonState, authreducer.acceptanceTicketListCountLoading);
export const acceptanceTicketListCountLoaded = createSelector(getCommonState, authreducer.acceptanceTicketListCountLoaded);
export const acceptanceTicketListCountFailed = createSelector(getCommonState, authreducer.acceptanceTicketListCountFailed);

export const sideScheduleCount = createSelector(getCommonState, authreducer.sideScheduleCount);
export const sideOpenTicketListCount = createSelector(getCommonState, authreducer.sideOpenTicketListCount);
export const sideInprogressTicketListCount = createSelector(getCommonState, authreducer.sideInprogressTicketListCount);
export const sideClosureCount = createSelector(getCommonState, authreducer.sideClosureCount);

export const offlinePayment = createSelector(getCommonState, authreducer.offlinePayment);
export const offlinePaymentLoading = createSelector(getCommonState, authreducer.offlinePaymentLoading);
export const offlinePaymentLoaded = createSelector(getCommonState, authreducer.offlinePaymentLoaded);
export const offlinePaymentFailed = createSelector(getCommonState, authreducer.offlinePaymentFailed);

export const sendMessageResponse = createSelector(getCommonState, authreducer.sendMessageResponse);
export const sendMessageLoading = createSelector(getCommonState, authreducer.sendMessageLoading);
export const loadingIndex = createSelector(getCommonState, authreducer.loadingIndex);

export const messageListResponse = createSelector(getCommonState, authreducer.messageListResponse);
export const messageListLoading = createSelector(getCommonState, authreducer.messageListLoading);

export const messageTicketDetails = createSelector(getCommonState, authreducer.messageTicketDetails);

export const messageListCount = createSelector(getCommonState, authreducer.messageListCount);

export const repliedBaseMessage = createSelector(getCommonState, authreducer.repliedBaseMessage);
export const repliedBaseMessageLoading = createSelector(getCommonState, authreducer.repliedBaseMessageLoading);


export const deleteMessage = createSelector(getCommonState, authreducer.deleteMessage);


// export const assignResourceList = createSelector(getCommonState, authreducer.assignresourceList);

export const undoApproval = createSelector(getCommonState, authreducer.undoApproval);

export const startWork = createSelector(getCommonState, authreducer.startWork);

export const mobileNotificationList = createSelector(getCommonState, authreducer.mobileNotificationList);

export const getMessageList = createSelector(getCommonState, authreducer.getMessageList);



export const clientCancelRequest = createSelector(getCommonState, authreducer.clientCancelRequest);
export const clientCancelRequestLoading = createSelector(getCommonState, authreducer.clientCancelRequestLoading);
export const clientCancelRequestLoaded = createSelector(getCommonState, authreducer.clientCancelRequestLoaded);
export const clientCancelRequestFailed = createSelector(getCommonState, authreducer.clientCancelRequestFailed);


export const approvedTicketListCount = createSelector(getCommonState, authreducer.approvedTicketListCount);
export const approvedTicketListCountLoading = createSelector(getCommonState, authreducer.approvedTicketListCountLoading);
export const approvedTicketListCountLoaded = createSelector(getCommonState, authreducer.approvedTicketListCountLoaded);
export const approvedTicketListCountFailed = createSelector(getCommonState, authreducer.approvedTicketListCountFailed);

export const orderDetails = createSelector(getCommonState, authreducer.getOrderDetails);
export const orderDetailsLoading = createSelector(getCommonState, authreducer.getOrderDetailsLoading);
export const orderDetailsLoaded = createSelector(getCommonState, authreducer.getOrderDetailsLoaded);
export const orderDetailsFailed = createSelector(getCommonState, authreducer.getOrderDetailsFailed);

export const updatePayments = createSelector(getCommonState, authreducer.getUpdatePayment);
export const updatePaymentsLoading = createSelector(getCommonState, authreducer.getUpdatePaymentLoading);
export const updatePaymentsLoaded = createSelector(getCommonState, authreducer.getUpdatePaymentLoaded);
export const updatePaymentsFailed = createSelector(getCommonState, authreducer.getUpdatePaymentFailed);


export const uploadProof = createSelector(getCommonState, authreducer.uploadProof);
export const uploadProofLoading = createSelector(getCommonState, authreducer.uploadProofLoading);
export const uploadProofLoaded = createSelector(getCommonState, authreducer.uploadProofLoaded);
export const uploadProofFailed = createSelector(getCommonState, authreducer.uploadProofFailed);

export const assessmentList = createSelector(getCommonState, authreducer.assessmentList);
export const assessmentListLoading = createSelector(getCommonState, authreducer.assessmentListLoading);
export const assessmentListLoaded = createSelector(getCommonState, authreducer.assessmentListLoaded);
export const assessmentListFailed = createSelector(getCommonState, authreducer.assessmentListFailed);

export const clientApproval = createSelector(getCommonState, authreducer.clientApproval);
export const clientApprovalLoading = createSelector(getCommonState, authreducer.clientApprovalLoading);
export const clientApprovalLoaded = createSelector(getCommonState, authreducer.clientApprovalLoaded);
export const clientApprovalFailed = createSelector(getCommonState, authreducer.clientApprovalFailed);

export const sidebarCount = createSelector(getCommonState, authreducer.sidebarCount);
export const sidebarCountLoading = createSelector(getCommonState, authreducer.sidebarCountLoading);
export const sidebarCountLoaded = createSelector(getCommonState, authreducer.sidebarCountLoaded);
export const sidebarCountFailed = createSelector(getCommonState, authreducer.sidebarCountFailed);







import {Component, OnInit, ViewChild, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbDropdown, NgbDropdownConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
// import {AdminSandbox} from 'src/app/admin/admin.sandbox';
import {PaymentsSandbox} from '../../../client/payments/payments.sandbox';
import {CommonSandbox} from '../../../layout/common/common.sandbox';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs/index';
import { InvoiceComponent } from '../invoice/invoice.component';
import { stringify } from 'querystring';

@Component({
    selector: 'app-offline-payment',
    templateUrl: './offline-payment.component.html',
    styleUrls: ['./offline-payment.component.scss'],
    providers: [NgbDropdownConfig, NgbDropdown],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class OfflinePaymentComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription> = [];
    paymentDetails: any;
    payType = '';
    amount = '';

    constructor(public modal: NgbActiveModal, public modalService: NgbModal,
                public commonSandbox: CommonSandbox,
                public paymentSandbox: PaymentsSandbox) {
    }

    ngOnInit() {
        this.amount = this.paymentDetails.amount;
        console.log('paymentDetails', this.paymentDetails);

    }
    submit() {
       const params: any = {};
       params.orderId = this.paymentDetails.orderId;
       params.amount = JSON.stringify(this.amount);
       params.paymentStatus = 1;
       params.offlineTransactionDetails = this.payType;
       params.paymentid = this.paymentDetails.id;
       console.log('paymentDetails', params);
       this.commonSandbox.offlinePayment(params);
       this.subscriptions.push(this.commonSandbox.offlinePaymentLoaded$.subscribe(detail => {
        if (detail) {
            this.modal.close('result');
        }

    }));
    }
    close() {
        this.modal.close();
    }
    ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

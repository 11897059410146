import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/providers/api/api';
import { ResponseInterface } from 'src/app/shared/interfaces/interface';

@Injectable()

export class NotificationService extends Api {

    private URL: string = this.getBaseUrl();

    getNotificationCount(params: any): Observable<ResponseInterface> {
      return this.http.get<ResponseInterface>( this.URL +'api/notification/list', { params: params });
    }

    getNotificationList(params: any): Observable<ResponseInterface> {
      return this.http.get<ResponseInterface>(this.URL +'api/notification/list', { params: params });
    }

    getNotificationDetail(params: any): Observable<ResponseInterface> {
      return this.http.get<ResponseInterface>(this.URL +'api/notification/notification-details/' + params.id);
    }

    changeNotification(params: any): Observable<ResponseInterface> {
      return this.http.delete<ResponseInterface>(this.URL +'api/notification/read-status/' + params.id);
    } 
    
    notificationUnread(params: any): Observable<ResponseInterface> {
      return this.http.post<ResponseInterface>(this.URL +'api/notification/unread-status/' + params.id, {});
    }

    deleteNotification(params: any): Observable<ResponseInterface> {
      return this.http.post<ResponseInterface>(this.URL +'api/notification/delete-message', params);
    }
}

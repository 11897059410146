import { Action } from '@ngrx/store';
import {type} from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
    PAYMENTS_DASHBOARD_COUNT: type('[payments] payment dashboard count'),
    PAYMENTS_DASHBOARD_COUNT_SUCCESS: type('[payments] payment dashboard count Success'),
    PAYMENTS_DASHBOARD_COUNT_FAIL: type('[payments] payment dashboard count Fail'),

    RECENT_PAYMENT_DUES: type('[payments] recent payment dues'),
    RECENT_PAYMENT_DUES_SUCCESS: type('[payments] recent payment dues Success'),
    RECENT_PAYMENT_DUES_FAIL: type('[payments] recent payment dues Fail'),

    GET_PAYMENTS_HISTORY_LIST: type('[payments] payment History list '),
    GET_PAYMENTS_HISTORY_LIST_SUCCESS: type('[payments] payment History list Success'),
    GET_PAYMENTS_HISTORY_LIST_FAIL: type('[payments] payment History list Fail'),

    PAYMENTS_ORDER_LIST: type('[payments] payment order list'),
    PAYMENTS_ORDER_LIST_SUCCESS: type('[payments] payment order list Success'),
    PAYMENTS_ORDER_LIST_FAIL: type('[payments] payment order list Fail'),

    PAYMENTS_HISTORY_LIST_COUNT: type('[payments] payment History list Count'),
    PAYMENTS_HISTORY_LIST_COUNT_SUCCESS: type('[payments] payment History list Count Success'),
    PAYMENTS_HISTORY_LIST_COUNT_FAIL: type('[payments] payment History list Count Fail'),

    GET_ORDER_LIST_COUNT: type('[payments] payments order list count'),
    GET_ORDER_LIST_COUNT_SUCCESS: type('[payments] payments order list count Success'),
    GET_ORDER_LIST_COUNT_FAIL: type('[payments] payments order list count Fail'),
};

//Payment Dashboard Count

export class PaymentDashboardCount implements Action {
  type = ActionTypes.PAYMENTS_DASHBOARD_COUNT;

  constructor(public payload: any) {
      console.log("actionsucess");
  }
}

export class PaymentDashboardCountSuccess implements Action {
  type = ActionTypes.PAYMENTS_DASHBOARD_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {

  }
}

export class PaymentDashboardCountFail implements Action {
  type = ActionTypes.PAYMENTS_DASHBOARD_COUNT_FAIL;

  constructor(public payload: any) {

  }
}

/**
 * RECENT PAYMENT DUES
 */
export class GetRecentPaymentDues implements Action {
  type = ActionTypes.RECENT_PAYMENT_DUES;

  constructor(public payload: any) {
  }
}

export class GetRecentPaymentDuesSuccess implements Action {
  type = ActionTypes.RECENT_PAYMENT_DUES_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetRecentPaymentDuesFailure implements Action {
  type = ActionTypes.RECENT_PAYMENT_DUES_FAIL;

  constructor(public payload: any) {
  }

}

// GetPaymentHistoryList
export class GetPaymentHistoryList implements Action {
  type = ActionTypes.GET_PAYMENTS_HISTORY_LIST;

  constructor(public payload: any) {
  }
}

export class GetPaymentHistoryListSuccess implements Action {
  type = ActionTypes.GET_PAYMENTS_HISTORY_LIST_SUCCESS;

  constructor(public payload: ResponseInterface, public payType: string) {
  }
}

export class GetPaymentHistoryListFail implements Action {
  type = ActionTypes.GET_PAYMENTS_HISTORY_LIST_FAIL;

  constructor(public payload: any) {
  }
}

//PaymentOrder List

export class PaymentOrderList implements Action {
  type = ActionTypes.PAYMENTS_ORDER_LIST;

  constructor(public payload: any) {
  }
}

export class PaymentOrderListSuccess implements Action {
  type = ActionTypes.PAYMENTS_ORDER_LIST_SUCCESS;

  constructor(public payload: ResponseInterface) {

  }
}

export class PaymentOrderListFail implements Action {
  type = ActionTypes.PAYMENTS_ORDER_LIST_FAIL;

  constructor(public payload: any) {

  }
}

// PaymentHistoryListCount
export class PaymentHistoryListCount implements Action {
  type = ActionTypes.PAYMENTS_HISTORY_LIST_COUNT;

  constructor(public payload: any) {
  }
}

export class PaymentHistoryListCountSuccess implements Action {
  type = ActionTypes.PAYMENTS_HISTORY_LIST_COUNT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class PaymentHistoryListCountFail implements Action {
  type = ActionTypes.PAYMENTS_HISTORY_LIST_COUNT_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * Payments Order list countactions.
 */
export class GetOrderListCountAction implements Action {
  type = ActionTypes.GET_ORDER_LIST_COUNT;

  constructor(public payload: any) {
  }
}

export class GetOrderListCountSuccess implements Action {
  type = ActionTypes.GET_ORDER_LIST_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class GetOrderListCountFailure implements Action {
  type = ActionTypes.GET_ORDER_LIST_COUNT_FAIL;

  constructor(public payload: any) {
  }
}

export type Actions =

| PaymentDashboardCount
| PaymentDashboardCountSuccess
| PaymentDashboardCountFail

| GetRecentPaymentDues
| GetRecentPaymentDuesSuccess
| GetRecentPaymentDuesFailure

| GetPaymentHistoryList
| GetPaymentHistoryListSuccess
| GetPaymentHistoryListFail

| PaymentOrderList
| PaymentOrderListSuccess
| PaymentOrderListFail

| PaymentHistoryListCount
| PaymentHistoryListCountSuccess
| PaymentHistoryListCountFail

| GetOrderListCountAction
| GetOrderListCountSuccess
| GetOrderListCountFailure
import { Map, Record } from 'immutable';


export interface ResourceState extends Map<string, any> {


    resourceNotifyCount: number;
}

export const resourceStateRecord = Record({

    resourceNotifyCount: 0,


});


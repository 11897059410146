import { Component, OnInit, OnChanges } from '@angular/core';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnChanges {
  invoiceId: Number;
  orderId: any;
  invoiceNo: any;
  constructor(public commonSanbox: CommonSandbox , public modal: NgbActiveModal) { }
  ngOnInit() {
    if (this.invoiceId !== void 0) {
      const params: any = {};
      params.id = this.invoiceId;
      this.commonSanbox.getInvoiceDetails(params);
      this.commonSanbox.invoiceDetails$.subscribe(data => {
        if (data) {
          this.orderId = data.orderPaymentId;
          this.invoiceNo = data.invoiceNo;
        }
      });
    }
  }
  ngOnChanges() {
  }
  downloadInvoice() {
    const param:any={};
    param.id= this.orderId;
    param.invoiceNo=this.invoiceNo;
    this.commonSanbox.downloadInvoice(param);
  }
  close() {
    this.modal.close();
}
}

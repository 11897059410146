export class TicketListModel {
    limit: number;
    count: string;
    offset: number;
    orderBy: string;
    keyword: string;
    clientId: string;
    projectId: string;
    ticketType: string;
    ticketStatusId: string;
    ticketPriorityId: string;
    ticketDate: string;
    sendApprovalStatus?: number;
    fromDate: string;
    toDate: string;
    ticketCostStatus: any;
    orderByModifiedDate: any;
    clientApprovalStatus: any;
    refresh: boolean;
    declined: number;
    waitForApproval:any;
    costRange:any
    amount:any;
    status:any;
    constructor(val: any) {

        this.limit = val.limit || '';
        this.status=val.status || '';
        this.count = val.count || '';
        this.offset = val.offset || 0;
        this.orderBy = val.orderBy || '';
        this.keyword = val.keyword || '';
        this.clientId = val.clientId || '';
        this.projectId = val.projectId || '';
        this.ticketStatusId = val.ticketStatusId || '';
        this.sendApprovalStatus = val.sendApprovalStatus || '';
        this.ticketPriorityId = val.ticketPriorityId || '';
        this.ticketDate = val.ticketDate || '';
        this.fromDate = val.fromDate || '';
        this.toDate = val.toDate || '';
        this.ticketCostStatus = val.ticketCostStatus === 0 ? 0 : '';
        this.refresh = val.refresh || false;
        this.orderByModifiedDate = val.orderByModifiedDate || '';
        this.ticketType = val.ticketType || '';
        this.clientApprovalStatus = val.clientApprovalStatus === 0 ? 0 : val.clientApprovalStatus === 1 ? 1 : '';
        this.costRange = val.costRange || '';
        this.amount=val.amount || '';
        this.waitForApproval =val.waitForApproval || '';
        if (val.declined === 0) {
            this.declined = 0;
        }
        if (val.declined === 1) {
            this.declined = 1;
        }
    }
}

export interface TicketList {
    limit?: any;
    offset?: number;
    search?: string;
    count?: boolean;
    refresh?: boolean;
    orderBy?: number;
}

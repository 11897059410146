import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../../app.state';
import * as notificationActions from './action/notification.action';
import { notificationStatusChange, notificationUnreadStatus, totalNotificationCount, totalNotificationCountFailed, totalNotificationCountLoaded, totalNotificationCountLoading, totalNotificationDetail, totalNotificationDetailFailed, totalNotificationDetailLoaded, totalNotificationDetailLoading, totalNotificationList, totalNotificationListFailed, totalNotificationListLoaded, totalNotificationListLoading } from './reducer/notification.selector';
import { NotificationListRequestModel } from 'src/app/layout/common/models/notification-list-request.model';

@Injectable()
export class NotificationSandbox {
    public notificationCount$ = this.appState.select(totalNotificationCount);
    public notificationCountLoading$ = this.appState.select(totalNotificationCountLoading);
    public notificationCountLoaded$ = this.appState.select(totalNotificationCountLoaded);
    public notificationCountFailed$ = this.appState.select(totalNotificationCountFailed);

    public notificationList$ = this.appState.select(totalNotificationList);
    public notificationListLoading$ = this.appState.select(totalNotificationListLoading);
    public notificationListLoaded$ = this.appState.select(totalNotificationListLoaded);
    public notificationListFailed$ = this.appState.select(totalNotificationListFailed);

    public notificationDetail$ = this.appState.select(totalNotificationDetail);
    public notificationDetailLoading$ = this.appState.select(totalNotificationDetailLoading);
    public notificationDetailLoaded$ = this.appState.select(totalNotificationDetailLoaded);
    public notificationDetailFailed$ = this.appState.select(totalNotificationDetailFailed);
    public notificationStatusChange$ = this.appState.select(notificationStatusChange);
    public notificationUnreadStatus$ = this.appState.select(notificationUnreadStatus);

    constructor(protected appState: Store<store.AppState>) {}

    public getNotificationCount(params) {
        this.appState.dispatch(new notificationActions.GetNotificationCount(params));
    }

    public getNotification(params) {
        this.appState.dispatch(new notificationActions.GetNotificationList(new NotificationListRequestModel(params)));
    }

    public getNotificationDetail(params) {
        this.appState.dispatch(new notificationActions.GetNotificationDetail(params));
    }

    public changeNotificationStatus(params) {
        this.appState.dispatch(new notificationActions.ChangeNotificationStatus(params));
    }

    public markNotificationUnread(params) {
        this.appState.dispatch(new notificationActions.NotificationUnread(params));
    }
}

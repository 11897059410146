import * as actions from '../action/resource.action';
import { ResourceState, resourceStateRecord } from './resource.state';


export const initialState: ResourceState = new resourceStateRecord() as unknown as ResourceState;

export function reducer(state = initialState, { type, payload }: any): ResourceState {
    if (!type) {
        return state;
    }

    switch (type) {

        /**
         * admin notification count case function
         */
        case actions.ActionTypes.RESOURCE_NOTIFY_COUNT:

            return Object.assign({}, state, {
            });

        case actions.ActionTypes.RESOURCE_NOTIFY_COUNT_SUCCESS:
            return Object.assign({}, state, {
                resourceNotifyCount: payload.data,
            });

        case actions.ActionTypes.RESOURCE_NOTIFY_COUNT_FAIL:

            return Object.assign({}, state, {
            });

        default: {
            return state;
        }
    }
}

/**
 * export values
 */

export const getResourceNotifyCount = (state: ResourceState) => state.resourceNotifyCount;


  export class PaymentList {
    amount: Number;
    comments: string;
    createdBy: string;
    createdDate: any;
    deleteFlag: string;
    gatewayResponse: string;
    id: string;
    isActive: string;
    modifiedBy: string;
    modifiedDate: string;
    netAmount: Number;
    orderDetails: any;
    invoiceDetails: any;
    paymentDate: string;
    bankName: string;
    overDueByDays: string;
    paymentDueDate: string;
    overDueStatus: Number;
    paidDate: string;
    paymentMode: Number;
    paymentStatus: string;
    taxAmount: Number;
    transactionId: string;
    transactionDetails: string;
    cancelledDate: string;
    currency: string;
    currencySymbol: any;
    orderId: any;
    constructor(val: any) {
      this.amount = val.amount || '';
      this.comments = val.comments || '';
      this.createdBy = val.createdBy || '';
      this.createdDate = new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) || '';
      this.deleteFlag = val.deleteFlag || '';
      this.gatewayResponse = val.gatewayResponse || '';
      this.id = val.id || '';
      this.isActive = val.isActive || '';
      this.modifiedBy = val.modifiedBy || '';
      this.modifiedDate = val.modifiedDate || '';
      this.netAmount = val.netAmount || '';
      this.orderDetails = val.orderDetails || '';
      this.paymentDueDate = val.paymentDueDate || '';
      this.paidDate = val.paidDate || '';
      this.invoiceDetails = val.invoiceDetails || '';
      this.paymentDate = val.paymentDate || '';
      this.bankName = val.bankName || '';
      this.overDueByDays = val.overDueByDays || '';
      this.overDueStatus = val.overDueStatus || '';
      this.paymentMode = val.paymentMode || '';
      this.paymentStatus = val.paymentStatus || '';
      this.taxAmount = val.taxAmount || '';
      this.transactionId = val.transactionId || '';
      this.transactionDetails = val.transactionDetails || '';
      this.cancelledDate = val.cancelledDate || '';
      this.currency = val.currency || '';
      this.currencySymbol = val.currencySymbol || '';
      this.orderId = val.orderId || '';
    }
  }
  export class PaymentListResponseModel {
    paidDate: string;
    paymentList: any ;
    constructor(val: any) {
      this.paidDate = val.paidDate || '';
      if (val.paymentList) {
          this.paymentList = val.paymentList.map(list => {
            const paymentDetails = new PaymentList(list);
            return paymentDetails;
          });
      } else {
          this.paymentList = new PaymentList(val);
      }
    }
  }

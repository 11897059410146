export class ProjectListRequest {
     limit: Number;
     offset: Number;
     orderBy: Number;
     clientId: Number;
     count: Number;
     search: String;
     refresh: Boolean;
     constructor(val: any) {
        this.limit = val.limit || '';
        this.offset = val.offset || '';
        this.clientId = val.clientId || '';
        this.count = val.count || '';
        this.search = val.search || '';
        this.orderBy = val.orderBy || 0;
        this.refresh = val.refresh || false;
     }
}

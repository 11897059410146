import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';
import * as settingsreducer from './settings.reducer';

export const getSettingsState = (state: AppState) => state.settings;

export const userProfile = createSelector(getSettingsState, settingsreducer.getUserProfile);
export const userProfileLoading = createSelector(getSettingsState, settingsreducer.getUserProfileLoading);
export const userProfileLoaded = createSelector(getSettingsState, settingsreducer.getUserProfileLoaded);
export const getUserProfileFailed = createSelector(getSettingsState, settingsreducer.getUserProfileFailed);

export const updateUserProfile = createSelector(getSettingsState, settingsreducer.updateUserProfile);
export const updateUserProfileLoading = createSelector(getSettingsState, settingsreducer.updateUserProfileLoading);
export const updateUserProfileLoaded = createSelector(getSettingsState, settingsreducer.updateUserProfileLoaded);
export const updateUserProfileFailed = createSelector(getSettingsState, settingsreducer.updateUserProfileLoaded);

export const subuserList = createSelector(getSettingsState, settingsreducer.subuserList);
export const subuserListLoading = createSelector(getSettingsState, settingsreducer.subuserListLoading);
export const subuserListLoaded = createSelector(getSettingsState, settingsreducer.subuserListLoaded);
export const subuserListFailed = createSelector(getSettingsState, settingsreducer.subuserListLoaded);

export const createSubuser = createSelector(getSettingsState, settingsreducer.createSubuser);
export const createSubuserLoading = createSelector(getSettingsState, settingsreducer.createSubuserLoading);
export const createSubuserLoaded = createSelector(getSettingsState, settingsreducer.createSubuserLoaded);
export const createSubuserFailed = createSelector(getSettingsState, settingsreducer.createSubuserLoaded);

export const updateSubuser = createSelector(getSettingsState, settingsreducer.updateSubuser);
export const updateSubuserLoading = createSelector(getSettingsState, settingsreducer.updateSubuserLoading);
export const updateSubuserLoaded = createSelector(getSettingsState, settingsreducer.updateSubuserLoaded);
export const updateSubuserFailed = createSelector(getSettingsState, settingsreducer.updateSubuserLoaded);

export const updateSubuserStatus = createSelector(getSettingsState, settingsreducer.updateSubuserStatus);
export const updateSubuserStatusLoading = createSelector(getSettingsState, settingsreducer.updateSubuserStatusLoading);
export const updateSubuserStatusLoaded = createSelector(getSettingsState, settingsreducer.updateSubuserStatusLoaded);
export const updateSubuserStatusFailed = createSelector(getSettingsState, settingsreducer.updateSubuserStatusLoaded);

export const deleteSubuser = createSelector(getSettingsState, settingsreducer.deleteSubuser);
export const deleteSubuserLoading = createSelector(getSettingsState, settingsreducer.deleteSubuserLoading);
export const deleteSubuserLoaded = createSelector(getSettingsState, settingsreducer.deleteSubuserLoaded);
export const deleteSubuserFailed = createSelector(getSettingsState, settingsreducer.deleteSubuserLoaded);
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { SettingsLayoutComponent } from './../settings-layout/settings-layout.component';
export const AUTH = {
  AuthComponent,
  SettingsLayoutComponent
};

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: []
})
export class AuthContainerModule {
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nodata',
  templateUrl: 'nodata.component.html',
  styleUrls: ['./nodata.component.scss']
})

export class NodataComponent implements OnInit {
  @Input() text: string;
  constructor() {

  }


  ngOnInit() {
  }
}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/providers/api/api';
import { environment } from 'src/environments/environment';

@Injectable()

export class MyDownloadsService extends Api {

  paymentUrl = environment.paymentUrl;
  
  loginOTP(params:any):Observable<any>{
    return this.http.post(this.paymentUrl + 'login-with-otp', params);
  }
  validateOTP(params:any):Observable<any>{
    return this.http.post(this.paymentUrl + 'validate-otp', params);
  }
  resentOTP(params:any):Observable<any>{
    return this.http.post(this.paymentUrl + 'resend-otp', params);
  }
  validateEmail(params: any):Observable<any> {
    const basicAuth = 'Basic ' + btoa(environment.piccoDigital.userName + ':' + environment.piccoDigital.password);
    const header = new HttpHeaders().append('Authorization', basicAuth);
    return this.http.post(this.paymentUrl + 'validate-email', params, {headers: header});
  }
  myPurchase(params: any):Observable<any> {
    return this.http.get(this.paymentUrl + 'myPurchase', {params:params});
  }
  validateKey(params: any):Observable<any> {
    return this.http.post(this.paymentUrl + 'access-key-validate', params);
  }
  assetVersion(params: any):Observable<any> {
    return this.http.post(this.paymentUrl + 'get-asset-versions', params);
  }
  download(params: any):Observable<any> {
    return this.http.post(this.paymentUrl + 'access-key-download', params);
  }

}

import { Map, Record } from 'immutable';
import { TicketListResponseModel } from 'src/app/layout/common/models/ticket-list-response.model';

export interface ApprovalsState extends Map<string, any> {

  getAssessmentTickets: any;
  getAssessmentTicketsLoading: boolean;
  getAssessmentTicketsLoaded: boolean;
  getAssessmentTicketsFailed: boolean;

  getAssessmentTicketsCount: number;
  getAssessmentTicketsCountLoading: boolean;
  getAssessmentTicketsCountLoaded: boolean;
  getAssessmentTicketsCountFailed: boolean;

  acceptAssessmentTickets: any;
  acceptAssessmentTicketsLoading: boolean;
  acceptAssessmentTicketsLoaded: boolean;
  acceptAssessmentTicketsFailed: boolean;

}

export const approvalsStateRecord = Record({

  getAssessmentTickets: [],
  getAssessmentTicketsLoading: false,
  getAssessmentTicketsLoaded: false,
  getAssessmentTicketsFailed: false,

  getAssessmentTicketsCount: [],
  getAssessmentTicketsCountLoading: false,
  getAssessmentTicketsCountLoaded: false,
  getAssessmentTicketsCountFailed: false,

  acceptAssessmentTickets: [],
  acceptAssessmentTicketsLoading: false,
  acceptAssessmentTicketsLoaded: false,
  acceptAssessmentTicketsFailed: false,
});


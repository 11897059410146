import { CommonSandbox } from '../common.sandbox';
import { SettingsSandbox } from 'src/app/settings/settings.sandbox';
import { Component, OnInit } from '@angular/core';
import { NotificationSandbox } from 'src/app/client/notification/notification.sandbox';
@Component({
    selector: 'app-common',
    templateUrl: './common.component.html',
    styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {
    clientId: number;
    userId: any;
    currentRole: any;
    showFooter: boolean = false;

    constructor(public commonSandbox: CommonSandbox, public settingsSandbox: SettingsSandbox, public notificationSandbox: NotificationSandbox) {
        this.currentRole = JSON.parse(
            localStorage.getItem('clientDetail')
        ).userGroupId;
        this.userId = JSON.parse(
            localStorage.getItem('clientDetail')
        ).id;
    }
    // @HostListener("window:scroll", [])
    // onScroll(): void {
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //         this.showFooter = true;
    //     } else {
    //         this.showFooter = false;
    //     }
    // }
    ngOnInit() {
        const param: any = {};
        param.orderByModifiedDate = 2;
        this.commonSandbox.getTicketList(param);
        if (this.clientId) {
            this.commonSandbox.getProjectList({
                clientId: this.clientId, refresh: true
            });
        }
        this.commonSandbox.getPriorityList();
        this.commonSandbox.GetStatus();
        this.settingsSandbox.userProfile();
        const params: any = {};
        params.count = true;
        params.userId = this.userId;
        this.notificationSandbox.getNotificationCount(params);
        }

    getClientId() {
        if (localStorage.getItem('clientDetail')) {
            this.clientId = JSON.parse(localStorage.getItem('clientDetail')).id;
        }
    }
}

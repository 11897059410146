import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeletePopupModalComponent } from "../../popups/delete-popup-modal/delete-popup-modal.component";
import { SettingsSandbox } from "src/app/settings/settings.sandbox";
import { NotificationSandbox } from "src/app/client/notification/notification.sandbox";

@Component({
  selector: "app-layout-settings",
  templateUrl: "./layout-settings.component.html",
  styleUrls: ["./layout-settings.component.scss"],
})
export class LayoutSettingsComponent implements OnInit {
  imageBaseURL: any;
  @Output() progressEmit = new EventEmitter<any>();

  constructor(
    public settingsSandbox: SettingsSandbox,
    public router: Router,
    public modal: NgbModal,
    public notificationSandbox: NotificationSandbox
  ) {}

  ngOnInit() {
    const that = this;
    $(document).mouseup(function (e: any) {
      const $menu = $(".dropdown");
      if (
        !$menu.is(e.target) && // if the target of the click isn't the container...
        $menu.has(e.target).length === 0
      ) {
      }
    });
    const $window = $(window);
    $window.on("scroll", function () {
      $(".dropdown-menu").removeClass("show");
    });
    this.imageBaseURL = environment.baseImgUrl;
    this.progressEmit.emit(false);
    this.settingsSandbox.userProfile$.subscribe(res=>{
      console.log('1414',res)
    })
  }

  logout() {
    const modelRef = this.modal.open(DeletePopupModalComponent, {
      size: "sm",
      windowClass: "delete-confirm",
      backdrop: "static",
    });
    modelRef.componentInstance.message = "Are you sure you want to logout ?";
    modelRef.result.then((result) => {
      if (result === "deleted") {
        localStorage.removeItem("clientDetail");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("clientToken");
        localStorage.removeItem("purchase");
        localStorage.removeItem("purchasedProduct");
        this.router.navigate(["/auth/login-client"]);
      }
    });
  }

  routingMyDownloads() {
    if (localStorage.getItem("purchase")) {
      this.router.navigate(["/client/mydownloads/mypurchase"]);
    } else {
      this.router.navigate(["/client/mydownloads/otpverify"]);
    }
  }
}

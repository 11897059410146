import { Action } from '@ngrx/store';
import {type} from '../../../shared/utility';
import {ResponseInterface} from '../../../shared/interfaces/interface';

export const ActionTypes = {
  GET_ASSESSMENT_TICKETS: type('[tickets] tickets assessment ticket'),
  GET_ASSESSMENT_TICKETS_SUCCESS: type('[tickets] tickets assessment ticket Success'),
  GET_ASSESSMENT_TICKETS_FAIL: type('[tickets] tickets assessment ticket Fail'),
  
  GET_ASSESSMENT_TICKETS_COUNT: type('[tickets] tickets assessment ticket count'),
  GET_ASSESSMENT_TICKETS_COUNT_SUCCESS: type('[tickets] tickets assessment ticket count Success'),
  GET_ASSESSMENT_TICKETS_COUNT_FAIL: type('[tickets] tickets assessment ticket count Fail'),

  ACCEPT_ASSESSMENT_TICKETS: type('[tickets] accept assessment ticket'),
  ACCEPT_ASSESSMENT_TICKETS_SUCCESS: type('[tickets] accept assessment ticket Success'),
  ACCEPT_ASSESSMENT_TICKETS_FAIL: type('[tickets] accept assessment ticket Fail'),

};

/**
 * ticket list actions.
 */
export class getAssessmentTicketsAction implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS;

  constructor(public payload: any) {
  }
}

export class getAssessmentTicketsSuccess implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class getAssessmentTicketsFail implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * AssessmentTicketsCount.
 */
export class getAssessmentTicketsCountAction implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS_COUNT;

  constructor(public payload: any) {
  }
}

export class getAssessmentTicketsCountSuccess implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS_COUNT_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class getAssessmentTicketsCountFail implements Action {
  type = ActionTypes.GET_ASSESSMENT_TICKETS_COUNT_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * acceptAssessmentTickets actions.
 */
export class acceptAssessmentTicketsAction implements Action {
  type = ActionTypes.ACCEPT_ASSESSMENT_TICKETS;

  constructor(public payload: any) {
  }
}

export class acceptAssessmentTicketsSuccess implements Action {
  type = ActionTypes.ACCEPT_ASSESSMENT_TICKETS_SUCCESS;

  constructor(public payload: ResponseInterface) {
  }
}

export class acceptAssessmentTicketsFail implements Action {
  type = ActionTypes.ACCEPT_ASSESSMENT_TICKETS_FAIL;

  constructor(public payload: any) {
  }
}

export type Actions =
  | getAssessmentTicketsAction
  | getAssessmentTicketsSuccess
  | getAssessmentTicketsFail
  | getAssessmentTicketsCountAction
  | getAssessmentTicketsCountSuccess
  | getAssessmentTicketsCountFail
  | acceptAssessmentTicketsAction
  | acceptAssessmentTicketsSuccess
  | acceptAssessmentTicketsFail;
import { AuthState, authStateRecord } from './auth.state';
import * as actions from '../actions/auth.action';
import { VerifyOtpResponseModel } from '../models/verify-otp-response.model';

export const initialState: AuthState = new authStateRecord() as unknown as AuthState;

export function reducer(state = initialState, { type, payload }: any): AuthState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * login reducer case function
     */
    case actions.ActionTypes.DO_LOGIN:

      return Object.assign({}, state, {
        loginsuccess:{},
        loginLoading: true,
        loginLoaded: false,
        loginFailed: false,
        loginfailure: {}
      });

    case actions.ActionTypes.DO_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loginfailure: {},
        loginLoading: false,
        loginLoaded: true,
        loginFailed: false,
        loginsuccess: payload,
      });

    case actions.ActionTypes.DO_LOGIN_FAIL:

      return Object.assign({}, state, {
        loginLoading: false,
        loginLoaded: false,
        loginFailed: true,
        loginfailure: payload

      });


    /**
     * login reducer case function
     */

    case actions.ActionTypes.DO_WEB_LOGIN:

      return Object.assign({}, state, {
        loginLoading: true,
        loginLoaded: false,
        loginFailed: false,
        loginfailure: {}
      });

    case actions.ActionTypes.DO_WEB_LOGIN_SUCCESS:
      const tempDataWeb = new VerifyOtpResponseModel(payload.data);
      return Object.assign({}, state, {
        loginfailure: {},
        loginLoading: false,
        loginLoaded: true,
        loginFailed: false,
        loginsuccess: tempDataWeb
      });

    case actions.ActionTypes.DO_WEB_LOGIN_FAIL:

      return Object.assign({}, state, {
        loginLoading: false,
        loginLoaded: true,
        loginFailed: true,
        loginfailure: payload

      });
    /**
  * forget password case function
  */
    case actions.ActionTypes.FORGET_PASSWORD:

      return Object.assign({}, state, {
        forgetPasswordLoading: true,
        forgetPasswordLoaded: false,
        forgetPasswordfailure: false
      });

    case actions.ActionTypes.FORGET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        forgetPasswordLoading: false,
        forgetPasswordLoaded: true,
        forgetPasswordfailure: false,
      });

    case actions.ActionTypes.FORGET_PASSWORD_FAIL:

      return Object.assign({}, state, {
        forgetPasswordLoading: false,
        forgetPasswordLoaded: true,
        forgetPasswordfailure: true,

      });
    /**
   * Reset password case function
   */
    case actions.ActionTypes.RESET_PASSWORD:

      return Object.assign({}, state, {
        resetPasswordLoading: true,
        resetPasswordLoaded: false,
        resetPasswordfailure: false
      });

    case actions.ActionTypes.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        resetPasswordLoading: false,
        resetPasswordLoaded: true,
        resetPasswordfailure: false,
      });

    case actions.ActionTypes.RESET_PASSWORD_FAIL:

      return Object.assign({}, state, {
        resetPasswordLoading: false,
        resetPasswordLoaded: true,
        resetPasswordfailure: true,

      });
    /**
* Check user case function
*/
    case actions.ActionTypes.CHECK_USER:

      return Object.assign({}, state, {
        checkUserLoading: true,
        checkUserLoaded: false,
        checkUserfailure: false
      });

    case actions.ActionTypes.CHECK_USER_SUCCESS:
      return Object.assign({}, state, {
        checkUser: payload,
        checkUserLoading: false,
        checkUserLoaded: true,
        checkUserfailure: false,
      });

    case actions.ActionTypes.CHECK_USER_FAIL:

      return Object.assign({}, state, {
        checkUserLoading: false,
        checkUserLoaded: true,
        checkUserfailure: true,

      });


     /**
     * Verify Otp case function
     */
    case actions.ActionTypes.VERIFY_OTP:

      return Object.assign({}, state, {
        verifyOtpLoading: true,
        verifyOtpLoaded: false,
        verifyOtpFailed: false,
        verifyOtpfailure: {}
      });

    case actions.ActionTypes.VERIFY_OTP_SUCCESS:
      const tempData = new VerifyOtpResponseModel(payload.data);
      return Object.assign({}, state, {
        verifyOtpfailure: {},
        verifyOtpLoading: false,
        verifyOtpLoaded: true,
        verifyOtpFailed: false,
        verifyOtpSuccess: tempData,
      });

    case actions.ActionTypes.VERIFY_OTP_FAIL:
   console.log('otp verify failer',payload);
      return Object.assign({}, state, {
        verifyOtpLoading: false,
        verifyOtpLoaded: false,
        verifyOtpFailed: true,
        verifyOtpfailure: payload

      });


     /**
     * Resend Otp case function
     */
    case actions.ActionTypes.RESEND_OTP:

      return Object.assign({}, state, {
        resendOtpLoading: true,
        resendOtpLoaded: false,
        resendOtpFailed: false,
        resendOtpfailure: {}
      });

    case actions.ActionTypes.RESEND_OTP_SUCCESS:
      
      return Object.assign({}, state, {
        resendOtpfailure: {},
        resendOtpLoading: false,
        resendOtpLoaded: true,
        resendOtpFailed: false,
        resendOtpsuccess: payload.data
      });

    case actions.ActionTypes.RESEND_OTP_FAIL:

      return Object.assign({}, state, {
        resendOtpLoading: false,
        resendOtpLoaded: true,
        resendOtpFailed: true,
        resendOtpfailure: payload

      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */
export const getLoginSuccess = (state: AuthState) => state.loginsuccess;
export const getLoginFailure = (state: AuthState) => state.loginfailure;

export const getLoginLoading = (state: AuthState) => state.loginLoading;
export const getLoginLoaded = (state: AuthState) => state.loginLoaded;
export const getLoginFailed = (state: AuthState) => state.loginFailed;

export const getForgetPassowrdLoading = (state: AuthState) => state.forgetPasswordLoading;
export const getForgetPassowrdLoaded = (state: AuthState) => state.forgetPasswordLoaded;
export const getForgetPassowrdFailed = (state: AuthState) => state.forgetPasswordfailure;

export const getResetPassowrdLoading = (state: AuthState) => state.resetPasswordLoading;
export const getResetPassowrdLoaded = (state: AuthState) => state.resetPasswordLoaded;
export const getResetPassowrdFailed = (state: AuthState) => state.resetPasswordfailure;

export const gettCheckUser = (state: AuthState) => state.checkUser;
export const getCheckUserLoading = (state: AuthState) => state.checkUserLoading;
export const getCheckUserLoaded = (state: AuthState) => state.checkUserLoaded;
export const getCheckUserFailed = (state: AuthState) => state.checkUserfailure;



export const verifyOtpSuccess = (state: AuthState) => state.verifyOtpSuccess;
export const verifyOtpFailure = (state: AuthState) => state.verifyOtpFailure;

export const verifyOtpLoading = (state: AuthState) => state.verifyOtpLoading;
export const verifyOtpLoaded = (state: AuthState) => state.verifyOtpLoaded;
export const verifyOtpFailed = (state: AuthState) => state.verifyOtpFailed;



export const resendOtpSuccess = (state: AuthState) => state.resendOtpSuccess;
export const resendOtpFailure = (state: AuthState) => state.resendOtpFailure;

export const resendOtpLoading = (state: AuthState) => state.resendOtpLoading;
export const resendOtpLoaded = (state: AuthState) => state.resendOtpLoaded;
export const resendOtpFailed = (state: AuthState) => state.resendOtpFailure;




import { Map, Record } from 'immutable';

export interface DashboardState extends Map<string, any> {

    paymentList: any;
    paymentListLoading: boolean;
    paymentListLoaded: boolean;
    paymentListFailed: boolean;
    paymentHistorycount: any;
    paymentRequestList: any;

    orderListCount: number;
    orderListCountLoading: boolean;
    orderListCountLoaded: boolean;
    orderListCountFailed: boolean;

    newOrderListCount: number;
    newOrderListCountLoading: boolean;
    newOrderListCountLoaded: boolean;
    newOrderListCountFailed: boolean;

    ticketAcceptReject: any;
    ticketAcceptRejectLoading: boolean;
    ticketAcceptRejectLoaded: boolean;
    ticketAcceptRejectFailed: boolean;


    recentTickets: any;
    recentTicketsLoading: boolean;
    recentTicketsLoaded: boolean;
    recentTicketsFailed: boolean;

    ticketStatusCount: any;
    ticketStatusCountLoading: boolean;
    ticketStatusCountLoaded: boolean;
    ticketStatusCountFailed: boolean;

}

export const dashboardStateRecord = Record({
    paymentList: 0,
    paymentRequestList: '',
    paymentListLoading: false,
    paymentListLoaded: false,
    paymentListFailed: false,
    paymentHistorycount: 0,


    orderListCount: {},
    orderListCountLoading: false,
    orderListCountLoaded: false,
    orderListCountFailed: false,

    newOrderListCount: {},
    newOrderListCountLoading: false,
    newOrderListCountLoaded: false,
    newOrderListCountFailed: false,

    ticketAcceptReject: {},
    ticketAcceptRejectLoading: false,
    ticketAcceptRejectLoaded: false,
    ticketAcceptRejectFailed: false,

    recentTickets: {},
    recentTicketsLoading: false,
    recentTicketsLoaded: false,
    recentTicketsFailed: false,

    ticketStatusCount: {},
    ticketStatusCountLoading: false,
    ticketStatusCountLoaded: false,
    ticketStatusCountFailed: false,

});


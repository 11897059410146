import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class Api {

  constructor(public http: HttpClient) {

  }

  protected getBaseUrl(): string {
    return environment.baseUrl;
  }
  protected getBaseLink(): string {
    return environment.baseLink;
  }

}

import { Component, Input, OnInit } from "@angular/core";
import { CropperSettings, Dimensions, ImageTransform } from "ngx-image-cropper";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "app-imagecrop",
  templateUrl: "./imagecrop.component.html",
  styleUrls: ["./imagecrop.component.scss"],
})
export class ImagecropComponent implements OnInit {
  @Input() fileName?: any;
  @Input() aspectRatio: any;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  cropperMinHeight = 500;
  canvasRotation = 0;
  rotation?: any;
  translateH = 0;
  translateV = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {
    translateUnit: "px",
  };
  loading = false;
  allowMoveImage = false;
  hidden = false;
  cropperSettings!: CropperSettings;
  paramsValue: any;

  constructor(
    private sanitizer: DomSanitizer,
    private model: NgbActiveModal,
    public router: Router
  ) {
    this.aspectRatio = this.aspectRatio ? this.aspectRatio : 4 / 3;
  }
  ngOnInit() {}   
  imageCropped(event: any) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl || event.base64 || ""
    );
  }

  submit() {
    const val = {
      croppedImage: this.croppedImage,
      isChoosed: true,
    };
    this.model.close(val);
  }

  zoom(event: any) {
    console.log(event, "Zoom");

    const inputValue = event.target.valueAsNumber;
    if (inputValue > 50) {
      // Zoom in
      this.scale = 1 + (inputValue - 50) / 100;
    } else if (inputValue < 50) {
      // Zoom out
      this.scale = 1 - (50 - inputValue) / 100;
    } else {
      // No zoom
      this.scale = 1;
    }

    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  imageLoaded(event: any) {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.loading = false;
  }

  loadImageFailed() {
    console.error("Load image failed");
  }

  modelClose() {
    this.model.close("close");
  }
}

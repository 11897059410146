import { Component, OnInit } from '@angular/core';
import { AuthSandbox } from 'src/app/auth/auth.sandbox';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  forgetpassword = '';
  emailMatch: any;
  emailCheck = false;
  submitted = false;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  constructor(public authSanbox: AuthSandbox) { }

  ngOnInit() {
  }
  checkEmail(email) {
      this.submitted = false;
   this.emailMatch = email.match(this.emailPattern);

   if (this.emailMatch == null) {
      this.emailCheck = true;
   } else {
     this.emailCheck = false;
   }
   this.forgetpassword = email;
  }
  forgetPassword() {
      this.submitted = true;
    if (this.forgetpassword !== '' && !this.emailCheck) {
    this.authSanbox.forgetPassword({emailId: this.forgetpassword});
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { Config } from 'protractor/built/config';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Router } from '@angular/router';
import { AuthSandbox } from '../../auth.sandbox';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupotp: boolean = false;
  submitted: boolean = false;
  signupForm: FormGroup;
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
  };
  @ViewChild("ngOtpInput") ngOtpInput: NgOtpInputComponent | undefined;
  isSpinner: boolean = false;
  emailRegexp =/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  otp: string;
  loginDetail: any;
  message: string;
  username: any;

  constructor(private fb: FormBuilder, private authService: AuthService, private route: Router, public authSandbox: AuthSandbox) { }

  ngOnInit(): void {
    this.oninitSignupform();
  }
  oninitSignupform() { 
    this.signupForm = this.fb.group({
      username: ['',[Validators.required]],
      email: ['',[Validators.required,Validators.pattern(this.emailRegexp)]]
    })
  }
  signupProceed(){
    this.submitted = true;
    this.isSpinner = true;
    if(this.signupForm.invalid){
      this.isSpinner = false;
      return;
    }
    const params:any = {
      name: this.signupForm.value.username,
      emailId: this.signupForm.value.email
    }
    this.authService.dosignUp(params).subscribe(res =>{
      if(res.status == 1){
        this.submitted = false;
        this.signupotp = true;
        this.isSpinner = false;
        this.loginDetail = res.data;
        this.message = res.message;
        this.username = res.data.emailId
      }
    })
  }
  onOtpChange(event: string): void {
    this.otp = event;
    this.submitted = true;
  }
  otpVerify(): void {
    if (JSON.parse(JSON.stringify(this.otp)).length !== 6) {
      return;
    }
    const params: any = {};
    params.otp = this.otp;
    params.id = this.loginDetail.id;
    this.authSandbox.doVerifyOtp(params);
    this.authSandbox.verifyOtpFailed$.subscribe((val) => {
      if (val == true) {
        this.otp = "";
        this.ngOtpInput.setValue("");
      }
    });
  }
  onPrev(): void {
    this.signupotp = false;
    this.submitted = false;
    this.signupForm.reset();
    this.signupForm.clearValidators();
    this.signupForm.controls["tabset"].setValue("email");
    this.username = "";
  }
  resendOtp(): void {
    if (this.ngOtpInput) {
      this.otp = "";
      this.ngOtpInput.setValue("");
      const param: any = {};
      param.login = this.username;
      this.authService.doLogin(param).subscribe(res=>{})
    }
  }
}

export class ProjectDetails {
  clientId: string;
  createdBy: string;
  createdDate: string;
  deleteFlag: string;
  id: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: string;
  projectCode: string;
  projectDescription: string;
  projectTitle: string;

  constructor(val: any) {
    this.clientId = val.clientId || '';
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.deleteFlag = val.deleteFlag || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.projectCode = val.projectCode || '';
    this.projectDescription = val.projectDescription || '';
    this.projectTitle = val.projectTitle || '';
  }
}

export class ProjectListResponse {

  clientId: Number;
  createdBy: null;
  createdDate: string;
  id: Number;
  projectDetails: any;
  projectId: Number;


  constructor(val: any) {
    this.clientId = val.clientId || '';
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.id = val.id || '';
    this.projectDetails = new ProjectDetails(val.projectDetails) || '';
    this.projectId = val.projectId || '';
  }
}



export class TotalProjectListResponse {

  createdBy: null;
  createdDate: string;
  id: Number;
  projectDescription: string;
  projectCode: string;
  projectTitle: string;
  ticketCreatedStatus?: number;

  constructor(val: any) {
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.id = val.id || '';
    this.projectDescription = val.projectDescription || '';
    this.projectCode = val.projectCode || '';
    this.projectTitle = val.projectTitle || '';
    this.ticketCreatedStatus = val.ticketCreatedStatus || '';

  }
}


import { Map, Record } from 'immutable';

export interface PaymentsState extends Map<string, any> {

  paymentDashboardCount: any;
  paymentDashboardCountLoading: boolean;
  paymentDashboardCountLoaded: boolean;
  paymentDashboardCountFailed: boolean;

  recentPaymentDues: Number;
  recentPaymentDuesLoading: boolean;
  recentPaymentDuesLoaded: boolean;
  recentPaymentDuesFailed: boolean;

  paymentHistoryList: Array<any>;
  paymentHistoryListLoading: boolean;
  paymentHistoryListLoaded: boolean;
  paymentHistoryListFailed: boolean;

  paymentOrderList: any;
  paymentOrderListLoading: boolean;
  paymentOrderListLoaded: boolean;
  paymentOrderListFailed: boolean;

  paymentHistoryListCount: any;

  orderListCount: number;
  orderListCountLoading: boolean;
  orderListCountLoaded: boolean;
  orderListCountFailed: boolean;
}

export const paymentsStateRecord = Record({

  paymentDashboardCount: {},
  paymentDashboardCountLoading: false,
  paymentDashboardCountLoaded: false,
  paymentDashboardCountFailed: false,

  recentPaymentDues: {},
  recentPaymentDuesLoading: false,
  recentPaymentDuesLoaded: false,
  recentPaymentDuesFailed: false,

  paymentHistoryList: [],
  paymentHistoryListLoading: false,
  paymentHistoryListLoaded: false,
  paymentHistoryListFailed: false,

  paymentOrderList: {},
  paymentOrderListLoading: false,
  paymentOrderListLoaded: false,
  paymentOrderListFailed: false,

  paymentHistoryListCount: {},

  orderListCount: 0,
  orderListCountLoading: false,
  orderListCountLoaded: false,
  orderListCountFailed: false,
  
});


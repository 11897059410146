import { filter } from 'rxjs/operators';
import * as actions from '../actions/common.action';
import { CommonState, commonStateRecord } from './common.state';
import { ProjectListResponse } from '../models/project-list.response';
import { PriorityListModel } from '../models/priority-list.model';
import { PaymentListResponseModel, PaymentList } from '../models/payment-list-response.modal';
import { StatusResponseModel } from '../models/status-response.model';
import { TicketListResponseModel } from '../models/ticket-list-response.model';
import { UserProfile } from '../models/user-profile.response.modal';
import { ActivityDetailModel } from '../models/activity-detail.model';
import { TotalProjectListResponse } from '../models/project-list.response';
import { OrderListResponseModal } from '../models/order-list-response-model';
import { PaymentDetailResponse } from '../models/payment-detail-response.model';
import { ResourceResponseModel } from '../models/resource.response.model';
import { AssignedResourceResponseModel } from '../models/assigned.resource.response.model';
import { GetResorceResponseModel } from '../models/get-resorce.model';



export const initialState: CommonState = new commonStateRecord() as unknown as CommonState;

export function reducer(state = initialState, { type, payload, payType,payload2,payload1 }: any): CommonState {
    if (!type) {
        return state;
    }

    switch (type) {
        // case actions.ActionTypes.BACK_STATUS:
        //   state.ticketDetail.tempticketStatusId -= 1;
        //   return Object.assign({}, state, {
        //     ticketDetail: state.ticketDetail
        //   });

        /**
         * project list reducer case function
         */
        case actions.ActionTypes.GET_PROJECT_LIST:

            return Object.assign({}, state, {
                projectListLoading: true,
                projectListLoaded: false,
                projectListFailed: false
            });

        case actions.ActionTypes.GET_PROJECT_LIST_SUCCESS:
            const templist = payload.data.projectList.map(list => {
                const projectDetail = new ProjectListResponse(list);
                return projectDetail;
            });

            return Object.assign({}, state, {
                projectList: templist,
                projectListLoading: false,
                projectListLoaded: true,
                projectListFailed: false,
            });

        case actions.ActionTypes.GET_PROJECT_LIST_FAIL:
            return Object.assign({}, state, {
                projectListLoading: false,
                projectListLoaded: true,
                projectListFailed: true,

            });
        /**
         * total project list reducer case function
         */
        case actions.ActionTypes.GET_TOTAL_PROJECT_LIST:
            const refresh = payload.refresh;
            if (refresh) {
                return Object.assign({}, state, {
                    totalprojectListLoading: true,
                    totalprojectListLoaded: false,
                    totalprojectListFailed: false,
                    totalprojectList: []
                });
            }
            return Object.assign({}, state, {
                totalprojectListLoading: true,
                totalprojectListLoaded: false,
                totalprojectListFailed: false
            });

        case actions.ActionTypes.GET_TOTAL_PROJECT_LIST_SUCCESS:
            let tempProjectList: TotalProjectListResponse[];
            const newFollowLeadList = payload.data.map(_projectList => {
                const tempProjectsList = new TotalProjectListResponse(_projectList);
                return tempProjectsList;
            });
            tempProjectList = state.totalprojectList ? state.totalprojectList : [];
            tempProjectList = tempProjectList.concat(newFollowLeadList);

            return Object.assign({}, state, {
                totalprojectList: tempProjectList,
                totalprojectListLoading: false,
                totalprojectListLoaded: true,
                totalprojectListFailed: false,
            });

        case actions.ActionTypes.GET_TOTAL_PROJECT_LIST_FAIL:

            return Object.assign({}, state, {
                totalprojectListLoading: false,
                totalprojectListLoaded: true,
                totalprojectListFailed: true,

            });
        /**
         * total project count reducer case function
         */
        case actions.ActionTypes.GET_TOTAL_PROJECT_COUNT:

            return Object.assign({}, state, {
                totalprojectCountLoading: true,
                totalprojectCountLoaded: false,
                totalprojectCountFailed: false
            });

        case actions.ActionTypes.GET_TOTAL_PROJECT_COUNT_SUCCESS:
            const temptotalCount = payload.data;
            return Object.assign({}, state, {
                totalprojectCount: temptotalCount,
                totalprojectCountLoading: false,
                totalprojectCountLoaded: true,
                totalprojectCountFailed: false,
            });

        case actions.ActionTypes.GET_TOTAL_PROJECT_COUNT_FAIL:

            return Object.assign({}, state, {
                totalprojectCountLoading: false,
                totalprojectCountLoaded: true,
                totalprojectCountFailed: true,

            });
        /**
         * priority list reducer case function
         */
        case actions.ActionTypes.GET_PRIORITY_LIST:

            return Object.assign({}, state, {
                priorityListLoading: true,
                priorityListLoaded: false,
                priorityListFailed: false
            });

        case actions.ActionTypes.GET_PRIORITY_LIST_SUCCESS:
            const tempPriority = payload.data.map(list => {
                const priorityDetail = new PriorityListModel(list);
                return priorityDetail;
            });
            return Object.assign({}, state, {
                priorityList: tempPriority,
                priorityListLoading: false,
                priorityListLoaded: true,
                priorityListFailed: false,
            });

        case actions.ActionTypes.GET_PRIORITY_LIST_FAIL:

            return Object.assign({}, state, {
                priorityListLoading: false,
                priorityListLoaded: true,
                priorityListFailed: true,

            });

        /**
         * create request reducer case function
         */
        case actions.ActionTypes.CREATE_REQUEST:
            return Object.assign({}, state, {
                createRequestLoading: true,
                createRequestLoaded: false,
                createRequestFailed: false
            });

        case actions.ActionTypes.CREATE_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                createRequestLoading: false,
                createRequestLoaded: true,
                createRequestFailed: false,
            });
        case actions.ActionTypes.CREATE_REQUEST_FAIL:
            return Object.assign({}, state, {
                createRequestLoading: false,
                createRequestLoaded: true,
                createRequestFailed: true,
            });
        /**
         * ticket change status  reducer case function
         */
        case actions.ActionTypes.TICKET_STATUS_CHANGE:

            return Object.assign({}, state, {
                TicketStatusChangeLoading: true,
                TicketStatusChangeLoaded: false,
                TicketStatusChangeFailed: false,
            });

        case actions.ActionTypes.TICKET_STATUS_CHANGE_SUCCESS:
            state.ticketDetail.ticketStatusId = payload.data.ticketStatusId;
            state.ticketDetail.tempticketStatusId = payload.data.ticketStatusId;
            state.ticketDetail.reviewNotes = payload.data.reviewNotes;
            state.ticketDetail.scheduleNotes = payload.data.scheduleNotes;
            state.ticketDetail.implementationNotes = payload.data.implementationNotes;
            state.ticketDetail.ticketDuration = payload.data.ticketDuration;
            state.ticketDetail.approvalNotes = payload.data.approvalNotes;
            state.ticketDetail.ticketStartDate = payload.data.ticketStartDate;
            state.ticketDetail.expectedClosedDate = payload.data.expectedClosedDate;
            state.ticketDetail.ticketClosureDate = payload.data.ticketClosureDate;
            state.ticketDetail.ticketCost = payload.data.ticketCost;
            state.ticketDetail.ticketLogDetails = payload.data.ticketLogDetails;
            state.ticketDetail.implementationFlag = payload.data.implementationFlag;
            return Object.assign({}, state, {
                TicketStatusChange: payload,
                TicketStatusChangeLoading: false,
                TicketStatusChangeLoaded: true,
                TicketStatusChangeFailed: false,
            });

        case actions.ActionTypes.TICKET_STATUS_CHANGE_FAIL:

            return Object.assign({}, state, {
                TicketStatusChangeLoading: false,
                TicketStatusChangeLoaded: true,
                TicketStatusChangeFailed: true,

            });

        /**
         * status list reducer case function
         */
        case actions.ActionTypes.GET_TICKET_STATUS_LIST:

            return Object.assign({}, state, {
                statusListLoading: true,
                statusListLoaded: false,
                statusListFailed: false
            });

        case actions.ActionTypes.GET_TICKET_STATUS_LIST_SUCCESS:
            const tempStatus = payload.data.filter(res=>res.statusName !== 'Assessment');
            return Object.assign({}, state, {
                statusList: tempStatus,
                statusListLoading: false,
                statusListLoaded: true,
                statusListFailed: false,
            });
        /**
         * get tickets reducer case function
         */
        case actions.ActionTypes.GET_TICKET_LIST:
            const refreshTicketList = payload.refresh;
            if (refreshTicketList) {
                return Object.assign({}, state, {
                    ticketList: [],
                    ticketListLoading: true,
                    ticketListLoaded: false,
                    ticketListFailed: false
                });
            }
            return Object.assign({}, state, {
                ticketListLoading: true,
                ticketListLoaded: false,
                ticketListFailed: false
            });
        case actions.ActionTypes.GET_TICKET_LIST_SUCCESS:
            // const tempticketlist = payload.data.map(list => {
            //   const ticketDetail = new TicketListResponseModel(list);
            //   return ticketDetail;
            // });
            let tempTicketList: TicketListResponseModel[];
            const newTicketList = payload.data.map(_ticketList => {
                const tempFollowLeadList = new TicketListResponseModel(_ticketList);
                return tempFollowLeadList;
            });
            tempTicketList = state.ticketList ? state.ticketList : [];
            tempTicketList = tempTicketList.concat(newTicketList);
            return Object.assign({}, state, {
                ticketList: tempTicketList,
                ticketListLoading: false,
                ticketListLoaded: true,
                ticketListFailed: false,
            });

        case actions.ActionTypes.GET_TICKET_LIST_FAIL:

            return Object.assign({}, state, {
                ticketListLoading: false,
                ticketListLoaded: true,
                ticketListFailed: true,
            });
        /**
         * get ticket count reducer case function
         */
        case actions.ActionTypes.GET_TICKET_COUNT:

            return Object.assign({}, state, {
                ticketCount:[],
                ticketCountLoading: true,
                ticketCountLoaded: false,
                ticketCountFailed: false
            });

        case actions.ActionTypes.GET_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                ticketCount: payload.data.ticketListCount,
                ticketCountLoading: false,
                ticketCountLoaded: true,
                ticketCountFailed: false,
            });

        case actions.ActionTypes.GET_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                ticketCountLoading: false,
                ticketCountLoaded: true,
                ticketCountFailed: true,
            });
        // GET_REVIEW_TICKET_LIST
        case actions.ActionTypes.GET_REVIEW_TICKET_LIST:
            return Object.assign({}, state, {
                reviewTicketListLoading: true,
                reviewTicketListLoaded: false,
                reviewTicketListFailed: false
            });

        case actions.ActionTypes.GET_REVIEW_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                reviewTicketList: payload.data,
                reviewTicketListLoading: false,
                reviewTicketListLoaded: true,
                reviewTicketListFailed: false,
            });

        case actions.ActionTypes.GET_REVIEW_TICKET_LIST_FAIL:

            return Object.assign({}, state, {
                reviewTicketListLoading: false,
                reviewTicketListLoaded: true,
                reviewTicketListFailed: true,
            });
        /**
         * get review ticket count reducer case function
         */
        case actions.ActionTypes.GET_REVIEW_TICKET_COUNT:

            return Object.assign({}, state, {
                reviewCountLoading: true,
                reviewCountLoaded: false,
                reviewCountFailed: false
            });

        case actions.ActionTypes.GET_REVIEW_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                reviewCount: payload.data.ticketListCount,
                reviewCountLoading: false,
                reviewCountLoaded: true,
                reviewCountFailed: false,
            });

        case actions.ActionTypes.GET_REVIEW_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                reviewCountLoading: false,
                reviewCountLoaded: true,
                reviewCountFailed: true,
            });
        /**
         * get schedule tickets reducer case function
         */
        case actions.ActionTypes.GET_SCHEDULE_TICKETS:
            return Object.assign({}, state, {
                scheduleListLoading: true,
                scheduleListLoaded: false,
                scheduleListFailed: false
            });

        case actions.ActionTypes.GET_SCHEDULE_TICKETS_SUCCESS:
            let tempSchedule = []
             tempSchedule = payload.data.map(list => {
                let scheduleDetail = new TicketListResponseModel(list);
                return scheduleDetail;
            });            
            return Object.assign({}, state, {
                scheduleList: tempSchedule,
                scheduleListLoading: false,
                scheduleListLoaded: true,
                scheduleListFailed: false,
            });

        case actions.ActionTypes.GET_SCHEDULE_TICKETS_FAIL:

            return Object.assign({}, state, {
                scheduleListLoading: false,
                scheduleListLoaded: true,
                scheduleListFailed: true,
            });
        /**
         * get schedule ticket count reducer case function
         */
        case actions.ActionTypes.GET_SCHEDULE_TICKET_COUNT:
            return Object.assign({}, state, {
                scheduleCountLoading: true,
                scheduleCountLoaded: false,
                scheduleCountFailed: false
            });

        case actions.ActionTypes.GET_SCHEDULE_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                scheduleCount: payload.data.ticketListCount,
                scheduleCountLoading: false,
                scheduleCountLoaded: true,
                scheduleCountFailed: false,
            });

        case actions.ActionTypes.GET_SCHEDULE_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                scheduleCountLoading: false,
                scheduleCountLoaded: true,
                scheduleCountFailed: true,
            });
       /**
         * get approved ticket list count.
         */
        case actions.ActionTypes.GET_APPROVED_TICKET_LIST_COUNT:

            return Object.assign({}, state, {
                approvedTicketListCountLoading: true,
                approvedTicketListCountLoaded: false,
                approvedTicketListCountFailed: false
            });

        case actions.ActionTypes.GET_APPROVED_TICKET_LIST_COUNT_SUCCESS:
            return Object.assign({}, state, {
                approvedTicketListCount: payload.data.ticketListCount,
                approvedTicketListCountLoading: false,
                approvedTicketListCountLoaded: true,
                approvedTicketListCountFailed: false,
            });

        case actions.ActionTypes.GET_APPROVED_TICKET_LIST_COUNT_FAIL:

            return Object.assign({}, state, {
                approvedTicketListCountLoading: false,
                approvedTicketListCountLoaded: true,
                approvedTicketListCountFailed: true,
            });
        /**
         * get total ticket count reducer case function
         */
        case actions.ActionTypes.GET_TOTAL_TICKET_COUNT:

            return Object.assign({}, state, {
                totalCountLoading: true,
                totalCountLoaded: false,
                totalCountFailed: false
            });

        case actions.ActionTypes.GET_TOTAL_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                totalCount: payload.data.ticketListCount,
                totalCountLoading: false,
                totalCountLoaded: true,
                totalCountFailed: false,
            });

        case actions.ActionTypes.GET_TOTAL_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                totalCountLoading: false,
                totalCountLoaded: true,
                totalCountFailed: true,
            });
        /**
         * get total ticket  reducer case function
         */
        case actions.ActionTypes.GET_TOTAL_TICKET:
            const isRefresh = payload.refresh;
            if (isRefresh) {
                return Object.assign({}, state, {
                    totalTicket: [],
                    totalTicketLoading: true,
                    totalTicketLoaded: false,
                    totalTicketFailed: false
                });
            } else {
                return Object.assign({}, state, {
                    totalTicketLoading: false,
                    totalTicketLoaded: false,
                    totalTicketFailed: false
                });
            }


        case actions.ActionTypes.GET_TOTAL_TICKET_SUCCESS:
            // const tempTotalTicket = payload.data.map(list => {
            //     const ticketDetail = new TicketListResponseModel(list);
            //     return ticketDetail;
            // });
            return Object.assign({}, state, {
                totalTicket: payload.data,
                totalTicketLoading: false,
                totalTicketLoaded: true,
                totalTicketFailed: false,
            });

        case actions.ActionTypes.GET_TOTAL_TICKET_FAIL:

            return Object.assign({}, state, {
                totalTicketLoading: false,
                totalTicketLoaded: true,
                totalTicketFailed: true,
            });

        /**
         * get total ticket count reducer case function
         */
        case actions.ActionTypes.GET_FREE_TICKET_COUNT:

            return Object.assign({}, state, {
                freeCountLoading: true,
                freeCountLoaded: false,
                freeCountFailed: false
            });

        case actions.ActionTypes.GET_FREE_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                freeCount: payload.data.ticketListCount,
                freeCountLoading: false,
                freeCountLoaded: true,
                freeCountFailed: false,
            });

        case actions.ActionTypes.GET_FREE_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                freeCountLoading: false,
                freeCountLoaded: true,
                freeCountFailed: true,
            });
        //
        case actions.ActionTypes.GET_CLOSURE_TICKET_LIST:
            return Object.assign({}, state, {
                closureListLoading: true,
                closureListLoaded: false,
                closureListFailed: false
            });

        case actions.ActionTypes.GET_CLOSURE_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                closureList: payload.data,
                closureListLoading: false,
                closureListLoaded: true,
                closureListFailed: false,
            });

        case actions.ActionTypes.GET_CLOSURE_TICKET_LIST_FAIL:

            return Object.assign({}, state, {
                closureListLoading: false,
                closureListLoaded: true,
                closureListFailed: true,
            });
        /**
         * get total ticket count reducer case function
         */
        case actions.ActionTypes.GET_CLOSURE_TICKET_COUNT:

            return Object.assign({}, state, {
                closureCountLoading: true,
                closureCountLoaded: false,
                closureCountFailed: false
            });

        case actions.ActionTypes.GET_CLOSURE_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                closureCount: payload.data.ticketListCount,
                closureCountLoading: false,
                closureCountLoaded: true,
                closureCountFailed: false,
            });

        case actions.ActionTypes.GET_CLOSURE_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                closureCountLoading: false,
                closureCountLoaded: true,
                closureCountFailed: true,
            });
        /**
         * get raised ticket count reducer case function
         */
        case actions.ActionTypes.GET_RAISED_TICKET_COUNT:

            return Object.assign({}, state, {
                raisedCountLoading: true,
                raisedCountLoaded: false,
                raisedCountFailed: false
            });

        case actions.ActionTypes.GET_RAISED_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                raisedCount: payload.data.ticketListCount,
                raisedCountLoading: false,
                raisedCountLoaded: true,
                raisedCountFailed: false,
            });

        case actions.ActionTypes.GET_RAISED_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
                raisedCountLoading: false,
                raisedCountLoaded: true,
                raisedCountFailed: true,
            });
        // GET_APPROVE_TICKET_LIST
        case actions.ActionTypes.GET_APPROVE_TICKET_LIST:
            return Object.assign({}, state, {
                approveTicketListLoading: true,
                approveTicketListLoaded: false,
                approveTicketListFailed: false
            });

        case actions.ActionTypes.GET_APPROVE_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                approveTicketList: payload.data,
                approveTicketListLoading: false,
                approveTicketListLoaded: true,
                approveTicketListFailed: false,
            });

        case actions.ActionTypes.GET_APPROVE_TICKET_LIST_FAIL:
            return Object.assign({}, state, {
                approveTicketListLoading: false,
                approveTicketListLoaded: true,
                approveTicketListFailed: true,
            });
        /**
         * get approval ticket count reducer case function
         */
        case actions.ActionTypes.GET_APPROVAL_COUNT:

            return Object.assign({}, state, {
                approvalCountLoading: true,
                approvalCountLoaded: false,
                approvalCountFailed: false
            });

        case actions.ActionTypes.GET_APPROVAL_COUNT_SUCCESS:
            return Object.assign({}, state, {
                approvalCount: payload.data.ticketListCount,
                approvalCountLoading: false,
                approvalCountLoaded: true,
                approvalCountFailed: false,
            });

        case actions.ActionTypes.GET_APPROVAL_COUNT_FAIL:

            return Object.assign({}, state, {
                approvalCountLoading: false,
                approvalCountLoaded: true,
                approvalCountFailed: true,
            });
        /**
         * get ticket detail reducer case function
         */
        case actions.ActionTypes.GET_TICKET_DETAIL:

            return Object.assign({}, state, {
                ticketDetail: {},
                ticketDetailLoading: true,
                ticketDetailLoaded: false,
                ticketDetailFailed: false
            });

        case actions.ActionTypes.GET_TICKET_DETAIL_SUCCESS:            
            const tempDetail = new ActivityDetailModel(payload.data);
            return Object.assign({}, state, {
                // tempTicketStatus: payload.data.ticketStatusId,
                ticketDetail: tempDetail,
                ticketDetailLoading: false,
                ticketDetailLoaded: true,
                ticketDetailFailed: false,
            });

        case actions.ActionTypes.GET_TICKET_DETAIL_FAIL:

            return Object.assign({}, state, {
                ticketDetailLoading: false,
                ticketDetailLoaded: true,
                ticketDetailFailed: true,
            });
        /**
         * change password case function
         */
        case actions.ActionTypes.CHANGE_PASSWORD:

            return Object.assign({}, state, {
                changePassword: {},
                changePasswordLoading: true,
                changePasswordLoaded: false,
                changePasswordFailed: false
            });

        case actions.ActionTypes.CHANGE_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                changePassword: payload,
                changePasswordLoading: false,
                changePasswordLoaded: true,
                changePasswordFailed: false,
            });

        case actions.ActionTypes.CHANGE_PASSWORD_FAIL:
            return Object.assign({}, state, {
                changePassword: payload,
                changePasswordLoading: false,
                changePasswordLoaded: true,
                changePasswordFailed: false,
            });


        case actions.ActionTypes.CLEAR_ORDER_LIST:
            return Object.assign({}, state, {
                orderList: [],
                orderListLoading: true,
                orderListLoaded: false,
                orderListFailed: false
            });
        /**
         * payment list reducer case function
         */
        case actions.ActionTypes.GET_PAYMENTS_LIST:
            const isRefrsh = payload.refresh;
            if (isRefrsh) {
                return Object.assign({}, state, {
                    paymentListLoading: true,
                    paymentListLoaded: false,
                    paymentListFailed: false
                });
            } else {
                return Object.assign({}, state, {
                    paymentListLoading: false,
                    paymentListLoaded: false,
                    paymentListFailed: false
                });
            }
        case actions.ActionTypes.GET_PAYMENTS_LIST_SUCCESS:
            let tempPayments = [];
            if (payType === 'listType') {
                tempPayments = payload.data.paymentsList.map(list => {
                    const paymentDetails = new PaymentList(list);
                    return paymentDetails;
                });

            } else {
                tempPayments = payload.data;
            }
            return Object.assign({}, state, {
                paymentList: tempPayments,
                paymentListLoading: false,
                paymentListLoaded: true,
                paymentListFailed: false,
            });

        case actions.ActionTypes.GET_PAYMENTS_LIST_FAIL:

            return Object.assign({}, state, {
                paymentListLoading: false,
                paymentListLoaded: false,
                paymentListFailed: true,

            });
        /**
         * payment list reducer case function
         */
        case actions.ActionTypes.GET_PAYMENTS_LIST_COUNT:

            return Object.assign({}, state, {
                paymentListCountLoading: true,
                paymentListCountLoaded: false,
                paymentListCountFailed: false
            });

        case actions.ActionTypes.GET_PAYMENTS_LIST_COUNT_SUCCESS:
            return Object.assign({}, state, {
                paymentListCount: payload.data['paymentsListCount'],
                paymentListCountLoading: false,
                paymentListCountLoaded: true,
                paymentListCountFailed: false,
            });

        case actions.ActionTypes.GET_PAYMENTS_LIST_COUNT_FAIL:

            return Object.assign({}, state, {
                paymentListCountLoading: false,
                paymentListCountLoaded: false,
                paymentListCountFailed: true,

            });

        /**
         * payment Details reducer case function
         */
        case actions.ActionTypes.GET_PAYMENTS_DETAILS:

            return Object.assign({}, state, {
                paymentDetailsLoading: true,
                paymentDetailsLoaded: false,
                paymentDetailsFailed: false,
            });

        case actions.ActionTypes.GET_PAYMENTS_DETAILS_SUCCESS:
            const tempPayDetails = new PaymentDetailResponse(payload.data);
            return Object.assign({}, state, {
                paymentDetails: tempPayDetails,
                paymentDetailsLoading: false,
                paymentDetailsLoaded: true,
                paymentDetailsFailed: false,
            });

        case actions.ActionTypes.GET_PAYMENTS_DETAILS_FAIL:

            return Object.assign({}, state, {
                paymentDetailsLoading: false,
                paymentDetailsLoaded: true,
                paymentDetailsFailed: true,

            });

        /**
         * Invoice Details reducer case function
         */
        case actions.ActionTypes.GET_INVOICE_DETAILS:

            return Object.assign({}, state, {
                invoiceDetails: {},
                invoiceDetailsLoaded: false,
                invoiceDetailsLoading: true,
                invoiceDetailsFailed: false
            });

        case actions.ActionTypes.GET_INVOICE_DETAILS_SUCCESS:
            const conversion = {
                twoDecimal: new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
            let invoiceDetail:any=[];
            invoiceDetail.push(payload.data);
            invoiceDetail.map(data =>{
                if(invoiceDetail.length > 0){
                 data.amount=data.invoiceNetAmount;
                 data.subTotal = conversion.twoDecimal.format((+data.invoiceNetAmount) - (+data.invoiceTaxAmount))
                 data.invoiceNetAmount=conversion.twoDecimal.format(data.invoiceNetAmount);
                 data.invoiceTaxAmount = conversion.twoDecimal.format(data.invoiceTaxAmount);
                  return 
                }
            })
            return Object.assign({}, state, {
                invoiceDetails:invoiceDetail[0],
                invoiceCompanyDetails: payload.companyDetails,
                invoiceDetailsLoaded: true,
                invoiceDetailsLoading: false,
                invoiceDetailsFailed: false
            });

        case actions.ActionTypes.GET_INVOICE_DETAILS_FAIL:

            return Object.assign({}, state, {
                invoiceDetails: payload.data,
                invoiceDetailsLoaded: false,
                invoiceDetailsLoading: false,
                invoiceDetailsFailed: true

            });

            // Assessment List
       case actions.ActionTypes.ASSESSMENT_LIST:
        return Object.assign({}, state, {
            assessmentListLoading: true,
            assessmentListLoaded: false,
            assessmentListFailed: false
        });
     case actions.ActionTypes.ASSESSMENT_LIST_SUCCESS:
        return Object.assign({}, state, {
            assessmentList: payload.data,
            assessmentListLoading: false,
            assessmentListLoaded: true,
            assessmentListFailed: false,
        });
     case actions.ActionTypes.ASSESSMENT_LIST_FAIL:
        return Object.assign({}, state, {
            assessmentListLoading: false,
            assessmentListLoaded: false,
            assessmentListFailed: true,
        });
     // Client Approval
       case actions.ActionTypes.CLIENT_APPROVAL:
        return Object.assign({}, state, {
            clientApprovalLoading: true,
            clientApprovalLoaded: false,
            clientApprovalFailed: false
        });
     case actions.ActionTypes.CLIENT_APPROVAL_SUCCESS:
        return Object.assign({}, state, {
            clientApproval: payload.data,
            clientApprovalLoading: false,
            clientApprovalLoaded: true,
            clientApprovalFailed: false,
        });
     case actions.ActionTypes.CLIENT_APPROVAL_FAIL:
        return Object.assign({}, state, {
            clientApprovalLoading: false,
            clientApprovalLoaded: false,
            clientApprovalFailed: true,
        });
        ``
        /**
         * Invoice Details reducer case function
         */
        case actions.ActionTypes.GET_PAYMENT_LOG:

            return Object.assign({}, state, {
                paymentLogLoaded: false,
                paymentLogLoading: true,
                paymentLogFailed: false
            });

        case actions.ActionTypes.GET_PAYMENT_LOG_SUCCESS:
            return Object.assign({}, state, {
                paymentLog: payload.data,
                paymentLogLoaded: true,
                paymentLogLoading: false,
                paymentLogFailed: false
            });

        case actions.ActionTypes.GET_PAYMENT_LOG_FAIL:

            return Object.assign({}, state, {
                paymentLogLoaded: false,
                paymentLogLoading: false,
                paymentLogFailed: true

            });
        /**
        * assign resources list case function
        */
        case actions.ActionTypes.ONLINE_RESOURCES_LIST:
            return Object.assign({}, state, {
                getOnlineResourcesListLoading: true,
                getOnlineResourcesListLoaded: false,
                getOnlineResourcesListFailed: false
            });

        case actions.ActionTypes.ONLINE_RESOURCES_LIST_SUCCESS:
            return Object.assign({}, state, {
                getOnlineResourcesList: payload,
                getOnlineResourcesListLoading: false,
                getOnlineResourcesListLoaded: true,
                getOnlineResourcesListFailed: false,
            });

        case actions.ActionTypes.ONLINE_RESOURCES_LIST_FAIL:
            return Object.assign({}, state, {
                getOnlineResourcesListLoading: false,
                getOnlineResourcesListLoaded: true,
                getOnlineResourcesListFailed: false,
            });
        /**
        * assigned resoureces list case function
        */
        case actions.ActionTypes.ASSIGNED_RESOURCES_LIST:
            return Object.assign({}, state, {
                getAssignedResourcesListLoading: true,
                getAssignedResourcesListLoaded: false,
                getAssignedResourcesListFailed: false
        });

        case actions.ActionTypes.ASSIGNED_RESOURCES_LIST_SUCCESS:
            const uniqueList = payload.data?.filter((item, index, self) => index === self.findIndex(t => (t.developerDetails.id === item.developerDetails.id)));
            return Object.assign({}, state, {
                getAssignedResourcesList: uniqueList,
                getAssignedResourcesLength: length,
                getAssignedResourcesListLoading: false,
                getAssignedResourcesListLoaded: true,
                getAssignedResourcesListFailed: false,
            });

        case actions.ActionTypes.ASSIGNED_RESOURCES_LIST_FAIL:
            return Object.assign({}, state, {
                getAssignedResourcesListLoading: false,
                getAssignedResourcesListLoaded: true,
                getAssignedResourcesListFailed: false
            });
        /**
         * Update Payment reducer case function
         */
           case actions.ActionTypes.UPDATE_PAYMENT:
 
               return Object.assign({}, state, {
                  updatePayment: {},
                  updatePaymentLoading: true,
                  updatePaymentLoaded: false,
                  updatePaymentFailed: false,
            });
            case actions.ActionTypes.UPDATE_PAYMENT_SUCCESS:
                return Object.assign({}, state, {
                    updatePayment: payload.data,
                    updatePaymentLoading: false,
                    updatePaymentLoaded: true,
                    updatePaymentFailed: false,
                });
    
            case actions.ActionTypes.UPDATE_PAYMENT_FAIL:
    
                return Object.assign({}, state, {
                    updatePayment: {},
                    updatePaymentLoading: false,
                    updatePaymentLoaded: false,
                    updatePaymentFailed: true,
    
                });
    /**
         * Upload Proof
         */
        case actions.ActionTypes.UPLOAD_PROOF:
 
            return Object.assign({}, state, {
               uploadProof: {},
               uploadProofLoading: true,
               uploadProofLoaded: false,
               uploadProofFailed: false,
         });
         case actions.ActionTypes.UPLOAD_PROOF_SUCCESS:
             return Object.assign({}, state, {
                uploadProof: payload,
                uploadProofLoading: false,
                uploadProofLoaded: true,
                uploadProofFailed: false,
             });
 
         case actions.ActionTypes.UPLOAD_PROOF_FAIL:
 
             return Object.assign({}, state, {
                uploadProof: {},
                uploadProofLoading: false,
                uploadProofLoaded: false,
                uploadProofFailed: true,
 
             });

        /**
        * Invoice download
        */
        case actions.ActionTypes.DOWNLOAD_INVOICE:
            return Object.assign({}, state, {
                invoiceNumber: payload.invNo,
                invoiceLoading: true,
                invoiceLoaded: false,
                invoiceFailed: false
            });

        case actions.ActionTypes.DOWNLOAD_INVOICE_SUCCESS:
            {
                // const invcs = state.invoiceNumber;
                // const newBlobs = new Blob([payload], { type: 'application/pdf' });
                // const downloadURLs = window.URL.createObjectURL(payload);
                // const links = document.createElement('a');
                // links.href = downloadURLs;
                // links.download = invcs + '.pdf';
                // links.click();
                return Object.assign({}, state, {
                    invoiceNumber: '',
                    invoiceLoading: false,
                    invoiceLoaded: true,
                    invoiceFailed: false
                });
            }
        case actions.ActionTypes.DOWNLOAD_INVOICE_FAIL:
            return Object.assign({}, state, {
                invoiceNumber: '',
                invoiceLoading: false,
                invoiceLoaded: true,
                invoiceFailed: true
            });
        /**
* ticket change status  reducer case function
*/
        case actions.ActionTypes.TICKET_DECLINE:

            return Object.assign({}, state, {
                TicketDeclineLoading: true,
                TicketDeclineLoaded: false,
                TicketDeclineFailed: false,
            });

        case actions.ActionTypes.TICKET_DECLINE_SUCCESS:
            state.ticketDetail.declined = payload.data.declined;
            state.ticketDetail.declineNotes = payload.data.declineNotes;
            return Object.assign({}, state, {
                TicketDecline: payload,
                TicketDeclineLoading: false,
                TicketDeclineLoaded: true,
                TicketDeclineFailed: false,
            });

        case actions.ActionTypes.TICKET_DECLINE_FAIL:

            return Object.assign({}, state, {
                TicketDeclineLoading: false,
                TicketDeclineLoaded: true,
                TicketDeclineFailed: true,

            });
        // GET_TICKET_UPDATE_LIST
        case actions.ActionTypes.GET_TICKET_UPDATE_LIST:
            return Object.assign({}, state, {
                updateTicketListLoaded: false,
            });
        case actions.ActionTypes.GET_TICKET_UPDATE_LIST_SUCCESS:
            var groupByName = {};
            payload.data.listData.forEach(function (a) {
                groupByName[a.updatedDate] = groupByName[a.updatedDate] || [];
                groupByName[a.updatedDate].push({ date: a.updatedDate, description: a.description, id: a.id, ticketId: a.ticketId });
            });
            // groupByName = Array.prototype.reverse.call(groupByName);
            // let arr = Object.keys(groupByName).reverse();
            return Object.assign({}, state, {
                updateTicketList: groupByName,
                clientUptateTicketList: payload.data.listData,
                updateTicketListLoaded: true,
            });

        case actions.ActionTypes.GET_TICKET_UPDATE_LIST_FAIL:
            return Object.assign({}, state, {
                updateTicketListLoaded: false,
            });

        // Open Ticket List
        case actions.ActionTypes.GET_OPEN_TICKET_LIST:
            return Object.assign({}, state, {
                openTicketList:[],
                openTicketListLoading: true,
                openTicketListLoaded: false,
                openTicketListFailed: false
            });
        case actions.ActionTypes.GET_OPEN_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                openTicketList: payload.data,
                openTicketListLoading: false,
                openTicketListLoaded: true,
                openTicketListFailed: false,
            });
        case actions.ActionTypes.GET_OPEN_TICKET_LIST_FAIL:
            return Object.assign({}, state, {
                openTicketListLoading: false,
                openTicketListLoaded: true,
                openTicketListFailed: true,
            });
        // Open Ticket List Count
        case actions.ActionTypes.GET_OPEN_TICKET_LIST_COUNT:
            return Object.assign({}, state, {
                openTicketListCount:[],
                openTicketListCountLoading: true,
                openTicketListCountLoaded: false,
                openTicketListCountFailed: false
            });
        case actions.ActionTypes.GET_OPEN_TICKET_LIST_COUNT_SUCCESS:
            return Object.assign({}, state, {
                openTicketListCount: payload.data.ticketListCount,
                openTicketListCountLoading: false,
                openTicketListCountLoaded: true,
                openTicketListCountFailed: false,
            });
        case actions.ActionTypes.GET_OPEN_TICKET_LIST_COUNT_FAIL:
            return Object.assign({}, state, {
                openTicketListCountLoading: false,
                openTicketListCountLoaded: true,
                openTicketListCountFailed: true,
            });
        // Inprogress Ticket List
        case actions.ActionTypes.GET_INPROGRESS_TICKET_LIST:
            return Object.assign({}, state, {
                inprogressTicketListLoading: true,
                inprogressTicketListLoaded: false,
                inprogressTicketListFailed: false
            });
        case actions.ActionTypes.GET_INPROGRESS_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                inprogressTicketList: payload.data,
                inprogressTicketListLoading: false,
                inprogressTicketListLoaded: true,
                inprogressTicketListFailed: false,
            });
        case actions.ActionTypes.GET_INPROGRESS_TICKET_LIST_FAIL:
            return Object.assign({}, state, {
                inprogressTicketListLoading: false,
                inprogressTicketListLoaded: true,
                inprogressTicketListFailed: true,
            });
        // Get Acceptance 
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS:
            return Object.assign({}, state, {
                acceptanceTicketListLoading: true,
                acceptanceListLoaded: false,
                acceptanceListFailed: false
            });
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS_SUCCESS:
            return Object.assign({}, state, {
                acceptanceTicketList: payload.data,
                acceptanceTicketListLoading: false,
                acceptanceTicketListLoaded: true,
                acceptanceTicketListFailed: false,
            });
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS_FAIL:
            return Object.assign({}, state, {
                acceptanceTicketListLoading: false,
                acceptanceTicketListLoaded: true,
                acceptanceTicketListFailed: true,
            });
        // acceptance Ticket List Count
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT:
            return Object.assign({}, state, {
                acceptanceTicketListCountLoading: true,
                acceptanceTicketListCountLoaded: false,
                acceptanceTicketListCountFailed: false
            });
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT_SUCCESS:
            return Object.assign({}, state, {
                acceptanceTicketListCount: payload.data.ticketListCount,
                acceptanceTicketListCountLoading: false,
                acceptanceTicketListCountLoaded: true,
                acceptanceopenTicketListCountFailed: false,
            });
        case actions.ActionTypes.GET_ACCEPTANCE_TICKETS_COUNT_FAIL:
            return Object.assign({}, state, {
                acceptanceTicketListCountLoading: false,
                acceptanceTicketListCountLoaded: true,
                acceptanceTicketListCountFailed: true,
            });
        // SideBar Count
        case actions.ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT:

            return Object.assign({}, state, {
            });

        case actions.ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                sideScheduleCount: payload.data.ticketListCount,
            });

        case actions.ActionTypes.GET_SIDE_SCHEDULE_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
            });
        // Side Open Ticket Count
        case actions.ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT_SUCCESS:
            return Object.assign({}, state, {
                sideOpenTicketListCount: payload.data.ticketListCount,
            });
        case actions.ActionTypes.GET_SIDE_OPEN_TICKET_LIST_COUNT_FAIL:
            return Object.assign({}, state, {
            });
        // Side InProgress Ticket
        case actions.ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST_SUCCESS:
            return Object.assign({}, state, {
                sideInprogressTicketListCount: payload.data.ticketListCount,
            });
        case actions.ActionTypes.GET_SIDE_INPROGRESS_TICKET_LIST_FAIL:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT_SUCCESS:
            return Object.assign({}, state, {
                sideClosureCount: payload.data.ticketListCount,
            });

        case actions.ActionTypes.GET_SIDE_CLOSURE_TICKET_COUNT_FAIL:

            return Object.assign({}, state, {
            });
        // UPLOAD_DOCUMENTS
        case actions.ActionTypes.UPLOAD_DOCUMENTS:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.UPLOAD_DOCUMENTS_SUCCESS:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.UPLOAD_DOCUMENTS_FAIL:

            return Object.assign({}, state, {
            });
        // DELETE_DOCUMENTS
        case actions.ActionTypes.DELETE_DOCUMENTS:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.DELETE_DOCUMENTS_SUCCESS:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.DELETE_DOCUMENTS_FAIL:

            return Object.assign({}, state, {
            });
        // OFFLINE_PAYMENT
        case actions.ActionTypes.OFFLINE_PAYMENT:
            return Object.assign({}, state, {
                offlinePaymentLoading: true,
                offlinePaymentLoaded: false,
                offlinePaymentFailed: false
            });
        case actions.ActionTypes.OFFLINE_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                offlinePayment: payload.data,
                offlinePaymentLoading: false,
                offlinePaymentLoaded: true,
                offlinePaymentFailed: false,
            });
        case actions.ActionTypes.OFFLINE_PAYMENT_FAIL:
            return Object.assign({}, state, {
                offlinePaymentLoading: false,
                offlinePaymentLoaded: true,
                offlinePaymentFailed: true,
            });


      // CLIENT_CANCEL_REQUEST
       case actions.ActionTypes.CLIENT_CANCEL_REQUEST:
        return Object.assign({}, state, {
            clientCancelRequestLoading: true,
            clientCancelRequestLoaded: false,
            clientCancelRequestFailed: false
        });
      case actions.ActionTypes.CLIENT_CANCEL_REQUEST_SUCCESS:
        return Object.assign({}, state, {
            clientCancelRequest: payload,
            clientCancelRequestLoading: false,
            clientCancelRequestLoaded: true,
            clientCancelRequestFailed: false,
        });

      case actions.ActionTypes.CLIENT_CANCEL_REQUEST_FAIL:

        return Object.assign({}, state, {
            clientCancelRequestLoading: false,
            clientCancelRequestLoaded: false,
            clientCancelRequestFailed: true,

        });

       /**
         * get order details reducer case function
         */
        case actions.ActionTypes.GET_ORDER_DETAILS:

            return Object.assign({}, state, {
                orderDetails:[],
                orderDetailsLoading: true,
                orderDetailsLoaded: false,
                orderDetailsFailed: false
            });
        case actions.ActionTypes.GET_ORDER_DETAILS_SUCCESS:            

            return Object.assign({}, state, {
                orderDetails: payload.data,
                orderDetailsLoading: false,
                orderDetailsLoaded: true,
                orderDetailsFailed: false
            });
        case actions.ActionTypes.GET_ORDER_DETAILS_FAIL:

            return Object.assign({}, state, {
                orderDetailsLoading: false,
                orderDetailsLoaded: true,
                orderDetailsFailed: true
            });

        // SEND_MESSAGE_DATA
        case actions.ActionTypes.SEND_MESSAGE_DATA:
            return Object.assign({}, state, {
                sendMessageLoading: true,
                loadingIndex: payload.index,
            });

        case actions.ActionTypes.SEND_MESSAGE_DATA_SUCCESS:
            state.messageListResponse.map(elements => {
                elements.check = 0;
                // elements.index = payload2.index;
                elements.replyMessages.map(data => {
                    data.check = 0;
                    // elements.index = payload2.index;
                });
            });

            if(payload2.check == 'edit'){
                state.messageListResponse.map(data => {
                    if(data.id == payload2.ticketId){
                        data.isPrivate = payload2.isPrivate;
                        data.check = 1;
                    }
                });
            }
            else if(payload2.check == 'replyedit'){
                state.messageListResponse.map(elements => {                   
                    elements.replyMessages.map(data => {
                        if(data.id == payload2.ticketId){
                           data.isPrivate = payload2.isPrivate;
                           data.check = 1;
                        }
                    });
                });
            }
            
            return Object.assign({}, state, {
                sendMessageResponse: payload,
                sendMessageLoading: false,
                loadingIndex: '',
            });

        case actions.ActionTypes.SEND_MESSAGE_DATA_FAIL:
            return Object.assign({}, state, {
                sendMessageLoading: false,
            });

            // MESSAGE_LIST_DATA
        case actions.ActionTypes.GET_MESSAGE_LIST:
            return Object.assign({}, state, {
                messageListLoading: true,
            });

        case actions.ActionTypes.GET_MESSAGE_LIST_SUCCESS:
            payload.data.forEach(element => {
                element.replyMessagesData = true;
            });
            return Object.assign({}, state, {
                messageListResponse: payload.data,
                messageListLoading: false,
            });

        case actions.ActionTypes.GET_MESSAGE_LIST_FAIL:
            return Object.assign({}, state, {
                messageListLoading: false,
            });
                // MESSAGE_LIST_COUNT
        case actions.ActionTypes.GET_MESSAGE_LIST_COUNT:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.GET_MESSAGE_LIST_COUNT_SUCCESS:
            return Object.assign({}, state, {
                messageListCount: payload.data,
            });

        case actions.ActionTypes.GET_MESSAGE_LIST_COUNT_FAIL:
            return Object.assign({}, state, {
            });
             // GET_REPLIEDBASEMESSAGE_LIST
        case actions.ActionTypes.GET_REPLIEDBASEMESSAGE_LIST:
            return Object.assign({}, state, {
                repliedBaseMessageLoading: true,
            });

        case actions.ActionTypes.GET_REPLIEDBASEMESSAGE_LIST_SUCCESS:
            let localMsg = state.messageListResponse;
            localMsg.forEach(data => {
                if(payload2.ticketConversationId == data.id){
                    data.replyCount = true;
                }
            });
            return Object.assign({}, state, {
                // messageListResponse: localMsg, 
                repliedBaseMessage: payload.data,
                repliedBaseMessageLoading: false,
            });

        case actions.ActionTypes.GET_REPLIEDBASEMESSAGE_LIST_FAIL:
            return Object.assign({}, state, {
                repliedBaseMessageLoading: false,
            });
                // DELETE_MESSAGE
        case actions.ActionTypes.DELETE_MESSAGE:
            return Object.assign({}, state, {
            });

        case actions.ActionTypes.DELETE_MESSAGE_SUCCESS:
            return Object.assign({}, state, {
                deleteMessage: payload,
            });

        case actions.ActionTypes.DELETE_MESSAGE_FAIL:
            return Object.assign({}, state, {
            });

            // status Activity
            case actions.ActionTypes.STATUS_ACTIVITY:
                if ((state.ticketDetail.ticketStatusId === 4 || 
                    state.ticketDetail.ticketStatusId === 5 ||
                    state.ticketDetail.ticketStatusId === 6 ||
                    state.ticketDetail.ticketStatusId === 3 )
                    && payload =='Accepted') {
                    state.ticketDetail.dupStatus = 'Accepted';
                } 
                else if (state.ticketDetail.ticketStatusId === 2 && payload =='Accepted') {
                    state.ticketDetail.dupStatus = 'Accepted';
                }
                else if ((state.ticketDetail.ticketStatusId === 4 || 
                    state.ticketDetail.ticketStatusId === 5 ||
                    state.ticketDetail.ticketStatusId === 2||
                    state.ticketDetail.ticketStatusId === 6 ||
                    state.ticketDetail.ticketStatusId === 3 || state.ticketDetail.ticketStatusId === 1)&& payload =='Reviewed') {
                    state.ticketDetail.dupStatus = 'Reviewed';
                }
                else if ((state.ticketDetail.ticketStatusId === 4 ||
                    state.ticketDetail.ticketStatusId === 3) && payload =='Estimation') {
                    state.ticketDetail.dupStatus = 'Estimation';
                }
                else if ((state.ticketDetail.ticketStatusId === 6 || state.ticketDetail.ticketStatusId === 5) && payload =='Estimation') {
                    state.ticketDetail.dupStatus = 'Estimation';
                }
                //    else if ((state.ticketDetail.ticketStatusId === 6 || state.ticketDetail.ticketStatusId === 5) && payload =='Implementation') {
                //     state.ticketDetail.dupStatus = 'Implementation';
                // }
                else if ((state.ticketDetail.ticketStatusId === 6 
                      || state.ticketDetail.ticketStatusId === 5)
                      && payload =='Implementation'&& payload1 == '') {
                    state.ticketDetail.dupStatus = 'Implementation';
                }
                else if ((state.ticketDetail.ticketStatusId === 6 
                    || state.ticketDetail.ticketStatusId === 5) 
                    && payload =='Closed' && payload1 == 2) {
                       state.ticketDetail.dupStatus = 'Closed';
                 }
                return Object.assign({}, state, {
                });
            // UNDO_APPROVAL
        case actions.ActionTypes.UNDO_APPROVAL:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.UNDO_APPROVAL_SUCCESS:
            return Object.assign({}, state, {
                undoApproval: payload.data,
            });
        case actions.ActionTypes.UNDO_APPROVAL_FAIL:
            return Object.assign({}, state, {
            });
        // Start Work
        case actions.ActionTypes.START_WORK:
            return Object.assign({}, state, {
                startWork: false
            });
        case actions.ActionTypes.START_WORK_SUCCESS:
            return Object.assign({}, state, {
                startWork: true,
            });
        case actions.ActionTypes.START_WORK_FAIL:
            return Object.assign({}, state, {
                startWork: false
            });
        // MOBILE_NOTIFICATION_LIST
        case actions.ActionTypes.MOBILE_NOTIFICATION_LIST:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.MOBILE_NOTIFICATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                mobileNotificationList: payload,
            });
        case actions.ActionTypes.MOBILE_NOTIFICATION_LIST_FAIL:
            return Object.assign({}, state, {
            });
        // CLEAR_DATA
        case actions.ActionTypes.CLEAR_DATA:
            return Object.assign({}, state, {
                uploadFile: {},
                startWork: false
            });
        // GET_MESSAGE_LIST_MOBILE
        case actions.ActionTypes.GET_MESSAGE_LIST_MOBILE:
            return Object.assign({}, state, {
            });
        case actions.ActionTypes.GET_MESSAGE_LIST_MOBILE_SUCCESS:
            return Object.assign({}, state, {
                getMessageList: payload.data,
                messageTicketDetails: payload.ticket,
            });
        case actions.ActionTypes.GET_MESSAGE_LIST_MOBILE_FAIL:
            return Object.assign({}, state, {
            });

            /**
         * sidebarCount reducer case function
         */
        case actions.ActionTypes.SIDEBAR_COUNT:

        return Object.assign({}, state, {
            sidebarCount:[],
            sidebarCountLoading: true,
            sidebarCountLoaded: false,
            sidebarCountFailed: false
        });

    case actions.ActionTypes.SIDEBAR_COUNT_SUCCESS:
        return Object.assign({}, state, {
            sidebarCount: payload.data,
            sidebarCountLoading: false,
            sidebarCountLoaded: true,
            sidebarCountFailed: false,
        });

    case actions.ActionTypes.SIDEBAR_COUNT_FAIL:

        return Object.assign({}, state, {
            sidebarCountLoading: false,
            sidebarCountLoaded: true,
            sidebarCountFailed: true,
        });

        default: {
            return state;
        }
    }
}
/**
 * export values
 */
export const getProjectList = (state: CommonState) => state.projectList;
export const getProjectListLoading = (state: CommonState) => state.projectListLoading;
export const getProjectListLoaded = (state: CommonState) => state.projectListLoaded;
export const getProjectListFailed = (state: CommonState) => state.projectListFailed;

export const getTotalProjectList = (state: CommonState) => state.totalprojectList;
export const getTotalProjectListLoading = (state: CommonState) => state.totalprojectListLoading;
export const getTotalProjectListLoaded = (state: CommonState) => state.totalprojectListLoaded;
export const getTotalProjectListFailed = (state: CommonState) => state.totalprojectListFailed;

export const getTotalProjectCount = (state: CommonState) => state.totalprojectCount;
export const getTotalProjectCountLoading = (state: CommonState) => state.totalprojectCountLoading;
export const getTotalProjectCountLoaded = (state: CommonState) => state.totalprojectCountLoaded;
export const getTotalProjectCountFailed = (state: CommonState) => state.totalprojectCountFailed;

export const getPriorityList = (state: CommonState) => state.priorityList;
export const getPriorityListLoading = (state: CommonState) => state.priorityListLoading;
export const getPriorityListLoaded = (state: CommonState) => state.priorityListLoaded;
export const getPriorityListFailed = (state: CommonState) => state.priorityListFailed;

export const getCreateRequestLoading = (state: CommonState) => state.createRequestLoading;
export const getCreateRequestLoaded = (state: CommonState) => state.createRequestLoaded;
export const getCreateRequestFailed = (state: CommonState) => state.createRequestFailed;

export const getStatusList = (state: CommonState) => state.statusList;
export const getStatusListLoading = (state: CommonState) => state.statusListLoading;
export const getStatusListLoaded = (state: CommonState) => state.statusListLoaded;
export const getStatusListFailed = (state: CommonState) => state.statusListFailed;

export const getTicketStatusChange = (state: CommonState) => state.TicketStatusChange;
export const getTicketStatusChangeLoading = (state: CommonState) => state.TicketStatusChangeLoading;
export const getTicketStatusChangeLoaded = (state: CommonState) => state.TicketStatusChangeLoaded;
export const getTicketStatusChangeFailed = (state: CommonState) => state.TicketStatusChangeFailed;

export const getTicketDecline = (state: CommonState) => state.TicketDecline;
export const getTicketDeclineLoading = (state: CommonState) => state.TicketDeclineLoading;
export const getTicketDeclineLoaded = (state: CommonState) => state.TicketDeclineLoaded;
export const getTicketDeclineFailed = (state: CommonState) => state.TicketDeclineFailed;

export const getTicketList = (state: CommonState) => state.ticketList;
export const getTicketListLoading = (state: CommonState) => state.ticketListLoading;
export const getTicketListLoaded = (state: CommonState) => state.ticketListLoaded;
export const getTicketListFailed = (state: CommonState) => state.ticketListFailed;

export const getTicketCount = (state: CommonState) => state.ticketCount;
export const getTicketCountLoading = (state: CommonState) => state.ticketCountLoading;
export const getTicketCountLoaded = (state: CommonState) => state.ticketCountLoaded;
export const getTicketCountFailed = (state: CommonState) => state.ticketCountFailed;

export const reviewTicketList = (state: CommonState) => state.reviewTicketList;
export const reviewTicketListLoading = (state: CommonState) => state.reviewTicketListLoading;
export const reviewTicketListLoaded = (state: CommonState) => state.reviewTicketListLoaded;
export const reviewTicketListFailed = (state: CommonState) => state.reviewTicketListFailed;

export const getReviewCount = (state: CommonState) => state.reviewCount;
export const getReviewCountLoading = (state: CommonState) => state.reviewCountLoading;
export const getReviewCountLoaded = (state: CommonState) => state.reviewCountLoaded;
export const getReviewCountFailed = (state: CommonState) => state.reviewCountFailed;

export const getScheduleList = (state: CommonState) => state.scheduleList;
export const getScheduleListLoading = (state: CommonState) => state.scheduleListLoading;
export const getScheduleListLoaded = (state: CommonState) => state.scheduleListLoaded;
export const getScheduleListFailed = (state: CommonState) => state.scheduleListFailed;

export const getScheduleCount = (state: CommonState) => state.scheduleCount;
export const getScheduleCountLoading = (state: CommonState) => state.scheduleCountLoading;
export const getScheduleCountLoaded = (state: CommonState) => state.scheduleCountLoaded;
export const getScheduleCountFailed = (state: CommonState) => state.scheduleCountFailed;

export const getTotalCount = (state: CommonState) => state.totalCount;
export const getTotalCountLoading = (state: CommonState) => state.totalCountLoading;
export const getTotalCountLoaded = (state: CommonState) => state.totalCountLoaded;
export const getTotalCountFailed = (state: CommonState) => state.totalCountFailed;

export const getTotalTicket = (state: CommonState) => state.totalTicket;
export const getTotalTicketLoading = (state: CommonState) => state.totalTicketLoading;
export const getTotalTicketLoaded = (state: CommonState) => state.totalTicketLoaded;
export const getTotalTicketFailed = (state: CommonState) => state.totalTicketFailed;

export const getFreeCount = (state: CommonState) => state.freeCount;
export const getFreeCountLoading = (state: CommonState) => state.freeCountLoading;
export const getFreeCountLoaded = (state: CommonState) => state.freeCountLoaded;
export const getFreeCountFailed = (state: CommonState) => state.freeCountFailed;

export const approveTicketList = (state: CommonState) => state.approveTicketList;
export const approveTicketListLoading = (state: CommonState) => state.approveTicketListLoading;
export const approveTicketListLoaded = (state: CommonState) => state.approveTicketListLoaded;
export const approveTicketListFailed = (state: CommonState) => state.approveTicketListFailed;

export const getApprovalCount = (state: CommonState) => state.approvalCount;
export const getApprovalCountLoading = (state: CommonState) => state.approvalCountLoading;
export const getApprovalCountLoaded = (state: CommonState) => state.approvalCountLoaded;
export const getApprovalCountFailed = (state: CommonState) => state.approvalCountFailed;

export const getClosureList = (state: CommonState) => state.closureList;
export const getClosureListLoading = (state: CommonState) => state.closureListLoading;
export const getClosureListLoaded = (state: CommonState) => state.closureListLoaded;
export const getClosureListFailed = (state: CommonState) => state.closureListFailed;

export const getClosureCount = (state: CommonState) => state.closureCount;
export const getClosureCountLoading = (state: CommonState) => state.closureCountLoading;
export const getClosureCountLoaded = (state: CommonState) => state.closureCountLoaded;
export const getClosureCountFailed = (state: CommonState) => state.closureCountFailed;

export const getRaisedCount = (state: CommonState) => state.raisedCount;
export const getRaisedCountLoading = (state: CommonState) => state.raisedCountLoading;
export const getRaisedCountLoaded = (state: CommonState) => state.raisedCountLoaded;
export const getRaisedCountFailed = (state: CommonState) => state.raisedCountFailed;

// export const getTempTicketsId = (state: CommonState) => state.tempTicketStatus;
export const getTicketsDetail = (state: CommonState) => state.ticketDetail;
export const getTicketsDetailLoading = (state: CommonState) => state.ticketDetailLoading;
export const getTicketsDetailLoaded = (state: CommonState) => state.ticketDetailLoaded;
export const getTicketsDetailFailed = (state: CommonState) => state.ticketDetailFailed;

export const changePassword = (state: CommonState) => state.changePassword;
export const changePasswordLoading = (state: CommonState) => state.changePasswordLoading;
export const changePasswordLoaded = (state: CommonState) => state.changePasswordLoaded;
export const changePasswordFail = (state: CommonState) => state.changePasswordFailed;

export const getpendingPaymentAmount = (state: CommonState) => state.pendingPaymentAmount;
export const getPaymentList = (state: CommonState) => state.paymentList;
export const getPaymentListLoading = (state: CommonState) => state.paymentListLoading;
export const getPaymentListLoaded = (state: CommonState) => state.paymentListLoaded;
export const getPaymentListFailed = (state: CommonState) => state.paymentListFailed;

export const getPaymentDetails = (state: CommonState) => state.paymentDetails;
export const getPaymentDetailsLoading = (state: CommonState) => state.paymentDetailsLoading;
export const getPaymentDetailsLoaded = (state: CommonState) => state.paymentDetailsLoaded;
export const getPaymentDetailsFailed = (state: CommonState) => state.paymentDetailsFailed;

export const getInvoiceDetails = (state: CommonState) => state.invoiceDetails;
export const invoiceCompanyDetails = (state: CommonState) => state.invoiceCompanyDetails;
export const getInvoiceDetailsLoading = (state: CommonState) => state.invoiceDetailsLoading;
export const getInvoiceDetailsLoaded = (state: CommonState) => state.invoiceDetailsLoaded;
export const getInvoiceDetailsFailed = (state: CommonState) => state.invoiceDetailsFail;

export const getPaymentListCount = (state: CommonState) => state.paymentListCount;
export const getPaymentListCountLoading = (state: CommonState) => state.paymentListCountLoading;
export const getPaymentListCountLoaded = (state: CommonState) => state.paymentListCountLoaded;
export const getPaymentListCountFailed = (state: CommonState) => state.paymentListCountFailed;

export const getPaymentLog = (state: CommonState) => state.paymentLog;
export const getPaymentLogLoading = (state: CommonState) => state.paymentLogLoading;
export const getPaymentLogLoaded = (state: CommonState) => state.paymentLogLoaded;
export const getPaymentLogFailed = (state: CommonState) => state.paymentLogFailed;
export const getInvoiceNumber = (state: CommonState) => state.invoiceNumber;

export const getAssignedResourcesLength = (state: CommonState) => state.getAssignedResourcesLength;

export const getOnlineResourcesList = (state: CommonState) => state.getOnlineResourcesList;
export const getOnlineResourcesListLoading = (state: CommonState) => state.getOnlineResourcesListLoading;
export const getOnlineResourcesListLoaded = (state: CommonState) => state.getOnlineResourcesListLoaded;
export const getOnlineResourcesListFailed = (state: CommonState) => state.getOnlineResourcesListFailed;

export const getAssignedResourcesList = (state: CommonState) => state.getAssignedResourcesList;
export const getAssignedResourcesListLoading = (state: CommonState) => state.getAssignedResourcesListLoading;
export const getAssignedResourcesListLoaded = (state: CommonState) => state.getAssignedResourcesListLoaded;
export const getAssignedResourcesListFailed = (state: CommonState) => state.getAssignedResourcesListFailed;


export const getInvoiceLoading = (state: CommonState) => state.invoiceLoading;
export const getInvoiceLoaded = (state: CommonState) => state.invoiceLoaded;
export const getInvoiceFailed = (state: CommonState) => state.invoiceFailed;

export const updateTicketList = (state: CommonState) => state.updateTicketList;
export const updateTicketListLoaded = (state: CommonState) => state.updateTicketListLoaded;
export const clientUptateTicketList = (state: CommonState) => state.clientUptateTicketList;




export const openTicketList = (state: CommonState) => state.openTicketList;
export const openTicketListLoading = (state: CommonState) => state.openTicketListLoading;
export const openTicketListLoaded = (state: CommonState) => state.openTicketListLoaded;
export const openTicketListFailed = (state: CommonState) => state.openTicketListFailed;

export const openTicketListCount = (state: CommonState) => state.openTicketListCount;
export const openTicketListCountLoading = (state: CommonState) => state.openTicketListCountLoading;
export const openTicketListCountLoaded = (state: CommonState) => state.openTicketListCountLoaded;
export const openTicketListCountFailed = (state: CommonState) => state.openTicketListCountFailed;

export const inprogressTicketList = (state: CommonState) => state.inprogressTicketList;
export const inprogressTicketListLoading = (state: CommonState) => state.inprogressTicketListLoading;
export const inprogressTicketListLoaded = (state: CommonState) => state.inprogressTicketListLoaded;
export const inprogressTicketListFailed = (state: CommonState) => state.inprogressTicketListFailed;

export const acceptanceTicketList = (state: CommonState) => state.acceptanceTicketList;
export const acceptanceTicketListLoading = (state: CommonState) => state.acceptanceTicketListLoading;
export const acceptanceTicketListLoaded = (state: CommonState) => state.acceptanceTicketListLoaded;
export const acceptanceTicketListFailed = (state: CommonState) => state.acceptanceTicketListFailed;

export const acceptanceTicketListCount = (state: CommonState) => state.acceptanceTicketListCount;
export const acceptanceTicketListCountLoading = (state: CommonState) => state.acceptanceTicketListCountLoading;
export const acceptanceTicketListCountLoaded = (state: CommonState) => state.acceptanceTicketListCountLoaded;
export const acceptanceTicketListCountFailed = (state: CommonState) => state.acceptanceTicketListCountFailed;

export const sideScheduleCount = (state: CommonState) => state.sideScheduleCount;
export const sideOpenTicketListCount = (state: CommonState) => state.sideOpenTicketListCount;
export const sideInprogressTicketListCount = (state: CommonState) => state.sideInprogressTicketListCount;
export const sideClosureCount = (state: CommonState) => state.sideClosureCount;

export const offlinePayment = (state: CommonState) => state.offlinePayment;
export const offlinePaymentLoading = (state: CommonState) => state.offlinePaymentLoading;
export const offlinePaymentLoaded = (state: CommonState) => state.offlinePaymentLoaded;
export const offlinePaymentFailed = (state: CommonState) => state.offlinePaymentFailed;

export const sendMessageResponse = (state: CommonState) => state.sendMessageResponse;
export const sendMessageLoading = (state: CommonState) => state.sendMessageLoading;
export const loadingIndex = (state: CommonState) => state.loadingIndex;

export const messageListResponse = (state: CommonState) => state.messageListResponse;
export const messageListLoading = (state: CommonState) => state.messageListLoading;
export const messageTicketDetails = (state: CommonState) => state.messageTicketDetails;

export const messageListCount = (state: CommonState) => state.messageListCount;

export const repliedBaseMessage = (state: CommonState) => state.repliedBaseMessage;
export const repliedBaseMessageLoading = (state: CommonState) => state.repliedBaseMessageLoading;

export const deleteMessage = (state: CommonState) => state.deleteMessage;


// export const assignresourceList = (state: CommonState) => state.assignresourceList;





export const undoApproval = (state: CommonState) => state.undoApproval;

export const startWork = (state: CommonState) => state.startWork;

export const mobileNotificationList = (state: CommonState) => state.mobileNotificationList;
export const getMessageList = (state: CommonState) => state.getMessageList;


export const  clientCancelRequest = (state: CommonState) => state.clientCancelRequest;
export const  clientCancelRequestLoading = (state: CommonState) => state.clientCancelRequestLoading;
export const  clientCancelRequestLoaded = (state: CommonState) => state.clientCancelRequestLoaded;
export const  clientCancelRequestFailed = (state: CommonState) => state.clientCancelRequestFailed;

export const  approvedTicketListCount = (state: CommonState) => state.approvedTicketListCount;
export const  approvedTicketListCountLoading = (state: CommonState) => state.approvedTicketListCountLoading;
export const  approvedTicketListCountLoaded = (state: CommonState) => state.approvedTicketListCountLoaded;
export const  approvedTicketListCountFailed = (state: CommonState) => state.approvedTicketListCountFailed;

export const getOrderDetails = (state: CommonState) => state.orderDetails;
export const getOrderDetailsLoading = (state: CommonState) => state.orderDetailsLoading;
export const getOrderDetailsLoaded = (state: CommonState) => state.orderDetailsLoaded;
export const getOrderDetailsFailed = (state: CommonState) => state.orderDetailsFailed;

export const getUpdatePayment = (state: CommonState) => state.updatePayment;
export const getUpdatePaymentLoading = (state: CommonState) => state.updatePaymentLoading;
export const getUpdatePaymentLoaded = (state: CommonState) => state.updatePaymentLoaded;
export const getUpdatePaymentFailed = (state: CommonState) => state.updatePaymentFailed;


export const uploadProof = (state: CommonState) => state.uploadProof;
export const uploadProofLoading = (state: CommonState) => state.uploadProofLoading;
export const uploadProofLoaded = (state: CommonState) => state.uploadProofLoaded;
export const uploadProofFailed = (state: CommonState) => state.uploadProofFailed;

export const  assessmentList = (state: CommonState) => state.assessmentList;
export const  assessmentListLoading = (state: CommonState) => state.assessmentListLoading;
export const  assessmentListLoaded = (state: CommonState) => state.assessmentListLoaded;
export const  assessmentListFailed = (state: CommonState) => state.assessmentListFailed;

export const  clientApproval = (state: CommonState) => state.clientApproval;
export const  clientApprovalLoading = (state: CommonState) => state.clientApprovalLoading;
export const  clientApprovalLoaded = (state: CommonState) => state.clientApprovalLoaded;
export const  clientApprovalFailed = (state: CommonState) => state.clientApprovalFailed;

export const  sidebarCount = (state: CommonState) => state.sidebarCount;
export const  sidebarCountLoading = (state: CommonState) => state.sidebarCountLoading;
export const  sidebarCountLoaded = (state: CommonState) => state.sidebarCountLoaded;
export const  sidebarCountFailed = (state: CommonState) => state.sidebarCountFailed;





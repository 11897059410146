import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';

import * as notificationreducer from './notification.reducer';

export const getNotificationState = (state: AppState) => state.notification;

export const totalNotificationCount = createSelector(getNotificationState, notificationreducer.getNotificationCount);
export const totalNotificationCountLoading = createSelector(getNotificationState, notificationreducer.getNotificationCountLoading);
export const totalNotificationCountLoaded = createSelector(getNotificationState, notificationreducer.getNotificationCountLoaded);
export const totalNotificationCountFailed = createSelector(getNotificationState, notificationreducer.getNotificationCountFailed);

export const totalNotificationList = createSelector(getNotificationState, notificationreducer.getNotificationList);
export const totalNotificationListLoading = createSelector(getNotificationState, notificationreducer.getNotificationListLoading);
export const totalNotificationListLoaded = createSelector(getNotificationState, notificationreducer.getNotificationListLoaded);
export const totalNotificationListFailed = createSelector(getNotificationState, notificationreducer.getNotificationListFailed);

export const totalNotificationDetail = createSelector(getNotificationState, notificationreducer.getNotificationDetail);
export const totalNotificationDetailLoading = createSelector(getNotificationState, notificationreducer.getNotificationDetailLoading);
export const totalNotificationDetailLoaded = createSelector(getNotificationState, notificationreducer.getNotificationDetailLoaded);
export const totalNotificationDetailFailed = createSelector(getNotificationState, notificationreducer.getNotificationDetailFailed);
export const notificationStatusChange = createSelector(getNotificationState, notificationreducer.getNotificationStatusChange);
export const notificationUnreadStatus = createSelector(getNotificationState, notificationreducer.getNotificationUnreadStatus);
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthSandbox } from 'src/app/auth/auth.sandbox';
import { MustMatch } from 'src/app/shared/validators/confirm.password';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  confirmpassword: FormControl;
  newpassword: FormControl;
  id: Number;
  userId: Number;
  passwordpattern = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{5,}$/;

  constructor(private fb: FormBuilder, public routerParam: Router, private router: ActivatedRoute, public authSandbox: AuthSandbox) { }

  ngOnInit() {
    this.router.params.subscribe(data => {
       if (data['id']) {
          this.id = data.id;
       }
    });
    const params: any = {};
    params.urlString = this.id;
    this.authSandbox.checkUser(params);
    this.authSandbox.checkUserFailed$.subscribe( data => {
      if (data === true) {
      this.routerParam.navigate(['auth/login']);
      }
    });
    this.authSandbox.checkUser$.subscribe( data => {
      if ( data ) {
       if (data.data) {
       this.userId = data.data.id;
       }
      }
    });
    this.resetPasswordForm = this.fb.group({
      newpassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmpassword: ['', Validators.compose([Validators.required])]
    }, {
      validator: MustMatch('newpassword', 'confirmpassword')
    });
  }
  resetPass() {
    if (!this.resetPasswordForm.valid) {
      this.validateAllFormFields(this.resetPasswordForm);
      return;
    }
    const params: any = {};
    params.loginId = this.userId;
    params.newPassword = this.resetPasswordForm.controls.newpassword.value;
    params.confirmPassword = this.resetPasswordForm.controls.confirmpassword.value;
    this.authSandbox.resetPassword(params);
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}

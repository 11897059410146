import { Map, Record } from "immutable";

export interface NotificationState extends Map<string, any> {
  notificationCount: any;
  notificationCountLoading: boolean;
  notificationCountLoaded: boolean;
  notificationCountFailed: boolean;

  notificationList: Array<any>;
  notificationListLoading: boolean;
  notificationListLoaded: boolean;
  notificationListFailed: boolean;

  notificationDetail: any;
  notificationDetailLoading: boolean;
  notificationDetailLoaded: boolean;
  notificationDetailFailed: boolean;
  notificationStatusChange: any;
  notificationUnreadStatus: any;

}

export const notificationStateRecord = Record({

  notificationCount: {},
  notificationCountLoading: false,
  notificationCountLoaded: false,
  notificationCountFailed: false,

  notificationList: [],
  notificationListLoading: false,
  notificationListLoaded: false,
  notificationListFailed: false,

  notificationDetail: {},
  notificationDetailLoading: false,
  notificationDetailLoaded: false,
  notificationDetailFailed: false,
  notificationStatusChange: {},
  notificationUnreadStatus: {},
  
});

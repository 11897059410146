export class LoggedinUserModel {
  id: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  loginId: string;
  emailId: string;
  mobileNo: string;
  userTypeId: string;
  userRoleId: string;
  isActive: string;
  userGroupId: number;
  clientName: string;
  changePasswordFlag: number;
  userGroupDetails: any;
  constructor(val: any) {
    this.id = val.id;
    this.createdBy = val.createdBy;
    this.createdDate = val.createdDate;
    this.modifiedBy = val.modifiedBy;
    this.modifiedDate = val.modifiedDate;
    this.loginId = val.loginId;
    this.emailId = val.emailId;
    this.mobileNo = val.mobileNo;
    this.userTypeId = val.userTypeId;
    this.userRoleId = val.userRoleId;
    this.isActive = val.isActive;
    this.userGroupId = val.userGroupId;
    this.clientName = val.clientName;
    this.changePasswordFlag = val.changePasswordFlag;
    this.userGroupDetails = val.userGroupDetails;
  }
}

export class VerifyOtpResponseModel {
  token: string;
  user: LoggedinUserModel;

  constructor(val: any) {
    this.token = val.token;
    this.user = new LoggedinUserModel(val.user);
    localStorage.setItem('clientToken', this.token);
    localStorage.setItem('clientDetail', JSON.stringify(this.user));
  }
}


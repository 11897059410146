import {
  Component,
  ChangeDetectionStrategy, OnInit
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './servererror.component.html',
  styleUrls: ['./servererror.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerComponent implements OnInit {


  constructor(public router: Router) {
  }

  ngOnInit() {

  }
  signin() {
    localStorage.removeItem('clientDetail');
    localStorage.removeItem('clientToken');
    this.router.navigate(['/auth/login-client']);

  }
}

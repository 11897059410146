import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';

import * as payments from './payments.reducer';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getPaymentsState = (state: AppState) => state.payments;

export const paymentDashboardCount = createSelector(getPaymentsState, payments.paymentDashboardCount);
export const paymentDashboardCountLoading = createSelector(getPaymentsState, payments.paymentDashboardCountLoading);
export const paymentDashboardCountLoaded = createSelector(getPaymentsState, payments.paymentDashboardCountLoaded);
export const paymentDashboardCountFailed = createSelector(getPaymentsState, payments.paymentDashboardCountFailed);

export const recentPaymentDues = createSelector(getPaymentsState, payments.recentPaymentDues);
export const recentPaymentDuesLoading = createSelector(getPaymentsState, payments.recentPaymentsDuesLoading);
export const recentPaymentDuesLoaded = createSelector(getPaymentsState, payments.recentPaymentsDuesLoaded);
export const recentPaymentDuesFailed = createSelector(getPaymentsState, payments.recentPaymentsDuesFailed);

export const paymentHistoryList = createSelector(getPaymentsState, payments.paymentHistoryList);
export const paymentHistoryListLoading = createSelector(getPaymentsState, payments.paymentHistoryListLoading);
export const paymentHistoryListLoaded = createSelector(getPaymentsState, payments.paymentHistoryListLoaded);
export const paymentHistoryListFailed = createSelector(getPaymentsState, payments.paymentHistoryListFailed);

export const paymentOrderList = createSelector(getPaymentsState, payments.paymentOrderList);
export const paymentOrderListLoading = createSelector(getPaymentsState, payments.paymentOrderListLoading);
export const paymentOrderListLoaded = createSelector(getPaymentsState, payments.paymentOrderListLoaded);
export const paymentOrderListFailed = createSelector(getPaymentsState, payments.paymentOrderListFailed);

export const paymentHistoryListCount = createSelector(getPaymentsState, payments.paymentHistoryListCount);

export const orderListCount = createSelector(getPaymentsState, payments.getOrderListCount);
export const orderListCountLoading = createSelector(getPaymentsState, payments.getOrderListCountLoading);
export const orderListCountLoaded = createSelector(getPaymentsState, payments.getOrderListCountLoaded);
export const orderListCountFailed = createSelector(getPaymentsState, payments.getOrderListCountFailed);
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { WebsocketService } from '../chat/services/websocket.service';

@Injectable()
export class AuthGuard implements CanActivate {
  currentRole: any;

  constructor(private router: Router,) { }
  // public wsService: WebsocketService

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // if (!this.wsService.connected) {
    //   await this.wsService.connect().toPromise();
    // }
    return this.checkLogin(state.url, route);
  }

 async checkLogin(url: string, role: any): Promise<boolean> {
    const currentUserDetail = JSON.parse(localStorage.getItem('clientDetail'));
    const currentUser = localStorage.getItem('clientToken');
    if (currentUser && localStorage.getItem('clientDetail')) {
      if (url === '/auth/login-client') {
        console.log('authurl', url);
        console.log('authrole', role);
        if (localStorage.getItem('clientDetail')) {
          this.currentRole = JSON.parse(
            localStorage.getItem('clientDetail')
          ).userGroupId;
          console.log('userGroupId', this.currentRole);
         if (this.currentRole === 2) {
            this.router.navigate(['/client/dashboard']);
            return false;
          }
          // else if (this.currentRole === 3) {
          //   this.router.navigate(['/resource/dashboard']);
          //   return false;
          // }
          
        }
        return true;
      } else {
        // console.log('authelseurl', url);
        // console.log('authelserole', role);
        if (role.children && role.children[0]) {
          const routesRole = role.children[0].data['role'];
          if (localStorage.getItem('clientDetail')) {
            this.currentRole = JSON.parse(
              localStorage.getItem('clientDetail')
            ).userGroupDetails.slug === 'superadmin' ||
            JSON.parse(
              localStorage.getItem('clientDetail')
            ).userGroupDetails.slug === 'admin'  ? 1 :
            JSON.parse(
              localStorage.getItem('clientDetail')
            ).userGroupDetails.slug === 'developer' ? 4 : JSON.parse(
              localStorage.getItem('clientDetail')
            ).userGroupDetails.slug === 'delivery_manager' ? 10 : 2 ;
            if (routesRole === this.currentRole || (routesRole == 1 && this.currentRole == 10)) {
              return true;
            } else {
              console.log('elsepart1', routesRole);
              console.log('elsepart2', this.currentRole);
              if (this.currentRole === 2) {
                this.router.navigate(['/client/dashboard']);
                return false;
              } 
              // else if (this.currentRole === 3) {
              //   this.router.navigate(['/resource/dashboard']);
              //   return false;
              // }
            }
          }
          return true;
        }
      }
      return true;
    } else {
      if (url === '/auth/login-client' ) {
        return true;
      }
    }
    this.router.navigate(['/auth/login-client']);

    return false;
  }
}

import { createSelector } from 'reselect';
import { AppState } from 'src/app/app.state';

import * as authreducer from '../../auth/reducer/auth.reducer';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getAuthState = (state: AppState) => state.auth;
export const loginFailure = createSelector(getAuthState, authreducer.getLoginFailure);
export const loginSuccess = createSelector(getAuthState, authreducer.getLoginSuccess);
export const loginLoading = createSelector(getAuthState, authreducer.getLoginLoading);
export const loginLoaded = createSelector(getAuthState, authreducer.getLoginLoaded);
export const loginFailed = createSelector(getAuthState, authreducer.getLoginFailed);

export const forgetPasswordLoading = createSelector(getAuthState, authreducer.getForgetPassowrdLoading);
export const forgetPasswordLoaded = createSelector(getAuthState, authreducer.getForgetPassowrdLoaded);
export const forgetPasswordFailed = createSelector(getAuthState, authreducer.getForgetPassowrdFailed);

export const resetPasswordLoading = createSelector(getAuthState, authreducer.getResetPassowrdLoading);
export const resetPasswordLoaded = createSelector(getAuthState, authreducer.getResetPassowrdLoaded);
export const resetPasswordFailed = createSelector(getAuthState, authreducer.getResetPassowrdFailed);

export const checkUser = createSelector(getAuthState, authreducer.gettCheckUser);
export const checkUserLoading = createSelector(getAuthState, authreducer.getCheckUserLoading);
export const checkUserLoaded = createSelector(getAuthState, authreducer.getCheckUserLoaded);
export const checkUserFailed = createSelector(getAuthState, authreducer.getCheckUserFailed);

export const verifyOtpFailure = createSelector(getAuthState, authreducer.verifyOtpFailure);
export const verifyOtpSuccess = createSelector(getAuthState, authreducer.verifyOtpSuccess);
export const verifyOtpLoading = createSelector(getAuthState, authreducer.verifyOtpLoading);
export const verifyOtpLoaded = createSelector(getAuthState, authreducer.verifyOtpLoaded);
export const verifyOtpFailed = createSelector(getAuthState, authreducer.verifyOtpFailed);

export const resendOtpFailure = createSelector(getAuthState, authreducer.resendOtpFailure);
export const resendOtpSuccess = createSelector(getAuthState, authreducer.resendOtpSuccess);
export const resendOtpLoading = createSelector(getAuthState, authreducer.resendOtpLoading);
export const resendOtpLoaded = createSelector(getAuthState, authreducer.resendOtpLoaded);
export const resendOtpFailed = createSelector(getAuthState, authreducer.resendOtpFailed);


import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-ckeditor',
  templateUrl: 'ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})

export class CkeditorComponent implements OnInit {
  @ViewChild('myckeditor', {static: true}) ckeditor: ElementRef;
    ckeConfig: any;
    public myContent: any;
    @Input() setclassname: boolean;
    @Input() indexTrans: string;
    @Input() i: string;
    @Output() messageEvent = new EventEmitter<string>();

    constructor() {

    }

    ngOnInit() {
        this.ckeConfig = { 
            removePlugins: 'templates,elementspath,save,font,undo,link,save,newpage,print,template,preview,image,flash,horizontalrule,smiley,specialchar,pagebreak,iframe,copyformatting,removeformat,outdent,blockquote,createDiv,bidiltr,idirtl,language,showblocks,about,forms,find,format,font,fontSize,justify',
            removeButtons: 'BidiLtr,BidiRtl,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,Scayt,RemoveFormat,Superscript,Subscript,BGColor,TextColor,Styles,Format,ShowBlocks,UIColor,Underline,Strike,Table,Cut,Copy,CreateDivContainer,Maximize,PasteFromWord,SpellChecker,SelectAll,Paste,PasteText', 
            extraPlugins: 'easyimage',
            toolbar: [ { name: 'document', items: [ 'Source', '-', 'Bold', 'Italic','EasyImageUpload'] }],
            // plugins.add
        };
    }

    sendFirstReply(){

    }

    cancel(data){       
        let a = 2;
        this.setclassname = false;
        let openWithTransition: boolean = this.setclassname;
        console.log(this.setclassname)
        this.messageEvent.emit('j');   
    }
}
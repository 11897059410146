import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '../../providers/api/api';
import { ResponseInterface } from '../../shared/interfaces/interface';



@Injectable()

export class CommonService extends Api {

  private URL: string = this.getBaseUrl();

  getProjects(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/project/list', { params: params });
  }
  downloadReceipt(params: any): Observable<any>  {
    console.log('downloadInvoice', params);
    return this.http.get<ResponseInterface>(this.URL +'api/image/view-payment-receipt/'+ params.id);
  }
  getTotalProjects(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/project/projects-list-with-search', { params: params });
  }
  getPriority(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/ticket-priority-list', { params: params });
  }
  createRequest(params: any): Observable<ResponseInterface> {
    console.log('servicevalue', params);

    const formData = new FormData();
    formData.append('ticketTitle', params.ticketTitle);
    formData.append('ticketDescription', params.ticketDescription);
    formData.append('ticketPriorityId', params.ticketPriorityId);
    formData.append('projectId', params.projectId);
    if (params.clientId !== undefined) {
      formData.append('clientId', params.clientId);
    }
    if (params.ticketId) {
      formData.append('ticketId', params.ticketId);
    }
    params.file.forEach(val => {
      formData.append('file', val);
    });
    // if (params.uploadDocuments) {
    //   params.uploadDocuments.forEach(val => {
    //     formData.append('uploadDocuments', val);
    //   });
    // }
    console.log('servicevalue1', formData);
    if (params.url !== '/ticket/update-client-ticket') {
      return this.http.post<ResponseInterface>(this.URL +'api' + params.url, formData);
    } else {
      return this.http.put<ResponseInterface>(this.URL +'api' + params.url, formData);
    }
  }
  getPaymentList(params: any): Observable<ResponseInterface> {
    delete params.paymentWayType;
    return this.http.get<ResponseInterface>(this.URL +'api/payments/list', { params: params });
  }
  getPaymentListWithGroupBy(params: any): Observable<ResponseInterface> {
    delete params.paymentWayType;
    return this.http.get<ResponseInterface>(this.URL +'api/payments/list-with-groupBy', { params: params });
  }
  getStatus(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/ticket-status-list', { params: params });
  }
  getTicketStatusChange(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/ticket/change-ticket-status', params);
  }
  getTicketDecline(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/ticket/decline-ticket', params);
  }
  getTicketList(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket', { params: params });
  }

  approvedTicketListCount(params: any): Observable<ResponseInterface> {

    return this.http.get<ResponseInterface>(this.URL +'api/ticket', { params: params });
  }
  getTicketDetail(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/ticket-details/' + params.id, { params: params });
  }
  
  changePassword(params) {
    return this.http.post<ResponseInterface>(this.URL +'api/auth/change-password', params);
  }

  getInvoiceDetails(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/invoice/invoice-details/' + params.id);
  }
  getPaymentDetails(params): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/payments/payment-details/' + params.id);
  }
  paymentLog(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/order-items/payment-log-list', { params: params });
  }
  onlineResourcesList(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/ticket-assign/assign-users', params);
  }
  assignedResourcesList(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-task/ticket-resource/' + params.ticketId, {params:params});
  }
  updatePayments(params: any): Observable<ResponseInterface> {
    const paymentId = params.paymentid;
    delete params.paymentid;
    return this.http.post<ResponseInterface>(this.URL +'api/payments/update-payments/' + paymentId, params);
  }
  updateTicketList(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-updates/list', { params: params });
  }
  uploadDocuments(params: any): Observable<ResponseInterface> {
    console.log('servicevalue', params);
    const formData = new FormData();
    formData.append('ticketId', params.ticketId);
    formData.append('file', params.file);
    console.log('servicevalue1', formData);
    return this.http.put<ResponseInterface>(this.URL +'api/ticket/update-client-ticket/file', formData);
  }
  deleteDocuments(params: any): Observable<ResponseInterface> {
    return this.http.delete<ResponseInterface>(this.URL +'api/ticket/client-ticket/delete-file/' + params.fileId);
  }
  // offlinePayment(params: any): Observable<ResponseInterface> {
  //   return this.http.post<ResponseInterface>('api/payments/update-payments/', params);
  // }

  sendMessage(params: any): Observable<ResponseInterface> {
    console.log(params);
    if(params.detail == 1){
      const params1: any = {};  
      params1.ticketId = params.ticketId;
      params1.message = params.message;
      return this.http.post<ResponseInterface>(this.URL +'api/ticket-conversation/send-message-to-ticket', params1);
    }else if(params.detail == 2) {
      const params1: any = {};
      params1.ticketId = params.ticketId;
      params1.message = params.message;
      params1.conversationTicketId = params.ticketConversationId;
      console.log('param', params1);
      
      return this.http.post<ResponseInterface>(this.URL +'api/ticket-conversation/send-reply-message-to-ticket', params1);
    }else if(params.detail == 3) {
      const params1: any = {}; 
      let ticketId = params.ticketId;
      params1.message = params.message;
      if(params.isPrivate) {
      params1.isPrivate = params.isPrivate;
      }
      return this.http.put<ResponseInterface>(this.URL +'api/ticket-conversation/edit-send-message-to-ticket/' + ticketId,params1);
    }else {
      const params1: any = {};
      let ticketId = params.ticketId;
      params1.message = params.message;
      if(params.isPrivate) {
      params1.isPrivate = params.isPrivate;
      }
      return this.http.put<ResponseInterface>(this.URL +'api/ticket-conversation/edit-reply-message-to-ticket/' + ticketId,params1);
    }   
  }

  getMessageList(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-conversation/base-ticket-message', { params: params });
  }
  getRepliedBaseMessage(data: any): Observable<ResponseInterface> {
    const params: any = {};
    params.limit = data.limit;
    params.offset = data.offset;
    params.ticketConversationId = data.ticketConversationId;
    params.count = data.count;
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-conversation/replied-base-ticket-message', { params: params });
  }
  getRepliedBaseMessageCount(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-conversation/replied-base-ticket-message', { params: params });
  }

  deleteMessage(params: any): Observable<ResponseInterface> {
    console.log('dde', params);
    
    if(params.detail == 'maindeletemsg'){
      console.log(params);
      return this.http.delete<ResponseInterface>(this.URL +'api/ticket-conversation/delete-send-message-to-ticket/'+params.data.id);
    }else{
      console.log(params);
      return this.http.delete<ResponseInterface>(this.URL +'api/ticket-conversation/delete-send-reply-message-to-ticket/'+params.data.id);
    }
  }
  getAssignedResorce(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-assign/assigned-resources-list/' + params.ticketId, {params: params});
  }
  undoApproval(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/clients-undo-approval-status/' + params.ticketId);
  }
  uploadFile(params: any): Observable<ResponseInterface> {
    console.log('params', params);
    const formData = new FormData();
    formData.append('ticketId', params.ticketId) ;
    formData.append('file', params.file);
    return this.http.post<ResponseInterface>(this.URL +'api/developer-ticket/ticket-documents' , formData );
  }  
  startWork(params: any): Observable<ResponseInterface> {
    return this.http.post<ResponseInterface>(this.URL +'api/developer-ticket/status-change' , params);
  }
  mobileNotificationList(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/ticket-route' , {params: params});
  }
  getMessageListMobile(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-conversation/base-ticket-message-short-url' , {params: params});
  }
  clientCancelRequest(params: any): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket/clients-cancel-request/' + params);
  }
  
  getOrderDetails(id: Number): Observable<ResponseInterface> {
    return this.http.get<ResponseInterface>(this.URL +'api/order/order-details/' + id);
  }
  
  uploadProof(params: any): Observable<ResponseInterface>{
    return this.http.post<ResponseInterface>(this.URL + 'api/payments/document-upload', params);
  }
  assessmentList(params: any): Observable<any> {
    return this.http.get<ResponseInterface>(this.URL +'api/assessment/ticket-assessment-list',{ params: params }) ;
}

  public export(params:any): Observable<any> {
    console.log("export",params);
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get<ResponseInterface>(this.URL + 'api/ticket/ticket-export/'+ params.clientId,reqOpts);
  }

  public orderExport(params:any): Observable<any> {
    const reqOpts: any = {};
    reqOpts.responseType = 'arraybuffer';
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.http.get<ResponseInterface>(this.URL + ''+ params.clientId,reqOpts);
  }
  clientApproval(params:any): Observable<any>{
    const id = params.id;
    delete params.id;
     return this.http.post<ResponseInterface>(this.URL +'api/assessment/client-approval/'+ id,params)
     }
     estimationTicketListCount(params: any): Observable<ResponseInterface> {
      return this.http.get<ResponseInterface>(this.URL +'api/ticket', { params: params });
    }
    deleteTicket(params: any): Observable<any> {
      return this.http.delete(this.URL+'api/ticket/delete-ticket/' + params.id);
  }
  sidebarCount(params: any): Observable<any> {
     return this.http.get<ResponseInterface>(this.URL +'api/ticket/count-list');
  }

  ticketTask(params: any): Observable<any> {
    return this.http.get<ResponseInterface>(this.URL +'api/ticket-task',{params: params});
 }
}


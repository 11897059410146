import { Injectable } from '@angular/core';
import ChatServer from '../shared-interfaces/server.interface';
import { ChatChannel } from '../shared-interfaces/channel.interface';

@Injectable()
export class AppStateService {
  public currentServer: ChatServer;
  public currentChannel: ChatChannel;
  public serverList: ChatServer[];

  constructor() {
  }

}

import { Routes } from "@angular/router";
import { AuthGuard } from "../shared/guards/auth.guard";
import { ForgetpasswordComponent } from "../shared/components/settings/forgetpassword/forgetpassword.component";
import { ResetpasswordComponent } from "../shared/components/settings/resetpassword/resetpassword.component";
import { AUTH } from "../layout/auth";
import { WebComponent } from "../layout/web/web.component";
import { ConfirmationPageComponent } from "../shared/components/settings/confirmation-page/confirmationpage.component";
import { LoginComponent } from "./components/login/login/login.component";
import { OptverifyComponent } from "./components/optverify/optverify.component";
import { SignupComponent } from "./components/signup/signup.component";

export const authRoutes: Routes = [
  { path: "", redirectTo: "/auth/login-client", pathMatch: "full" },
  {
    path: "",
    component: AUTH.AuthComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "login-client",
        component: LoginComponent,
      },
    ],
  },
  // {
  //   path: "settings",
  //   component: AUTH.SettingsLayoutComponent,
  //   children: [
  //     {
  //       path: "forgetpassword",
  //       component: ForgetpasswordComponent,
  //     },
  //     {
  //       path: "confirmation",
  //       component: ConfirmationPageComponent,
  //     },
  //     {
  //       path: "resetpassword/:id",
  //       component: ResetpasswordComponent,
  //     },
  //   ],
  // },

  {
    path: "login-web/:loginId",
    component: WebComponent,
  },

  {
    path: "verify-otp",
    component: OptverifyComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
];

export class ProjectModel {
  clientId: string;
  createdBy: string;
  createdDate: string;
  deleteFlag: string;
  id: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: string;
  projectDescription: string;
  projectNo: string;
  projectTitle: string;

  constructor(val: any) {
    this.clientId = val.clientId ? val.clientId : '';
    this.createdBy = val.createdBy ? val.createdBy : '';
    this.createdDate = val.createdDate ? val.createdDate : '';
    this.deleteFlag = val.deleteFlag ? val.deleteFlag : '';
    this.id = val.id ? val.id : '';
    this.isActive = val.isActive ? val.isActive : '';
    this.modifiedBy = val.modifiedBy ? val.modifiedBy : '';
    this.modifiedDate = val.modifiedDate ? val.modifiedDate : '';
    this.projectDescription = val.projectDescription ? val.projectDescription : '';
    this.projectNo = val.projectNo ? val.projectNo : '';
    this.projectTitle = val.projectTitle ? val.projectTitle : '';
  }
}
export class StatusModel {
  createdBy: string;
  createdDate: string;
  deleteFlag: string;
  id: any;
  isActive: string;
  modifiedBy: string;
  modifiedDate: string;
  statusName: string;

  constructor(val: any) {
    this.createdBy = val.createdBy ? val.createdBy : '';
    this.createdDate = val.createdDate ? val.createdDate : '';
    this.deleteFlag = val.deleteFlag ? val.deleteFlag : '';
    this.id = val.id ? val.id : 0;
    this.isActive = val.isActive ? val.isActive : '';
    this.modifiedBy = val.modifiedBy ? val.modifiedBy : '';
    this.modifiedDate = val.modifiedDate ? val.modifiedDate : '';
    this.statusName = val.statusName ? val.statusName : '';
  }
}
export class PriorityModel {
  createdBy?: string;
  createdDate?: string;
  id?: string;
  isActive?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  priorityName?: string;
  prioritySlug?: string;

  constructor(val: any) {
    this.createdBy = val.createdBy ? val.createdBy : '';
    this.createdDate = val.createdDate ? val.createdDate : '';
    this.id = val.id ? val.id : '';
    this.isActive = val.isActive ? val.isActive : '';
    this.modifiedBy = val.modifiedBy ? val.modifiedBy : '';
    this.modifiedDate = val.modifiedDate ? val.modifiedDate : '';
    this.priorityName = val.priorityName ? val.priorityName : '';
    this.prioritySlug = val.prioritySlug ? val.prioritySlug : '';
  }
}
export class TicketLogDetails {
  id: Number;
  createdBy: Number;
  createdDate: any;
  modifiedBy: string;
  modifiedDate: any;
  ticketId: Number;
  ticketNo: Number;
  ticketTitle: string;
  ticketDescription: string;
  ticketDate: string;
  implementationFlag?: any;
  existingTicketStatusId: string;
  changedTicketStatusId: string;
  ticketPaymentStatus: string;
  ticketType: string;
  ticketCreatedBy: string;
  projectId: Number;
  clientId: Number;
  deleteFlag: Number;
  isActive: Number;
  constructor(val: any) {
    this.id = val.id ? val.id : '';
    this.createdBy = val.createdBy ? val.createdBy : '';
    this.createdDate = val.createdDate ?  new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) : '';
    this.modifiedBy = val.modifiedBy ? val.modifiedBy : '';
    this.modifiedDate = val.modifiedDate ? new Date(new Date(val.modifiedDate).setMinutes(new Date(val.modifiedDate).getMinutes() - 330)) : '';
    this.ticketId = val.ticketId ? val.ticketId : '';
    this.ticketNo = val.ticketNo ? val.ticketNo : '';
    this.ticketTitle = val.ticketTitle ? val.ticketTitle : '';
    this.ticketDescription = val.ticketDescription ? val.ticketDescription : '';
    this.ticketDate = val.ticketDate ? val.ticketDate : '';
    this.implementationFlag = val.implementationFlag ? val.implementationFlag : ''
    this.existingTicketStatusId = val.existingTicketStatusId ? val.existingTicketStatusId : '';
    this.changedTicketStatusId = val.changedTicketStatusId ? val.changedTicketStatusId : '';
    this.ticketPaymentStatus = val.ticketPaymentStatus ? val.ticketPaymentStatus : '';
    this.ticketType = val.ticketType ? val.ticketType : '';
    this.ticketCreatedBy = val.ticketCreatedBy ? val.ticketCreatedBy : '';
    this.projectId = val.projectId ? val.projectId : '';
    this.clientId = val.clientId ? val.clientId : '';
    this.deleteFlag = val.deleteFlag ? val.deleteFlag : '';
    this.isActive = val.isActive ? val.isActive : '';
  }
}

export class ActivityDetailModel {
  clientId: string;
  createdBy: string;
  createdDate: any;
  duration:number;
  clientsApprovedDate: any;
  declineNotes: string;
  declined: number;
  deleteFlag: string;
  id: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: any;
  priorityDetails: PriorityModel;
  projectDetails: ProjectModel;
  projectId: string;
  statusDetails: StatusModel;
  ticketAttachmentsDetails: any;
  ticketClosedDate: number;
  ticketCost: any;
  ticketDate: string;
  ticketDescription: string;
  ticketNo: string;
  ticketNotesDetails: any;
  ticketPaymentStatus: string;
  ticketPriorityId: string;
  tempticketStatusId: number;
  ticketStatusId: number;
  ticketTitle: string;
  ticketType: string;
  reviewNotes: string;
  scheduleNotes: string;
  ticketDuration: any;
  ticketStartDate: any;
  expectedClosedDate: any;
  ticketClosureDate: any;
  implementationNotes: string;
  implementationFlag: any;
  clientDetails: any;
  approvalNotes: string;
  clientsApprovalStatus: number;
  sendApprovalStatus: number;
  sendApprovalDate: any;
  ticketLogDetails: Array<any>;
  currency?: string;
  currencySymbol: any;
  isAutoApprove: any;
  isFreeCost: any;
  dupStatus: any;
  isReviewStart: any;
  constructor(val: any) {
    this.clientId = val.clientId ? val.clientId : '';
    this.createdBy = val.createdBy ? val.createdBy : '';
    this.createdDate = val.createdDate ?  new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) : '';
    this.clientsApprovedDate = val.clientsApprovedDate ? new Date(new Date(val.clientsApprovedDate).setMinutes(new Date(val.clientsApprovedDate).getMinutes() - 330)) : '';
    this.declineNotes = val.declineNotes ? val.declineNotes : '';
    this.declined = val.declined ? val.declined : 0;
    this.deleteFlag = val.deleteFlag ? val.deleteFlag : '';
    this.id = val.id ? val.id : '';
    this.isActive = val.isActive ? val.isActive : '';
    this.modifiedBy = val.modifiedBy ? val.modifiedBy : '';
    this.modifiedDate = val.modifiedDate ? new Date(new Date(val.modifiedDate).setMinutes(new Date(val.modifiedDate).getMinutes() - 330)) : '';
    this.priorityDetails = val.priorityDetails ? new PriorityModel(val.priorityDetails) : null;
    this.projectDetails = val.projectDetails ? new ProjectModel(val.projectDetails) : null;
    this.projectId = val.projectId ? val.projectId : '';
    this.statusDetails = val.statusDetails ? new StatusModel(val.statusDetails) : null;
    this.ticketAttachmentsDetails = val.ticketAttachmentsDetails || [];
    this.ticketLogDetails = val.ticketLogDetails.length > 0 ? (val.ticketLogDetails.map(list => {
      const ticketDetail = new TicketLogDetails(list);
      return ticketDetail;
    })) : [];
    this.ticketClosedDate = val.ticketClosedDate ? val.ticketClosedDate : '';
    this.ticketCost = val.ticketCost ? val.ticketCost : 0;
    this.ticketDate = val.ticketDate ? val.ticketDate : '';
    this.ticketDescription = val.ticketDescription ? val.ticketDescription : '';
    this.ticketNotesDetails = val.ticketNotesDetails ? val.ticketNotesDetails : [];
    this.ticketNo = val.ticketNo ? val.ticketNo : '';
    this.ticketPaymentStatus = val.ticketPaymentStatus ? val.ticketPaymentStatus : '';
    this.ticketPriorityId = val.ticketPriorityId ? val.ticketPriorityId : '';
    this.tempticketStatusId = val.ticketStatusId ? val.ticketStatusId : '';
    this.ticketStatusId = val.ticketStatusId ? val.ticketStatusId : '';
    this.implementationFlag = val.implementationFlag ? val.implementationFlag : '';
    this.clientDetails = val.clientDetails ? val.clientDetails : {};
    this.tempticketStatusId = val.ticketStatusId ? val.ticketStatusId : '';
    this.ticketTitle = val.ticketTitle ? val.ticketTitle : '';
    this.ticketType = val.ticketType ? val.ticketType : '';
    this.scheduleNotes = val.scheduleNotes ? val.scheduleNotes : '';
    this.reviewNotes = val.reviewNotes ? val.reviewNotes : '';
    this.ticketDuration = val.ticketDuration ? val.ticketDuration : '';
    this.duration=val.duration ? val.duration :'';
    this.ticketStartDate = val.ticketStartDate ? val.ticketStartDate : '';
    this.expectedClosedDate = val.expectedClosedDate ? val.expectedClosedDate : '';
    this.ticketClosureDate = val.ticketClosureDate ? val.ticketClosureDate : '';
    this.implementationNotes = val.implementationNotes ? val.implementationNotes : '';
    this.approvalNotes = val.approvalNotes ? val.approvalNotes : '';
    this.clientsApprovalStatus = val.clientsApprovalStatus ? val.clientsApprovalStatus : 0;
    this.sendApprovalStatus = val.sendApprovalStatus ? val.sendApprovalStatus : 0;
    this.sendApprovalDate = val.sendApprovalDate ? new Date(new Date(val.sendApprovalDate).setMinutes(new Date(val.sendApprovalDate).getMinutes() - 330)) : '';
    this.currency = val.currency ? val.currency : '';
    this.currencySymbol = val.currencySymbol ? val.currencySymbol : '';
    this.isAutoApprove = val.isAutoApprove ? val.isAutoApprove : '';
    this.isFreeCost = val.isFreeCost ? val.isFreeCost : '';
    this.dupStatus = val.ticketStatusId ? val.ticketStatusId : '';
    this.isReviewStart = val.isReviewStart ? val.isReviewStart : 0;
  }
}

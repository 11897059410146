import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { authRoutes } from "./auth.routing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthSandbox } from "./auth.sandbox";
import { AuthService } from "./auth.service";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffect } from "./effects/auth.effect";
import { ForgetpasswordComponent } from "../shared/components/settings/forgetpassword/forgetpassword.component";
import { ResetpasswordComponent } from "../shared/components/settings/resetpassword/resetpassword.component";
import { AUTH } from "../layout/auth";
import { SharedComponentsModule } from "../shared/components";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule, WavesModule } from "angular-bootstrap-md";
import { ConfirmationPageComponent } from "../shared/components/settings/confirmation-page/confirmationpage.component";
import { WebComponent } from "../layout/web/web.component";
import { LoginComponent } from "./components/login/login/login.component";
import { NumberAcceptModule } from "../shared/Directive/onlyNumber.module";
import { NgOtpInputModule } from "ng-otp-input";
import { OptverifyComponent } from "./components/optverify/optverify.component";
import { SignupComponent } from "./components/signup/signup.component";
import { DashbaordModule } from "../client/dashboard/dashbaord.module";
import { MyDownloadsService } from "../client/mydownloads/mydownloads.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    RouterModule.forChild(authRoutes),
    EffectsModule.forFeature([AuthEffect]),
    NgbModule,
    CarouselModule,
    WavesModule,
    NumberAcceptModule,
    NgOtpInputModule,
  ],
  declarations: [
    LoginComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    ConfirmationPageComponent,
    AUTH.AuthComponent,
    AUTH.SettingsLayoutComponent,
    WebComponent,
    OptverifyComponent,
    SignupComponent
  ],
  providers: [AuthSandbox, AuthService, MyDownloadsService],
})
export class AuthModule {}

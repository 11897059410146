import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { AdminSandbox } from 'src/app/admin/admin.sandbox';
import { Subscription } from 'rxjs';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';

@Component({
  selector: 'app-deletepopup',
  templateUrl: './deletepopup.component.html',
  styleUrls: ['./deletepopup.component.scss']
})
export class DeletePopupComponent implements OnInit, OnDestroy {
id: any;
detail: any;
fileDetails: any;
revokeDetails: any;
closeDetails: any;
msgDetails: any;
declineData: any;
startworkDetails: any;
revisedPriceDetails:any;
private subscriptions: Array<Subscription> = [];
  constructor(public modal: NgbActiveModal, public commonSandbox: CommonSandbox) { }

  dismiss() {
    this.modal.close();
  }

  ngOnInit() {
    console.log('filedetails', this.fileDetails,this.revokeDetails,this.revisedPriceDetails);
    this.commonSandbox.clearData();
  }
  delete() {
    console.log("revised");
  //   if(this.detail){
  //     if (this.detail === 'delete-client') {
  //       this.adminSandbox.deleteClient(this.id);
  //       this.subscriptions.push(this.adminSandbox.deleteClientLoaded$.subscribe(loaded => {
  //       if (loaded) {
  //         this.dismiss();
  //       }
  //       })
  //   );
  //       }
  // if (this.detail === 'delete-project') {
  //     this.adminSandbox.deleteProject(this.id);
  //     this.subscriptions.push(this.adminSandbox.deleteProjectLoaded$.subscribe(loaded => {
  //       if (loaded) {
  //         this.dismiss();
  //       }
  //     }));
  //   }
  // if (this.detail === 'delete-order') {
  //         this.adminSandbox.deleteOrder(this.id);
  //         this.subscriptions.push(this.adminSandbox.deleteOrderLoaded$.subscribe(loaded => {
  //           if (loaded) {
  //          this.dismiss();
  //          }
  //            })
  //        );
  //   }
  //   }
    
    if(this.msgDetails && this.msgDetails.detail){
        this.commonSandbox.deleteMessage(this.msgDetails);  
        this.subscriptions.push(this.commonSandbox.deleteMessage$.subscribe(data => {
          if(data){
            this.modal.close('success');
          }
      }));
    }

    if (this.fileDetails && this.fileDetails.filePath) {
      console.log('ddd');
      this.commonSandbox.deleteDocumets({ fileId: this.fileDetails.id });
      // this.dismiss();
      this.modal.close('success');

    }

    if(this.revokeDetails && this.revokeDetails.detail){
      if(this.revokeDetails.detail == 'revoke'){
        this.commonSandbox.undoApproval(this.revokeDetails);
        this.subscriptions.push(this.commonSandbox.undoApproval$.subscribe(data => {
          if(data){
            this.modal.close('success');
          }
        })); 
      }
    }
    if(this.revisedPriceDetails && this.revisedPriceDetails.detail){
    if(this.revisedPriceDetails.detail == 'revisedPrice'){
      console.log("revised  revised 12345");
      this.commonSandbox.undoApproval(this.revisedPriceDetails);
      this.subscriptions.push(this.commonSandbox.undoApproval$.subscribe(data => {
        if(data){
          this.modal.close('success');
        }
      })); 
    }
    }
    if(this.startworkDetails && this.startworkDetails.detail){
      if(this.startworkDetails.detail == 'startwork'){
        const params: any = {};
        params.status = 'inprogress';
        params.ticketId = this.startworkDetails.ticketId;
        console.log('ssd', params);
        this.commonSandbox.startWork(params);
        this.subscriptions.push(this.commonSandbox.startWork$.subscribe(data => {
          if(data){
            this.modal.close('success');
          }
        })); 
      }
    }

    if(this.closeDetails && this.closeDetails.detail){
      if(this.closeDetails.detail == 'close'){
        console.log('close');
        const params: any = {}
        params.ticketId = this.closeDetails.ticketId;
        params.ticketStatusId = 6;
        this.commonSandbox.ticketStausChange(params);
        this.modal.close();
      }
    }

    if(this.declineData && this.declineData.ticketId){
        const params: any = {}
        params.ticketId = this.declineData.ticketId;
        params.declineNotes = this.declineData.declineNotes;
        console.log('decline',this.declineData);
        this.commonSandbox.ticketDecline(params);
        this.commonSandbox.ticketDecline$.subscribe(data => {
          if (data) {
              this.modal.close('success');
          }
        });
        // this.modal.close('success');
    }

}
ngOnDestroy() {
  this.subscriptions.forEach(sub => sub.unsubscribe());
}
}

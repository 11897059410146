export class NotesListModel {
  comments: string;
  createdBy: string;
  createdDate: any;
  id: string;
  modifiedBy: string;
  modifiedDate: string;
  count: any;
  constructor(val: any) {
    this.comments = val.comments || '';
    this.createdBy = val.createdBy || '';
    this.createdDate = new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) || '';
    this.id = val.id || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.count = val.count || 0;
  }
}

import {trigger, state, query, stagger, style, keyframes, animate, transition} from '@angular/animations';

export const sideMenu =



/*trigger('sideMenu', [
  transition(':enter', [
    query('li', style({opacity: 0})),
    query('li', [
      stagger(35, [
        animate('.1s', style({ opacity: 1 }))
      ])
    ])
  ]),
]);*/


  trigger('sideMenu', [
    // route 'enter' transition
    transition(':enter', [

      // styles at start of transition
      query('div', style({opacity: 0})),

      query('div', animate('1s', style({ opacity: 1 }))),

      // animation and styles at end of transition

    ]),
  ]);

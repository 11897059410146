import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/app-config.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    protected userTokenDetail: any = {};
    public paymentUrl = environment.paymentUrl;

    constructor(private router: Router, private toastr: ToastrService,
        private translateService: TranslateService, private configService: ConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        
        this.userTokenDetail = req.url.includes(this.paymentUrl) ? (localStorage.getItem('purchase')) : localStorage.getItem('clientToken');
        if (this.userTokenDetail) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.userTokenDetail}`,
                },
                withCredentials: false,
            });
        }
        return next.handle(req).pipe(
            map((responsedata: any) => {
                if (responsedata instanceof HttpResponse) {
                    const response = responsedata.body;
                    if (response && response.message != '' && req.method !== 'GET' && response.message !== 'You logged in.') {
                        this.showNotificationSuccess('Success', response.message);
                    }
                }
                return responsedata;
            }),
            catchError(response => {
                switch (response.status) {
                    case 400:
                        this.handleBadRequest(response.error);
                        break;

                    case 401:
                        this.handleUnauthorized(response);
                        break;
                    case 502:
                        this.handleServerError502();
                        break;
                    case 403:
                        this.handleForbidden();
                        break;

                    case 404:
                        this.handleNotFound(response);
                        break;

                    case 500:
                        this.handleServerError();
                        break;
                    case 409:
                        this.handleErrorMessages(response.error.meta);
                        break;
                    default:
                        break;
                }
                return throwError(response);
            })
        );
    }

    public handleServerError502() {
        this.router.navigate(['server-error']);
    }
    public handleDoc(response) {

    }
    /**
     * Shows notification errors when server response status is 401
     *
     * @param error
     */
    private handleBadRequest(responseBody: any): void {
        if(responseBody.message !== 'You are not yet registered with us.') {
            this.showNotificationError('', responseBody.message);
        }
    }

    /**
     * Shows notification errors when server response status is 401 and redirects user to login page
     *
     * @param responseBody
     */
    private handleUnauthorized(responseBody: any): void {
        this.showNotificationError('', 'unauthorized');
        this.router.navigate(['/auth/login-client']);
        localStorage.clear();
           // Read configuration in order to see if we need to display 401 notification message
           let unauthorizedEndpoints: Array<string> = this.configService.get('notifications').unauthorizedEndpoints;
           localStorage.clear();
           unauthorizedEndpoints = unauthorizedEndpoints.filter(endpoint => this.getRelativeUrl(responseBody.url) === endpoint);
           if (unauthorizedEndpoints.length) {
             const errorMessage = responseBody.error;
             this.showNotificationError('Error', this.getTranslatedValue(errorMessage.meta.msg));
           } else {
             this.router.navigate(['/auth/login-client']);
           }

    }

    /**
     * Shows notification errors when server response status is 403
     */
    private handleForbidden(): void {
        this.toastr.error('ServerError403', 'Error');
        this.router.navigate(['/auth/login-client']);
    }

    /**
     * Shows notification errors when server response status is 404
     *
     * @param responseBody
     */
    private handleNotFound(responseBody: any): void {
        // Read configuration in order to see if we need to display 401 notification message
        let notFoundEndpoints: Array<string> = environment.notifications.notFoundEndpoints;
        notFoundEndpoints = notFoundEndpoints.filter(endpoint => this.getRelativeUrl(responseBody.url) === endpoint);

        if (notFoundEndpoints.length) {
            const message = 'Page Not Found',
                title = '404';

            this.showNotificationError(title, message);
        }
    }

    /**
     * Shows notification errors when server response status is 500
     */
    private handleServerError(): void {
        const message = 'Internal Server Error',
            title = '500';

        this.showNotificationError(title, message);
    }

    /**
     * Parses server response and shows notification errors with translated messages
     *
     * @param response
     */
    private handleErrorMessages(response: any): void {
        if (!response) {
            return;
        }
        this.showNotificationError('Error', response.message);
    }
  /**
   * Extracts and returns translated value from server response
   *
   * @param value
   * @returns {string}
   */
  private getTranslatedValue(value: string): string {
    if (value.indexOf('[') > -1) {
      const key = value.substring(value.lastIndexOf('[') + 1, value.lastIndexOf(']'));
      value = this.translateService.instant(key);
    }

    return value;
  }
    /**
     * Returns relative url from the absolute path
     *
     * @param responseBody
     * @returns {string}
     */
    private getRelativeUrl(url: string): string {
        return url.toLowerCase().replace(/^(?:\/\/|[^\/]+)*\//, '');
    }

    /**
     * Shows error notification with given title and message
     *
     * @param title
     * @param message
     */
    private showNotificationError(title: string, message: string): void {
        this.toastr.error(message, title);
    }

    /**
     * Shows success notification with given title and message
     *
     * @param title
     * @param message
     */
    private showNotificationSuccess(title: string, message: string): void {
        if (message === 'You successfully changed the notification read status') {
            return;
        }
        this.toastr.success(message, title);
    }
}

import * as actions from '../actions/approvals.action';
import { ApprovalsState, approvalsStateRecord } from './approvals.state';

export const initialState: ApprovalsState = new approvalsStateRecord() as unknown as ApprovalsState;

export function reducer(state = initialState, { type, payload }: any): ApprovalsState {
  if (!type) {
    return state;
  }

  switch (type) {
   
    case actions.ActionTypes.GET_ASSESSMENT_TICKETS:

    return Object.assign({}, state, {
      getAssessmentTicketsLoading: true,
      getAssessmentTicketsLoaded: false,
      getAssessmentTicketsFailed: false
    });

  case actions.ActionTypes.GET_ASSESSMENT_TICKETS_SUCCESS:
    const assessmentTicket = payload.data.map(res=> ({...res, ticketAssessment: {...res.ticketAssessment, assessmentRequirement: JSON.parse(res?.ticketAssessment?.assessmentRequirement || '{}')}}));
    return Object.assign({}, state, {
      getAssessmentTickets: assessmentTicket,
      getAssessmentTicketsLoading: false,
      getAssessmentTicketsLoaded: true,
      getAssessmentTicketsFailed: false,
    });

  case actions.ActionTypes.GET_ASSESSMENT_TICKETS_FAIL:

    return Object.assign({}, state, {
      getAssessmentTicketsLoading: false,
      getAssessmentTicketsLoaded: true,
      getAssessmentTicketsFailed: true,
    });

    // GET_ASSESSMENT_TICKETS_COUNT

    case actions.ActionTypes.GET_ASSESSMENT_TICKETS_COUNT:

    return Object.assign({}, state, {
      getAssessmentTicketsCountLoading: true,
      getAssessmentTicketsCountLoaded: false,
      getAssessmentTicketsCountFailed: false
    });

  case actions.ActionTypes.GET_ASSESSMENT_TICKETS_COUNT_SUCCESS:
    return Object.assign({}, state, {
      getAssessmentTicketsCount: payload.data,
      getAssessmentTicketsCountLoading: false,
      getAssessmentTicketsCountLoaded: true,
      getAssessmentTicketsCountFailed: false,
    });

  case actions.ActionTypes.GET_ASSESSMENT_TICKETS_COUNT_FAIL:

    return Object.assign({}, state, {
      getAssessmentTicketsCountLoading: false,
      getAssessmentTicketsCountLoaded: true,
      getAssessmentTicketsCountFailed: true,
    });

    case actions.ActionTypes.ACCEPT_ASSESSMENT_TICKETS:
    return Object.assign({}, state, {
      acceptAssessmentTicketsLoading: true,
      acceptAssessmentTicketsLoaded: false,
      acceptAssessmentTicketsFailed: false
    });

  case actions.ActionTypes.ACCEPT_ASSESSMENT_TICKETS_SUCCESS:
    return Object.assign({}, state, {
      acceptAssessmentTickets: payload.data,
      acceptAssessmentTicketsLoading: false,
      acceptAssessmentTicketsLoaded: true,
      acceptAssessmentTicketsFailed: false,
    });

  case actions.ActionTypes.ACCEPT_ASSESSMENT_TICKETS_FAIL:

    return Object.assign({}, state, {
      acceptAssessmentTicketsLoading: false,
      acceptAssessmentTicketsLoaded: true,
      acceptAssessmentTicketsFailed: true,
    });

    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const getAssessmentTickets = (state: ApprovalsState) => state.getAssessmentTickets;
export const getAssessmentTicketsLoading = (state: ApprovalsState) => state.getAssessmentTicketsLoading;
export const getAssessmentTicketsLoaded = (state: ApprovalsState) => state.getAssessmentTicketsLoaded;
export const getAssessmentTicketsFailed = (state: ApprovalsState) => state.getAssessmentTicketsFailed;

export const getAssessmentTicketsCount = (state: ApprovalsState) => state.getAssessmentTicketsCount;
export const getAssessmentTicketsCountLoading = (state: ApprovalsState) => state.getAssessmentTicketsCountLoading;
export const getAssessmentTicketsCountLoaded = (state: ApprovalsState) => state.getAssessmentTicketsCountLoaded;
export const getAssessmentTicketsCountFailed = (state: ApprovalsState) => state.getAssessmentTicketsCountFailed;

export const acceptAssessmentTickets = (state: ApprovalsState) => state.acceptAssessmentTickets;
export const acceptAssessmentTicketsLoading = (state: ApprovalsState) => state.acceptAssessmentTicketsLoading;
export const acceptAssessmentTicketsLoaded = (state: ApprovalsState) => state.acceptAssessmentTicketsLoaded;
export const acceptAssessmentTicketsFailed = (state: ApprovalsState) => state.acceptAssessmentTicketsFailed;

export class NotificationListRequestModel {

  limit: any;
  offset: any;
  orderBy: number;
  count: any;
  userId: number;
  fromDate?: string;
  toDate?: string;
  isRead: any;
  search: string;
  url: string;
  refresh: boolean;
  constructor(val: any) {
    this.limit = val.limit || '';
    this.offset = val.offset || '';
    this.orderBy = val.orderBy || '';
    this.count = val.count || '';
    this.userId = val.userId || '';
    this.fromDate = val.fromDate || '';
    this.toDate = val.toDate || '';
    this.isRead = val.isRead || '';
    this.search = val.search || '';
    this.refresh = val.refresh || false;
    this.url = val.url || '';
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonSandbox } from '../layout/common/common.sandbox';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-notification',
  templateUrl: './mobile-notification.component.html',
  styleUrls: ['./mobile-notification.component.scss']
})
export class MobileNotificationComponent implements OnInit {
  urlId: any;
  imageBaseURL: any;
  private subscriptions: Array<Subscription> = [];
  constructor(private route: ActivatedRoute,public commonSandbox: CommonSandbox) {
      this.route.params.subscribe(val => {
      if (val) {
        this.urlId = val.id;
        console.log('conversation page');  
      }
    });
   }

  ngOnInit() {
    this.imageBaseURL = environment.baseImgUrl;
    this.getMessageList();
  } 
  getMessageList() {
    const params: any = {};
    params.key = this.urlId;
    this.commonSandbox.getMessageListMobile(params);
  }
}

export class ProjectDetailModel {
  clientId: string;
  createdBy: string;
  createdDate: string;
  deleteFlag: string;
  id: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: string;
  projectDescription: string;
  projectNo: string;
  projectTitle: string;

  constructor(val: any) {
    this.clientId = val.clientId || '';
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.deleteFlag = val.deleteFlag || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.projectDescription = val.projectDescription || '';
    this.projectNo = val.projectNo || '';
    this.projectTitle = val.projectTitle || '';
  }
}
export class StatusDetailModel {
  createdBy: string;
  createdDate: string;
  deleteFlag: string;
  id: string;
  isActive: string;
  modifiedBy: string;
  modifiedDate: string;
  statusName: string;

  constructor(val: any) {
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.deleteFlag = val.deleteFlag || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.statusName = val.statusName || '';
  }
}
export class PriorityDetailModel {
  createdBy?: string;
  createdDate?: string;
  id?: string;
  isActive?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  priorityName?: string;
  prioritySlug?: string;

  constructor(val: any) {
    this.createdBy = val.createdBy || '';
    this.createdDate = val.createdDate || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.priorityName = val.priorityName || '';
    this.prioritySlug = val.prioritySlug || '';
  }
}

export class TicketListResponseModel {
  clientId?: string;
  createdBy: string;
  createdDate: any;
  ticketStartDate: any;
  clientDetails: any;
  declined: number;
  deleteFlag: string;
  id: string;
  sendApprovalStatus: any;
  isActive: string;
  modifiedBy: string;
  modifiedDate: any;
  priorityDetails: PriorityDetailModel;
  projectDetails: any;
  projectId: string;
  statusDetails: StatusDetailModel;
  ticketCreatedBy: string;
  ticketClosureDate: string;
  ticketCost:any;
  ticketDate: string;
  ticketDescription: string;
  ticketNo: string;
  clientsApprovalStatus: any;
  ticketPaymentStatus: string;
  ticketPriorityId: string;
  ticketStatusId: string;
  ticketTitle: string;
  ticketType: number;
  expectedClosedDate: string;
  selected: boolean;
  currency: any;
  currencySymbol: any;
  implementationFlag: any;
  cilentRevisedStatus:any;
  cilentRevisedDate:any;
  clientsApprovedDate:any;
  scheduleNotes: string;
  sendApprovalDate: string;
  lastConversation: any;
  constructor(val: any) {
    // console.log('ticketlistresponsemodal', val );
    
    this.clientId = val.clientId || '';
    this.implementationFlag = val.implementationFlag || '';
    this.createdBy = val.createdBy || '';
    this.createdDate = new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) || '';
    this.declined = val.declined || 0;
    this.deleteFlag = val.deleteFlag || '';
    this.id = val.id || '';
    this.isActive = val.isActive || '';
    this.clientsApprovalStatus = val.clientsApprovalStatus == 0 ? 0 : val.clientsApprovalStatus || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = new Date(new Date(val.modifiedDate).setMinutes(new Date(val.modifiedDate).getMinutes() - 330)) || '';
    this.priorityDetails = val.priorityDetails ? new PriorityDetailModel(val.priorityDetails) : null;
    this.projectDetails = val.projectDetails ? new ProjectDetailModel(val.projectDetails) : '';
    this.projectId = val.projectId || '';
    this.statusDetails = val.statusDetails ? new StatusDetailModel(val.statusDetails) : null;
    this.ticketCreatedBy = val.ticketCreatedBy || '';
    this.ticketClosureDate = val.ticketClosureDate || '';
    this.ticketCost = val.ticketCost || 0 ;
    this.ticketDate = val.ticketDate || '';
    this.ticketDescription = val.ticketDescription || '';
    this.ticketNo = val.ticketNo || '';
    this.ticketPaymentStatus = val.ticketPaymentStatus || '';
    this.ticketPriorityId = val.ticketPriorityId || '';
    this.ticketStatusId = val.ticketStatusId || '';
    this.ticketTitle = val.ticketTitle || '';
    this.ticketType = val.ticketType || '';
    this.expectedClosedDate = val.expectedClosedDate || '';
    this.currency = val.currency || '';
    this.currencySymbol = val.currencySymbol || '';
    this.sendApprovalStatus = val.sendApprovalStatus || '';
    this.selected = false;
    this.clientDetails = val.clientDetails || {};
    this.cilentRevisedStatus=val.cilentRevisedStatus || '';
    this.cilentRevisedDate=val.cilentRevisedDate || '';
    this.clientsApprovedDate=val.clientsApprovedDate || '';
    this.ticketStartDate = new Date(new Date(val.ticketStartDate).setMinutes(new Date(val.ticketStartDate).getMinutes() - 330)) || '';
    this.scheduleNotes = val.scheduleNotes || '';
    this.sendApprovalDate = val.sendApprovalDate || '';
    this.lastConversation = val.lastConversation || '';
  }
}

export class UserDetail {
    companyName?: string;
    mobileNo?: Number;
    gstNo ?: string;
    companyAddress?: string;
    emailId?: string;
    firstName?: string;
    lastName?: string;
    companyContactNumber?: Number;
    logoName?: string;
    logoPath?: string;
    notificationMailId?: string;
    clientParentId: number;
    constructor(val: any) {
        if (val) {
            this.firstName = val.firstName || '';
            this.lastName = val.lastName || '';
            this.companyName = val.companyName || '';
            this.mobileNo = val.mobileNo || '';
            this.gstNo = val.gstNo || '';
            this.companyAddress = val.companyAddress || '';
            this.companyContactNumber = val.companyContactNumber || '';
            this.emailId = val.emailId || '';
            this.logoName = val.logoName || '';
            this.logoPath = val.logoPath || '';
            this.notificationMailId = val.notificationMailId || '';
            this.clientParentId = val.clientParentId || 0;
        }
    }
}

export class UserProfile {
    createdBy?: number;
    createdDate?: string;
    emailId?: string;
    id?: number;
    isActive?: number;
    loginId?: string;
    mobileNo?: string;
    modifiedBy?: string;
    modifiedDate?: string;
    userDetails?: any;
    userGroupId?: number;
    userRoleId?: number;
    userTypeId?: number;
    constructor(val: any) {
    this.createdBy = val.createdBy || 0;
    this.createdDate = val.createdDate || '';
    this.emailId = val.emailId || '';
    this.id = val.id || 0;
    this.isActive = val.isActive || 0;
    this.loginId = val.loginId || '';
    this.mobileNo = val.mobileNo || '';
    this.modifiedBy = val.modifiedBy || '';
    this.modifiedDate = val.modifiedDate || '';
    this.userDetails = new UserDetail(val.userDetails) || {};
    this.userGroupId = val.userGroupId || 0;
    this.userRoleId = val.userRoleId || 0;
    this.userTypeId = val.userTypeId || 0;
    }
}


import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponent } from './component/common.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export const CONTAINERS = {
  CommonComponent,
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InfiniteScrollModule],
})
export class ContainersModule {
}

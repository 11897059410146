import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { AsyncSubject } from 'rxjs/AsyncSubject';
import { handlers } from './websocket-events/websocket-events';
import { ErrorService, ErrorNotification } from './error.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/app-config.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class WebsocketService {
  url = environment.socketUrl;
  public socket: any;
  public connected = false;
  public reconnecting = false;
  public errorMessage: ErrorNotification;

  constructor(
    public errorService: ErrorService,
    public router: Router,
    private toastr: ToastrService,
    protected configService: ConfigService,
    // public cookie: CookieService
  ) {
    errorService.errorMessage.subscribe(error => {
      this.errorMessage = error;
    });
  }

  // socket manual disconnecting event
  public disConnect() {
    if (this.socket && this.socket.connected) {
      this.socket.close();
    }
  }
  // socket connection event for communicate with server socket
  public connect() {
    const subj = new AsyncSubject<boolean>();
    // if (this.socket && this.socket.connected) {
      subj.next(true);
      subj.complete();
      return subj;
    // }

    // connect the server url by using socket-io-client
    // this.socket = io.connect(this.url);

    // trigger the listener
    // this.addSocketListeners(subj);
    // return subj;
  }
  // get the instant messages via observer
  // public typeIndication = () => {
  //   return Observable.create((observer) => {
  //     console.log('observing');
  //     this.socket.on('typing', (message) => { // socket send message event on here (to get instant message)
  //       console.log('observing-msg');
  //       console.log(message, 'test type');
  //       observer.next(message);
  //     });
  //   });
  // }
  // get the instant messages via observer
  public getMessages = () => {
    return Observable.create((observer) => {
      this.socket.on('send-message', (message) => { // socket send message event on here (to get instant message)
        observer.next(message);
      });
    });
  }

  // get the instant messages via observer
  public test = (serverId, userName) => {
    // console.log('heelo', this.socket);
    // console.log('heelo', io.Socket);
    const message = {
      message: 'is typing....',
      name: userName,
      server_id: serverId,
    };
      this.socket.emit('typing', message);
  }

  // send message event
  public sendMessage = (messageString, channelId, serverId) => {
    console.log('mmm1', messageString);
    console.log('mmm2', channelId);
    console.log('mmm3', serverId);
    
    const message = {
      message: messageString,
      channel_id: channelId,
      server_id: serverId,
    };
    this.socket.emit('send-message', message); // emit send message event to server via socket
  }

  // get all initial messages from server
  public getAllMessages = (serverId) => {
    const chatMeassageRequestParam = {
      server_id: serverId,
      token: localStorage.getItem('clientToken'),
    };
    this.socket.emit('chat-message-request', chatMeassageRequestParam); // emit chat-message-request event to server via socket
  }

  // socket listener event
  private addSocketListeners(subj: AsyncSubject<boolean>) {
    this.socket.on('connect', async (data: any) => { // socket connection done here
      if (this.reconnecting) {
        if (this.errorMessage && this.errorMessage.id === 'lost-connection') {
          this.errorService.errorMessage.next(undefined);
        }
        this.reconnectToChannels('1');
      }
      this.connected = true;
      this.reconnecting = false;
      subj.next(true);
      subj.complete();
    });
    this.socket.on('disconnect', (reason: string) => { // socket connection lost here
      // this.toastr.error('Error', 'Lost connection to server. Attempting to reconnect.');
      subj.next(false);
      subj.complete();
      this.connected = false;
      this.reconnecting = true;
    });
    this.socket.on('error', (data: any) => { // socket error occured here
      if (data === 'No token provided' || data === 'Invalid token' || data === 'User not found') {
        subj.next(false);
        subj.complete();
      }
    });
    for (const addHandler of Object.values(handlers)) {
      addHandler(this);
    }
  }

  // handle socket events
  async awaitNextEvent(eventName: string, timeOut: number) {
    return await new Promise((resolve, reject) => {
      let resolved = false;
      let error = false;
      const onComplete = (data: any) => {
        resolved = true;
        return resolve(data);
      };
      const onError = () => {
        resolved = true;
        error = true;
      };
      this.socket.once(eventName, onComplete);
      this.socket.once('soft-error', onError);
      setTimeout(() => {
        if (!error) {
          this.socket.removeListener('soft-error', onError);
        }
        if (!resolved) {
          this.socket.removeListener(eventName, onComplete);
          reject(new Error('Request timed out'));
        }
      }, timeOut);
    });
  }

  // socket reconnect event ( it will trigger when the socket is disconnected)
  private reconnectToChannels(id) {
    this.socket.emit('join-server', id); // emit join-server event to server via socket
  }
}

export class UpdateUserDetailRequest {
    id?: string;
    firstName?: Number;
    lastName ?: string;
    mobileNo?: string;
    emailId?: string;
    companyName?: string;
    companyAddress?: string;
    companyContactNumber?: Number;
    image?: string;
    contactPerson?: Number;
    gstNo ?: string;
    location?: string;
    notes?: string;
    sendOTPflag?: number
    constructor(val: any) {
        this.id = val.id || '';
        this.firstName = val.firstname || '';
        this.lastName = val.lastname || '';
        this.companyName = val.companyname || '';
        this.companyContactNumber = val.companycontact || '';
        this.mobileNo = val.mobile || '';
        this.gstNo = val.gst || '';
        this.image = val.profileimgs === 0 ? 0 : val.profileimgs || '';
        this.companyAddress = val.address || '';
        this.emailId = val.emailId || '';
        this.sendOTPflag = val.sendOTPflag
    }
}


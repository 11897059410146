import { Action } from '@ngrx/store';
import { type } from 'src/app/shared/utility';

export const ActionTypes = {

    GET_NOTIFICATION_COUNT: type('[notification] notification count'),
    GET_NOTIFICATION_COUNT_SUCCESS: type('[notification] notification count Success'),
    GET_NOTIFICATION_COUNT_FAIL: type('[notification] notification count Fail'),

    GET_NOTIFICATION_LIST: type('[notification] notification list'),
    GET_NOTIFICATION_LIST_SUCCESS: type('[notification] notification list Success'),
    GET_NOTIFICATION_LIST_FAIL: type('[notification] notification list Fail'),

    GET_NOTIFICATION_DETAIL: type('[notification] notification detail'),
    GET_NOTIFICATION_DETAIL_SUCCESS: type('[notification] notification detail Success'),
    GET_NOTIFICATION_DETAIL_FAIL: type('[notification] notification detail Fail'),

    CHANGE_NOTIFICATION_STATUS: type('[notification] notification status'),
    CHANGE_NOTIFICATION_STATUS_SUCCESS: type('[notification] notification status Success'),
    CHANGE_NOTIFICATION_STATUS_FAIL: type('[notification] notification status Fail'),

    NOTIFICATION_UNREAD: type('[notification] notification unread'),
    NOTIFICATION_UNREAD_SUCCESS: type('[notification] notification unread Success'),
    NOTIFICATION_UNREAD_FAIL: type('[notification] notification unread Fail'),
};

/**
 * get notification count actions.
 */
export class GetNotificationCount implements Action {
    type = ActionTypes.GET_NOTIFICATION_COUNT;

    constructor(public payload: any) {
    }
}

export class GetNotificationCountSuccess implements Action {
    type = ActionTypes.GET_NOTIFICATION_COUNT_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetNotificationCountFail implements Action {
    type = ActionTypes.GET_NOTIFICATION_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get notification actions.
 */

export class GetNotificationList implements Action {
    type = ActionTypes.GET_NOTIFICATION_LIST;

    constructor(public payload: any) {
    }
}

export class GetNotificationListSuccess implements Action {
    type = ActionTypes.GET_NOTIFICATION_LIST_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetNotificationListFail implements Action {
    type = ActionTypes.GET_NOTIFICATION_LIST_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get notification detail actions.
 */
export class GetNotificationDetail implements Action {
    type = ActionTypes.GET_NOTIFICATION_DETAIL;

    constructor(public payload: any) {
    }
}

export class GetNotificationDetailSuccess implements Action {
    type = ActionTypes.GET_NOTIFICATION_DETAIL_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetNotificationDetailFail implements Action {
    type = ActionTypes.GET_NOTIFICATION_DETAIL_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * change notification status actions.
 */
export class ChangeNotificationStatus implements Action {
    type = ActionTypes.CHANGE_NOTIFICATION_STATUS;

    constructor(public payload: any) {
    }
}

export class ChangeNotificationStatusSuccess implements Action {
    type = ActionTypes.CHANGE_NOTIFICATION_STATUS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class ChangeNotificationStatusFail implements Action {
    type = ActionTypes.CHANGE_NOTIFICATION_STATUS_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * change mark notification as unread actions.
 */
export class NotificationUnread implements Action {
    type = ActionTypes.NOTIFICATION_UNREAD;

    constructor(public payload: any) {
    }
}

export class NotificationUnreadSuccess implements Action {
    type = ActionTypes.NOTIFICATION_UNREAD_SUCCESS;

    constructor(public payload: any) {
    }
}

export class NotificationUnreadFail implements Action {
    type = ActionTypes.NOTIFICATION_UNREAD_FAIL;

    constructor(public payload: any) {
    }
}


export type Actions =

    | GetNotificationCount
    | GetNotificationCountSuccess
    | GetNotificationCountFail

    | GetNotificationList
    | GetNotificationListSuccess
    | GetNotificationListFail

    | GetNotificationDetail
    | GetNotificationDetailSuccess
    | GetNotificationDetailFail

    | ChangeNotificationStatus
    | ChangeNotificationStatusSuccess
    | ChangeNotificationStatusFail

    | NotificationUnread
    | NotificationUnreadSuccess
    | NotificationUnreadFail
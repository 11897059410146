import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {
  transform(items: any[], field: string): any[] {
    if (!items) {
      return [];
    }
    if (field) {
      items.sort((a, b) => <any>new Date(b[field]).getTime() - <any>new Date(a[field]).getTime());

    } else {
      items.sort((a, b) => a > b ? 1 : -1);
    }
    return items;
  }

}

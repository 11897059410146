import { Action } from '@ngrx/store';
import { UpdateUserDetailRequest } from 'src/app/layout/common/models/update.profile.request';
import { type } from 'src/app/shared/utility';

export const ActionTypes = {

    GET_USER_PROFILE: type('[settings] user profile'),
    GET_USER_PROFILE_SUCCESS: type('[settings] user profile success'),
    GET_USER_PROFILE_FAIL: type('[settings] user profile Fail'),

    UPDATE_USER_PROFILE: type('[settings] update user profile'),
    UPDATE_USER_PROFILE_SUCCESS: type('[settings] update user profile success'),
    UPDATE_USER_PROFILE_FAIL: type('[settings] update user profile Fail'),

    SUB_USER_LIST: type('[settings] sub user list'),
    SUB_USER_LIST_SUCCESS: type('[settings] sub user list success'),
    SUB_USER_LIST_FAIL: type('[settings] sub user list Fail'),

    CREATE_SUB_USER: type('[settings] create sub user'),
    CREATE_SUB_USER_SUCCESS: type('[settings] create sub user success'),
    CREATE_SUB_USER_FAIL: type('[settings] create sub user Fail'),

    UPDATE_SUB_USER: type('[settings] update sub user'),
    UPDATE_SUB_USER_SUCCESS: type('[settings] update sub user success'),
    UPDATE_SUB_USER_FAIL: type('[settings] update sub user Fail'),

    UPDATE_SUB_USER_STATUS: type('[settings] update sub user status'),
    UPDATE_SUB_USER_STATUS_SUCCESS: type('[settings] update sub user status success'),
    UPDATE_SUB_USER_STATUS_FAIL: type('[settings] update sub user status Fail'),

    DELETE_SUB_USER: type('[settings] delete sub user'),
    DELETE_SUB_USER_SUCCESS: type('[settings] delete sub user success'),
    DELETE_SUB_USER_FAIL: type('[settings] delete sub user Fail'),

};

/**
 * get user profile actions.
 */
export class GetUserProfileAction implements Action {
    type = ActionTypes.GET_USER_PROFILE;

    constructor() {
    }
}

export class GetUserProfileSuccessAction implements Action {
    type = ActionTypes.GET_USER_PROFILE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetUserProfileFail implements Action {
    type = ActionTypes.GET_USER_PROFILE_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get user profile actions.
 */
export class UpdateUserProfileAction implements Action {
    type = ActionTypes.UPDATE_USER_PROFILE;

    constructor(public payload: UpdateUserDetailRequest) {
    }
}

export class UpdateUserProfileSuccess implements Action {
    type = ActionTypes.UPDATE_USER_PROFILE_SUCCESS;

    constructor(public payload: any) {
    }
}

export class UpdateUserProfileFail implements Action {
    type = ActionTypes.UPDATE_USER_PROFILE_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * sub user list.
 */
export class SubuserListAction implements Action {
    type = ActionTypes.SUB_USER_LIST;

    constructor(public payload: any) {
    }
}

export class SubuserListSuccess implements Action {
    type = ActionTypes.SUB_USER_LIST_SUCCESS;

    constructor(public payload: any) {
    }
}

export class SubuserListFail implements Action {
    type = ActionTypes.SUB_USER_LIST_FAIL;

    constructor(public payload: any) {
    }
}


/**
 * CreateSubuser
 */
export class CreateSubuserAction implements Action {
    type = ActionTypes.CREATE_SUB_USER;

    constructor(public payload: any) {
    }
}

export class CreateSubuserSuccess implements Action {
    type = ActionTypes.CREATE_SUB_USER_SUCCESS;

    constructor(public payload: any) {
    }
}

export class CreateSubuserFail implements Action {
    type = ActionTypes.CREATE_SUB_USER_FAIL;

    constructor(public payload: any) {
    }
}

/**
 *UpdateSubuserAction
 */
export class UpdateSubuserAction implements Action {
    type = ActionTypes.UPDATE_SUB_USER;

    constructor(public payload: any) {
    }
}

export class UpdateSubuserSuccess implements Action {
    type = ActionTypes.UPDATE_SUB_USER_SUCCESS;

    constructor(public payload: any) {
    }
}

export class UpdateSubuserFail implements Action {
    type = ActionTypes.UPDATE_SUB_USER_FAIL;

    constructor(public payload: any) {
    }
}

/**
 *UpdateSubuserStatusAction
 */
 export class UpdateSubuserStatusAction implements Action {
    type = ActionTypes.UPDATE_SUB_USER_STATUS;

    constructor(public payload: any) {
    }
}

export class UpdateSubuserStatusSuccess implements Action {
    type = ActionTypes.UPDATE_SUB_USER_STATUS_SUCCESS;

    constructor(public payload: any) {
    }
}

export class UpdateSubuserStatusFail implements Action {
    type = ActionTypes.UPDATE_SUB_USER_STATUS_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * DeleteSubuserAction
 */
export class DeleteSubuserAction implements Action {
    type = ActionTypes.DELETE_SUB_USER;

    constructor(public payload: any) {
    }
}

export class DeleteSubuserSuccess implements Action {
    type = ActionTypes.DELETE_SUB_USER_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DeleteSubuserFail implements Action {
    type = ActionTypes.DELETE_SUB_USER_FAIL;

    constructor(public payload: any) {
    }
}

export type Actions =

    | GetUserProfileAction
    | GetUserProfileSuccessAction
    | GetUserProfileFail

    | UpdateUserProfileAction
    | UpdateUserProfileSuccess
    | UpdateUserProfileFail

    | SubuserListAction
    | SubuserListSuccess
    | SubuserListFail

    | CreateSubuserAction
    | CreateSubuserSuccess
    | CreateSubuserFail

    | UpdateSubuserAction
    | UpdateSubuserSuccess
    | UpdateSubuserFail

    | UpdateSubuserStatusAction
    | UpdateSubuserStatusSuccess
    | UpdateSubuserStatusFail

    | DeleteSubuserAction
    | DeleteSubuserSuccess
    | DeleteSubuserFail
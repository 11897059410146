import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../app.state';
import * as authActions from './actions/auth.action';
import {
  loginLoading,
  loginLoaded,
  loginFailed,
  loginSuccess,
  loginFailure, forgetPasswordLoading, forgetPasswordLoaded, forgetPasswordFailed, resetPasswordLoading,
  resetPasswordLoaded, resetPasswordFailed, checkUser, checkUserFailed, checkUserLoaded, checkUserLoading, verifyOtpSuccess, verifyOtpFailure, verifyOtpLoading, verifyOtpLoaded, verifyOtpFailed, resendOtpSuccess, resendOtpFailure, resendOtpLoading, resendOtpLoaded, resendOtpFailed
} from './reducer/selector';
import { Subscription } from 'rxjs/index';
import { LoginRequestModel } from './models/login-request.model';
import { WebLoginRequestModel } from './models/web-login-request.model';
import { VerifyOtpRequestModel } from './models/verify-otp-request.model';

@Injectable()
export class AuthSandbox {

  public getLoginSuccess$ = this.appState.select(loginSuccess);
  public getLoginFailure$ = this.appState.select(loginFailure);
  public loginLoading$ = this.appState.select(loginLoading);
  public loginLoaded$ = this.appState.select(loginLoaded);
  public loginFailed$ = this.appState.select(loginFailed);

  public forgotPasswordLoading$ = this.appState.select(forgetPasswordLoading);
  public forgotPasswordLoaded$ = this.appState.select(forgetPasswordLoaded);
  public forgotPasswordFailed$ = this.appState.select(forgetPasswordFailed);

  public resetPasswordLoading$ = this.appState.select(resetPasswordLoading);
  public resetPasswordLoaded$ = this.appState.select(resetPasswordLoaded);
  public resetPasswordFailed$ = this.appState.select(resetPasswordFailed);

  public checkUser$ = this.appState.select(checkUser);
  public checkUserLoading$ = this.appState.select(checkUserLoading);
  public checkUserLoaded$ = this.appState.select(checkUserLoaded);
  public checkUserFailed$ = this.appState.select(checkUserFailed);



  public verifyOtpSuccess$ = this.appState.select(verifyOtpSuccess);
  public verifyOtpFailure$ = this.appState.select(verifyOtpFailure);
  public verifyOtpLoading$ = this.appState.select(verifyOtpLoading);
  public verifyOtpLoaded$ = this.appState.select(verifyOtpLoaded);
  public verifyOtpFailed$ = this.appState.select(verifyOtpFailed);


  public resendOtpSuccess$ = this.appState.select(resendOtpSuccess);
  public resendOtpFailure$ = this.appState.select(resendOtpFailure);
  public resendOtpLoading$ = this.appState.select(resendOtpLoading);
  public resendOtpLoaded$ = this.appState.select(resendOtpLoaded);
  public resendOtpFailed$ = this.appState.select(resendOtpFailed);

  private subscriptions: Array<Subscription> = [];

  constructor(protected appState: Store<store.AppState>) {

  }

  public doLogin(params) {
    this.appState.dispatch(new authActions.DoLoginAction(params));
  }
  public doWebLogin(params) {
    this.appState.dispatch(new authActions.DoWebLoginAction(new WebLoginRequestModel(params)));
  }
  public forgetPassword(params) {
    this.appState.dispatch(new authActions.ForgetPassword(params));
  }
  public resetPassword(params) {
    this.appState.dispatch(new authActions.ResetPassword(params));
  }
  public checkUser(params) {
    this.appState.dispatch(new authActions.CheckUser(params));
  }
  public doVerifyOtp(params){
    this.appState.dispatch(new authActions.VerifyOtpAction(new VerifyOtpRequestModel(params)));
  }
}

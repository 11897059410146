import { Injectable } from '@angular/core';
import { Api } from '../providers/api/api';
import { ResponseInterface } from '../shared/interfaces/interface';
import { Observable } from 'rxjs';

@Injectable()

export class SettingsService extends Api {

  private URL: string = this.getBaseUrl();
  public baseLink: string = this.getBaseLink();

  getUserProfile(): Observable<any> {
    return this.http.get<ResponseInterface>(this.URL +'api/auth/user-profile');
  }
  updateUserProfile(params): Observable<any> {
    return this.http.post<ResponseInterface>(this.URL +'api/client/update-client/' + params.id, params);
  }
  CreateSubuser(params): Observable<any> {
    return this.http.post(this.URL + 'api/client/create-client', params);
  }
  updateSubuser(params): Observable<any> {
    return this.http.post(this.URL + 'api/client/update-client-for-admin/' + params.id, params);
  }
  updateSubuserStatus(params: any): Observable<any> {
    return this.http.put(this.URL + 'api/client/update-client/status/' + params.id + '/'+ params.activeId, {});
  }
  subuserList(params): Observable<any>{
    return this.http.get(this.URL +'api/client/sub-client',{params: params})
  }
  deleteSubuser(params): Observable<any>{
    return this.http.delete(this.URL + 'api/client/delete-client/' + params.id);
  }
}

import * as actions from '../actions/openticket.action';
import { OpenTicketState, openticketStateRecord } from './openticket.state';


export const initialState: OpenTicketState = new openticketStateRecord() as unknown as OpenTicketState;

export function reducer(state = initialState, { type, payload }: any): OpenTicketState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get tickets reducer case function
     */
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

// export const getTicketsList = (state: ActivityState) => state.ticketsList;
// export const getTicketsListLoading = (state: ActivityState) => state.ticketsListLoading;
// export const getTicketsListLoaded = (state: ActivityState) => state.ticketsListLoaded;
// export const getTicketsListFailed = (state: ActivityState) => state.ticketsListFailed;

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonSandbox } from 'src/app/layout/common/common.sandbox';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() priceFilter: boolean;
  @Input() dateFilter: boolean;
  @Input() projectFilter: boolean;
  @Input() searchFilter: boolean;
  @Input() priceRangeFilter: boolean;
  @Input() paymentStatusFilter: boolean;
  @Input() amountFilter: boolean;
  @Input() invoiceFilter: boolean;
  @Input() orderFilter: boolean;
  @Input() NotificationFilter: boolean;
  @Input() priceRangeList;
  @Input() paymentStatus;
  @Input() tabFilter: string;
  @Input() notificationStatus;
  @Input() filterRemoveOption: boolean;
  @Output() filterData = new EventEmitter<any>();
  _obj = Object;
  filterForm: FormGroup;
  clientId = JSON.parse(localStorage.getItem('clientDetail')).id;
  submitted: boolean = false;
  today: any;
  filterObjItem: any = {};
  constructor(private fb: FormBuilder, private datePipe: DatePipe, public commonSandbox: CommonSandbox) { }

  ngOnInit(): void {
    this.initFilterForm();
    const dateVal = this.datePipe.transform(new Date(), 'dd/MM/yyyy').split('/');
    this.today = {day: +dateVal[0], month: +dateVal[1], year: +dateVal[2]};

  }

  initFilterForm(){
    this.filterForm = this.fb.group({})
    if(this.dateFilter) {
      this.filterForm.addControl('fromDate', this.fb.control(''));
      this.filterForm.addControl('toDate', this.fb.control(''));
    }
    if(this.projectFilter) {
      this.projectList();
      this.filterForm.addControl('projectName', this.fb.control(null));
    }
    if(this.priceFilter) {
      this.filterForm.addControl('minAmount', this.fb.control(''));
      this.filterForm.addControl('maxAmount', this.fb.control(''));
    }
    if(this.searchFilter) {
      this.filterForm.addControl('search', this.fb.control(''));
    }
    if(this.priceRangeFilter) {
      this.filterForm.addControl('priceRange', this.fb.control(null));
    }
    if(this.paymentStatusFilter) {
      this.filterForm.addControl('paymentStatus', this.fb.control(null));
    }
    if(this.amountFilter) {
      this.filterForm.addControl('amount', this.fb.control(''));
    }
    if(this.invoiceFilter) {
      this.filterForm.addControl('invoiceNo', this.fb.control(''));
    }
    if(this.orderFilter) {
      this.filterForm.addControl('orderNo', this.fb.control(''));
    }
    if(this.notificationStatus) {
      this.filterForm.addControl('notificationStatus', this.fb.control(null));
    }
  }
  projectList() {
    this.commonSandbox.getProjectList({ clientId: this.clientId });
  }
  applyFilter() {
    this.submitted = true;
    if(this.filterForm.value.fromDate && !this.filterForm.value.toDate || !this.filterForm.value.fromDate && this.filterForm.value.toDate
      || !this.filterForm.value.minAmount && this.filterForm.value.maxAmount || this.filterForm.value.minAmount && !this.filterForm.value.maxAmount) {
      return
    }
    else {
      this.filterForm.value.fromDate = this.filterForm.value.fromDate ?  this.dataFormat(this.filterForm.value.fromDate) : '';
      this.filterForm.value.toDate = this.filterForm.value.toDate ?  this.dataFormat(this.filterForm.value.toDate) : '';
      if(this.tabFilter) {
        this.filterForm.value.tabFilter = this.tabFilter;
      }
      if(this.filterRemoveOption && Object.values(this.filterForm.value).some(value=> value)){
          Object.entries(this.filterForm.value).map(([key,value])=>{
            if(value && !key.includes('Date')){
              this.filterObjItem[key] = (value == 1 ? 'Read' : value == 0 ? 'Unread' : value)
            }
            if(value && key.includes('Date')){
              this.filterObjItem[key] = value.toString().split('/').reverse().join('/');
            }
          })
      }
      this.filterData.emit(this.filterForm.value);
      this.submitted = false;
    }
  }
  clearFilter() {
    this.filterForm.reset();
    this.filterObjItem = {};
    if(this.tabFilter) {
      this.filterForm.value.tabFilter = this.tabFilter;
    }
    this.filterData.emit(this.filterForm.value);
  }
  clearFilterItem(key){
    this.filterForm.controls[key].reset();
    delete this.filterObjItem[key];
    this.applyFilter();
  }
  dataFormat(date) {
    return date.year + '/' + date.month + '/' + date.day;
  }
}

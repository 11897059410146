import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Config } from 'protractor/built/config';
import { AuthService } from 'src/app/auth/auth.service';
import { SettingsSandbox } from 'src/app/settings/settings.sandbox';

@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.scss']
})
export class VerifyotpComponent implements OnInit {

  updatedDetails: any;
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
  };
  @ViewChild("ngOtpInput") ngOtpInput: NgOtpInputComponent | undefined;
  otp: string;
  submitted: boolean = false;

  constructor(private activeModal: NgbActiveModal, private authService: AuthService, private settingsSandbox: SettingsSandbox) { }

  ngOnInit(): void {
  }
  
  onOtpChange(event: string): void {
    this.otp = event;
    this.submitted = true;
  }
  otpVerify(): void {
    if (JSON.parse(JSON.stringify(this.otp)).length !== 6) {
      return;
    }
    const params: any = {};
    params.otp = this.otp;
    params.id = this.updatedDetails.id;
    this.authService.doVerifyOtp(params).subscribe(res => {
      if(res.status == 1) {
        const param: any = {
          emailId: this.updatedDetails.newEmailId,
          id: res.data.user.id
        }
        this.settingsSandbox.updateProfile(param);
        this.settingsSandbox.updateUserProfile$.subscribe(res =>{
          if(res.status == 1){
            this.activeModal.close(res)
          }
        })
      }
    },error => {
        this.otp = "";
        this.ngOtpInput.setValue("");
    });
  }
  resendOtp(): void {
    if (this.ngOtpInput) {
      this.otp = "";
      this.ngOtpInput.setValue("");
      const param: any = {};
      param.newEmailId = this.updatedDetails.newEmailId;
      param.oldEmailId = this.updatedDetails.oldEmailId;
      this.authService.doResendOtp(param).subscribe(res=>{})
    }
  }
  public dismiss() {
    this.activeModal.close();
  }

}

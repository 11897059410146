import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class SharedEventEmitterService {

  private pendingAmtShow = new BehaviorSubject(false);
  private chatEnable = new BehaviorSubject('');
   chatEnbaleStatus = this.chatEnable.asObservable();

   pendingAmt = this.pendingAmtShow.asObservable();

  constructor() { }

  pendingAmtShows(status: boolean) {
    this.pendingAmtShow.next(status);
  }
  chatEnables(status: string) {
    this.chatEnable.next(status);
  }
}

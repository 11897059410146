import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility';
import { ResponseInterface } from '../../../shared/interfaces/interface';
import { PaymentHistoryModel } from '../models/payment-history.model';

export const ActionTypes = {
    GET_PAYMENT_HISTORY: type('[payment] payment history'),
    GET_PAYMENT_HISTORY_SUCCESS: type('[payment] payment history Success'),
    GET_PAYMENT_HISTORY_FAIL: type('[payment] payment history Fail'),

    GET_PAYMENT_HISTORY_COUNT: type('[payment] payment history count'),
    GET_PAYMENT_HISTORY_COUNT_SUCCESS: type('[payment] payment history count Success'),
    GET_PAYMENT_HISTORY_COUNT_FAIL: type('[payment] payment history count Fail'),

    GET_PAYMENT_REQUESTS: type('[payment] payment requests count'),
    GET_PAYMENT_REQUESTS_SUCCESS: type('[payment] payment requests count Success'),
    GET_PAYMENT_REQUESTS_FAIL: type('[payment] payment requests count Fail'),

    GET_ORDER_LIST_COUNT: type('[payments] order list count'),
    GET_ORDER_LIST_COUNT_SUCCESS: type('[payments] order list count Success'),
    GET_ORDER_LIST_COUNT_FAIL: type('[payments] order list count Fail'),

    GET_NEW_ORDER_LIST_COUNT: type('[payments]new order list count'),
    GET_NEW_ORDER_LIST_COUNT_SUCCESS: type('[payments]new order list count Success'),
    GET_NEW_ORDER_LIST_COUNT_FAIL: type('[payments]new order list count Fail'),

    CLIENT_TICKET_ACCEPT: type('[Ticket] ticket status change'),
    CLIENT_TICKET_ACCEPT_SUCESS: type('[Ticket] ticket status change Success'),
    CLIENT_TICKET_ACCEPT_FAIL: type('[Ticket] ticket status change Fail'),

    RECENT_TICKETS: type('[RECENT_TICKETS] RECENT_TICKETS'),
    RECENT_TICKETS_SUCESS: type('[RECENT_TICKETS] RECENT_TICKETS Success'),
    RECENT_TICKETS_FAIL: type('[RECENT_TICKETS] RECENT_TICKETS Fail'),

    TICKET_STATUS_COUNT: type('[dashboard] ticket status count'),
    TICKET_STATUS_COUNT_SUCCESS: type('[dashboard] ticket status count Success'),
    TICKET_STATUS_COUNT_FAIL: type('[dashboard] ticket status count Fail'),

};


/**
 * get payment history actions.
 */
export class GetPaymentHistoryAction implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY;

    constructor(public payload: PaymentHistoryModel) {
    }
}

export class GetPaymentHistorySuccess implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPaymentHistoryFail implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * get payment requests actions.
 */
export class GetPaymentRequestsAction implements Action {
    type = ActionTypes.GET_PAYMENT_REQUESTS;

    constructor(public payload: PaymentHistoryModel) {
    }
}

export class GetPaymentRequestsSuccess implements Action {
    type = ActionTypes.GET_PAYMENT_REQUESTS_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPaymentRequestsFail implements Action {
    type = ActionTypes.GET_PAYMENT_REQUESTS_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * get payment history count actions.
 */
export class recentTickets implements Action {
    type = ActionTypes.RECENT_TICKETS;

    constructor(public payload: PaymentHistoryModel) {
    }
}

export class recentTicketsSuccess implements Action {
    type = ActionTypes.RECENT_TICKETS_SUCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class recentTicketsFail implements Action {
    type = ActionTypes.RECENT_TICKETS_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * Payments Order list actions.
 */
export class GetOrderListsCount implements Action {
    type = ActionTypes.GET_ORDER_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class GetOrderListCountSuccess implements Action {
    type = ActionTypes.GET_ORDER_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetOrderListCountFailure implements Action {
    type = ActionTypes.GET_ORDER_LIST_COUNT_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * Payments new Order list actions.
 */
export class GetNewOrderListsCount implements Action {
    type = ActionTypes.GET_NEW_ORDER_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class GetNewOrderListCountSuccess implements Action {
    type = ActionTypes.GET_NEW_ORDER_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetNewOrderListCountFailure implements Action {
    type = ActionTypes.GET_ORDER_LIST_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

/**
 * CLIENT TICKET ACCEPT OR REJECT ACTIONS.
 */
export class TicketAcceptReject implements Action {
    type = ActionTypes.CLIENT_TICKET_ACCEPT;

    constructor(public id: any, public accetype: any) {
    }
}

export class TicketAcceptRejectSuccess implements Action {
    type = ActionTypes.CLIENT_TICKET_ACCEPT_SUCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class TicketAcceptRejectFailure implements Action {
    type = ActionTypes.CLIENT_TICKET_ACCEPT_FAIL;

    constructor(public payload: any) {
    }
}

export class GetPaymentHistoryCountAction implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY_COUNT;

    constructor(public payload: PaymentHistoryModel) {
    }
}

export class GetPaymentHistoryCountSuccess implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class GetPaymentHistoryCountFail implements Action {
    type = ActionTypes.GET_PAYMENT_HISTORY_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

export class ticketStatusCount implements Action {
    type = ActionTypes.TICKET_STATUS_COUNT;

    constructor(public payload: any) {
    }
}

export class ticketStatusCountSuccess implements Action {
    type = ActionTypes.TICKET_STATUS_COUNT_SUCCESS;

    constructor(public payload: any) {
    }
}

export class ticketStatusCountFail implements Action {
    type = ActionTypes.TICKET_STATUS_COUNT_FAIL;

    constructor(public payload: any) {
    }
}
/**
 * CLIENT TICKET ACCEPT OR REJECT ACTIONS.
 */


export type Actions =


    | GetPaymentHistoryAction
    | GetPaymentHistorySuccess
    | GetPaymentHistoryFail

    | GetPaymentHistoryCountAction
    | GetPaymentHistoryCountSuccess
    | GetPaymentHistoryCountFail

    | GetPaymentRequestsAction
    | GetPaymentRequestsSuccess
    | GetPaymentRequestsFail

    | GetOrderListsCount
    | GetOrderListCountSuccess
    | GetOrderListCountFailure

    | GetNewOrderListsCount
    | GetNewOrderListCountSuccess
    | GetNewOrderListCountFailure

    | TicketAcceptReject
    | TicketAcceptRejectSuccess
    | TicketAcceptRejectFailure

    | ticketStatusCount
    | ticketStatusCountSuccess
    | ticketStatusCountFail;

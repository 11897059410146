import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { WebsocketService } from '../services/websocket.service';
import ChatServer from '../shared-interfaces/server.interface';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/filter';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ServerResolver implements Resolve<ChatServer> {
  myMethod$: Observable<any>;
  id: any;
  private myMethodSubject = new Subject<any>();

  constructor(
    private wsService: WebsocketService,
    private router: Router,
    private errorService: ErrorService,
  ) {
  this.myMethod$ = this.myMethodSubject.asObservable();
  }
  myMethod(data) {
    // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.myMethodSubject.next(data);
    this.id = String(data);
  }
  async resolve(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
  ): Promise<any> {

    // get id via router link
    const id = route.paramMap.get('id');
    if (id) {
      this.id = id;
    }
    if (this.id) {
      try {

        // waiting the connection of join server
        await this.joinServer(this.id);
      } catch (e) {
        this.errorService.errorMessage.next({
          message: 'Failed to join server.',
          duration: 2500,
          id: new Date().toUTCString(),
        });
        this.router.navigate(['']);
      }
      return {
        server: this.id,
      };
    }
  }

// join server event
  async joinServer(id: string) {
    this.wsService.socket.emit('join-server', id);
  }
}

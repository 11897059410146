import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../app.state';
import * as settingsActions from './action/settings.action';
import { createSubuser, createSubuserFailed, createSubuserLoaded, createSubuserLoading, deleteSubuser, deleteSubuserFailed, deleteSubuserLoaded, deleteSubuserLoading, subuserList, subuserListFailed, subuserListLoaded, subuserListLoading, updateSubuser, updateSubuserFailed, updateSubuserLoaded, updateSubuserLoading, updateSubuserStatus, updateSubuserStatusFailed, updateSubuserStatusLoaded, updateSubuserStatusLoading, updateUserProfile, updateUserProfileFailed, updateUserProfileLoaded, updateUserProfileLoading, userProfile, userProfileLoaded, userProfileLoading } from './reducer/settings.selector';
import { UpdateUserDetailRequest } from '../layout/common/models/update.profile.request';

@Injectable()

export class SettingsSandbox {
    public userProfile$ = this.appState.select(userProfile);
    public userProfileLoading$ = this.appState.select(userProfileLoading);
    public userProfileLoaded$ = this.appState.select(userProfileLoaded);
    public userProfileFailed$ = this.appState.select(userProfileLoaded);

    public updateUserProfile$ = this.appState.select(updateUserProfile);
    public updateUserProfileLoading$ = this.appState.select(updateUserProfileLoading);
    public updateUserProfileLoaded$ = this.appState.select(updateUserProfileLoaded);
    public updateUserProfileFailed$ = this.appState.select(updateUserProfileFailed);

    public subuserList$ = this.appState.select(subuserList);
    public subuserListLoading$ = this.appState.select(subuserListLoading);
    public subuserListLoaded$ = this.appState.select(subuserListLoaded);
    public subuserListFailed$ = this.appState.select(subuserListFailed);

    public createSubuser$ = this.appState.select(createSubuser);
    public createSubuserLoading$ = this.appState.select(createSubuserLoading);
    public createSubuserLoaded$ = this.appState.select(createSubuserLoaded);
    public createSubuserFailed$ = this.appState.select(createSubuserFailed);

    public updateSubuser$ = this.appState.select(updateSubuser);
    public updateSubuserLoading$ = this.appState.select(updateSubuserLoading);
    public updateSubuserLoaded$ = this.appState.select(updateSubuserLoaded);
    public updateSubuserFailed$ = this.appState.select(updateSubuserFailed);

    public updateSubuserStatus$ = this.appState.select(updateSubuserStatus);
    public updateSubuserStatusLoading$ = this.appState.select(updateSubuserStatusLoading);
    public updateSubuserStatusLoaded$ = this.appState.select(updateSubuserStatusLoaded);
    public updateSubuserStatusFailed$ = this.appState.select(updateSubuserStatusFailed);

    public deleteSubuser$ = this.appState.select(deleteSubuser);
    public deleteSubuserLoading$ = this.appState.select(deleteSubuserLoading);
    public deleteSubuserLoaded$ = this.appState.select(deleteSubuserLoaded);
    public deleteSubuserFailed$ = this.appState.select(deleteSubuserFailed);

    constructor(protected appState: Store<store.AppState>) {}

    public userProfile() {
        this.appState.dispatch(new settingsActions.GetUserProfileAction());
    }

    public updateProfile(params) {
        this.appState.dispatch(new settingsActions.UpdateUserProfileAction(new UpdateUserDetailRequest(params)));
    }

    public subuserList(params) {
        this.appState.dispatch(new settingsActions.SubuserListAction(params));
    }

    public createSubuser(params) {
        this.appState.dispatch(new settingsActions.CreateSubuserAction(params));
    }

    public updateSubuser(params) {
        this.appState.dispatch(new settingsActions.UpdateSubuserAction(params));
    }

    public updateSubuserStatus(params) {
        this.appState.dispatch(new settingsActions.UpdateSubuserStatusAction(params));
    }

    public deleteSubuser(params) {
        this.appState.dispatch(new settingsActions.DeleteSubuserAction(params));
    }

}
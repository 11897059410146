export class PaymentLogRequestModel {
    orderPaymentId?: number;
    paymentStatus: number;
    orderBy?: number;
    constructor(val: any) {
        this.orderPaymentId = val.id || '';
        this.paymentStatus = 3;
        this.orderBy = 2;
    }
}

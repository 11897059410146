
export class RemainderModel {
    comments?: string;
    createdDate: any;
    orderPaymentId: number;
    id: number;
    constructor(val: any) {
   this.comments = val.comments;
   this.createdDate = new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) || '';
  this.orderPaymentId = val.orderPaymentId || '';
  this.id = val.id;
    }
}


export class PaymentDetailResponse {
    amount?: Number;
    bankName?: string;
    cancelledDate?: string;
    clientId?: number;
    comments?: string;
    createdBy?: string;
    createdDate?: any;
    deleteFlag?: string;
    gatewayResponse?: string;
    id?: string;
    isActive?: string;
    modifiedBy?: string;
    modifiedDate?: string;
    netAmount?: Number;
    orderDetails?: any;
    invoiceDetails?: any;
    paymentDate?: string;
    overDueByDays?: string;
    paymentRemainderDetails: any;
    paymentDueDate?: string;
    overDueStatus?: Number;
    paidDate?: string;
    paymentMode?: Number;
    paymentStatus?: string;
    taxAmount?: Number;
    transactionId?: string;
    depositDate: string;
    transactionDetails?: string;
    orderId?: number;
    paymentType?: number;
    currencySymbol: any;
    currency: any;

    constructor(val: any) {
      this.amount = val.amount || '';
      this.bankName = val.bankName || '';
      this.cancelledDate = val.cancelledDate || '';
      this.clientId = val.clientId || 0;
      this.comments = val.comments || '';
      this.createdBy = val.createdBy || '';
      this.createdDate = new Date(new Date(val.createdDate).setMinutes(new Date(val.createdDate).getMinutes() - 330)) || '';
      this.deleteFlag = val.deleteFlag || '';
      this.gatewayResponse = val.gatewayResponse || '';
      this.id = val.id || '';
      this.isActive = val.isActive || '';
      this.modifiedBy = val.modifiedBy || '';
      this.modifiedDate = val.modifiedDate || '';
      this.netAmount = val.netAmount || '';
      this.orderDetails = val.orderDetails || '';
      this.paymentDueDate = val.paymentDueDate || '';
      this.paidDate = val.paidDate || '';
      this.invoiceDetails = val.invoiceDetails || '';
      this.paymentDate = val.paymentDate || '';
      this.overDueByDays = val.overDueByDays || '';
      if (val.paymentRemainderDetails) {
        this.paymentRemainderDetails = val.paymentRemainderDetails.map(list => {
          const paymentDetails = new RemainderModel(list);
          return paymentDetails;
        });
      this.overDueStatus = val.overDueStatus || '';
      this.paymentMode = val.paymentMode || '';
      this.paymentStatus = val.paymentStatus || '';
      this.taxAmount = val.taxAmount || '';
      this.transactionId = val.transactionId || '';
      this.depositDate = val.depositDate || '';
      this.transactionDetails = val.transactionDetails || '';
      this.orderId = val.orderId || 0;
      this.currencySymbol = val.currencySymbol || '';
      this.currency = val.currency || '';
      this.paymentType = val.paymentType || 0;

    }
  }
}

import * as actions from '../actions/closed.action';
import { ClosedState, closedStateRecord } from './closed.state';


export const initialState: ClosedState = new closedStateRecord() as unknown as ClosedState;

export function reducer(state = initialState, { type, payload }: any): ClosedState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get tickets reducer case function
     */
    default: {
      return state;
    }
  }
}

/**
 * export values
 */

// export const getTicketsList = (state: ActivityState) => state.ticketsList;
// export const getTicketsListLoading = (state: ActivityState) => state.ticketsListLoading;
// export const getTicketsListLoaded = (state: ActivityState) => state.ticketsListLoaded;
// export const getTicketsListFailed = (state: ActivityState) => state.ticketsListFailed;

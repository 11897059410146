import { Map, Record } from 'immutable';

export interface AuthState extends Map<string, any> {

    loginsuccess: boolean;
    loginfailure: any;
    loginLoading: boolean;
    loginLoaded: boolean;
    loginFailed: boolean;


    forgetPassword: any;
    forgetPasswordfailure: boolean;
    forgetPasswordLoading: boolean;
    forgetPasswordLoaded: boolean;

    resetPassword: any;
    resetPasswordfailure: boolean;
    resetPasswordLoading: boolean;
    resetPasswordLoaded: boolean;

    checkUser: any;
    checkUserfailure: boolean;
    checkUserLoading: boolean;
    checkUserLoaded: boolean;

    verifyOtpSuccess: boolean;
    verifyOtpFailure: any;
    verifyOtpLoading: boolean;
    verifyOtpLoaded: boolean;
    verifyOtpFailed: boolean;


    resendOtpSuccess: boolean;
    resendOtpFailure: any;
    resendOtpLoading: boolean;
    resendOtpLoaded: boolean;
    resendOtpFailed: boolean;

}

export const authStateRecord = Record({
    
    loginsuccess: false,
    loginfailure: {},
    loginLoading: false,
    loginLoaded: false,
    loginFailed: false,

    forgetPassword: {},
    forgetPasswordfailure: false,
    forgetPasswordLoading: false,
    forgetPasswordLoaded: false,

    resetPassword: {},
    resetPasswordfailure: false,
    resetPasswordLoading: false,
    resetPasswordLoaded: false,


    checkUser: {},
    checkUserfailure: false,
    checkUserLoading: false,
    checkUserLoaded: false,



    verifyOtpsuccess: {},
    verifyOtpFailure: {},
    verifyOtpLoading: false,
    verifyOtpLoaded: false,
    verifyOtpFailed: false,

    resendOtpsuccess: false,
    resendOtpfailure: {},
    resendOtpLoading: false,
    resendOtpLoaded: false,
    resendOtpFailed: false

});


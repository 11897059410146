import * as actions from '../actions/dashboard.action';
import { DashboardState, dashboardStateRecord } from './dashboard.state';

export const initialState: DashboardState = new dashboardStateRecord() as unknown as DashboardState;

export function reducer(state = initialState, { type, payload }: any): DashboardState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get payment history list reducer case function
     */
    case actions.ActionTypes.GET_PAYMENT_HISTORY:

      return Object.assign({}, state, {
        paymentListLoading: true,
        paymentListLoaded: false,
        paymentListFailed: false
      });

    case actions.ActionTypes.GET_PAYMENT_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        paymentList: payload.data.paymentsList,
        paymentListLoading: false,
        paymentListLoaded: true,
        paymentListFailed: false,
      });

    case actions.ActionTypes.GET_PAYMENT_HISTORY_FAIL:

      return Object.assign({}, state, {
        paymentListLoading: false,
        paymentListLoaded: true,
        paymentListFailed: true,
      });
    /**
   * get payment requests list reducer case function
   */
    case actions.ActionTypes.GET_PAYMENT_REQUESTS:

      return Object.assign({}, state, {
      });

    case actions.ActionTypes.GET_PAYMENT_REQUESTS_SUCCESS:
      return Object.assign({}, state, {
        paymentRequestList: payload.data.paymentsList,
      });

    case actions.ActionTypes.GET_PAYMENT_REQUESTS_FAIL:

      return Object.assign({}, state, {
      });


    /**
    * get payment history count reducer case function
    */
    case actions.ActionTypes.GET_PAYMENT_HISTORY_COUNT:

      return Object.assign({}, state, {
      });

    case actions.ActionTypes.GET_PAYMENT_HISTORY_COUNT_SUCCESS:
      return Object.assign({}, state, {
        paymentHistorycount: payload.data.paymentsListCount,
      });

    case actions.ActionTypes.GET_PAYMENT_HISTORY_COUNT_FAIL:

      return Object.assign({}, state, {
      });
    /**
   * get order list count reducer case function
   */
    case actions.ActionTypes.GET_ORDER_LIST_COUNT:
      return Object.assign({}, state, {
        orderListCountLoading: true,
        orderListCountLoaded: false,
        orderListCountFailed: false
      });


    case actions.ActionTypes.GET_ORDER_LIST_COUNT_SUCCESS:
      return Object.assign({}, state, {
        orderListCount: payload.data.ordersListCount,
        orderListCountLoading: false,
        orderListCountLoaded: true,
        orderListCountFailed: false
      });


    case actions.ActionTypes.GET_ORDER_LIST_COUNT_FAIL:

      return Object.assign({}, state, {
        orderListCountLoading: false,
        orderListCountLoaded: true,
        orderListCountFailed: true
      });
    /**
   * get new order list count reducer case function
   */
    case actions.ActionTypes.GET_NEW_ORDER_LIST_COUNT:
      return Object.assign({}, state, {
        newOrderListCountLoading: true,
        newOrderListCountLoaded: false,
        newOrderListCountFailed: false
      });


    case actions.ActionTypes.GET_NEW_ORDER_LIST_COUNT_SUCCESS:
      return Object.assign({}, state, {
        newOrderListCount: payload.data.ordersListCount,
        newOrderListCountLoading: false,
        newOrderListCountLoaded: true,
        newOrderListCountFailed: false
      });


    case actions.ActionTypes.GET_NEW_ORDER_LIST_COUNT_FAIL:

      return Object.assign({}, state, {
        newOrderListCountLoading: false,
        newOrderListCountLoaded: true,
        newOrderListCountFailed: true
      });

    /**
     * ticket accept reject reducer case function
     */
    case actions.ActionTypes.CLIENT_TICKET_ACCEPT:
      return Object.assign({}, state, {
        ticketAcceptRejectLoading: true,
        ticketAcceptRejectLoaded: false,
        ticketAcceptRejectFailed: false
      });


    case actions.ActionTypes.CLIENT_TICKET_ACCEPT_SUCESS:
      return Object.assign({}, state, {
        ticketAcceptReject: payload.data,
        ticketAcceptRejectLoading: false,
        ticketAcceptRejectLoaded: true,
        ticketAcceptRejectFailed: false
      });


    case actions.ActionTypes.CLIENT_TICKET_ACCEPT_FAIL:

      return Object.assign({}, state, {
        ticketAcceptRejectLoading: false,
        ticketAcceptRejectLoaded: true,
        ticketAcceptRejectFailed: true
      });


      case actions.ActionTypes.RECENT_TICKETS:
        return Object.assign({}, state, {
          recentTicketsLoading: true,
          recentTicketsLoaded: false,
          recentTicketsFailed: false
        });
  
  
      case actions.ActionTypes.RECENT_TICKETS_SUCESS:
        return Object.assign({}, state, {
          recentTickets: payload.data,
          recentTicketsLoading: false,
          recentTicketsLoaded: true,
          recentTicketsFailed: false
        });
  
  
      case actions.ActionTypes.RECENT_TICKETS_FAIL:
  
        return Object.assign({}, state, {
          recentTicketsLoading: false,
          recentTicketsLoaded: true,
          recentTicketsFailed: true
        });

        case actions.ActionTypes.TICKET_STATUS_COUNT:
          return Object.assign({}, state, {
            ticketStatusCountLoading: true,
            ticketStatusCountLoaded: false,
            ticketStatusCountFailed: false
          });
    
    
        case actions.ActionTypes.TICKET_STATUS_COUNT_SUCCESS:
          return Object.assign({}, state, {
            ticketStatusCount: payload.data.ticketListCount,
            ticketStatusCountLoading: false,
            ticketStatusCountLoaded: true,
            ticketStatusCountFailed: false
          });
    
    
        case actions.ActionTypes.TICKET_STATUS_COUNT_FAIL:
    
          return Object.assign({}, state, {
            ticketStatusCountLoading: false,
            ticketStatusCountLoaded: true,
            ticketStatusCountFailed: true
          });
  

    default: {
      return state;
    }
  }
}

/**
 * export values
 */

export const getOrderListCount = (state: DashboardState) => state.orderListCount;
export const getOrderListCountLoading = (state: DashboardState) => state.orderListCountLoading;
export const getOrderListCountLoaded = (state: DashboardState) => state.orderListCountLoaded;
export const getOrderListCountFailed = (state: DashboardState) => state.orderListCountFailed;

export const getNewOrderListCount = (state: DashboardState) => state.newOrderListCount;
export const getNewOrderListCountLoading = (state: DashboardState) => state.newOrderListCountLoading;
export const getNewOrderListCountLoaded = (state: DashboardState) => state.newOrderListCountLoaded;
export const getNewOrderListCountFailed = (state: DashboardState) => state.newOrderListCountFailed;

export const getPaymentList = (state: DashboardState) => state.paymentList;
export const getPaymentListLoading = (state: DashboardState) => state.paymentListLoading;
export const getPaymentListLoaded = (state: DashboardState) => state.paymentListLoaded;
export const getPaymentListFailed = (state: DashboardState) => state.paymentListFailed;
export const getPaymentHistorycount = (state: DashboardState) => state.paymentHistorycount;

export const getTicketAcceptReject = (state: DashboardState) => state.ticketAcceptReject;
export const getTicketAcceptRejectLoading = (state: DashboardState) => state.ticketAcceptRejectLoading;
export const getTicketAcceptRejectLoaded = (state: DashboardState) => state.ticketAcceptRejectLoaded;
export const getTicketAcceptRejectFailed = (state: DashboardState) => state.ticketAcceptRejectFailed;
export const getPaymentRequestList = (state: DashboardState) => state.paymentRequestList;

export const recentTickets = (state: DashboardState) => state.recentTickets;
export const recentTicketsLoading = (state: DashboardState) => state.recentTicketsLoading;
export const recentTicketsLoaded = (state: DashboardState) => state.recentTicketsLoaded;
export const recentTicketsFailed = (state: DashboardState) => state.recentTicketsFailed;

export const ticketStatusCount = (state: DashboardState) => state.ticketStatusCount;
export const ticketStatusCountLoading = (state: DashboardState) => state.ticketStatusCountLoading;
export const ticketStatusCountLoaded = (state: DashboardState) => state.ticketStatusCountLoaded;
export const ticketStatusCountFailed = (state: DashboardState) => state.ticketStatusCountFailed;



